import axios from 'axios';
import config from '../config';
import { IDomain } from '../interface';

export async function getDomain(fqdn: string, accessToken: string): Promise<IDomain> {
  const url = new URL(`/domain/${fqdn}`, `${config.api.endpoint}`);
  // const url = new URL(`/domain/${fqdn}`, `${process.env.REACT_APP_API_LOCAL_ENDPOINT}`);
  const response = await axios.get<IDomain>(url.href, { headers: { Authorization: `Bearer ${accessToken}` } });
  return response.data;
}
