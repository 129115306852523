import config from '../config';
import axios from 'axios';

export async function createUnionPay(accessToken: string, orderId: string) {
  const url = new URL(`/payment/${orderId}/union`, `${config.api.endpoint}`);
  const response = await axios.post(url.href, undefined, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });
  return response.data;
}
