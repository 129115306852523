import {Icon} from '@iconify/react';
import {motion} from 'framer-motion';
import {Link} from 'react-router-dom';
import {useState} from 'react';
import IotexLogo from '../../static/iotex_icon.svg'

interface Props {
  name: string;
  tld: string;
  index: number;
  chainID:number
  owner?: string;
  default?: boolean;
  callback?;
}


export const chainIcon={
  5:<Icon icon="cryptocurrency:eth" color="blue"  className="h-12 w-auto" />,
  66:<Icon icon="cryptocurrency:ok"  className="h-12 w-auto"/>,
  65:<Icon icon="cryptocurrency:ok" className="h-12 w-auto"/>,
  137:<Icon icon="cryptocurrency:matic" className="h-12 w-auto text-purple-500" />,
  // 4689:<Icon icon="cryptocurrency:iotx"  className="h-12 w-auto"/>,
  // 4690:<Icon icon="cryptocurrency:iotx"  className="h-12 w-auto"/>,
  4689:<img src={IotexLogo} alt="React Logo" className="h-12 w-auto"/>,
  4690:<img src={IotexLogo} alt="React Logo"className="h-12 w-auto" />,
}

function Manage(props: Props) {
  const [remove, setRemove] = useState(false);
  return (
    <motion.div
      initial={{ y: 20, opacity: 0 }}
      whileInView={{ y: 0, opacity: 1 }}
      // transition={{ ease: 'easeOut', duration: 0.5, delay: props.index * 0.2 }}
      viewport={{ once: true }}
      className={`box-shadow relative flex min-w-[200px] flex-col justify-between gap-5 rounded-lg border bg-white px-7 py-6 text-lg md:gap-0 md:py-7
        ${props.default ? 'bg-blue-700 text-white' : 'bg-white'}
        `}
    >
      <Link to={`/profile/${props.name}/${props.tld}/profile`}>
        {/* <Icon icon="iwwa:option" className="absolute top-4 right-2 h-8 w-auto cursor-pointer" /> */}
        <div className="flex flex-1 flex-col items-center justify-between gap-2">
          <div className="grid h-12 place-items-center rounded-full bg-white">
            {/*<Icon icon={chainIcon[props.chainID]} className="h-12 w-auto text-purple-500" />*/}
            {chainIcon[props.chainID]}
          </div>

          <span className="font-normal">{props.name}</span>
          <span className="font-medium">.{props.tld}</span>
        </div>
        {/* <Link
        to={`/profile/${props.name}/${props.tld}`}
        className="flex justify-center py-2 md:py-0 items-center gap-2 px-10 bg-white hover:bg-blue-500 text-blue-500 hover:text-white border-2 border-blue-500 rounded-md duration-200"
      >
        <Icon icon="eva:options-2-outline" /> Manage
      </Link> */}
      </Link>
      {props.owner && !props.default && (
        <div
          className="absolute top-6 right-4 z-[100] flex cursor-pointer"
          onClick={() => {
            setRemove(!remove);
          }}
        >
          <Icon className={`option h-10 w-auto cursor-pointer `} icon="iwwa:option" />
          <motion.div animate={remove ? { display: 'block' } : { display: 'none' }} className="e absolute top-[135%] right-0 z-50 flex w-[10rem] flex-col">
            {/*{!props.isDefaultEthereumAddress && (*/}
            <button
              onClick={async (e) => {
                // await setReverse({domain: `${props.name}.${props.tld}`, _ownerAddress: props.owner!})
                props.callback({ domain: `${props.name}.${props.tld}`, _ownerAddress: props.owner!,chainID:props.chainID });
              }}
              className="w-full cursor-pointer rounded-md border border-b-0 bg-white px-8 py-3 font-medium shadow-md duration-200 hover:bg-blue-500 hover:text-white"
            >
              Set Default
            </button>
            {/*)}*/}
          </motion.div>
        </div>
      )}
    </motion.div>
  );
}

export default Manage;
