import axios from 'axios';
import config from '../config';
import { IOrder } from '../interface';

export async function creatrOrder(accessToken: string): Promise<IOrder> {
  const url = new URL(`/order`, `${config.api.endpoint}`);
  const response = await axios.post<IOrder>(url.href, undefined, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });
  return response.data;
}
