import Nav from '../../components/Nav/Nav';
import { motion } from 'framer-motion';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { IDomain } from '../../interface';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import Select from 'react-select';
import i18nIsoCountries from 'i18n-iso-countries';
import * as luxon from 'luxon';
import { getDomain } from '../../api/get-domain';
import { ethers } from 'ethers';
import config from '../../config';
import { formatsByName } from '@ensdomains/address-encoder';
import PublicResolver from '../../contracts/PublicResolver';
import axios from 'axios';
import BindPhone from '../../components/domain/BindPhone';
import { Icon } from '@iconify/react';
import { toast, Toaster } from 'react-hot-toast';
import NFTCard, { prcMap } from '../../components/nft/cardView';
import { FetchWrapper } from 'use-nft';
import { Fetcher, FetcherDeclaration, NftMetadata } from 'use-nft/dist/src/types';
import { Card, CardBody, CardHeader, Typography } from '@material-tailwind/react';
import { GlobalStateContext } from '../../context/GlobalStateContext';
import { createShoppingCartDomain, CreateShoppingCartDomainType } from '../../api/create-shopping-cart-domain';

// import provider from "../../contracts/provider";
i18nIsoCountries.registerLocale(require('i18n-iso-countries/langs/en.json'));
// const { connector, library, chainId, account, activate, deactivate, active, error } = useWeb3React<ethers.providers.Web3Provider>();
// const labelhash = (label: string) => ethers.utils.keccak256(ethers.utils.toUtf8Bytes(label));

const recordTypeOptions = [
  { value: 'address', label: 'Address' },
  // {value:'NFT',label:'NFT'}
  { value: 'textRecord', label: 'Text Record' },
  // { value: "content", label: "Content" },
];
const chainTypeOptions = [
  { value: 1, label: 'Ethereum' },
  { value: 137, label: 'Polygon' },
  { value: 56, label: 'Binance' },
  // { value: 42161, label: 'Arbitrum' },
  { value: 43114, label: 'Avalanche' },
  { value: 10, label: 'Optimism' },
  { value: 250, label: 'Fantom' },
  { value: 42161, label: 'Arbitrum' },
  // { value: 100, label: 'Gnosis' },
  // { value: "content", label: "Content" },
];
const textRecordTypeOptions = [
  { value: 'email', label: 'Email' },
  { value: 'url', label: 'Url' },
  { value: 'avatar', label: 'Avatar' },
  { value: 'description', label: 'Description' },
  { value: 'notice', label: 'Notice' },
  { value: 'keywords', label: 'Keywords' },
  { value: 'discord', label: 'Discord' },
  { value: 'github', label: 'Github' },
  { value: 'reddit', label: 'Reddit' },
  { value: 'twitter', label: 'Twitter' },
  { value: 'telegram', label: 'Telegram' },
  { value: 'delegate', label: 'Delegate' },
  { value: 'cname', label: 'CNAME' },
  { value: 'dedrive', label: 'Dedrive' },
  { value: 'domain', label: 'Domain' },
];

const recordAddressCategoryOptions = Object.keys(formatsByName)
  .filter((c) => !c.match(/_LEGACY/))
  .filter((x) => x != 'BTC')
  .sort()
  .map((v) => ({ value: v, label: v }));

// const ani = {
//   hide: {
//     opacity: 0,
//     zIndex: '-1',
//     transition: { zIndex: { delay: 0.35 } },
//     duration: 0.2,
//   },
//   show: { opacity: 1, zIndex: '100', transition: { duration: 0.2 } },
// };

interface IRecord {
  coinType: string;
  address: string;
}

interface ITextRecord {
  textType: string;
  address: string;
}

interface INFTRecord {
  chainID: number;
  contractAddress: string;
  tokenID: string;
}

const Profile = () => {
  const params = useParams();
  const scrolls = useRef<HTMLDivElement>(null);
  const [add, setAdd] = useState<boolean>(false);
  const [domainName, setDomainName] = useState<string | undefined>(undefined);
  const [domainTld, setDomainTld] = useState<string | undefined>(undefined);
  const [accessToken, setAccessToken] = useState<string | undefined>(undefined);
  const [domain, setDomain] = useState<IDomain | undefined>(undefined);
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [profile, setProfile] = useState([true, false, false, false, false, false, false, false]);
  const [records, setRecords] = useState<IRecord[]>([]);
  const [textRecords, setTextRecords] = useState<ITextRecord[]>([]);
  const [currentSelectedRecordCoinType, setCurrentSelectedRecordCoinType] = useState<string | undefined>('ETH');
  const [currentSelectedRecordTextype, setCurrentSelectedRecordTextype] = useState<string | undefined>('email');
  const [currentSelectedRecordType, setCurrentSelectedRecordType] = useState<string | undefined>('address');
  const [currentSelectedChain, setCurrentSelectedChain] = useState<number | undefined>(1);
  const currentRecordAddress = useRef<HTMLInputElement>(null);
  const currentContractAddress = useRef<HTMLInputElement>(null);
  const currentTokenID = useRef<HTMLInputElement>(null);
  let navigate = useNavigate();
  const [ownerAddress, setOwnerAddress] = useState<string | undefined>(undefined);
  const [openTab, setOpenTab] = useState(1);
  const [bound, setBound] = useState(false);
  const [reload, setReload] = useState(false);
  const [isOwner, setIsOwner] = useState(false);
  const [isNetwork, setIsNework] = useState(false);
  const [load, setLoad] = useState(false);
  const [needReclaim, setNeedReclaim] = useState<boolean | undefined>(undefined);
  const [addRecord, setAddRecord] = useState(false);
  const [addNFT, setAddNFT] = useState(false);
  const [check, setCheck] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [currentNft, setCurrentNFT] = useState<NftMetadata | null>(null);
  const [nftReocrds, setNftRecords] = useState<INFTRecord[] | null | undefined>(null);
  const [error, SetError] = useState<string | null>(null);
  const { useWeb3Function, updateShoppingCart, updateShoppingCartDomainsCount } = useContext(GlobalStateContext);
  const [isAddedDomainToShoppingCart, setIsAddedDomainToShoppingCart] = useState<boolean>(false);
  const [cartReload, setCartReload] = useState<boolean>(false);
  const [showNav, setShowNav] = useState(false);
  const [isAddingDomainToShoppingCart, setIsAddingDomainToShoppingCart] = useState(false);
  useEffect(() => {
    async function getAccessToken() {
      if (accessToken) return accessToken;
      const _accessToken = await getAccessTokenSilently();
      setAccessToken(_accessToken);
      return _accessToken;
    }

    async function exec() {
      const _domainName = params.domain;
      const _domainTld = params.type;
      setDomainName(_domainName);
      setDomainTld(_domainTld);
      if (isAuthenticated) {
        const _accessToken = await getAccessToken();
        const _domain = await getDomain(`${_domainName}.${_domainTld}`, _accessToken);
        console.log(_domain);
        setOwnerAddress(_domain.owner.address);
        console.log(_domain);
        setDomain(_domain);
      }
    }

    exec();
  }, [accessToken, getAccessTokenSilently, isAuthenticated, params]);
  async function handlerReclaim() {
    if (domain) {
      await useWeb3Function[domain?.tld.chainId].reclaim({
        domain: domain?.fqdn!,
        _ownerAddress: domain?.owner.address!,
        tokenID: domain?.tokenId!,
      });
      await new Promise((resolve) => setTimeout(resolve, 50000));
      const reseult = await useWeb3Function[domain?.tld.chainId].checkReclaim({ domain: domain?.fqdn!, tokenID: domain?.tokenId! });
      setNeedReclaim(reseult);
    }
  }

  async function handlerRenewal() {
    setAdd(true);
    const [_name, _tld] = domain!.fqdn.split('.');
    setIsAddingDomainToShoppingCart(true);
    await createShoppingCartDomain(accessToken!, CreateShoppingCartDomainType.RENEWAL, _tld, _name);
    await updateShoppingCart();
    await updateShoppingCartDomainsCount();
    setIsAddingDomainToShoppingCart(false);
    setIsAddedDomainToShoppingCart(true);
    window.innerWidth > 768 && setShowNav(true);
  }
  async function unBound() {
    try {
      await axios.delete(`${process.env.REACT_APP_API_ENDPOINT}/domain/bindPhone/${domain?.fqdn}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      });
      setDomain(await getDomain(domain?.fqdn!, accessToken!));
    } catch (e) {}
  }

  async function afterBoundFunction() {
    setBound(false);
    setDomain(await getDomain(domain?.fqdn!, accessToken!));
    setProfile([true, false, false, false, false, false, false, false]);
  }

  useEffect(() => {
    if (scrolls.current) {
      if (scrolls.current.scrollWidth > scrolls.current.clientWidth) {
        scrolls.current.classList.add('cursor-grab');
      } else {
        scrolls.current.classList.remove('cursor-grab');
      }
    }

    window.addEventListener('resize', () => {
      // check horizontal overflow
      if (scrolls.current) {
        if (scrolls.current.scrollWidth > scrolls.current.clientWidth) {
          scrolls.current.classList.add('cursor-grab');
        } else {
          scrolls.current.classList.remove('cursor-grab');
        }
      }
    });
  }, []);

  if (scrolls.current) {
    if (scrolls.current.scrollWidth > scrolls.current.clientWidth) {
      scrolls.current.classList.add('cursor-grab');
    } else {
      // console.log(scrolls.current.scrollWidth, scrolls.current.clientWidth);
      scrolls.current.classList.remove('cursor-grab');
    }
  }

  let pos = { top: 0, left: 0, x: 0, y: 0 };
  let ele;
  const mouseDownHandler = function (e) {
    if (scrolls.current) {
      ele = scrolls.current;
    }
    // ele.style.cursor = 'grabbing';
    ele.style.userSelect = 'none';
    pos = {
      // The current scroll
      left: ele.scrollLeft,
      top: ele.scrollTop,
      // Get the current mouse position
      x: e.clientX,
      y: e.clientY,
    };

    document.addEventListener('mousemove', mouseMoveHandler);
    document.addEventListener('mouseup', mouseUpHandler);
  };
  const mouseMoveHandler = function (e) {
    if (scrolls.current) {
      ele = scrolls.current;
    }
    // How far the mouse has been moved
    const dx = e.clientX - pos.x;
    const dy = e.clientY - pos.y;

    // Scroll the element
    ele.scrollTop = pos.top - dy;
    ele.scrollLeft = pos.left - dx;
  };
  const mouseUpHandler = function () {
    if (scrolls.current) {
      ele = scrolls.current;
    }
    document.removeEventListener('mousemove', mouseMoveHandler);
    document.removeEventListener('mouseup', mouseUpHandler);

    // ele.style.cursor = 'grab';
    ele.style.removeProperty('user-select');
  };

  useEffect(() => {
    async function exec() {
      const coins = Object.keys(formatsByName)
        .filter((c) => !c.match(/_LEGACY/))
        .sort();
      let texts: string[] = textRecordTypeOptions.map((x) => {
        return x.value;
        // texts.push(x.value!)
      });

      // currentSelectedRecordCoinType.current.value = "BTC";
      if (domain) {
        const nodehash = useWeb3Function[domain?.tld.chainId].namehash(domain.name, domain.tld.name);
        const _records: IRecord[] = [];
        const _textrecords: ITextRecord[] = [];
        const queue: Promise<string>[] = [];
        const textQueue: Promise<string>[] = [];
        for (const coin of coins) {
          queue.push(PublicResolver()[domain.tld.chainId].callStatic['addr(bytes32,uint256)'](nodehash, formatsByName[coin].coinType));
        }
        texts.map((x) => textQueue.push(PublicResolver()[domain.tld.chainId].callStatic.text(nodehash, x)));
        const results = await Promise.all(queue);
        const textResults = await Promise.all(textQueue);
        results.forEach((result, i) => {
          if (result !== '0x') {
            let address = result;
            if (!ethers.utils.isAddress(result)) {
              try {
                address = formatsByName[coins[i]].encoder(Buffer.from(ethers.utils.toUtf8String(result), 'hex'));
              } catch {
                address = result;
              }
            }
            _records.push({
              coinType: coins[i],
              address,
            });
          }
        });
        textResults.forEach((result, i) => {
          if (result !== '') {
            // console.log(result);
            _textrecords.push({
              textType: texts[i],
              address: result,
            });
          }
        });
        setTextRecords(_textrecords);
        setRecords(_records);
      }
    }

    exec().catch(() => {
      console.log('Get Resolver Record Error');
    });
  }, [domain, ownerAddress, reload]);
  useEffect(() => {
    async function exec() {
      if (domain) {
        const reclaim_result = await useWeb3Function[domain?.tld.chainId].checkReclaim({ domain: domain?.fqdn!, tokenID: domain?.tokenId! });
        setNeedReclaim(reclaim_result);
      }
    }
    exec().catch(() => {
      console.log('Check Reclaim Contract Error');
      setNeedReclaim(true);
    });
  }, [domain]);
  useEffect(() => {
    async function exec() {
      if (nftReocrds != null && nftReocrds != undefined) {
        return;
      }
      if (domain) {
        const nftList: INFTRecord[] | undefined = [];
        const nodehash = useWeb3Function[domain?.tld.chainId].namehash(domain!.name, domain!.tld.name);
        await Promise.all(
          chainTypeOptions.map(async (x) => {
            const reseult = await useWeb3Function[domain?.tld.chainId].getNFT({ chainID: `${x.value}`, nodehash: nodehash });

            if (reseult.chainId._hex !== '0x00') {
              console.log(reseult);
              const nftEntity: INFTRecord = {
                chainID: Number(reseult.chainId),
                contractAddress: reseult.contractAddress,
                tokenID: reseult.tokenId.toHexString(),
              };
              nftList.push(nftEntity);
            }
            return reseult;
          }),
        );
        nftList.length > 0 ? setNftRecords(nftList) : setNftRecords(undefined);
      }
    }
    exec().catch(() => {
      console.log('Get NFT Contract Error');
    });
  }, [domain]);
  // useEffect(() => {
  //   async function onInit() {
  //     await window.ethereum.enable();
  //     const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
  //     const account = accounts[0];
  //     setIsOwner(account.toLowerCase() === domain?.owner.address.toLowerCase());
  //     window.ethereum.on('accountsChanged', function (accounts) {
  //       const account = accounts[0];
  //       setIsOwner(account.toLowerCase() === domain?.owner.address.toLowerCase());
  //     });

  //     window.ethereum.on('networkChanged', function (networkId) {
  //       if (config.env === 'production') {
  //         setIsNework(networkId === 137);
  //       } else {
  //         setIsNework(networkId === 5);
  //       }
  //     });
  //   }
  //   onInit();
  // });

  async function setAddr() {
    if (domain && currentRecordAddress) {
      SetError(null);
      const nodehash = useWeb3Function[domain?.tld.chainId].namehash(domain.name, domain.tld.name);
      if (currentSelectedRecordType === recordTypeOptions[0].value && currentSelectedRecordCoinType) {
        // const _address = formatsByName[currentSelectedRecordCoinType].decoder(currentRecordAddress.current!.value);
        // if (!_address) {
        //   throw new Error();
        // }
        // console.log({_address} )
        const hasError = await useWeb3Function[domain?.tld.chainId].setRecord({
          _ownerAddress: domain.owner.address,
          nodehash,
          type: 'address',
          address: { coinType: formatsByName[currentSelectedRecordCoinType].coinType, address: currentRecordAddress.current!.value },
        });
        hasError === 'Wrong Signer. Please Switch to Correct Signer before set Record.' ? SetError(hasError + 'Owner Address : ' + domain.owner.address) : SetError(hasError);
      } else if (currentSelectedRecordType === recordTypeOptions[1].value) {
        const text = currentRecordAddress.current!.value;
        const hasError = await useWeb3Function[domain?.tld.chainId].setRecord({
          _ownerAddress: domain.owner.address,
          nodehash,
          type: 'text',
          text: { type: currentSelectedRecordTextype!, text: text },
        });
        hasError === 'Wrong Signer. Please Switch to Correct Signer before set Record.' ? SetError(hasError + 'Owner Address : ' + domain.owner.address) : SetError(hasError);
      }

      if (reload) {
        setReload(false);
      } else {
        setReload(true);
      }
    }
  }

  async function setNFT() {
    setCurrentNFT(null);
    try {
      const fetcher: Fetcher<unknown> | FetcherDeclaration = ['ethers', { provider: new ethers.providers.JsonRpcProvider(prcMap[currentSelectedChain!]) }];
      const fetchWrapper = new FetchWrapper(fetcher);

      const result = await fetchWrapper.fetchNft(currentContractAddress.current?.value!, currentTokenID.current?.value!);
      setCurrentNFT(result);
      console.log({ result });
    } catch (e) {
      console.log({ e });
    }
  }

  function backProfile() {
    setProfile([true, false, false, false, false, false, false, false]);
  }
  async function setNFTRecord() {
    if (domain) {
      const nodehash = useWeb3Function[domain?.tld.chainId].namehash(domain!.name, domain!.tld.name);
      SetError(
        await useWeb3Function[domain?.tld.chainId].setNFTRecordWeb3({
          nodehash: nodehash,
          tokenId: currentTokenID.current?.value!,
          contractAddress: currentContractAddress.current?.value!,
          chainID: `${currentSelectedChain}`,
          _ownerAddress: domain?.owner.address!,
        }),
      );
    }
  }

  useEffect(() => {
    setLoad(false);
    console.log('domain', domain?.phoneNumber);
  }, [textRecords]);

  useEffect(() => {
    console.log(check);
  }, [check]);

  return (
    <>
      <Nav
        reload={cartReload}
        setreload={setCartReload}
        cartCount={add}
        setCartCount={setAdd}
        title="Domain Info"
        showNav={showNav}
        setShowNav={setShowNav}
        setIsAddedDomainToShoppingCart={setIsAddedDomainToShoppingCart}
      />
      <Toaster />
      <div className="flex h-full flex-1 flex-col overflow-y-auto p-10 px-6 pt-6 md:px-10">
        <div className="account-r-con flex w-full flex-1 flex-col gap-2 md:min-w-[897px] lg:gap-8">
          <div className="text-xl ">
            <span className="self-end font-normal">
              {params.domain}.{params.type}
            </span>
          </div>
          <div className="flex flex-wrap">
            {needReclaim != undefined && needReclaim == true && (
              <div className="flex w-full flex-col">
                {profile[0] === true && (
                  <div className="flex flex-col items-center justify-between md:flex-row">
                    <ul className="mt-4 flex list-none flex-col flex-wrap gap-4 pb-4 text-center md:mt-0 md:flex-row md:gap-0" role="tablist">
                      <li className={'md:mr-4 '}>
                        <a
                          className={
                            'text-bold px-2 pb-1 text-lg font-medium uppercase ' +
                            (params.tab !== 'nft' && params.tab !== 'records'
                              ? 'border-b-4 border-[#93278f]  text-[#93278f]'
                              : 'text-gray-300 hover:border-b-4 hover:border-[#93278f]')
                          }
                          onClick={(e) => {
                            e.preventDefault();
                            // setOpenTab(1);
                            SetError(null);
                            navigate('/profile/' + params.domain + '/' + params.type + '/profile');
                          }}
                          data-toggle="tab"
                          href="#link1"
                          role="tablist"
                        >
                          Profile
                        </a>
                      </li>
                      <li className={'px-2 md:mr-4 '}>
                        <a
                          className={
                            'text-bold px-2 pb-1 text-lg font-medium uppercase ' +
                            (params.tab === 'records' ? 'border-b-4 border-[#93278f]  text-[#93278f]' : 'text-gray-300 hover:border-b-4 hover:border-[#93278f]')
                          }
                          onClick={(e) => {
                            e.preventDefault();
                            // setOpenTab(2);
                            SetError(null);
                            navigate('/profile/' + params.domain + '/' + params.type + '/records');
                          }}
                          data-toggle="tab"
                          href="#link2"
                          role="tablist"
                        >
                          Records
                        </a>
                      </li>
                      <li className={'px-2 md:mr-4 '}>
                        <a
                          className={
                            'text-bold px-2 pb-1 text-lg font-medium uppercase ' +
                            (params.tab === 'nft' ? 'border-b-4 border-[#93278f]  text-[#93278f]' : 'text-gray-300 hover:border-b-4 hover:border-[#93278f]')
                          }
                          onClick={(e) => {
                            e.preventDefault();
                            // setOpenTab(3);
                            SetError(null);
                            navigate('/profile/' + params.domain + '/' + params.type + '/nft');
                          }}
                          data-toggle="tab"
                          href="#link3"
                          role="tablist"
                        >
                          NFTS
                        </a>
                      </li>
                    </ul>
                    {params.tab === 'records' && (
                      <button
                        onClick={() => setAddRecord(!addRecord)}
                        className="rounded-md border-2 border-[#93278f] px-6 py-2 font-medium text-[#93278f] duration-150 hover:bg-[#93278f] hover:text-white"
                      >
                        Add Record
                      </button>
                    )}
                    {params.tab === 'nft' && (
                      <button
                        onClick={() => setAddNFT(!addNFT)}
                        className="rounded-md border-2 border-[#93278f] px-6 py-2 font-medium text-[#93278f] duration-150 hover:bg-[#93278f] hover:text-white"
                      >
                        Add NFTS
                      </button>
                    )}
                  </div>
                )}

                {profile[0] === true && (
                  <div className="box-shadow relative mb-2 mt-2 flex w-full min-w-0 flex-1 flex-col break-words rounded-xl border bg-white">
                    <div className="h-full justify-center overflow-hidden py-10" style={{ minHeight: '700px' }}>
                      <div className="mx-auto px-6 md:px-10">
                        {params.tab !== 'records' && params.tab !== 'nft' && (
                          <div id="link1">
                            <motion.div
                              animate={
                                profile[0]
                                  ? {
                                      opacity: '1',
                                      zIndex: 1,
                                      height: 'auto',
                                    }
                                  : { opacity: '0', zIndex: -1, height: 0 }
                              }
                              transition={{ height: { duration: 0 } }}
                              className="flex w-full flex-col overflow-hidden duration-200 "
                            >
                              <div className="flex flex-col items-center justify-between gap-3 py-6 text-base lg:flex-row lg:gap-0">
                                <div className="flex-[3] shrink-0 text-2xl font-medium lg:text-base">TLD</div>
                                <div className="text-center text-right text-base lg:text-base">{domain?.tld.name}</div>
                              </div>
                              <div className="flex flex-col items-center justify-between gap-3 py-6 text-base lg:flex-row lg:gap-0">
                                <div className="flex-[3] shrink-0 text-2xl font-medium lg:text-base">TOKEN ID</div>
                                <div className="w-full truncate text-center text-base md:text-right lg:text-base">{domain?.tokenId}</div>
                              </div>
                              <div className="flex flex-col items-center justify-between gap-3 py-6 text-base lg:flex-row lg:gap-0">
                                <div className="flex-[3] shrink-0 text-2xl font-medium lg:text-base">OWNER</div>
                                <div className="w-full truncate text-center text-base md:text-right lg:text-base">{domain?.owner.address}</div>
                              </div>
                              {/* <div className="flex flex-col items-center justify-between gap-3 py-6 text-base lg:flex-row lg:gap-0">
                              <div className="flex-[3] shrink-0 text-2xl font-medium lg:text-base">REGISTRANT</div>
                              <div className="w-full truncate text-center text-base md:text-right lg:text-base">{domain?.owner.address}</div>
                            </div>
                            <div className="flex flex-col items-center justify-between gap-3 py-6 text-base lg:flex-row lg:gap-0">
                              <div className="flex-[3] shrink-0 text-2xl font-medium lg:text-base">CONTROLLER</div>
                              <div className="w-full truncate text-center text-base md:text-right lg:text-base">{domain?.owner.address}</div> */}
                              <div className="flex flex-col items-center justify-between gap-3 py-6 text-base lg:flex-row lg:gap-0">
                                <div className="flex-[3] shrink-0 text-2xl font-medium lg:text-base">EXPIRATION DATE</div>
                                <div className="w-full truncate text-center text-base md:text-right lg:text-base">
                                  <div style={{ display: 'flex', justifyContent: 'end', flexDirection: 'column' }}>
                                    {domain ? luxon.DateTime.fromISO(domain.expiredAt).toHTTP() : ''}
                                    <div>
                                      <button
                                        className="shrink-0 rounded-full border-2 border-[#93278f] px-12 py-2 font-bold text-[#93278f] duration-200 hover:bg-[#93278f] hover:text-white"
                                        disabled={isAddingDomainToShoppingCart}
                                        onClick={async () => {
                                          try {
                                            await handlerRenewal();
                                            toast.success('added to cart.');
                                          } catch (e: any) {
                                            console.log(e);
                                            toast.error(e.message);
                                          }
                                        }}
                                      >
                                        {isAddingDomainToShoppingCart ? <div>Loading...</div> : <p>Renew</p>}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="my-4 w-full border"></div>
                              <div className="mt-2 flex flex-col items-center justify-between gap-3 rounded-xl border-2 py-6 px-5 text-base lg:mt-0 lg:flex-row lg:gap-0 lg:border-0 lg:px-0">
                                <div className="flex-[3] shrink-0 text-2xl font-medium lg:text-base">CONTRACT</div>
                                <div className="w-full truncate text-right text-base lg:text-base">{domain ? config.contractAddress[domain.tld.chainId].baseRegistrar : ''}</div>
                                {/* <button className="w-full absolute shrink-0 hover:bg-blue-500 py-2 border-2 border-blue-500 text-blue-500 hover:text-white duration-200 rounded-full font-bold">
									Set
								</button> */}
                              </div>
                              <div className="mt-2 flex flex-col items-center justify-center gap-3 rounded-xl border-2 py-6 px-5 text-base lg:mt-0 lg:flex-row lg:gap-0 lg:border-0 lg:px-0">
                                <div className="flex-[3] shrink-0 text-2xl font-medium lg:text-base">Bound Phone</div>

                                {domain?.phoneNumber === null || domain?.phoneNumber === undefined ? (
                                  <button
                                    className="shrink-0 rounded-full border-2 border-[#93278f] px-12 py-2 font-bold text-[#93278f] duration-200 hover:bg-[#93278f] hover:text-white"
                                    onClick={() => {
                                      // setProfile([false, false, false, false, false, false, false, true]);
                                      setBound(true);
                                    }}
                                  >
                                    Set
                                  </button>
                                ) : (
                                  <div className="flex flex-col items-center justify-between gap-6 overflow-hidden text-ellipsis text-xl md:flex-row lg:text-left lg:text-base">
                                    {domain?.phoneNumber}

                                    <button
                                      className="purple-500 flex-[3] shrink-0 rounded-full border-2 border-[#93278f] px-8 py-2 font-bold text-[#93278f]  duration-200 hover:bg-[#93278f] hover:text-white"
                                      onClick={unBound}
                                    >
                                      Unbound
                                    </button>
                                  </div>
                                )}
                              </div>
                            </motion.div>
                          </div>
                        )}
                        {params.tab === 'records' && (
                          <div id="link2">
                            {/*<form action="" className="">*/}
                            {error != null && (
                              <div className="border-l-4 border-red-500 bg-red-100 p-4 text-red-700" role="alert">
                                <p className="font-bold">Incorrect Signer</p>
                                <p>{error}</p>
                              </div>
                            )}
                            <div data-show={addRecord} className="record-con relative flex flex-col justify-between gap-5 lg:flex-row">
                              <div className="flex flex-[8] flex-col justify-between gap-5 rounded-md bg-gray-200 p-3 lg:flex-row">
                                <div className="relative flex-[2]">
                                  <Select
                                    defaultValue={recordTypeOptions[0]}
                                    options={recordTypeOptions}
                                    className="fixed top-0 left-0"
                                    onChange={(e) => setCurrentSelectedRecordType(e?.value)}
                                  />
                                </div>

                                {currentSelectedRecordType === recordTypeOptions[0].value && (
                                  <div className="relative flex-[2]">
                                    <Select
                                      defaultValue={recordAddressCategoryOptions.find((v) => v.value === 'ETH')}
                                      options={recordAddressCategoryOptions}
                                      onChange={(e) => setCurrentSelectedRecordCoinType(e?.value)}
                                      className={`fixed top-0 left-0 `}
                                    />
                                  </div>
                                )}
                                {currentSelectedRecordType === recordTypeOptions[1].value && (
                                  <div className="relative flex-[2]">
                                    <Select
                                      defaultValue={textRecordTypeOptions[0]}
                                      options={textRecordTypeOptions}
                                      onChange={(e) => setCurrentSelectedRecordTextype(e?.value)}
                                      className={`fixed top-0 left-0 `}
                                    />
                                  </div>
                                )}

                                <motion.input
                                  className="flex-[4] rounded-sm px-3 py-2 outline-none"
                                  placeholder="Enter a address"
                                  ref={currentRecordAddress}
                                  disabled={load}
                                ></motion.input>
                              </div>
                              <div className="flex-[2] p-2">
                                <button
                                  // disabled={!check}
                                  className={`h-full w-full rounded-md border-2 border-[#93278f] py-3 text-lg font-bold text-[#93278f] duration-200 lg:py-0 ${'opacity-100 hover:bg-[#93278f] hover:text-white'}`}
                                  onClick={() => {
                                    setAddr();
                                    // console.log("hi")
                                    // setLoad(true);
                                  }}
                                >
                                  {load ? <Icon className="mx-auto h-6 w-auto" icon="eos-icons:loading" /> : <p>Save</p>}
                                </button>
                              </div>
                            </div>
                            <div data-show={addRecord} className="record-con relative mt-5 flex flex-col justify-between gap-5 lg:flex-row">
                              <div className="mb-12 flex gap-5">
                                {/* <input type="checkbox" required onClick={() => setCheck(!check)} /> */}
                                <div className="flex">
                                  &#10071;&#10071;{' '}
                                  <b>
                                    All records will be added to the blockchain and be publicly searchable. Records on the blockchain cannot be deleted and will remain after the
                                    domain is transferred.
                                  </b>
                                </div>
                              </div>
                            </div>
                            {/*</form>*/}
                            <div className="">
                              <table className="min-w-full table-fixed shadow-md">
                                <tr className="rounded-t-lg border bg-[#93278f]  text-left text-white">
                                  <th className="w-[15%] border border-transparent">Type</th>
                                  <th className="w-[15%] border border-transparent">Category</th>
                                  <th className="w-[60%] border border-transparent">Value</th>
                                </tr>
                                {records.map((record, i) => (
                                  <tr className="rounded-b-lg  text-left " key={i}>
                                    <td className="border px-2 py-2">Address</td>

                                    <td className="border px-2 py-2">{record.coinType}</td>
                                    <td className="border px-2 py-2">{record.address}</td>
                                  </tr>
                                ))}
                                {/*{textRecords.filter(x=> x.address != "").map((record, i) => (*/}
                                {textRecords.map((record, i) => (
                                  <tr className="rounded-b-lg  text-left " key={i}>
                                    <td className="border px-2 py-2">Text Record</td>
                                    <td className="border px-2 py-2">{record.textType}</td>
                                    <td className="border px-2 py-2">{record.address}</td>
                                  </tr>
                                ))}
                              </table>
                            </div>
                          </div>
                        )}
                        {params.tab === 'nft' && (
                          <div id="link3" style={{ minHeight: '500px' }}>
                            {error != null && (
                              <div className="border-l-4 border-red-500 bg-red-100 p-4 text-red-700" role="alert">
                                <p className="font-bold">Incorrect Signer</p>
                                <p>{error}</p>
                              </div>
                            )}
                            <div data-show={addNFT} className="record-con relative flex flex-col justify-between gap-5 pb-4 lg:flex-row">
                              <div className="flex flex-[8] flex-col justify-between gap-5 rounded-md bg-gray-200 p-3 lg:flex-row">
                                <div className="relative flex-[2]">
                                  <Select
                                    defaultValue={chainTypeOptions[0]}
                                    options={chainTypeOptions}
                                    className="fixed top-0 left-0"
                                    onChange={(e) => setCurrentSelectedChain(e?.value)}
                                  />
                                </div>

                                <motion.input
                                  className="flex-[4] rounded-sm px-3 py-2 outline-none"
                                  placeholder="Enter a Contract Address"
                                  ref={currentContractAddress}
                                  disabled={load}
                                ></motion.input>

                                <motion.input
                                  className="flex-[4] rounded-sm px-3 py-2 outline-none"
                                  placeholder="Enter a Token ID"
                                  ref={currentTokenID}
                                  disabled={load}
                                ></motion.input>
                              </div>
                              <div className="flex-[2] p-2">
                                <button
                                  // disabled={!check}
                                  className={`h-full w-full rounded-md border-2 border-[#93278f] py-3 text-lg font-bold text-[#93278f] duration-200 lg:py-0 ${'opacity-100 hover:bg-[#93278f] hover:text-white'}`}
                                  onClick={async () => {
                                    setLoad(true);
                                    await setNFT();
                                    setLoad(false);
                                    setShowModal(true);
                                    // console.log("hi")
                                  }}
                                >
                                  {load ? <Icon className="mx-auto h-6 w-auto" icon="eos-icons:loading" /> : <p>Save</p>}
                                </button>
                              </div>
                            </div>
                            <div className={`${nftReocrds !== null && nftReocrds !== undefined && 'nft-grid'} w-full md:pr-2 `}>
                              {nftReocrds !== null ? (
                                nftReocrds != undefined ? (
                                  nftReocrds.map((x) => {
                                    return <NFTCard chainID={x.chainID} contractAddress={x.contractAddress} tokenID={x.tokenID.toString()} />;
                                  })
                                ) : (
                                  <div>No Record</div>
                                )
                              ) : (
                                <div className="mx-auto w-fit">
                                  <div className="lds-facebook ">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                  </div>
                                </div>
                              )}
                            </div>
                            {showModal ? (
                              <>
                                <div className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto overflow-x-hidden outline-none focus:outline-none">
                                  <div className="relative my-6 mx-auto w-auto max-w-3xl">
                                    {/*content*/}
                                    <div className="relative flex w-full flex-col rounded-lg border-0 bg-white shadow-lg outline-none focus:outline-none">
                                      {/*header*/}
                                      <div className="flex items-start justify-between rounded-t border-b border-solid border-slate-200 p-5">
                                        <h3 className="text-3xl font-semibold">Add NFT Confirmations</h3>
                                        <button
                                          className="float-right ml-auto border-0 bg-transparent p-1 text-3xl font-semibold leading-none text-black opacity-5 outline-none focus:outline-none"
                                          onClick={() => setShowModal(false)}
                                        >
                                          <span className="block h-6 w-6 bg-transparent text-2xl text-black opacity-5 outline-none focus:outline-none">×</span>
                                        </button>
                                      </div>

                                      <div className="relative flex-auto p-6">
                                        <div className="my-4 text-lg leading-relaxed text-slate-500">
                                          {currentNft != null ? (
                                            <div>
                                              <Card className="w-96">
                                                <CardHeader color="blue" className="relative h-56">
                                                  <img src={currentNft.image} alt="img-blur-shadow" className="h-full w-full" />
                                                </CardHeader>
                                                <CardBody className="text-center">
                                                  <Typography variant="h5" className="mb-2">
                                                    {currentNft.name}
                                                  </Typography>
                                                  <Typography>{currentNft.description}</Typography>
                                                </CardBody>
                                              </Card>
                                            </div>
                                          ) : (
                                            <div>NFT Not Found. Please Check your contract Address and TokenID</div>
                                          )}
                                        </div>
                                      </div>
                                      {/*footer*/}
                                      <div className="flex items-center justify-end rounded-b border-t border-solid border-slate-200 p-6">
                                        <button
                                          className="background-transparent mr-1 mb-1 px-6 py-2 text-sm font-bold uppercase text-red-500 outline-none transition-all duration-150 ease-linear focus:outline-none"
                                          type="button"
                                          onClick={() => setShowModal(false)}
                                        >
                                          Close
                                        </button>
                                        {currentNft != null ? (
                                          <button
                                            className="mr-1 mb-1 rounded bg-emerald-500 px-6 py-3 text-sm font-bold uppercase text-white shadow outline-none transition-all duration-150 ease-linear hover:shadow-lg focus:outline-none active:bg-emerald-600"
                                            type="button"
                                            onClick={async () => {
                                              await setNFTRecord();
                                              setShowModal(false);
                                            }}
                                          >
                                            Add NFT
                                          </button>
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="fixed inset-0 z-40 bg-black opacity-25"></div>
                              </>
                            ) : null}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
                <div className="bg-bound absolute top-0 left-0 z-[410] h-full w-full bg-gray-500/20" data-show={bound} onClick={() => setBound(false)}></div>
                <div className="info border" data-show={bound}>
                  <BindPhone domain={params.domain?.concat('.').concat(params.type!)!} accessToken={accessToken} backProfile={backProfile} fn={afterBoundFunction}></BindPhone>
                </div>
              </div>
            )}

            {needReclaim == false && (
              <div className="flex h-full flex-1 flex-col overflow-y-auto p-10 px-6 pt-6 md:px-10" style={{ height: 'fit-content' }}>
                <div className=" border border-red-400 bg-red-100 px-4 py-3 text-red-700" role="alert">
                  <p className="md:pt-3">
                    <strong className="font-bold" style={{ fontSize: '25px' }}>
                      Reclaim!
                    </strong>
                  </p>
                  <p className="md:pt-3">
                    <span className="block sm:inline"> After Transfer. You need to Reclaim the Domain before you use.</span>
                  </p>
                  <p className="md:pt-3">
                    <button
                      onClick={async (e) => {
                        toast.promise(handlerReclaim(), {
                          loading: 'Loading',
                          success: 'Success.',
                          error: 'Error, Please Check the wallet account',
                        });
                      }}
                      className="h-full w-full rounded-md border-2 border-red-400 py-3 text-lg font-bold text-red-700 duration-200  hover:bg-red-400 hover:text-white md:pt-3 lg:py-0"
                    >
                      Reclaim
                    </button>
                  </p>
                </div>
              </div>
            )}

            {needReclaim === undefined && (
              <div className="grid w-full flex-1 place-items-center">
                <div className="lds-facebook">
                  <div></div>
                  <div></div>
                  <div></div>
                </div>{' '}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
