import axios from 'axios';
import config from '../config';
import { IShoppingCart } from '../interface';
import { IShoppingCartDomain } from '../interface/shopping-cart-domain.interface';

export interface IUpdateShoppingCartDomainBody {
  durations?: number;
  owner?: string;
  reseller?: string;
}

export async function updateShoppingCartDomain(accessToken: string, id: number, body: IUpdateShoppingCartDomainBody): Promise<IShoppingCart> {
  const url = new URL(`/cart/domain/${id}`, `${config.api.endpoint}`);
  const response = await axios.patch<IShoppingCart>(
    url.href,
    { ...body },
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    },
  );
  return response.data;
}
