import {Icon} from '@iconify/react';
import {motion} from 'framer-motion';
import {useEffect, useState} from 'react';
import {IEthereumAddress} from '../../interface';

export interface IEthereumAddressViewProps {
  key: number;
  index: any;
  ethereumAddress: IEthereumAddress;
  hoverChangeBg?: boolean;
  isDefaultEthereumAddress?: boolean;
  handleSetAsDefaultEthereumAddressClick(address: string): Promise<void>;
  handleDeleteEthereumAddressClick(address: string): Promise<void>;
  handleClick?: any;
  change?: number;
  setChange?: (change: number) => void;
  setSelected?: (selected: string[]) => void;
  type?: string;
  setReveal?: any;
  handleReveal?: any;
}

const EthereumAddressView = (props: IEthereumAddressViewProps) => {
  const [remove, setRemove] = useState(false);
  const [change, setChange] = useState(false);
  useEffect(() => {
    window.onclick = function (event) {
      if (!event.target.matches('.option')) {
        document.querySelectorAll<HTMLDivElement>('.e').forEach((e) => {
          setRemove(false);
          e.style.display = 'none';
        });
      }
    };
  });

  return (
    <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ delay: props.index * 0.15, ease: 'easeOut', duration: 0.3 }}
      viewport={{ once: true }}
      key={props.key}
      className={`box-shadow relative flex w-fit shrink-0 flex-col justify-between gap-5 overflow-hidden rounded-2xl border px-8 py-8 duration-200 ${
        props.isDefaultEthereumAddress ? 'bg-blue-700' : 'bg-white'
      }
        ${props.hoverChangeBg ? ` cursor-pointer hover:bg-blue-700 hover:text-white ${props.change === props.index && 'bg-blue-700 text-white'}` : 'text-blue-800'}
      `}
      onClick={() => {
        if(props.handleClick) props.handleClick(props.ethereumAddress.address);
        if (props.setChange) props.setChange(props.index);
        props.setSelected && props.setSelected([]);
      }}
    >
      {props.isDefaultEthereumAddress ? <div className="address-card"></div> : null}

      <div className="z-50 flex items-end gap-12">
        {props.type !== 'MANAGED_WALLET' ? (
          <>
            <Icon icon="logos:metamask-icon" className="h-auto w-20 shrink-0" />
            <span className="flex-[1] text-lg font-medium text-white lg:mr-5">{props.isDefaultEthereumAddress && 'Default'}</span>
          </>
        ) : (
          <></>
        )}
      </div>
      <div className="z-50">
        {props.type === 'MANAGED_WALLET' && <div className={`mb-2 text-xl font-medium lg:mr-5`}>Managed Wallet</div>}
        <span className={`z-50 truncate text-center font-medium lg:text-left ${props.isDefaultEthereumAddress ? 'text-white' : ''}   `}>{props.ethereumAddress.address}</span>
      </div>

      <div
        className="absolute top-6 right-4 z-[100] flex cursor-pointer"
        onClick={() => {
          setRemove(!remove);
        }}
      >
        <Icon
          className={`option h-10 w-auto cursor-pointer ${props.hoverChangeBg ? ' hidden' : ''} ${props.isDefaultEthereumAddress ? 'text-white' : 'text-black'}`}
          icon="iwwa:option"
        />

        <motion.div animate={remove ? { display: 'block' } : { display: 'none' }} className="e absolute top-[135%] right-0 z-50 flex w-[10rem] flex-col">
          {!props.isDefaultEthereumAddress && props.type !== 'MANAGED_WALLET' && (
            <button
              onClick={() => props.handleSetAsDefaultEthereumAddressClick(props.ethereumAddress.address)}
              className="w-full cursor-pointer rounded-md rounded-b-none border border-b-0 bg-white px-8 py-3 font-medium shadow-md duration-200 hover:bg-blue-500 hover:text-white"
            >
              Set Default
            </button>
          )}
          {props.type !== 'MANAGED_WALLET' && (
            <button
              onClick={() => props.handleDeleteEthereumAddressClick(props.ethereumAddress.address)}
              className={`w-full cursor-pointer rounded-md rounded-t-none border bg-white px-8 py-3 font-medium shadow-md duration-200 hover:bg-red-500 hover:text-white ${
                props.isDefaultEthereumAddress && 'rounded-t-md'
              }`}
            >
              Remove
            </button>
          )}
          {props.type === 'MANAGED_WALLET' && (
            <button
              onClick={() => {
                props.handleReveal();
              }}
              className={`w-full cursor-pointer rounded-md rounded-t-none border bg-white px-8 py-3 font-medium shadow-md duration-200 hover:bg-red-500 hover:text-white ${
                props.isDefaultEthereumAddress && 'rounded-t-md'
              }`}
            >
              Reveal Key
            </button>
          )}
        </motion.div>
      </div>
    </motion.div>
  );
};

export default EthereumAddressView;
