import axios from 'axios';
import config from '../config';
import { IShoppingCart } from '../interface';
export async function getShoppingCart(accessToken: string, skippValidation?: boolean): Promise<IShoppingCart> {
  const url = new URL(`/cart`, `${config.api.endpoint}`);
  if (skippValidation) {
    url.searchParams.set('skippValidation', `${skippValidation}`);
  }
  const response = await axios.get<IShoppingCart>(url.href, { headers: { Authorization: `Bearer ${accessToken}` } });
  return response.data;
}
