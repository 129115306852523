import {IDomain} from './domain.interface';
import {IEthereumAddress} from './ethereum-address.interface';
import {ITld} from './tld.interface';

export enum OrderStatus {
  CREATED = 'CREATED',
  COMPLETED = 'COMPLETED',
  PENDING_PAYMENT = 'PENDING_PAYMENT',
  FAILED = 'FAILED',
  MINTING = 'MINTING',
  PARTIAL_COMPLETED = 'PARTIAL_COMPLETED',
  CANCELED = 'CANCELED',
  PAID = 'PAID'
}

export interface IOrderDomain {
  id: number;
  type: string;
  status?: OrderStatus;
  tld: ITld;
  name: string;
  fqdn: string;
  owner: IEthereumAddress;
  durations: number;
  domain: IDomain;
  price: number;
  createdAt: string;
  updatedAt: string;
}

export interface ICheckoutPaymentLink {
  id: number;
  pid: string;
  link: string;
}

export interface INowPaymentsPaymentLink {
  id: number;
  pid: string;
  link: string;
}

export interface ICoingatePaymentLink {
  id: number;
  pid: string;
  link: string;
}
export interface IUnionPaymentLink {
  id: number;
  pid: string;
  link: string;
}

export interface IPayments {
  id: string;
  nowPaymentsPaymentLink: INowPaymentsPaymentLink;
  checkoutPaymentLink: ICheckoutPaymentLink;
  coingatePaymentLink: ICoingatePaymentLink;
  unionPaymentLink: IUnionPaymentLink;
}

export interface IOrder {
  id: string;
  status: string;
  domains: IOrderDomain[];
  successNowPayments?: INowPaymentsPaymentLink;
  successCheckout?: ICheckoutPaymentLink;
  successCoingate?: ICoingatePaymentLink;
  payments?: IPayments;
  amount: number;
  createdAt: string;
  updatedAt: string;
  expiredAt: string;
}

export interface IGetOrders {
  orders: IOrder[];
}
