import { Icon } from '@iconify/react';
import { useEffect } from 'react';

interface NFooterProps {
  setNav: (nav: boolean) => void;
  nav?: boolean;
}

const NFooter = (props: NFooterProps) => {
  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false);
    return () => {
      document.removeEventListener('click', handleClickOutside, false);
    };
  });

  const handleClickOutside = (event) => {
    if (!(event.target as HTMLElement).closest('.btn-extend') && props.nav) {
      props.setNav(false);
    }
  };

  return (
    <div className="hidden shrink-0 justify-end gap-5 py-5 px-10 pt-0 text-xs text-gray-500 md:flex md:text-sm">
      {/* <Icon onClick={() => props.setNav(true)} className="btn-option visible h-full w-auto cursor-pointer" icon="charm:menu-hamburger" color="#93278f" /> */}
      <div className="flex gap-5">
        <a target="_blank" href="https://www.edns.domains/privacy-policy">
          <button className="duration-200 hover:underline">Privacy Policy</button>
        </a>
        <a target="_blank" href="https://www.edns.domains/terms-of-use">
          <button className="duration-200 hover:underline">Terms of Use</button>
        </a>
      </div>
    </div>
  );
};

export default NFooter;
