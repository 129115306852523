import Nav from '../../components/Nav/Nav';
import {motion} from 'framer-motion';
import {Icon} from '@iconify/react';
import {useContext, useEffect, useState} from 'react';
import {IOrder, OrderStatus} from '../../interface';
import {useNavigate} from 'react-router-dom';
import {withAuthenticationRequired} from '@auth0/auth0-react';
import i18nIsoCountries from 'i18n-iso-countries';
import {cancelOrder} from '../../api/delete-order';
import _ from 'lodash';
import * as luxon from 'luxon';
import {GlobalStateContext} from '../../context/GlobalStateContext';
import OrderPage from '../../components/order/OrderPage';

i18nIsoCountries.registerLocale(require('i18n-iso-countries/langs/en.json'));

const ani = {
  hide: {
    opacity: 0,
    zIndex: '-1',
    transition: { zIndex: { delay: 0.35 } },
    duration: 0.2,
    y: 25,
  },
  show: { opacity: 1, zIndex: '410', y: 0, transition: { duration: 0.2 } },
};

const Order = () => {
  let navigate = useNavigate();

  const { accessToken, orders, updateOrders } = useContext(GlobalStateContext);
  const [page, setPage] = useState(0);
  const [pages, setPages] = useState<any[]>();

  const [orderInModal, setOrderInModal] = useState<IOrder | undefined>(undefined);
  const [isOrderDetailModalOpen, setIsOrderDetailModalOpen] = useState<boolean>(false);
  const [isCancelingOrder, setIsCancelingOrder] = useState<{ [key: string]: boolean }>({});

  const handleCancelOrderClick = async (orderId: string) => {
    if (accessToken && !isCancelingOrder[orderId]) {
      const _isCancelingOrder = _.clone(isCancelingOrder);
      _isCancelingOrder[orderId] = true;
      setIsCancelingOrder(_isCancelingOrder);

      await cancelOrder(accessToken, orderId);
      await updateOrders();

      _isCancelingOrder[orderId] = false;
      setIsCancelingOrder(_isCancelingOrder);
    }
  };

  const customStyles = {
    control: (base) => ({
      ...base,
      height: '100%',
      border: '1px rgb(229, 231, 235) solid',
    }),
  };

  useEffect(() => {
    const order: any = [];
    console.log(orders.length / 15);
    if (orders.length > 15) {
      for (var i = 0; i <= orders.length / 15; i++) {
        order.push(<OrderPage page={page} pages={i} setPage={setPage} />);
      }
    }
    setPages(order);
  }, [orders.length, page]);

  useEffect(() => {
    const con = document.querySelector('.order-con') as HTMLDivElement;
    con.scrollTo(0, 0);
  }, [page]);

  return (
    <>
      <div className="e fixed z-[49] hidden h-full w-full"></div>
      <Nav title="Order" />
      <motion.div
        variants={ani}
        animate={isOrderDetailModalOpen ? 'show' : 'hide'}
        onClick={() => setIsOrderDetailModalOpen(false)}
        className="absolute z-[-1] grid h-full w-full place-items-center overflow-hidden opacity-0 backdrop-blur-sm"
      >
        <div className="flex h-full w-full flex-col overflow-hidden rounded-none shadow-2xl lg:h-auto lg:w-[32rem] lg:rounded-lg">
          <div className="theme bg flex items-center justify-between px-8 py-7 text-xl font-semibold text-white shadow-2xl lg:rounded-t-lg">
            <span>Order information</span>
            <Icon icon="ep:close-bold" className="h-7 w-auto cursor-pointer" onClick={() => setIsOrderDetailModalOpen(false)} />
          </div>
          <div className="max-h-auto z-50 flex h-full flex-col gap-5 overflow-y-scroll bg-white px-8 py-9 lg:max-h-[500px] lg:rounded-b-lg">
            {orderInModal && (
              <>
                <div className="flex flex-col items-start gap-0 lg:flex-row lg:items-center lg:gap-5">
                  <span className="flex-[2] text-lg font-medium">Order ID</span>
                  <span className="lg:text-md text-xs">{orderInModal.id}</span>
                </div>
                <div className="flex flex-col items-start gap-0 lg:flex-row lg:items-center lg:gap-5">
                  <span className="flex-[2] font-medium">Status</span>
                  <span>{_.startCase(_.toLower(orderInModal.status))}</span>
                </div>
                <div className="border-b"></div>
                {orderInModal.domains.map((orderDomain, i) => (
                  <div key={i} className="flex flex-col gap-5">
                    <div className="flex flex-col items-start gap-0 lg:flex-row lg:items-center lg:gap-5">
                      <span className="flex-[2] font-medium">Domain</span>
                      <span>{orderDomain.fqdn}</span>
                    </div>
                    <div className="flex flex-col items-start gap-0 lg:flex-row lg:items-center lg:gap-5">
                      <span className="flex-[2] font-medium">Owner Address</span>
                      <span className="lg:text-md text-xs">{orderDomain.owner['address']}</span>
                    </div>
                    <div className="flex flex-col items-start gap-0 lg:flex-row lg:items-center lg:gap-5">
                      <span className="flex-[2] font-medium">Price</span>
                      <span>$ {orderDomain.price} USD</span>
                    </div>
                    <div className="flex flex-col items-start gap-0 lg:flex-row lg:items-center lg:gap-5">
                      <span className="flex-[2] font-medium">Type</span>
                      <span>{_.startCase(_.toLower(orderDomain.type))}</span>
                    </div>
                    <div className="flex flex-col items-start gap-0 lg:flex-row lg:items-center lg:gap-5">
                      <span className="flex-[2] font-medium">Durations</span>
                      <span>{orderDomain.durations} years</span>
                    </div>
                    <div className="border-b"></div>
                  </div>
                ))}
                {orderInModal.successNowPayments && (
                  <div className="flex gap-5">
                    <span className="flex-[2] font-medium">Payment Gateway</span>
                    <span>Cryptocurrency</span>
                  </div>
                )}
                {orderInModal.successCheckout && (
                  <div className="flex gap-5">
                    <span className="flex-[2] font-medium">Payment Gateway</span>
                    <span>Credit Card</span>
                  </div>
                )}
                <div className="flex gap-5">
                  <span className="flex-[2] font-medium">Amount</span>
                  <span>$ {orderInModal.amount} USD</span>
                </div>
              </>
            )}
          </div>
        </div>
      </motion.div>
      <section className="order-con h-full min-h-[250px] w-full overflow-y-auto scroll-smooth px-6 pb-0 md:flex md:px-10 lg:min-h-[560px]">
        <div className="mt-6 flex flex-1 flex-col justify-between gap-5">
          <div className="flex flex-1 flex-col gap-5 rounded-xl md:border lg:gap-0">
            <div className="hidden w-full gap-5 rounded-t-lg border-b px-8 py-5 text-[#809FB8] lg:flex">
              <span className="flex-[2]">ID</span>
              <span className="flex-[1]">Amount</span>
              <span className="flex-[1]">Status</span>
              <span className="flex-[1]">Created At</span>
              <span className="flex-[1]"></span>
            </div>
            {orders ? (
              orders.slice(page, page + 15).map((order, i) => {
                return (
                  <div
                    key={i}
                    className={`flex w-full flex-1 items-center rounded-xl border bg-white md:border-0 ${orders.slice(page, page + 15).length < 15 && 'max-h-[64px]'} ${
                      (orders.slice(page, page + 15).length < 15 || i !== orders.slice(page, page + 15).length - 1) && 'md:border-b lg:rounded-none'
                    }`}
                  >
                    <div className="props.items-center flex w-full flex-col gap-5 px-8 py-10 lg:flex-row lg:py-5 ">
                      <span className="flex flex-[2] flex-col gap-2 md:items-center lg:flex-row">
                        <span className="block text-lg font-medium lg:hidden">ID</span>
                        <span className="truncate">{order.id}</span>
                      </span>
                      <span className="flex flex-[1] items-center justify-between">
                        <span className="block text-lg font-medium lg:hidden">Amount</span>
                        <span>${order.amount}</span>
                      </span>
                      <span className="flex flex-[1] items-center justify-between">
                        <span className="block text-lg font-medium lg:hidden">Status</span>
                        <span>
                              {_.startCase(order.status) === 'PENDING PAYMENT' ? (
                                <div className="flex items-center justify-between gap-2 font-medium text-orange-500">
                                  <div className="aspect-square h-3 rounded-full bg-orange-500"></div>Pending
                                </div>
                              ) : _.startCase(order.status) === 'COMPLETED' ? (
                                <div className="flex items-center justify-between gap-2 font-medium text-blue-900">
                                  <div className="aspect-square h-3 rounded-full bg-blue-900"></div>Completed
                                </div>
                              ) : _.startCase(order.status) === 'MINTING' ? (
                                <div className="flex items-center justify-between gap-2 font-medium text-orange-500">
                                  <div className="aspect-square h-3 rounded-full bg-orange-500"></div>Minting
                                </div>
                              ) : _.startCase(order.status) === 'CANCELED' ? (
                                <div className="flex items-center justify-between gap-2 font-medium text-red-600">
                                  <div className="aspect-square h-3 rounded-full bg-red-600"></div>Canceled
                                </div>
                              ) : _.startCase(order.status) === 'PAID' ? (
                                <div className="flex items-center justify-between gap-2 font-medium text-green-600">
                                  <div className="aspect-square h-3 rounded-full bg-green-600"></div>Paid
                                </div>
                              ) : _.startCase(order.status) === 'FAILED' ? (
                                <div className="flex items-center justify-between gap-2 font-medium text-red-600">
                                  <div className="aspect-square h-3 rounded-full bg-red-600"></div>Failed
                                </div>
                              ) : _.startCase(order.status) === 'PARTIAL COMPLETED' ? (
                                <div className="flex items-center justify-between gap-2 font-medium text-orange-500">
                                  <div className="aspect-square h-3 rounded-full bg-orange-500"></div>Partial Completed
                                </div>
                              ) : _.startCase(order.status) === 'CREATED' ? (
                                <div className="flex items-center justify-between gap-2 font-medium text-yellow-600">
                                  <div className="aspect-square h-3 rounded-full bg-yellow-600"></div>Created
                                </div>
                              ) : (
                                <div className="flex items-center justify-between gap-2 font-medium text-red-600">
                                  <div className="aspect-square h-3 rounded-full bg-red-600"></div>
                                  {_.startCase(order.status)}
                                </div>
                              )}
                            </span>
                      </span>
                      <span className="flex flex-[1] items-center justify-between">
                        <span className="block text-lg font-medium lg:hidden">Created At</span>
                        <span>{luxon.DateTime.fromISO(order.createdAt).toRelative()}</span>
                      </span>
                      {order.status !== OrderStatus.PENDING_PAYMENT ? (
                        <span className="props.items-center mt-6 flex flex-[1] justify-between gap-2 lg:mt-0">
                          <Icon
                            icon="fe:search"
                            className="h-6 w-auto cursor-pointer text-blue-500 duration-200 hover:scale-110"
                            onClick={() => {
                              setOrderInModal(order);
                              setIsOrderDetailModalOpen(true);
                            }}
                          />
                        </span>
                      ) : (
                        <span className="mt-6 flex flex-[1] items-center justify-between gap-2 lg:mt-0">
                          <Icon
                            icon="fe:search"
                            className="h-6 w-auto cursor-pointer text-blue-500 duration-200 hover:scale-110"
                            onClick={() => {
                              setOrderInModal(order);
                              setIsOrderDetailModalOpen(true);
                            }}
                          />
                          <Icon
                            icon="gridicons:cross"
                            className="h-7 w-auto cursor-pointer text-blue-500 duration-200 hover:scale-110"
                            onClick={() => handleCancelOrderClick(order.id)}
                          />
                          <Icon
                            onClick={() => {
                              if (order.status === OrderStatus.PENDING_PAYMENT) {
                                navigate(`/checkout?id=${order.id}`);
                              }
                            }}
                            icon="fluent:payment-16-regular"
                            className="h-7 w-auto cursor-pointer text-blue-500 duration-200 hover:scale-110"
                          />
                        </span>
                      )}
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="grid w-full flex-1 place-items-center rounded-lg py-10 lg:rounded-b-lg">
                <Icon icon="eos-icons:loading" className="h-16 w-auto text-gray-300" />
              </div>
            )}
          </div>
          {orders !== undefined && (
            <ul className="order-wrapper flex w-full justify-center gap-2 pb-10">
              <li className="theme bg cursor-pointer rounded-md p-2 px-3 text-white md:hidden" onClick={() => page > 0 && setPage(page - 1)}>
                <Icon icon="akar-icons:chevron-left" />
              </li>
              {pages}
              <li className="theme bg cursor-pointer rounded-md p-2 px-3 text-white md:hidden" onClick={() => setPage(page + 1)}>
                <Icon icon="akar-icons:chevron-right" />
              </li>
            </ul>
          )}
        </div>
      </section>
    </>
  );
};
export default withAuthenticationRequired(Order, {
  onRedirecting: () => <div className="load-center"></div>,
});
