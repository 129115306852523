import axios from 'axios';
import config from '../config';
import { IEthereumAddress } from '../interface';

export async function createEthereumAddress(accessToken: string, address: string, message: string, signature: string): Promise<IEthereumAddress> {
  const url = new URL(`/user/ethereum-address`, `${config.api.endpoint}`);
  const response = await axios.post<IEthereumAddress>(
    url.href,
    { address, signature, message },
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    },
  );
  return response.data;
}
