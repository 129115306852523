import axios from 'axios';
import config from '../config';
import { IBillingAddress } from '../interface';

export interface IUpdateBillingAddressInput {
  firstname: string;
  lastname: string;
  addressLine1: string;
  addressLine2?: string;
  city: string;
  state: string;
  zip: string;
  country: string;
}

export async function updateBillingAddress(accessToken: string, billingAddress: IUpdateBillingAddressInput): Promise<IBillingAddress> {
  const url = new URL(`/user/billing-address`, `${config.api.endpoint}`);
  const response = await axios.put<IBillingAddress>(
    url.href,
    { ...billingAddress },
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    },
  );
  return response.data;
}
