import Nav from '../../components/Nav/Nav';
import {ethers} from 'ethers';
import {motion} from 'framer-motion';
import {Icon} from '@iconify/react';
import {useContext, useEffect, useRef, useState} from 'react';
import {IBillingAddress, IDomain, IEthereumAddress, IOrder, IUser} from '../../interface';
import {useNavigate} from 'react-router-dom';
import {useAuth0} from '@auth0/auth0-react';
import i18nIsoCountries from 'i18n-iso-countries';
import {cancelOrder} from '../../api/delete-order';
import {getUser} from '../../api/get-user';
import {createEthereumAddress as _createEthereumAddress} from '../../api/create-ethereum-address';
import {getOrders} from '../../api/get-orders';
import _ from 'lodash';
import {createDefaultEthereumAddress} from '../../api/create-default-ethereum-address';
import {getDefaultEthereumAddress} from '../../api/get-default-ethereum-address';
import {deleteEthereumAddress} from '../../api/delete-ethereum-address';
import {getEthereumAddresses} from '../../api/get-ethereum-addresses';
import EthereumAddressView from '../../components/Account/EthereumAddressView';
import {getDomains} from '../../api/get-domains';
import Skeleton from 'react-loading-skeleton';
import config from '../../config';
import {Web3ReactProvider} from '@web3-react/core';
import {toast, Toaster} from 'react-hot-toast';
import {GlobalStateContext} from "../../context/GlobalStateContext";
import {chainIcon} from "../../components/domain/Manage";

i18nIsoCountries.registerLocale(require('i18n-iso-countries/langs/en.json'));

const ani = {
  hide: {
    opacity: 0,
    zIndex: '-1',
    transition: { zIndex: { delay: 0.35 } },
    duration: 0.2,
  },
  show: { opacity: 1, zIndex: '100', transition: { duration: 0.2 } },
};

const TransferOrSell = () => {
  let navigate = useNavigate();

  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [accessToken, setAccessToken] = useState<string | undefined>(undefined);
  const [isRetreivedAccessToken, setIsRetreivedAccessToken] = useState<boolean>(false);
  const [isBillingAddressHasBeenSetup, setIsBillingAddressHasBeenSetup] = useState<boolean | undefined>(undefined);
  const [user, setUser] = useState<IUser | undefined>(undefined);
  const [orders, setOrders] = useState<IOrder[] | undefined>(undefined);

  const [billingAddress, setBillingAddress] = useState<IBillingAddress | undefined>(undefined);
  const [ethereumAddresses, setEthereumAddresses] = useState<IEthereumAddress[] | undefined>(undefined);
  const [defaultEthereumAddress, setDefaultEthereumAddress] = useState<IEthereumAddress | undefined>(undefined);

  const [orderInModal, setOrderInModal] = useState<IOrder | undefined>(undefined);

  const [isConnectingToWeb3Wallet, setIsConnectingToWeb3Wallet] = useState<boolean>(false);
  const [isOrderDetailModalOpen, setIsOrderDetailModalOpen] = useState<boolean>(false);

  const [isCancelingOrder, setIsCancelingOrder] = useState<{ [key: string]: boolean }>({});

  const [isEthereumAddressHasBeenSetup, setIsEthereumAddressHasBeenSetup] = useState<boolean | undefined>(undefined);
  const [isDuplicatedBindingEthereumAddress, setIsDuplicatedBindingEthereumAddress] = useState<boolean | undefined>(undefined);
  const scrolls = useRef<HTMLDivElement>(null);
  const [customAddWalletErrorMessage, setCustomAddWalletErrorMessage] = useState<string | undefined>(undefined);
  const footer = useRef<HTMLDivElement>(null);
  const [countryOptions, setCountryOptions] = useState<{ value: string; label: string }[]>([]);
  const [domains, setDomains] = useState<IDomain[]>([]);
  const [selectWallet, setSelectWallet] = useState<string>('');
  const [selectedDomain, setSelectedDomain] = useState<string[]>([]);
  const [selectedToken, setSelectedToken] = useState<string[]>([]);
  const [isUsingOwnerAddress, setIsUsingOwnerAddress] = useState<boolean | undefined>(undefined);
  const [isUsingCorrectNetwork, setIsUsingCorrectNetwork] = useState<boolean | undefined>(undefined);
  const [isValidRecipantAddress, setIsValidReciputeAddress] = useState<boolean | undefined>(undefined);
  const [recipantAddress, setRecipantAddress] = useState<string | undefined>(undefined);
  const [isOwner, setIsOwner] = useState(false);
  const [isNetwork, setIsNetwork] = useState(false);
  const [account, setAccount] = useState<string | undefined>(undefined);
  const [transferDomain, setTransferDomain] = useState(false);
  const [change, setChange] = useState<number>(-1);
  const [managedWallet, setManagedWallet] = useState<any>();
  const [managedAddress, setManagedAddress] = useState<string>('');
  const [transferAction, setTransferAction] = useState<boolean>(false);
  const { useWeb3Function} = useContext(GlobalStateContext);

  function handleSelectDomain(fqdn: string) {
    if (selectedDomain.includes(fqdn)) {
      setSelectedDomain(selectedDomain.filter((e) => e !== fqdn));
    } else {
      setSelectedDomain((oldArray) => [...oldArray, fqdn]);
    }
  }

  function handleSelectToken(fqdn: string) {
    if (selectedToken.includes(fqdn)) {
      setSelectedToken(selectedToken.filter((e) => e !== fqdn));
    } else {
      setSelectedToken((oldArray) => [...oldArray, fqdn]);
    }
  }

  useEffect(() => {
    async function exec() {
      if (accessToken) {
        const _user = await getUser(accessToken);
        try {
          setManagedWallet(_user.managedWallet);
          setManagedAddress(_user.managedWallet.address);
        } catch (e) {
          console.error(e);
        }
      }
    }
    exec();
  }, [accessToken]);

  useEffect(() => {
    const _countryOptions = new Set<{ value: string; label: string }>();
    const names = i18nIsoCountries.getNames('en');
    for (const code in names) {
      _countryOptions.add({ label: names[code], value: code });
    }
    setCountryOptions([..._countryOptions]);
  }, []);

  // Get access token from Auth0
  useEffect(() => {
    async function exec() {
      if (isAuthenticated) {
        const token = await getAccessTokenSilently();
        setAccessToken(token);
      }
      setIsRetreivedAccessToken(true);
    }
    exec();
  }, [isAuthenticated, getAccessTokenSilently]);

  // Get and set user
  useEffect(() => {
    async function exec() {
      if (accessToken && isRetreivedAccessToken && isAuthenticated && countryOptions.length > 0) {
        const _user = await getUser(accessToken);
        setUser(_user);
        setBillingAddress(_user.billingAddress);
        setEthereumAddresses(_.orderBy(_user.ethereumAddresses, 'updatedAt', 'desc'));
        setDefaultEthereumAddress(_user.defaultEthereumAddress);
        const _domains = await getDomains(accessToken);
        setDomains(_domains);
      }
    }
    exec();
  }, [accessToken, countryOptions, isAuthenticated, isRetreivedAccessToken, transferAction]);

  function setWallet(ethereumAddress: string) {
    // console.log(ethereumAddress)
    setSelectWallet(ethereumAddress);
  }
  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsConnectingToWeb3Wallet(true);
  };
  const handleRecipantAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const _recipantAddress = e.target.value;
    setRecipantAddress(_recipantAddress);
    setIsValidReciputeAddress(ethers.utils.isAddress(_recipantAddress));
  };

  useEffect(() => {
    if (accessToken && isRetreivedAccessToken && isAuthenticated && billingAddress) {
      const _isBillingAddressHasBeenSetup =
        !!billingAddress.addressLine1 &&
        !!billingAddress.city &&
        !!billingAddress.state &&
        !!billingAddress.zip &&
        !!billingAddress.country &&
        !!billingAddress.firstname &&
        !!billingAddress.lastname;
      setIsBillingAddressHasBeenSetup(_isBillingAddressHasBeenSetup);
    }
  }, [accessToken, billingAddress, isAuthenticated, isRetreivedAccessToken]);

  useEffect(() => {
    if (accessToken && isRetreivedAccessToken && isAuthenticated && ethereumAddresses) {
      setIsEthereumAddressHasBeenSetup(ethereumAddresses.length > 0);
    }
  }, [accessToken, ethereumAddresses, isAuthenticated, isRetreivedAccessToken]);

  // Get and set orders
  useEffect(() => {
    async function exec() {
      if (accessToken && isRetreivedAccessToken && isAuthenticated) {
        const _orders = await getOrders(accessToken);
        setOrders(_.orderBy(_orders.orders, 'createdAt', 'desc'));
      }
    }
    exec();
  }, [accessToken, isAuthenticated, isRetreivedAccessToken]);
  // useEffect(() => {
  //   async function getAc() {
  //     console.log(window.ethereum)
  //     await window.ethereum.enable();
  //     const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
  //     console.log(accounts)
  //     setAccount(accounts[0]);
  //     window.ethereum.on('accountsChanged', function (accounts) {
  //       setAccount(accounts[0]);
  //     });
  //   }
  //   getAc();
  // });
  const createEthereumAddress = async (address: string, message: string, signature: string) => {
    if (accessToken) {
      const isEthereumAddressAlreadyExist = ethereumAddresses?.find((_address) => _address.address === address);
      if (!isEthereumAddressAlreadyExist) {
        await _createEthereumAddress(accessToken, address, message, signature);
        const [_defaultEthereumaddress, _ethereumAddresses] = await Promise.all([getDefaultEthereumAddress(accessToken), getEthereumAddresses(accessToken)]);
        setEthereumAddresses(_ethereumAddresses);
        setDefaultEthereumAddress(_defaultEthereumaddress);
      } else {
        setIsDuplicatedBindingEthereumAddress(true);
      }
    }
  };

  const handleAddWalletClick = async () => {
    if (accessToken && isRetreivedAccessToken && isAuthenticated) {
      try {
        const result = await useWeb3Function[137].signMessage();
        if (result) {
          const { address, signature, message } = result;
          await createEthereumAddress(address, message, signature);
        }
      } catch (e) {
        console.error({ e });
        if (e instanceof Error) {
          if (e.message === 'No Binance Chain Wallet found') setCustomAddWalletErrorMessage(e.message);
        }
      }
    }
  };

  const handleSetAsDefaultEthereumAddressClick = async (address: string) => {
    if (accessToken && isRetreivedAccessToken && isAuthenticated) {
      await createDefaultEthereumAddress(accessToken, address);
      const _address = await getDefaultEthereumAddress(accessToken);
      setDefaultEthereumAddress(_address);
    }
  };

  const handleDeleteEthereumAddressClick = async (address: string) => {
    if (accessToken && isRetreivedAccessToken && isAuthenticated) {
      await deleteEthereumAddress(accessToken, address);
      const [_defaultEthereumaddress, _ethereumAddresses] = await Promise.all([getDefaultEthereumAddress(accessToken), getEthereumAddresses(accessToken)]);
      setEthereumAddresses(_ethereumAddresses);
      setDefaultEthereumAddress(_defaultEthereumaddress);
    }
  };

  const handleCancelOrderClick = async (orderId: string) => {
    if (accessToken && isRetreivedAccessToken && isAuthenticated && !isCancelingOrder[orderId]) {
      const _isCancelingOrder = _.clone(isCancelingOrder);
      _isCancelingOrder[orderId] = true;
      setIsCancelingOrder(_isCancelingOrder);

      await cancelOrder(accessToken, orderId);
      const _orders = await getOrders(accessToken);
      setOrders(_.orderBy(_orders.orders, 'createdAt', 'desc'));

      _isCancelingOrder[orderId] = false;
      setIsCancelingOrder(_isCancelingOrder);
    }
  };

  async function handleTransfer() {
    // await window.ethereum.enable();
    // const provider = new ethers.providers.Web3Provider(window.ethereum);
    // provider.on('network', (newNetwork, oldNetwork) => {
    //   if (oldNetwork) {
    //     navigate(0);
    //   }
    // });
    // const network = await provider.getNetwork();
    if (recipantAddress && isValidRecipantAddress) {
      const selectDomain = selectedDomain.map((x) => {
        return domains.filter((domain) => domain.fqdn === x);
      });
      let provider: ethers.providers.Web3Provider | undefined = undefined;
      for (let i = 0; i < selectDomain.length; i++) {
        if (!provider) {
          provider = await useWeb3Function[selectDomain[i][0].tld.chainId].transferDomain({ tokenId: selectDomain[i][0].tokenId, newOwner: recipantAddress });
        } else {
          await useWeb3Function[selectDomain[i][0].tld.chainId].transferDomain({ tokenId: selectDomain[i][0].tokenId, newOwner: recipantAddress, provider });
        }
      }
      setTransferAction(!transferAction);
    }
    // if (selectDomain.length > 0) {
    //   const signer = provider.getSigner(selectDomain[0][0].owner.address);
    //   const baseRegistrar = BaseRegistrar__factory.connect(config.contractAddress.baseRegistrar, signer);
    //   if (recipantAddress != undefined)
    //     for (let i = 0; i < selectDomain.length; i++) {
    //       const owner = await baseRegistrar.ownerOf(selectDomain[i][0].tokenId);
    //       console.log(owner);
    //       const tx = await baseRegistrar.transferFrom(owner, recipantAddress, selectDomain[i][0].tokenId, { gasLimit: 400000 });
    //     }
    // }

    // if (reload) {
    //   setReload(false);
    // } else {
    //   setReload(true);
    // }
  }

  const EthereumAddressesView = ethereumAddresses?.map((ethereumAddress, i) => {
    return (
      <EthereumAddressView
        key={i}
        index={i + 1}
        ethereumAddress={ethereumAddress}
        handleClick={setWallet}
        handleSetAsDefaultEthereumAddressClick={handleSetAsDefaultEthereumAddressClick}
        handleDeleteEthereumAddressClick={handleDeleteEthereumAddressClick}
        hoverChangeBg={true}
        change={change}
        setChange={setChange}
        setSelected={setSelectedDomain}
        type={ethereumAddress.type}
      />
    );
  });

  useEffect(() => {
    if (scrolls.current) {
      if (scrolls.current.scrollWidth > scrolls.current.clientWidth) {
        scrolls.current.classList.add('cursor-grab');
      } else {
        scrolls.current.classList.remove('cursor-grab');
      }
    }

    window.addEventListener('resize', () => {
      // check horizontal overflow
      if (scrolls.current) {
        if (scrolls.current.scrollWidth > scrolls.current.clientWidth) {
          scrolls.current.classList.add('cursor-grab');
        } else {
          scrolls.current.classList.remove('cursor-grab');
        }
      }
    });
  }, []);

  if (scrolls.current) {
    if (scrolls.current.scrollWidth > scrolls.current.clientWidth) {
      scrolls.current.classList.add('cursor-grab');
    } else {
      // console.log(scrolls.current.scrollWidth, scrolls.current.clientWidth);
      scrolls.current.classList.remove('cursor-grab');
    }
  }

  let pos = { top: 0, left: 0, x: 0, y: 0 };
  let ele;
  const mouseDownHandler = function (e) {
    if (scrolls.current) {
      ele = scrolls.current;
    }
    ele.style.cursor = 'grab';
    ele.style.userSelect = 'none';
    pos = {
      // The current scroll
      left: ele.scrollLeft,
      top: ele.scrollTop,
      // Get the current mouse position
      x: e.clientX,
      y: e.clientY,
    };

    document.addEventListener('mousemove', mouseMoveHandler);
    document.addEventListener('mouseup', mouseUpHandler);
  };
  const mouseMoveHandler = function (e) {
    if (scrolls.current) {
      ele = scrolls.current;
    }
    // How far the mouse has been moved
    const dx = e.clientX - pos.x;
    const dy = e.clientY - pos.y;

    // Scroll the element
    ele.scrollTop = pos.top - dy;
    ele.scrollLeft = pos.left - dx;
  };
  const mouseUpHandler = function () {
    if (scrolls.current) {
      ele = scrolls.current;
    }
    document.removeEventListener('mousemove', mouseMoveHandler);
    document.removeEventListener('mouseup', mouseUpHandler);

    ele.style.cursor = 'default';
    ele.style.removeProperty('user-select');
  };

  useEffect(() => {
    let a = document.querySelector(':root') as HTMLElement;
    let ethernetAddressesLength;
    if (ethereumAddresses) {
      ethereumAddresses?.length >= 5 ? (ethernetAddressesLength = '5') : (ethernetAddressesLength = (ethereumAddresses?.length + 1).toString());
    }
    if (ethernetAddressesLength) {
      // console.log(ethernetAddressesLength);
      a.style.setProperty('--card-quantity', ethernetAddressesLength);
    }
  }, [ethereumAddresses]);

  useEffect(() => {
    let r = document.querySelector(':root') as HTMLElement;
    if (footer.current) {
      r.style.setProperty('--footer-h', footer.current.offsetHeight + 'px');
    }
  });

  function renderDomain(name: string, tld: string, tokenId: string,chainID:number) {
    return (
      <div
        className={`${
          selectedDomain.includes(`${name}.${tld}`) === true ? 'bg-purple-700 text-white' : 'bg-white '
        } transfer-domain-con flex cursor-pointer flex-col items-center justify-center gap-2 rounded-xl border p-10 py-6 duration-200 hover:bg-purple-700 hover:text-white md:max-w-[325px]`}
        onClick={(x) => {
          handleSelectDomain(`${name}.${tld}`);
          handleSelectToken(`${tokenId}`);
        }}
      >
        {chainIcon[chainID]}
        <text className="mt-2 text-lg">{name}</text>
        <div className="font-medium">.{tld}</div>
      </div>
    );
  }

  function checkWalletOwnDomain() {
    let newList = domains;
    newList = newList.filter((x) => x.owner.address === selectWallet);
    if (newList.length > 0) return true;
    else return false;
  }
  function handleSell() {}

  useEffect(() => {
    window.onclick = function (event) {
      // console.log(event.target);
      if (!event.target.closest('.transfer-bg') && transferDomain) {
        setTransferDomain(false);
      }
    };
  });

  return (
    <>
      <motion.div
        variants={ani}
        onClick={() => setIsOrderDetailModalOpen(false)}
        data-show={isOrderDetailModalOpen}
        className="modal-con fixed z-[-1] grid h-full w-full place-items-center overflow-hidden opacity-0 backdrop-blur-sm"
      >
        <div className="flex h-full w-full flex-col overflow-hidden rounded-none shadow-2xl lg:h-auto lg:w-[32rem] lg:rounded-lg">
          <div className="flex items-center justify-between bg-blue-500 px-8 py-7 text-xl font-semibold text-white shadow-2xl lg:rounded-t-lg">
            <span>Order information</span>
            <Icon icon="ep:close-bold" className="h-7 w-auto cursor-pointer" onClick={() => setIsOrderDetailModalOpen(false)} />
          </div>
          <div className="max-h-auto z-50 flex h-full flex-col gap-5 overflow-y-scroll bg-white px-8 py-9 lg:max-h-[500px] lg:rounded-b-lg">
            {orderInModal && (
              <>
                <div className="flex flex-col items-start gap-0 lg:flex-row lg:items-center lg:gap-5">
                  <span className="flex-[2] text-lg font-medium">Order ID</span>
                  <span className="lg:text-md text-xs">{orderInModal.id}</span>
                </div>
                <div className="flex flex-col items-start gap-0 lg:flex-row lg:items-center lg:gap-5">
                  <span className="flex-[2] font-medium">Status</span>
                  <span>{_.startCase(_.toLower(orderInModal.status))}</span>
                </div>
                <div className="border-b"></div>
                {orderInModal.domains.map((orderDomain, i) => (
                  <div key={i} className="flex flex-col gap-5">
                    <div className="flex flex-col items-start gap-0 lg:flex-row lg:items-center lg:gap-5">
                      <span className="flex-[2] font-medium">Domain</span>
                      <span>{orderDomain.fqdn}</span>
                    </div>
                    <div className="flex flex-col items-start gap-0 lg:flex-row lg:items-center lg:gap-5">
                      <span className="flex-[2] font-medium">Owner Address</span>
                      <span className="lg:text-md text-xs">{orderDomain.owner['address']}</span>
                    </div>
                    <div className="flex flex-col items-start gap-0 lg:flex-row lg:items-center lg:gap-5">
                      <span className="flex-[2] font-medium">Price</span>
                      <span>$ {orderDomain.price} USD</span>
                    </div>
                    <div className="flex flex-col items-start gap-0 lg:flex-row lg:items-center lg:gap-5">
                      <span className="flex-[2] font-medium">Type</span>
                      <span>{_.startCase(_.toLower(orderDomain.type))}</span>
                    </div>
                    <div className="flex flex-col items-start gap-0 lg:flex-row lg:items-center lg:gap-5">
                      <span className="flex-[2] font-medium">Durations</span>
                      <span>{orderDomain.durations} years</span>
                    </div>
                    <div className="border-b"></div>
                  </div>
                ))}
                {orderInModal.successNowPayments && (
                  <div className="flex gap-5">
                    <span className="flex-[2] font-medium">Payment Gateway</span>
                    <span>Cryptocurrency</span>
                  </div>
                )}
                {orderInModal.successCheckout && (
                  <div className="flex gap-5">
                    <span className="flex-[2] font-medium">Payment Gateway</span>
                    <span>Credit Card</span>
                  </div>
                )}
                <div className="flex gap-5">
                  <span className="flex-[2] font-medium">Amount</span>
                  <span>$ {orderInModal.amount} USD</span>
                </div>
              </>
            )}
          </div>
        </div>
      </motion.div>

      {/*      <motion.div variants={ani} animate={isConnectingToWeb3Wallet ? 'show' : 'hide'} className="fixed z-[-1] grid h-full w-full place-items-center opacity-0 backdrop-blur-sm">*/}
      {/*        <div className="flex h-full w-full flex-col lg:h-[40rem] lg:w-[27rem] ">*/}
      {/*          <div className="flex flex-[1] items-center justify-between bg-blue-500 px-8 py-8 text-xl font-semibold text-white shadow-2xl lg:rounded-t-lg">*/}
      {/*            <span>Connect Wallet</span>*/}
      {/*            <Icon icon="ep:close-bold" className="h-7 w-auto cursor-pointer" onClick={() => setIsConnectingToWeb3Wallet(false)} />*/}
      {/*          </div>*/}
      {/*          <div className="relative grid flex-[12] grid-cols-1 grid-rows-2 gap-5 bg-white px-12 py-10 shadow-2xl lg:rounded-b-lg">*/}
      {/*            <div*/}
      {/*              className="flex h-full w-full cursor-pointer flex-col items-center justify-center gap-3 rounded-lg border font-semibold shadow-lg duration-200 hover:scale-110"*/}
      {/*              onClick={() => handleMetaMaskClick()}*/}
      {/*            >*/}
      {/*              <div className="grid h-12 place-items-center">*/}
      {/*                <Icon className="h-12 w-auto" icon="logos:metamask-icon" />*/}
      {/*              </div>*/}
      {/*              MetaMask*/}
      {/*            </div>*/}
      {/*            <div*/}
      {/*              className="flex w-full cursor-pointer flex-col items-center justify-center gap-3 rounded-lg border font-semibold shadow-lg duration-200 hover:scale-110"*/}
      {/*              onClick={() => handleWalletConnectClick()}*/}
      {/*            >*/}
      {/*              <div className="grid h-12 place-items-center">*/}
      {/*                <svg className="h-8 w-auto" version="1.1" baseProfile="basic" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 387.6 237.6">*/}
      {/*                  <path*/}
      {/*                    id="WalletConnect_00000073703063438220642730000002917717552236472496_"*/}
      {/*                    fill="#3B99FC"*/}
      {/*                    d="M79.4,46.4*/}
      {/*c63.2-61.9,165.7-61.9,228.9,0l7.6,7.4c3.2,3.1,3.2,8.1,0,11.2l-26,25.5c-1.6,1.5-4.1,1.5-5.7,0l-10.5-10.3*/}
      {/*c-44.1-43.2-115.6-43.2-159.7,0l-11.2,11c-1.6,1.5-4.1,1.5-5.7,0L71,65.8c-3.2-3.1-3.2-8.1,0-11.2L79.4,46.4z M362.1,99.1l23.2,22.7*/}
      {/*c3.2,3.1,3.2,8.1,0,11.2L280.8,235.3c-3.2,3.1-8.3,3.1-11.4,0c0,0,0,0,0,0l-74.1-72.6c-0.8-0.8-2.1-0.8-2.9,0c0,0,0,0,0,0*/}
      {/*l-74.1,72.6c-3.2,3.1-8.3,3.1-11.4,0c0,0,0,0,0,0L2.4,133c-3.2-3.1-3.2-8.1,0-11.2l23.2-22.7c3.2-3.1,8.3-3.1,11.4,0l74.1,72.6*/}
      {/*c0.8,0.8,2.1,0.8,2.9,0c0,0,0,0,0,0l74.1-72.6c3.2-3.1,8.3-3.1,11.4,0c0,0,0,0,0,0l74.1,72.6c0.8,0.8,2.1,0.8,2.9,0l74.1-72.6*/}
      {/*C353.8,96,358.9,96,362.1,99.1z"*/}
      {/*                  />*/}
      {/*                </svg>*/}
      {/*              </div>*/}
      {/*              <span>WalletConnect</span>*/}
      {/*            </div>*/}
      {/*          </div>*/}
      {/*          <div className="flex"></div>*/}
      {/*        </div>*/}
      {/*      </motion.div>*/}
      <Nav title="Transfer / Sell" />
      <div className="flex flex-1 flex-col overflow-y-auto p-10 px-6 pb-0 md:px-10">
        <div className="account-r-con flex w-full flex-1 flex-col gap-2 lg:gap-8">
          {customAddWalletErrorMessage && (
            <div className="relative rounded border border-red-400 bg-red-100 px-4 py-3 text-red-700" role="alert">
              <span className="block sm:inline">{customAddWalletErrorMessage}</span>
              <span className="absolute top-0 bottom-0 right-0 px-4 py-3" onClick={() => setCustomAddWalletErrorMessage(undefined)}>
                <svg className="h-6 w-6 fill-current text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <title>Close</title>
                  <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
                </svg>
              </span>
            </div>
          )}
          {isDuplicatedBindingEthereumAddress && (
            <div className="relative rounded border border-red-400 bg-red-100 px-4 py-3 text-red-700" role="alert">
              <strong className="font-bold">Duplicated Address!</strong>
              <span className="block sm:inline"> You have already bind this address into your account.</span>
              <span className="absolute top-0 bottom-0 right-0 px-4 py-3" onClick={() => setIsDuplicatedBindingEthereumAddress(false)}>
                <svg className="h-6 w-6 fill-current text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <title>Close</title>
                  <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
                </svg>
              </span>
            </div>
          )}
          <div className="text-xl ">Select the wallet</div>
          <Toaster />
          <div>
            <div ref={scrolls} onMouseDown={mouseDownHandler} className="wallet-bar z-[0] flex min-h-[100px] w-full shrink-0 gap-5 overflow-x-auto rounded-xl border p-10 ">
              {isEthereumAddressHasBeenSetup === false && managedAddress === '' && (
                <div className="flex min-h-[220px] shrink-0 items-center justify-center rounded-2xl border bg-red-500 px-5 py-5 font-medium text-white">
                  Please add your wallet address
                </div>
              )}
              {ethereumAddresses ? (
                <>
                  <motion.button
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    transition={{ ease: 'easeOut', duration: 0.3 }}
                    viewport={{ once: true }}
                    className="box-shadow flex min-h-[220px] w-full shrink-0 items-center justify-center gap-5 rounded-2xl border bg-white px-7 py-3 text-lg font-medium text-blue-500 duration-200 hover:bg-blue-500 hover:text-white md:w-[350px]"
                    onClick={() => {
                      handleAddWalletClick();
                      // setIsConnectingToWeb3Wallet(true);
                      // setIsDuplicatedBindingEthereumAddress(false);
                    }}
                  >
                    <Icon icon="ant-design:plus-outlined" className="h-8 w-auto" />
                    Add Wallet
                  </motion.button>
                  {/* {managedWallet && (
                    <motion.button
                      initial={{ x: -20, opacity: 0 }}
                      whileInView={{ x: 0, opacity: 1 }}
                      transition={{ ease: 'easeOut', duration: 0.5 }}
                      viewport={{ once: true }}
                      className={`box-shadow relative flex shrink-0 flex-col justify-between gap-5 overflow-hidden rounded-2xl border px-8 py-8 duration-200`}
                      onClick={() => {
                        setWallet(managedWallet.address);

                        // handleWalletRevailClick();
                        // setIsConnectingToWeb3Wallet(true);
                        // setIsDuplicatedBindingEthereumAddress(false);
                      }}
                    >
                      <div className="z-50 flex items-end gap-12">
                        <span className="mt-2 flex-[1] text-2xl font-medium text-black lg:mr-5">Managed Wallet</span>
                      </div>

                      <span className={`z-50 w-full truncate text-center font-medium lg:text-left`}>{managedWallet.address}</span>
                    </motion.button>
                  )} */}
                  {EthereumAddressesView}
                </>
              ) : (
                <Skeleton style={{ border: '1px solid rgb(229, 231, 235)' }} width="350px" height={210} borderRadius={'1.25rem'} baseColor={'white'} highlightColor={'#f1f1f1'} />
              )}
            </div>
          </div>

          <div className="flex-1" style={selectWallet ? { display: 'flex' } : { display: 'none' }}>
            <section className="mb-10 flex w-full flex-1 flex-col gap-5 overflow-hidden">
              <div>
                <span className="text-lg">Domains</span>
              </div>
              <div className="flex flex-1 flex-col justify-between rounded-xl border p-10">
                <div className="" style={checkWalletOwnDomain() === true ? { display: 'none' } : { display: 'flex' }}>
                  <span className="text-lg">No Domain in this Wallet</span>
                </div>
                <div className="domains-grid-con w-full ">
                  {domains.map((x) => {
                    if (x.owner.address === selectWallet) return renderDomain(x.name, x.tld.name, x.tokenId,x.tld.chainId);
                  })}
                </div>
                <div className="mt-5 text-right">
                  <button
                    data-show={selectedDomain.length > 1}
                    className={`btn-select mx-4 w-28 rounded-full border-2 border-[#93278f] px-2 py-2 text-[#93278f] duration-200 hover:bg-[#93278f] hover:text-white`}
                    onClick={(x) => {
                      selectedToken.map((i) => {
                        window.open(`https://opensea.io/assets/matic/${config.contractAddress[137].baseRegistrar}/${i}`, '_blank' + i);
                        console.log(i);
                      });
                    }}
                  >
                    Sell
                  </button>
                  <button
                    className={`w-28 rounded-full border-2 border-[#93278f] px-2 py-2 text-[#93278f] duration-200 hover:bg-[#93278f] hover:text-white`}
                    onClick={(x) => setTransferDomain(true)}
                    data-show={transferDomain}
                  >
                    Transfer
                  </button>
                </div>
              </div>
              <div data-show={transferDomain} className="bg-bound absolute top-0 left-0 z-[410] h-full w-full bg-gray-500/20">
                <div className="info transfer-bg border" data-show={transferDomain}>
                  <div className="box-shadow flex max-w-2xl flex-col gap-5 p-10">
                    {isUsingCorrectNetwork === false && (
                      <div id="alert-additional-content-2" className="mb-4 rounded-lg bg-red-100 p-4 dark:bg-red-200" role="alert">
                        <div className="flex items-center">
                          <h3 className="text-lg font-medium text-red-700 dark:text-red-800">Incorrect network</h3>
                        </div>
                        <div className="mt-2 mb-4 text-sm text-red-700 dark:text-red-800">
                          <p>Please switch your blockchain network to Polygon Mainnet</p>
                        </div>
                      </div>
                      // {/* </div>
                      //     <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4" role="alert">
                      //       <p className="font-bold">Incorrect network</p>
                      //       <p>Please switch your blockchain network to Polygon Mainnet</p>
                      //     </div> */}
                    )}
                    {isUsingOwnerAddress === false && (
                      // <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4" role="alert">
                      //   <p className="font-bold">Incorrect owner address</p>
                      //   <p>Please switch your wallet address to the domain owner address</p>
                      //   <p>
                      //     {domains.map((x) => {
                      //       <i>The current domain owner address: {x.owner.address} </i>;
                      //     })}
                      //   </p>
                      //   <p>
                      //     <i>Your current using wallet address: {account}</i>
                      //   </p>
                      // </div>
                      <div id="alert-additional-content-2" className="mb-4 rounded-lg bg-red-100 p-4 dark:bg-red-200" role="alert">
                        <div className="flex items-center">
                          <h3 className="text-lg font-medium text-red-700 dark:text-red-800">Incorrect owner address</h3>
                        </div>
                        <div className="mt-2 mb-4 text-sm text-red-700 dark:text-red-800">
                          <p>Please switch your wallet address to the domain owner address</p>
                          <p>
                            {domains.map((x) => {
                              <i>The current domain owner address: {x.owner.address} </i>;
                            })}
                          </p>
                          <p>
                            <i>Your current using wallet address: {account}</i>
                          </p>
                        </div>
                      </div>
                    )}
                    <p className="text-2xl font-medium">Transfer Domain To Other Address</p>
                    <span>
                      You must have the private key in order to manage your domain. if you transfer the domain to an exchange or any other account where you do not control the
                      private key, you will not be able to access your domain. Not your keys, not your domain.
                    </span>
                    <form onSubmit={(e) => handleFormSubmit(e)}>
                      <div className="flex w-full flex-col gap-2">
                        <span className="font-bold">Recipient address</span>
                        <input
                          id="newOwnerInput"
                          onChange={(e) => handleRecipantAddressChange(e)}
                          className="w-full rounded-md border bg-white px-5 py-3 outline-none duration-200 focus:outline-[#93278f]"
                          placeholder="Enter recipient address"
                          required
                        ></input>
                      </div>
                      {isValidRecipantAddress === false && (
                        <div className="my-4 rounded-lg bg-red-100 p-4 text-sm text-red-700 dark:bg-red-200 dark:text-red-800" role="alert">
                          <span className="font-medium">Please enter a valid Ethereum address.</span>
                        </div>
                      )}
                      <div className="mt-5 w-full border"></div>

                      <div className="flex w-full flex-col gap-5 py-5">
                        <div className="flex items-center ">
                          <input
                            id="cb1"
                            type="checkbox"
                            value=""
                            className="focus:ring-3 h-4 w-4 shrink-0 rounded border border-gray-300 bg-gray-50 focus:ring-blue-300 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-blue-600"
                            required
                          />

                          <label className="ml-2 text-sm text-gray-900 dark:text-gray-400">
                            I understand that I am transferring ownership of this domain and this action is irreversible
                          </label>
                        </div>
                        <div className="flex items-center ">
                          <input
                            id="cb2"
                            type="checkbox"
                            value=""
                            className="focus:ring-3 h-4 w-4 shrink-0 rounded border border-gray-300 bg-gray-50 focus:ring-blue-300 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-blue-600"
                            required
                          />

                          <label className="ml-2 text-sm text-gray-900 dark:text-gray-400">I'm not transferring to an exchange address</label>
                        </div>
                      </div>
                      <div className="w-full border"></div>
                      <div className="mt-5 text-right">
                        <p id="errormsg" style={{ color: 'red' }}></p>
                        <button
                          className="mt-2 w-full rounded-xl border-2 border-[#93278f] px-8 py-4 font-medium text-[#93278f] duration-200 hover:bg-[#93278f] hover:text-white"
                          onClick={async (e) => {
                            toast.promise(handleTransfer(), {
                              loading: 'Loading',
                              success: 'Success.',
                              error: 'Error, Please Check the wallet account',
                            });
                          }}
                          type="submit"
                          // onClick={(x) => setTransferDomain(false)}
                        >
                          Transfer Domain
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};

// export default withAuthenticationRequired(TransferOrSell, {
//   onRedirecting: () => <div className="load-center"></div>,
// });

const WrappedTransferOrSell = () => {
  function getLibrary(provider) {
    const _provider = new ethers.providers.Web3Provider(provider);
    _provider.pollingInterval = 12000;
    return _provider;
  }
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <TransferOrSell />
    </Web3ReactProvider>
  );
};
export default WrappedTransferOrSell;
