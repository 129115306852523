import Nav from '../../components/Nav/Nav';
import {motion} from 'framer-motion';
import {GlobalStateContext} from '../../context/GlobalStateContext';
import Select from 'react-select';
import {formatsByName} from '@ensdomains/address-encoder';
import {useContext, useEffect, useRef, useState} from 'react';
import {LookupAddress, LookUpText, TextType} from "@edns/sdk";

const recordTypeOptions = [
  { value: 'all', label: 'All' },
  { value: 'address', label: 'Address' },
  // {value:'NFT',label:'NFT'}
  { value: 'textRecord', label: 'Text Record' },
  // { value: "content", label: "Content" },
];
const textRecordTypeOptions = [
  { value: 'EMAIL', label: 'Email' },
  { value: 'URL', label: 'Url' },
  { value: 'AVATAR', label: 'Avatar' },
  { value: 'DESCRIPTION', label: 'Description' },
  { value: 'NOTICE', label: 'Notice' },
  { value: 'KEYWORDS', label: 'Keywords' },
  { value: 'DISCORD', label: 'Discord' },
  { value: 'GITHUB', label: 'Github' },
  { value: 'REDDIT', label: 'Reddit' },
  { value: 'TWITTER', label: 'Twitter' },
  { value: 'TELEGRAM', label: 'Telegram' },
  { value: 'DELEGATE', label: 'Delegate' },
];
interface IRecord {
  type: string;
  coinType: string;
  address: string;
}
interface ITextRecord {
  type: string;
  textType: string;
  address: string;
}
interface ILookupResult {
  fqdn: string;
  recordCoinType: string;
  subType: string;
}


const recordAddressCategoryOptions = Object.keys(formatsByName)
  .filter((c) => !c.match(/_LEGACY/))
  .sort()
  .map((v) => ({ value: v, label: v }));
const LookupResult = (props:ILookupResult) => {
  type OptionType = {
    value: any;
    label: any;
  };
  const { tlds } = useContext(GlobalStateContext);
  const [currentSelectedRecordCoinTypeLs, setCurrentSelectedRecordCoinTypeLs] = useState<string[] | undefined>([]);
  const [currentSelectedRecordTextypeLs, setCurrentSelectedRecordTextypeLs] = useState<string[] | undefined>([]);
  const [currentSelectedRecordCoinType, setCurrentSelectedRecordCoinType] = useState<string | undefined>("BTC");
  const [currentSelectedRecordTextype, setCurrentSelectedRecordTextype] = useState<string | undefined>("EMAIL");
  const [currentSelectedRecordType,setCurrentSelectedRecordType] = useState<string>("address");
  const [fqdn,setFqdn] = useState<string|undefined>(undefined)
  const [domainName,setDomainName] = useState<string|undefined>(undefined)
  const [tld,setTLD] = useState<string|undefined>(undefined)
  const input = useRef<HTMLInputElement>(null);
  const [tldOptions, setTldOptions] = useState<OptionType[]>([]);
  const [selectedTldOption, setSelectedTldOption] = useState<OptionType | undefined>(undefined);
  const [records, setRecords] = useState<IRecord[]>([]);
  const [textRecords, setTextRecords] = useState<ITextRecord[]>([]);
  const [load,setLoad] = useState(false)
  useEffect(() => {
    const _tldOptions = tlds.map((_tld) => ({ value: _tld.name, label: `.${_tld.name}` }));
    setTldOptions(_tldOptions);
    const metaTld = tlds.find((_tld) => _tld.name === 'meta');
    if (metaTld) setSelectedTldOption({ value: metaTld.name, label: `.${metaTld.name}` });
    setTLD("meta")

  }, [tlds]);

  useEffect(() => {
    FirstLoad().then(r => setLoad(!load));
  }, []);

  const lookup = async () => {

    const domain = `${domainName}.${selectedTldOption!.value}`

    if(fqdn!==domain){
      setFqdn(domain)
      setCurrentSelectedRecordCoinTypeLs([])
      setCurrentSelectedRecordTextypeLs([])
    }
    const textList = currentSelectedRecordTextypeLs!
    const coinList = currentSelectedRecordCoinTypeLs!
    if(currentSelectedRecordType === "address"){
      if(currentSelectedRecordCoinType){
        const found = currentSelectedRecordCoinTypeLs?.some(x=>x === currentSelectedRecordCoinType)
        if(!found)
          coinList.push(currentSelectedRecordCoinType)
        setCurrentSelectedRecordCoinTypeLs(coinList)
      }
    }else if(currentSelectedRecordType === "textRecord"){
      if(currentSelectedRecordTextype){
        const found = currentSelectedRecordTextypeLs?.some(x=>x === currentSelectedRecordTextype)
        if(!found)
          textList?.push(currentSelectedRecordTextype)
        setCurrentSelectedRecordTextypeLs(textList)
      }
    }
    await getWithPromiseAll(domain)
  };
  const FirstLoad = async () => {

    setFqdn(props.fqdn)
    setDomainName(props.fqdn.split('.')[0])
    setTLD(props.fqdn.split('.')[1])
    setSelectedTldOption({ value: props.fqdn.split('.')[1], label: `.${props.fqdn.split('.')[1]}` })
    if (props.recordCoinType == "address") {
      if(currentSelectedRecordCoinType){
        const found = currentSelectedRecordCoinTypeLs?.some(x=>x === props.subType)
        if(!found){
          const newList = currentSelectedRecordCoinTypeLs!
          newList.push( props.subType)
          setCurrentSelectedRecordCoinTypeLs(newList)
        }
      }
    } else if (props.recordCoinType == "textRecord") {
      if(currentSelectedRecordTextype){
        const found = currentSelectedRecordTextypeLs?.some(x=>x ===  props.subType)

        if(!found){
          const newList = currentSelectedRecordTextypeLs!
          newList.push( props.subType)
          setCurrentSelectedRecordTextypeLs(newList)
        }
      }
    }
    await getWithPromiseAll(props.fqdn)
  };

  const getWithPromiseAll = async(domainfqdn:string) => {
    const _records: IRecord[] = [];
    const _textrecords: ITextRecord[] = [];
    console.log({domainfqdn})
    console.log({currentSelectedRecordTextypeLs})
    await Promise.all(
      currentSelectedRecordCoinTypeLs!.map(async (x) => {

          const address = await LookupAddress(domainfqdn, x)
          if(address)
            _records.push({
              type:"Address",
              coinType:x,
              address:address
            })

      })
    )
    await Promise.all(
    currentSelectedRecordTextypeLs!.map(async (x)=>{
      console.log({x})
      const type:TextType = TextType[x]
      console.log({type})
      if(type){
        const content = await LookUpText(domainfqdn, type)
        if(content)
          _textrecords.push({
            type:"Text",
            textType:type,
            address:content
          })
      }


    })
    )
    console.log("set Record")
    setRecords(_records)
    setTextRecords(_textrecords)
  }

  const customStyles = {
    control: (base) => ({
      ...base,
      width: '100%',
      height: '100%',
      border: '2px solid rgb(229, 231, 235)',
      outline: 'none',
      padding: '0.375rem 0',
      borderRadius: '0.5rem',
    }),
  };
  function handleChange(options) {
    setSelectedTldOption(options)
  };
  function handleAddAddressRecord(record:string|undefined){
    setCurrentSelectedRecordCoinType(record)
  }
  function handleAddTextRecord(record:string|undefined){
    setCurrentSelectedRecordTextype(record)
  }

  return (
    <div className="">
      <Nav home={true} title="Look Up Result" />

      <div className="h-full overflow-y-auto">
        <div className="my-2  flex-1 flex-col p-10 px-6 pt-6 md:px-10 ">
          <div className='box-shadow bg-white" relative  mt-2 flex w-full min-w-0 flex-1 flex-col break-words rounded-xl border'>
            <div className="justify-center px-5 py-10">
              <motion.div className="relative">
                <h1 className="text-center text-lg font-bold sm:text-sm">Enter Domain and Select Record Type below</h1>
              </motion.div>

              <motion.div className="relative mt-10 flex  w-full flex-col gap-5 text-xl md:h-14 md:flex-row  md:gap-5">
                <div className="relative w-full flex-col">
                  <input
                    id="lookUpDomain"
                    ref={input}
                    onChange={(e) => {
                      // setError(!regex.test(e.currentTarget.value));
                      setDomainName(e.currentTarget.value);
                    }}
                    className="main input md:text-md relative  h-full w-full rounded-lg border-2 px-10 py-3.5 text-sm outline-blue-400 2xl:px-12"
                    type="text"
                    placeholder="Enter Valid Domain"
                  />
                  <svg className="absolute left-3 top-[16px] h-5 w-5 fill-current text-3xl text-gray-300 2xl:left-5 2xl:top-[18px]" x="0px" y="0px" viewBox="0 0 56.966 56.966">
                    <path d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z" />
                  </svg>
                </div>
                <div className="search-con z-[80] flex h-full items-center rounded-md text-base outline-none">
                  {tldOptions && <Select options={tldOptions} value={selectedTldOption} styles={customStyles} onChange={(x)=>handleChange(x)} className="h-full w-full text-center " />}
                </div>
              </motion.div>
              <motion.div className="text-md relative mt-3 mb-3 flex  w-full flex-col gap-5 text-center md:flex-row">
                <label className="py-4" htmlFor="">
                  Record Type:
                </label>

                <button
                  className={`rounded border-2 px-6 py-2 ${
                    currentSelectedRecordType === recordTypeOptions[1].value ? 'border-[#93278f] bg-[#93278f] text-white' : 'border-[#93278f] text-[#93278f]'
                  }`}
                  onClick={() => setCurrentSelectedRecordType(recordTypeOptions[1].value)}
                >
                  Address
                </button>{' '}
                <button
                  className={`rounded border-2 px-4 py-2 ${
                    currentSelectedRecordType === recordTypeOptions[2].value ? 'border-[#93278f] bg-[#93278f] text-white' : 'border-[#93278f] text-[#93278f]'
                  }`}
                  onClick={() => setCurrentSelectedRecordType(recordTypeOptions[2].value)}
                >
                  Text Record
                </button>
              </motion.div>

              <motion.div
                animate={currentSelectedRecordType !== recordTypeOptions[0].value ? 'hidden' : 'show'}
                className={`relative mt-3 mb-3 flex h-full w-full flex-col gap-5 text-center text-sm md:flex-row `}
              >
                {currentSelectedRecordType === recordTypeOptions[1].value && (
                  <div className="flex flex-col md:flex-row ">
                    <label className="py-2 pr-4" htmlFor="">
                      Record Subtype:
                    </label>
                    <div className="relative flex py-2">
                      <Select
                        defaultValue={recordAddressCategoryOptions.find((v) => v.value === 'BTC')}
                        options={recordAddressCategoryOptions}
                        onChange={(e) => handleAddAddressRecord(e?.value)}
                        styles={customStyles}
                        className={`fixed top-0 left-0 h-full w-full text-center`}
                      />
                    </div>
                  </div>
                )}
                {currentSelectedRecordType === recordTypeOptions[2].value && (
                  <div className="flex">
                    <label className="py-2 pr-4" htmlFor="">
                      Record Subtype:
                    </label>
                    <div className="relative flex py-2">
                      <Select
                        defaultValue={textRecordTypeOptions[0]}
                        options={textRecordTypeOptions}
                        onChange={(e) => handleAddTextRecord(e?.value)}
                        styles={customStyles}
                        className={`fixed top-0 left-0 z-[80] h-full w-full text-center`}
                      />
                    </div>
                  </div>
                )}
              </motion.div>
              <motion.div className="relative text-right">
                <button
                  className="relative rounded border-2 border-[#93278f] px-4 py-4 text-[#93278f] hover:bg-[#93278f] hover:text-white"
                  onClick={() => {
                    lookup();
                  }}
                >
                  Look up Domain
                </button>
              </motion.div>
            </div>
          </div>
        </div>
        <div className="h-full  flex-1 flex-col  px-6  md:px-10 ">
          <div className=' box-shadow bg-white" relative mb-2 flex w-full min-w-0 flex-1 flex-col break-words rounded-xl border'>
            <div className="justify-center px-5 py-10">
              <div className="">
                <div>
                  <p className="relative flex-1 text-center text-lg ">
                    <span className="font-normal">Result for&nbsp;</span>
                    <span className="truncate font-semibold">{fqdn?.split('.')[0]}</span>
                    <span className="font-semibold">.{fqdn?.split('.')[1]}</span>
                  </p>
                </div>
                <div className="relative">
                  <table className=" relative mt-5  min-w-full table-fixed shadow-md">
                    <thead>
                      <tr className="rounded-t-lg border bg-[#93278f]  text-left text-white">
                        <th className="w-[15%] border border-transparent">Type</th>
                        <th className="w-[15%] border border-transparent">Category</th>
                        <th className="w-[60%] border border-transparent">Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      {records.length > 0 ? (
                        records.map((record, i) => (
                          <tr className="rounded-b-lg  text-left " key={i}>
                            <td className="border px-2 py-2">{record.type}</td>

                            <td className="border px-2 py-2">{record.coinType}</td>
                            <td className="border px-2 py-2">{record.address}</td>
                          </tr>
                        ))
                      ) : (
                        <></>
                      )}
                      {textRecords.length > 0 ? (
                        textRecords.map((record, i) => (
                          <tr className={`rounded-b-lg  text-left `} key={i}>
                            <td className="border px-2 py-2">{record.type}</td>
                            <td className="border px-2 py-2">{record.textType}</td>
                            <td className="border px-2 py-2">{record.address}</td>
                          </tr>
                        ))
                      ) : (
                        <></>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default LookupResult;
