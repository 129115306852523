import '../../App.scss';
import {useNavigate} from 'react-router-dom';
import {Icon} from '@iconify/react';
import Nav from '../../components/Nav/Nav';
import {motion} from 'framer-motion';
import React, {useContext, useEffect, useRef, useState} from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import {creatrOrder} from '../../api/create-order';
import {applyPromotionalCode} from '../../api/apply-promotional-code';
import {removePromotionalCode} from '../../api/remove-promotional-code';
import {GlobalStateContext} from '../../context/GlobalStateContext';
import Domain from '../../components/cart/Domain';
import {getUser} from '../../api/get-user';

// const ani = {
//   hide: {
//     opacity: 0,
//     zIndex: '-1',
//     transition: { zIndex: { delay: 0.35 } },
//     duration: 0.2,
//   },
//   show: { opacity: 1, zIndex: '100', transition: { duration: 0.2 } },
// };

function Cart() {
  const button = useRef<HTMLButtonElement>(null);

  const { user, accessToken, shoppingCart, setShoppingCart, isBillingAddressFinishSetup, isEthereumAddressesFinishSetup, isManagedWallet,updateOrders,updateShoppingCart,updateShoppingCartDomainsCount  } = useContext(GlobalStateContext);

  const [isCreatingOrder, setIsCreatingOrder] = useState<boolean>(false);
  const [orderDelete, setOrderDelete] = useState<boolean>(false);
  const [isInvalidPromotionalCode, setIsInvalidPromotionalCode] = useState<boolean>(false);
  const [promotionalCode, setPromotionalCode] = useState<string | undefined>(undefined);
  const [managedAddress, setManagedAddress] = useState<string>('');
  // const [haveReseller,setHaveReseller] = useState(true)
  const [errorMsg,setErrorMsg] = useState<string|undefined>(undefined)
  // const domainUpdated = (shoppingCart: IShoppingCart) => {
  //   setShoppingCart(shoppingCart);
  // };

  // const domainDeleted = (shoppingCart: IShoppingCart) => {
  //   setShoppingCart(shoppingCart);
  // };

  const navigate = useNavigate();

  const checkoutAndCreateOrder = async () => {
    if (accessToken) {
      setIsCreatingOrder(true);
      const order = await creatrOrder(accessToken);
      // await createCoingatePay(accessToken, order.id);
      // if (config.env === 'development') await createUnionPay(accessToken, order.id);
      // if (user?.billingAddress.country != null) {
      //   await createCheckoutPay(accessToken, order.id, user.billingAddress.country, false);
      // }
      await updateOrders()
      await updateShoppingCart()
      await updateShoppingCartDomainsCount()

      setIsCreatingOrder(false);
      if (order.amount === 0) {
        navigate(`/payment/success/${order.id}`);
      } else {
        navigate(`/checkout?id=${order.id}`);
      }
    }
  };

  useEffect(() => {
    async function exec() {
      if (accessToken) {
        const _user = await getUser(accessToken);
        try {
          _user.managedWallet?.address !== undefined ? setManagedAddress(_user.managedWallet.address) : setManagedAddress('undefined');
        } catch (e) {
          console.error(e);
        }
      }
    }
    exec();
  }, [accessToken]);

  // // Get user information
  // useEffect(() => {
  //   async function exec() {
  //     if (user) {
  //       if (!user.billingAddress.country) {
  //         setIsMissingBillingAddress(true);
  //       }
  //       if (user.ethereumAddresses.length === 0) {
  //         setIsMissingEthereumAddress(true);
  //       }
  //     }
  //   }
  //   exec();
  // }, [user]);

  const handleApplyPromotionCode = async () => {
    if (accessToken && promotionalCode) {
      try {
        // setHaveReseller(true)
        setErrorMsg(undefined)
        const _cart = await applyPromotionalCode(accessToken, promotionalCode);
        setShoppingCart(_cart);
        setPromotionalCode('');
        setIsInvalidPromotionalCode(false);
      } catch(e) {
        const error:any = e

        if(error.response.data.message == 'User have not bound Reseller.' || error.response.data.message==='PromotionalCode Not available for Renewal.'){
          setErrorMsg(error.response.data.message)
        }
        setIsInvalidPromotionalCode(true);
      }
    }
  };

  const handleRemovePromotionCode = async () => {
    if (accessToken) {
      const _cart = await removePromotionalCode(accessToken);
      setShoppingCart(_cart);
      setPromotionalCode(undefined);
    }
  };

  return (
    <div className="flex h-screen flex-col">
      <Nav cartCount={orderDelete} setCartCount={setOrderDelete} title="Shopping Cart" />
      {!isEthereumAddressesFinishSetup && managedAddress === 'undefined' && (
        <div data-show={!isEthereumAddressesFinishSetup} className="missingCon cart box-shadow relative mt-2 mb-0 border px-10 py-5 lg:rounded-md">
          <div className="absolute top-0 left-0 h-full w-2 rounded-l-md bg-red-500"></div>
          <div className="absolute left-0 top-0 h-full w-[10px] rounded-l-md bg-[#ea6667]"></div>
          <div className="flex items-center gap-4">
            <Icon className="h-20 w-auto text-[#ea6667] md:h-10" icon="radix-icons:cross-circled" />
            <div className="flex flex-col justify-between">
              <p className="text-lg">Alert</p>
              <span onClick={() => navigate('/account')} className={`hover:underline ${!isEthereumAddressesFinishSetup ? 'block' : 'hidden'}`}>
                Please bind at least one wallet address before adding domain into shopping cart
              </span>
              {/* <span onClick={() => navigate('/setting')} className={`hover:underline ${!isBillingAddressFinishSetup ? 'block' : 'hidden'}`}>
                Click here to setup your billing address before adding domain into shopping cart
              </span> */}
            </div>
          </div>
        </div>
      )}
      <div className="flex flex-1 overflow-y-hidden">
        <div className="flex h-full w-full flex-[7] flex-col overflow-scroll">
          <section className="flex w-full flex-col px-6 pt-6 pb-12 md:px-10 lg:pb-20">
            {shoppingCart && user ? (
              <motion.div
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: true }}
                className="flex w-full flex-col justify-between gap-0 lg:min-h-[525px] lg:flex-row lg:gap-16"
              >
                <div className="flex flex-[9] flex-col ">
                  <div className="flex justify-between gap-0 border-b px-0  text-gray-400 md:pt-3 lg:gap-10"></div>
                  {accessToken &&
                    shoppingCart &&
                    shoppingCart.domains.map((domain, i) => (
                      <Domain domain={domain} user={user} index={i} accessToken={accessToken} popup={true} length={shoppingCart.domains.length - 1} />
                    ))}
                </div>
                <div className="total-box shoppingCart-right order box-shadow relative mt-6 flex h-auto w-full max-w-[420px] flex-[3] flex-col justify-between gap-5 self-start rounded-xl border bg-white py-8 px-6 text-gray-700">
                  <span className="block w-full border-b-2 border-gray-500 pb-2 text-2xl font-medium">Order Summary</span>
                  <div className="text-md flex-[1] flex-col">
                    <div className="flex justify-between pt-2">
                      <span>Subtotal</span>
                      <span className="font-semibold">USD {shoppingCart.subtotal}</span>
                    </div>
                  </div>

                  {!!shoppingCart.discounted && (
                    <div className="text-md flex-[1] flex-col">
                      <div className="flex justify-between pb-2">
                        <span>Discounts</span>
                        <span className="font-semibold">- USD {shoppingCart.discounted}</span>
                      </div>
                    </div>
                  )}

                  <div className="mt-5 flex items-end justify-between pb-2 text-2xl font-semibold">
                    <span>Total</span>
                    <span>USD {shoppingCart.total}</span>
                  </div>
                  {shoppingCart.domains.length > 0 && (
                    <div className="relative mt-2">
                      <div className="absolute top-4 left-3">
                        <i className="fa fa-search z-20 text-gray-400 hover:text-gray-500"></i>
                      </div>
                      <input
                        type="text"
                        className="z-0 h-14 w-full appearance-none rounded rounded-lg border pl-4 pr-24 shadow focus:shadow focus:outline-none"
                        placeholder="Promotional Code"
                        disabled={!!shoppingCart.promotionalCode}
                        value={promotionalCode}
                        onChange={(e) => {
                          setPromotionalCode(e.target.value);
                          setIsInvalidPromotionalCode(false);
                        }}
                      />
                      <div className="absolute top-2 right-2">
                        <button
                          type="button"
                          className={`mb-2 rounded-md bg-gradient-to-br from-purple-600 to-blue-500 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-gradient-to-bl focus:outline-none ${
                            !!shoppingCart.promotionalCode ? 'opacity-50' : 'opacity-100'
                          }`}
                          disabled={!!shoppingCart.promotionalCode}
                          onClick={() => handleApplyPromotionCode()}
                        >
                          Apply
                        </button>
                      </div>
                    </div>
                  )}
                  {isInvalidPromotionalCode && (
                    <div className="border-t border-b border-red-500 bg-red-100 px-4 py-3 text-red-700" role="alert">
                      <p className="font-bold">Invalid Code</p>
                      {/*{errorMsg===undefined ?*/}
                      {/*  <p className="text-sm">The code you have entered does not exist or may already be used.</p>*/}
                      {/*    :*/}
                      <p className="text-sm">{errorMsg===undefined? "The code you have entered does not exist or may already be used.": `${errorMsg}`}</p>
                      {/*// }*/}

                    </div>
                  )}

                  {shoppingCart.promotionalCode && (
                    <div className="inline-flex">
                      <button className="rounded-l-lg border border-gray-200 bg-white py-2 px-4 text-sm font-bold text-blue-700 hover:bg-gray-100 focus:z-10 focus:text-blue-700 focus:ring-2 focus:ring-blue-700">
                        {shoppingCart.promotionalCode?.code}
                      </button>
                      <button
                        className={`rounded-r-md border border-gray-200 bg-white py-1 px-2 text-sm font-medium text-gray-900 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:text-blue-700 focus:ring-2 focus:ring-blue-700`}
                        onClick={() => handleRemovePromotionCode()}
                      >
                        <Icon icon="emojione:cross-mark" className="h-4 w-auto" />
                      </button>
                    </div>
                  )}
                  {isManagedWallet ? (
                    <button
                      ref={button}
                      // className={`flex w-full text-xl font-semibold ${
                      //   !isBillingAddressFinishSetup ? 'opacity-50' : 'opacity-100'
                      // } btn-now mt-1 items-center justify-center gap-2 rounded-lg py-5 text-white`}
                      className={`btn-now mt-1 flex w-full items-center justify-center gap-2 rounded-lg py-5 text-xl font-semibold text-white opacity-100`}
                      onClick={() => {
                        if (button.current) {
                          button.current.disabled = true;
                        }
                        checkoutAndCreateOrder();
                        // setI(true);
                      }}
                      disabled={!isManagedWallet ? !isEthereumAddressesFinishSetup || shoppingCart.domains.length === 0 : shoppingCart.domains.length === 0}
                    >
                      <motion.span animate={isCreatingOrder ? { display: 'none' } : { display: 'block' }}>Checkout</motion.span>
                      <motion.div animate={isCreatingOrder ? { display: 'block' } : { display: 'none' }}>
                        <Icon icon="eos-icons:loading" className="h-7 w-auto" />
                      </motion.div>
                    </button>
                  ) : (
                    <button
                      ref={button}
                      className={`flex w-full text-xl font-semibold ${
                        !isEthereumAddressesFinishSetup && managedAddress === 'undefined' ? 'opacity-50' : 'opacity-100'
                      } btn-now mt-1 items-center justify-center gap-2 rounded-lg py-5 text-white`}
                      onClick={() => {
                        if (button.current) {
                          button.current.disabled = true;
                        }
                        checkoutAndCreateOrder();
                        // setI(true);
                      }}
                      disabled={(!isEthereumAddressesFinishSetup || shoppingCart.domains.length === 0) && managedAddress === 'undefined'}
                    >
                      <motion.span animate={isCreatingOrder ? { display: 'none' } : { display: 'block' }}>Checkout</motion.span>
                      <motion.div animate={isCreatingOrder ? { display: 'block' } : { display: 'none' }}>
                        <Icon icon="eos-icons:loading" className="h-7 w-auto" />
                      </motion.div>
                    </button>
                  )}
                  {/* </Link> */}
                </div>
              </motion.div>
            ) : (
              <motion.div
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: true }}
                className="flex w-full flex-col justify-between gap-0 lg:min-h-[525px] lg:flex-row lg:gap-16"
              >
                <div className="flex flex-[9] flex-col ">
                  <div className="flex justify-between gap-0 border-b py-3 text-gray-400 md:px-6 lg:gap-10">
                    {/* <div className="flex-[2]">Domain</div>
                    <div className="flex-[1]">Price</div>
                    <div className="flex-[1] text-center lg:text-left">Year</div>
                    <div className="flex-[1] text-right lg:text-left">Total</div> */}
                  </div>
                  <div className="flex flex-col gap-5 md:px-6 lg:gap-0">
                    <div className="relative flex justify-between gap-5 py-12 lg:gap-10">
                      <div className="flex-[2] text-base lg:text-lg">
                        <Skeleton />
                      </div>
                      <div className="flex-[1] overflow-hidden text-ellipsis whitespace-nowrap">
                        <Skeleton />
                      </div>
                      <div className=" flex-[1]">
                        <Skeleton />
                      </div>
                      <div className="flex-[1] text-right lg:text-left">
                        <Skeleton />
                      </div>
                      <div className="absolute right-0 bottom-[-10px] lg:right-3 lg:top-[40px]"></div>
                    </div>
                    <div className="w-full border-b pb-10 lg:w-[100%]">
                      <Skeleton width={window.innerWidth < 1024 ? '100%' : '70%'} height={30} />
                    </div>
                  </div>
                </div>
                <div className="shoppingCart-right order relative mt-6 flex h-auto w-full flex-[3] flex-col justify-between gap-5 self-start rounded-xl border-2 bg-white py-8 px-6 text-gray-700">
                  <span className="block w-full border-b-2 border-gray-200 pb-2 text-2xl font-medium">
                    <Skeleton />
                  </span>
                  <div className="w-full">
                    <div className="w-full py-2">
                      <Skeleton />
                    </div>
                  </div>

                  <div className="mt-5 w-full pt-2 pb-2">
                    <Skeleton width={'100%'} height={30} />
                  </div>
                  <Skeleton className="py-5" borderRadius="0.5rem" />
                </div>
              </motion.div>
            )}
          </section>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
}

export default Cart;
