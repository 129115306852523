import axios from 'axios';
import config from '../config';
import { IShoppingCart } from '../interface';

export async function removePromotionalCode(accessToken: string): Promise<IShoppingCart> {
  const url = new URL(`/cart/code`, `${config.api.endpoint}`);
  const response = await axios.delete<IShoppingCart>(url.href, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });
  return response.data;
}
