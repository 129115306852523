import React, {createContext, useEffect, useMemo, useState} from 'react';
import _ from 'lodash';
import {IDomain, IEthereumAddress, IShoppingCart, ITld, IUser} from '../interface';
import {useAuth0} from '@auth0/auth0-react';
import {getUser} from '../api/get-user';
import {getShoppingCart} from '../api/get-shopping-cart';
import {getShoppingCartDomainsCount} from '../api/get-shopping-cart-domains-count';
import {getEthereumAddresses} from '../api/get-ethereum-addresses';
import {getDomains} from '../api/get-domains';
import {IOrder} from '../interface/order.interface';
import {getOrders} from '../api/get-orders';
import {IBillingAddress} from '../interface/billing-address.interface';
import {getBillingAddress} from '../api/get-billing-address';
import {getTlds} from '../api/get-tlds';
import {getDefaultEthereumAddress} from '../api/get-default-ethereum-address';
import {IuseWeb3, useWeb3, useWeb3_TestNet} from "../components/web3/Web3";
import Config from "../config";

export interface IGlobalStateContext {
  isAuthenticated: boolean;
  accessToken: string | undefined;
  tlds: ITld[];
  user: IUser | undefined;
  billingAddress: IBillingAddress | undefined;
  setBillingAddress: (billingAddress: IBillingAddress) => void;
  defaultEthereumAddress: IEthereumAddress | undefined;
  ethereumAddresses: IEthereumAddress[];
  ethereumAddressesLength: number;
  domains: IDomain[];
  orders: IOrder[];
  shoppingCart: IShoppingCart | undefined;
  setShoppingCart: (_cart: IShoppingCart) => void;
  shoppingCartDomainsCount: number;
  updateUser: () => Promise<void>;
  updateBillingAddress: () => Promise<void>;
  updateDefaultEthereumAddress: () => Promise<void>;
  updateEthereumAddresses: () => Promise<void>;
  updateDomains: () => Promise<void>;
  updateOrders: () => Promise<void>;
  updateShoppingCart: () => Promise<void>;
  updateShoppingCartDomainsCount: () => Promise<void>;
  isBillingAddressFinishSetup: boolean;
  isEthereumAddressesFinishSetup: boolean;
  isManagedWallet: boolean;
  useWeb3Function:IuseWeb3
}

export const GlobalStateContext = createContext<IGlobalStateContext>({
  isAuthenticated: false,
  accessToken: undefined,
  tlds: [],
  user: undefined,
  billingAddress: undefined,
  setBillingAddress: () => {},
  defaultEthereumAddress: undefined,
  ethereumAddresses: [],
  ethereumAddressesLength: -1,
  domains: [],
  orders: [],
  shoppingCart: undefined,
  setShoppingCart: () => {},
  shoppingCartDomainsCount: 0,
  updateUser: async () => {},
  updateBillingAddress: async () => {},
  updateDefaultEthereumAddress: async () => {},
  updateEthereumAddresses: async () => {},
  updateDomains: async () => {},
  updateOrders: async () => {},
  updateShoppingCart: async () => {},
  updateShoppingCartDomainsCount: async () => {},
  isBillingAddressFinishSetup: false,
  isEthereumAddressesFinishSetup: false,
  isManagedWallet: false,
  useWeb3Function:Config.env == "development" ? useWeb3_TestNet : useWeb3
});

export default function GlobalStateProvider(props: React.PropsWithChildren<any>) {
  const { isAuthenticated: _isAuthenticated, getAccessTokenSilently } = useAuth0();

  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(_isAuthenticated);
  const [accessToken, setAccessToken] = useState<string | undefined>(undefined);

  const [tlds, setTlds] = useState<ITld[]>([]);

  const [user, setUser] = useState<IUser | undefined>(undefined);
  const [billingAddress, setBillingAddress] = useState<IBillingAddress | undefined>(undefined);
  const [defaultEthereumAddress, setDefaultEthereumAddress] = useState<IEthereumAddress | undefined>(undefined);
  const [ethereumAddresses, setEthereumAddresses] = useState<IEthereumAddress[]>([]);
  const [ethereumAddressesLength, setEthereumAddressesLength] = useState<number>(-1);
  const [domains, setDomains] = useState<IDomain[]>([]);
  const [orders, setOrders] = useState<IOrder[]>([]);

  const [isBillingAddressFinishSetup, setIsBillingAddressFinishSetup] = useState<boolean>(false);
  const [isEthereumAddressesFinishSetup, setIsEthereumAddressesFinish] = useState<boolean>(false);
  const [isManagedWallet, setIsManagedWallet] = useState<boolean>(false);

  const [shoppingCart, setShoppingCart] = useState<IShoppingCart | undefined>(undefined);
  const [shoppingCartDomainsCount, setShoppingCartDomainsCount] = useState<number>(0);

  const _setShoppingCart = (_shoppingCart: IShoppingCart) => {
    const __shoppingCart = _.cloneDeep(_shoppingCart);
    __shoppingCart.domains = _.sortBy(_shoppingCart.domains, 'id');
    return setShoppingCart(__shoppingCart);
  };

  const updateTlds = async () => {
    const _tlds = await getTlds();
    setTlds(_tlds);
  };
  const updateUser = async () => {
    if (accessToken) {
      const _user = await getUser(accessToken);
      console.log({_user})
      setUser(_user);
    }
  };
  const updateBillingAddress = async () => {
    if (accessToken) {
      const _billingAddress = await getBillingAddress(accessToken);
      setBillingAddress(_billingAddress);
    }
  };
  const updateShoppingCart = async () => {
    if (accessToken) {
      const _shoppingCart = await getShoppingCart(accessToken);
      _setShoppingCart(_shoppingCart);
    }
  };
  const updateShoppingCartDomainsCount = async () => {
    if (accessToken) {
      const _shoppingCartDomainsCount = await getShoppingCartDomainsCount(accessToken);
      setShoppingCartDomainsCount(_shoppingCartDomainsCount);
    }
  };
  const updateDefaultEthereumAddress = async () => {
    if (accessToken) {
      const _defaultEthereumAddress = await getDefaultEthereumAddress(accessToken);
      setDefaultEthereumAddress(_defaultEthereumAddress);
    }
  };
  const updateEthereumAddresses = async () => {
    if (accessToken) {
      const _addresses = await getEthereumAddresses(accessToken);
      setEthereumAddresses(_addresses);
      setEthereumAddressesLength(_addresses.length);
    }
  };
  const updateManagedWallet = async () => {
    if (accessToken) {
      const _managedWallet = (await getUser(accessToken)).managedWallet;
      setIsManagedWallet(!!_managedWallet);
    }
  };
  const updateDomains = async () => {
    if (accessToken) {
      const _domains = await getDomains(accessToken);
      setDomains(_domains);
    }
  };
  const updateOrders = async () => {
    if (accessToken) {
      const _orders = await getOrders(accessToken);
      setOrders(_.orderBy(_orders.orders, 'createdAt', 'desc'));
    }
  };


  useEffect(() => {
    setIsAuthenticated(_isAuthenticated);
  }, [_isAuthenticated]);

  useEffect(() => {
    async function exec() {
      if (isAuthenticated) {
        const _accessToken = await getAccessTokenSilently();
        setAccessToken(_accessToken);
      }
    }
    exec();
  }, [isAuthenticated]);

  useEffect(() => {
    if (billingAddress)
      setIsBillingAddressFinishSetup(
        !!billingAddress.addressLine1 &&
          !!billingAddress.city &&
          !!billingAddress.state &&
          !!billingAddress.zip &&
          !!billingAddress.country &&
          !!billingAddress.firstname &&
          !!billingAddress.lastname,
      );
  }, [billingAddress]);

  useEffect(() => {
    setIsEthereumAddressesFinish(!!ethereumAddresses.length);
  }, [ethereumAddresses]);

  // Init
  useEffect(() => {
    updateTlds();
    updateUser();
    updateBillingAddress();
    updateEthereumAddresses();
    updateDomains();
    updateOrders();
    updateShoppingCart();
    updateShoppingCartDomainsCount();
    updateDefaultEthereumAddress();
    updateManagedWallet();
  }, [accessToken]);

  const useWeb3Function = Config.env == "development" ? useWeb3_TestNet : useWeb3 ;
  const context = useMemo<IGlobalStateContext>(
    () => ({
      isAuthenticated,
      accessToken,
      tlds,
      user,
      billingAddress,
      setBillingAddress,
      defaultEthereumAddress,
      ethereumAddresses,
      ethereumAddressesLength,
      domains,
      orders,
      shoppingCart,
      shoppingCartDomainsCount,
      setShoppingCart: _setShoppingCart,
      updateUser,
      updateBillingAddress,
      updateDefaultEthereumAddress,
      updateEthereumAddresses,
      updateDomains,
      updateOrders,
      updateShoppingCart,
      updateShoppingCartDomainsCount,
      updateManagedWallet,
      isBillingAddressFinishSetup,
      isEthereumAddressesFinishSetup,
      isManagedWallet,
      useWeb3Function
    }),
    [
      isAuthenticated,
      accessToken,
      tlds,
      user,
      billingAddress,
      defaultEthereumAddress,
      ethereumAddresses,
      domains,
      orders,
      shoppingCart,
      shoppingCartDomainsCount,
      isBillingAddressFinishSetup,
      isEthereumAddressesFinishSetup,
      isManagedWallet,
    ],
  );

  return (
    <React.Fragment>
      <GlobalStateContext.Provider value={context}>{props.children}</GlobalStateContext.Provider>
    </React.Fragment>
  );
}
