import { Card, CardBody, CardFooter, CardHeader, Typography } from '@material-tailwind/react';
import { ethers } from 'ethers';
import { FetchWrapper } from 'use-nft';
import { Fetcher, FetcherDeclaration, NftMetadata } from 'use-nft/dist/src/types';
import { useEffect, useState } from 'react';
import { Icon } from '@iconify/react';

export interface IRPCJSON {
  [chainID: number]: string;
}

export const prcMap: IRPCJSON = {
  1: 'https://rpc.ankr.com/eth',
  137: 'https://polygon-rpc.com',
  56: 'https://bsc-dataseed2.binance.org',
  43114: 'https://rpc.ankr.com/avalanche',
  250: 'https://fantom-mainnet.public.blastapi.io',
  42161: 'https://arb1.arbitrum.io/rpc',
  10: 'https://mainnet.optimism.io',
};
//
// function Nft(props:{chainID:number,contractAddress:string, tokenId:number}) {
//     const { loading, error, nft } = useNft(
//         "0x53a0018f919bde9C254bda697966C5f448ffDDcB",
//         "89145472301929620862609683860974771909133622630047277108368202589807602967219"
//     )
// // nft.loading is true during load.
//     if (loading) return <>Loading…</>
//
//     // nft.error is an Error instance in case of error.
//     if (error || !nft) return <>Error.</>
//     return (
//         <Card className="w-96">
//             <CardHeader color="blue" className="relative h-56">
//                 <img
//                     src={nft.image}
//                     alt="img-blur-shadow"
//                     className="h-full w-full"
//                 />
//             </CardHeader>
//             <CardBody className="text-center">
//                 <Typography variant="h5" className="mb-2">
//                     {nft.name}
//                 </Typography>
//                 <Typography>
//                     {nft.description}
//                 </Typography>
//             </CardBody>
//             <CardFooter divider className="flex items-center justify-between py-3">
//                 <Typography variant="small">Chain ID : {props.chainID}</Typography>
//                 <Typography variant="small" color="gray" className="flex gap-1">
//                     <i className="fas fa-map-marker-alt fa-sm mt-[3px]" />
//                     <button onClick={(e)=>{
//                         window.open(nft.metadataUrl)
//                     }}>
//                         MetaData
//                     </button>
//
//                 </Typography>
//             </CardFooter>
//         </Card>
//     )
//
// }

export default function NFTCard(props: { chainID: number; contractAddress: string; tokenID: string }) {
  const [currentNft, setCurrentNFT] = useState<NftMetadata | null | undefined>(null);

  useEffect(() => {
    if (currentNft == null)
      try {
        const fetcher: Fetcher<unknown> | FetcherDeclaration = ['ethers', { provider: new ethers.providers.JsonRpcProvider(prcMap[props.chainID]) }];
        console.log({ fetcher });
        const fetchWrapper = new FetchWrapper(fetcher);

        const result = fetchWrapper.fetchNft(props.contractAddress, props.tokenID).then((meta) => {
          setCurrentNFT(meta);
        });
      } catch (e) {
        setCurrentNFT(undefined);
      }
  });

  if (currentNft) {
    return (
      <Card className="mx-auto min-w-[285px] max-w-[384px] border">
        <CardHeader color="blue" className="relative mt-4 h-56">
          <img src={currentNft.image} alt="img-blur-shadow " className="h-full w-full object-cover" />
        </CardHeader>
        <CardBody className="text-center">
          <Typography variant="h5" className="mb-2">
            {currentNft.name}
          </Typography>
          <Typography style={{ height: '100px', overflow: 'auto' }}>{currentNft.description}</Typography>
        </CardBody>
        <CardFooter divider className="flex items-center justify-between py-3">
          <Typography variant="small">Chain ID : {props.chainID}</Typography>
          <Typography variant="small" color="gray" className="flex gap-1">
            <i className="fas fa-map-marker-alt fa-sm mt-[3px]" />
            <button
              onClick={(e) => {
                window.open(currentNft.metadataUrl);
              }}
            >
              MetaData
            </button>
          </Typography>
        </CardFooter>
      </Card>
    );
  } else if (currentNft === undefined) {
    return (
      <Card className="mx-auto min-w-[285px] max-w-[384px] border">
        <CardHeader color="blue" className="relative h-56">
          Token Undefined
        </CardHeader>
        <CardBody className="text-center">
          <Typography variant="h5" className="mb-2">
            Contract Address: {props.contractAddress}
          </Typography>
          <Typography>Token ID: {props.tokenID}</Typography>
        </CardBody>
        <CardFooter divider className="flex items-center justify-between py-3">
          <Typography variant="small">Chain ID : {props.chainID}</Typography>
          <Typography variant="small" color="gray" className="flex gap-1">
            <i className="fas fa-map-marker-alt fa-sm mt-[3px]" />
          </Typography>
        </CardFooter>
      </Card>
    );
  } else {
    return (
      <Card className="mx-auto min-w-[285px] max-w-[384px] border">
        <CardHeader color="blue" className="relative grid h-56 place-items-center">
          <Icon className="mx-auto h-6 w-auto" icon="eos-icons:loading" />
        </CardHeader>
        <CardBody className="text-center">
          <Typography variant="h5" className="mb-2">
            Loading...
          </Typography>
          <Typography></Typography>
        </CardBody>
        <CardFooter divider className="flex items-center justify-between py-3">
          <Typography variant="small">Chain ID : {props.chainID}</Typography>
          <Typography variant="small" color="gray" className="flex gap-1">
            <i className="fas fa-map-marker-alt fa-sm mt-[3px]" />
          </Typography>
        </CardFooter>
      </Card>
    );
  }
}
