import {useParams} from 'react-router-dom';
import React, {useContext, useEffect, useRef, useState} from 'react';
import {GlobalStateContext} from '../../context/GlobalStateContext';
import {bindReseller} from '../../api/bind-reseller';
import {getReseller} from '../../api/get-reseller';
import {Icon} from '@iconify/react';

interface IBind {
  display: boolean;
  setDisplay: (display: boolean) => void;
  code:string
}

const ResellerBind = (props: IBind) => {
  const params = useParams();
  const { user, accessToken,updateUser } = useContext(GlobalStateContext);
  const [isDone, setIsDone] = useState(false);
  const [resellerId, setResellerId] = useState<string | null>(null);
  const [boundStatus, setBoundStatus] = useState(false);
  const [isBinding, setIsBinging] = useState(false);
  const input = useRef<HTMLInputElement>(null);

  const requestBind = async () => {
    const exec = async () => {
      if (!accessToken) {
        return;
      }
      if (user?.reseller == null && input.current) {
        try {
          setIsBinging(true);
          console.log(input.current.value);
          const reseller = await getReseller(accessToken, input.current.value);

          setResellerId(reseller.id);
          await bindReseller(accessToken, reseller.id);
          setBoundStatus(true);
          setIsDone(true);
          await updateUser()
        } catch (e) {
          setIsDone(true);
          console.error(e);
        }
      }
    };
    exec();
  };

  const reset = () => {
    setIsDone(false);
    setBoundStatus(false);
    setIsBinging(false);
    if (input.current) {
      input.current.value = '';
    }
  };

  useEffect(() => {
    if (!props.display) {
      reset();
    }
  }, [props.display]);

  useEffect(()=>{
    if(props.code != '' && accessToken && user?.reseller == null){
      if (input.current) {
        input.current.value = props.code
        requestBind()
      }
    }
  },[accessToken,user])

  //   useEffect(() => {
  //     const exec = async () => {
  //       if (!accessToken) {
  //         return;
  //       }
  //       if (!params.id) {
  //         return;
  //       }

  //       if (user?.reseller == null) {
  //         try {
  //           const reseller = await getReseller(accessToken, params.id);
  //           setResellerId(reseller.id);
  //           await bindReseller(accessToken, reseller.id);
  //           setBoundStatus(true);
  //           setLoading(false);
  //         } catch (e) {
  //           setLoading(false);
  //         }
  //       }
  //     };
  //     exec();
  //   }, [accessToken]);

  return (
    <>
      <div onClick={() => props.setDisplay(false)} data-display={props.display} className="bind-bg fixed top-0 left-0 z-[999] h-full w-full bg-black/40"></div>
      <div data-display={props.display} className="bind-main-con fixed bottom-0 left-0 z-[999] h-fit w-full">
        <div className="absolute rounded-t-2xl md:rounded-none bottom-0 left-0 w-full bg-white p-10">
          <div className="mx-auto w-full max-w-[350px]">
            <div className="mb-4 flex items-end justify-between text-2xl">
              <p>Reseller Binding</p>
              {isDone && (
                <p onClick={reset} className="cursor-pointer text-[rgba(147, 39, 143, 1)] text-base hover:underline">
                  back
                </p>
              )}
            </div>
            <div data-binding={isBinding} data-done={isDone} className="bind-con relative">

              {user?.reseller === null ? (
                <>
                  <div data-binding={isBinding} className="binding-con relative">
                    <input ref={input} type="text" className="w-full rounded-md border px-3 py-2 text-[18px] outline-none" placeholder="Enter Reseller Code" />
                    <Icon icon="ant-design:login-outlined" onClick={requestBind} className="icon-align-center absolute right-2 h-[20px] w-auto cursor-pointer text-gray-500" />
                  </div>
                  <div data-binding={isBinding} data-loading={isDone} className="loading absolute top-0 left-0 grid h-full w-full place-items-center">
                    <Icon icon="eos-icons:bubble-loading" className="h-[50px] w-auto text-[rgba(147, 39, 143, 1)]" />
                  </div>
                  <div data-done={isDone} className="binding-msg-con absolute left-0 top-0 flex h-full w-full gap-10">
                    {isDone === true && (
                      <>
                        {user?.reseller === null && (
                          <>
                            {resellerId === null ? (
                              /*Can't Find Reseller In DB*/
                              <div className="text-lg">Invalid Reseller Link</div>
                            ) : (
                              <>
                                {boundStatus ? (
                                  /*Bound Action Success*/
                                  <div className="text-lg">Success to Bound Reseller by using Code {params.id}</div>
                                ) : (
                                  /*Bound Action Fail*/
                                  <div className="text-lg">Bound Fail</div>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </div>
                </>
              ) : (
                  <>
                    { user === undefined ?
                    <div data-binding={true} data-loading={false} className="loading absolute top-0 left-0 grid h-full w-full place-items-center">
                      <Icon icon="eos-icons:bubble-loading" className="h-[50px] w-auto text-[rgba(147, 39, 143, 1)]" />
                    </div>
                        :
                    <div className="text-lg"> You already Bound.</div>}
                  </>


              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResellerBind;
