import config from "../config";
import axios from "axios";


export async function bindReseller(accessToken: string, resellerID:string): Promise<boolean> {
    const url = new URL(`/user/reseller`, `${config.api.endpoint}`);
    const response = await axios.post(
        url.href,
        {
            resellerId:resellerID,
        },
        {
            headers: { Authorization: `Bearer ${accessToken}` },
        },
    );
    return response.data;
}
