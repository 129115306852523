import axios from 'axios';
import config from '../config';

interface IResponse {
  count: number;
}
export async function getShoppingCartDomainsCount(accessToken: string): Promise<number> {
  const url = new URL(`/cart/domains/count`, `${config.api.endpoint}`);
  const response = await axios.get<IResponse>(url.href, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });
  return response.data.count;
}
