import axios from 'axios';
import config from '../config';
import {ILookupDomain} from '../interface/lookup-domain.interface';

export async function lookupDomainWithAuth(fqdn: string, accessToken: string): Promise<ILookupDomain> {
  console.log(fqdn)
  const url = new URL(`/domain/lookup/_auth/${fqdn}`, `${config.api.endpoint}`);
  const response = await axios.get<ILookupDomain>(url.href, { headers: { Authorization: `Bearer ${accessToken}` } });
  console.log({response})
  return response.data;
}
