import Nav from '../../components/Nav/Nav';
import { motion } from 'framer-motion';
import { Icon } from '@iconify/react';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { IOrder, OrderStatus } from '../../interface';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import i18nIsoCountries from 'i18n-iso-countries';
import { cancelOrder } from '../../api/delete-order';
import { getUser } from '../../api/get-user';
import { createEthereumAddress as _createEthereumAddress } from '../../api/create-ethereum-address';
import { revealWalletKey } from '../../api/reveal-private-key';
import _ from 'lodash';
import { createDefaultEthereumAddress } from '../../api/create-default-ethereum-address';
import { deleteEthereumAddress } from '../../api/delete-ethereum-address';
import EthereumAddressView from '../../components/Account/EthereumAddressView';
import * as luxon from 'luxon';
import Manage from '../../components/domain/Manage';
import Skeleton from 'react-loading-skeleton';
import axios from 'axios';
import config from '../../config';
import { GlobalStateContext } from '../../context/GlobalStateContext';
import { createManganedWallet } from '../../api/create-managed-wallet';

i18nIsoCountries.registerLocale(require('i18n-iso-countries/langs/en.json'));

const ani = {
  hide: {
    opacity: 0,
    zIndex: '-1',
    transition: { zIndex: { delay: 0.35 } },
    duration: 0.2,
    y: 25,
  },
  show: { opacity: 1, zIndex: '410', y: 0, transition: { duration: 0.2 } },
};

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}
interface IindividualInfo {
  firstname: string;
  lastname: string;
  email: string;
}
interface IcoperateInfo {
  name: string;
  phone: string;
  email: string;
  address: string;
}

const scrollToRight = () => {
  const addressViewDiv = document.getElementById('ethview');
  console.log(addressViewDiv?.clientWidth);

  if (addressViewDiv) {
    addressViewDiv.scrollLeft += addressViewDiv.scrollWidth;
  }
};

const Account = () => {
  let navigate = useNavigate();
  let query = useQuery();
  // const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  // const [accessToken, setAccessToken] = useState<string | undefined>(undefined);
  // const [isRetreivedAccessToken, setIsRetreivedAccessToken] = useState<boolean>(false);
  // const [isBillingAddressHasBeenSetup, setIsBillingAddressHasBeenSetup] = useState<boolean | undefined>(undefined);
  // const [user, setUser] = useState<IUser | undefined>(undefined);
  // const [orders, setOrders] = useState<IOrder[] | undefined>(undefined);

  // const [billingAddress, setBillingAddress] = useState<IBillingAddress | undefined>(undefined);
  // const [ethereumAddresses, setEthereumAddresses] = useState<IEthereumAddress[] | undefined>(undefined);
  // const [defaultEthereumAddress, setDefaultEthereumAddress] = useState<IEthereumAddress | undefined>(undefined);

  const {
    domains,
    accessToken,
    orders,
    defaultEthereumAddress,
    ethereumAddresses,
    isEthereumAddressesFinishSetup,
    updateDefaultEthereumAddress,
    updateEthereumAddresses,
    updateOrders,
    updateUser,
    isManagedWallet,
    useWeb3Function,
  } = useContext(GlobalStateContext);

  const [individualInfo, setIndividualInfo] = useState<IindividualInfo>();
  const [corporateInfo, setCorporateInfo] = useState<IcoperateInfo>();
  const [orderInModal, setOrderInModal] = useState<IOrder | undefined>(undefined);
  // const [isConnectingToWeb3Wallet, setIsConnectingToWeb3Wallet] = useState<boolean>(false);
  const [isOrderDetailModalOpen, setIsOrderDetailModalOpen] = useState<boolean>(false);

  const [isCancelingOrder, setIsCancelingOrder] = useState<{ [key: string]: boolean }>({});

  // const [isEthereumAddressHasBeenSetup, setIsEthereumAddressHasBeenSetup] = useState<boolean | undefined>(undefined);
  const [isDuplicatedBindingEthereumAddress, setIsDuplicatedBindingEthereumAddress] = useState<boolean | undefined>(undefined);
  const scrolls = useRef<HTMLDivElement>(null);
  const [customAddWalletErrorMessage, setCustomAddWalletErrorMessage] = useState<string | undefined>(undefined);
  const footer = useRef<HTMLDivElement>(null);
  const [countryOptions, setCountryOptions] = useState<{ value: string; label: string }[]>([]);
  // const [domains, setDomains] = useState<IDomain[]>([]);
  const [reseller, setReseller] = useState(false);
  const [passport, setPassport] = useState<any>(null);
  const [selfie, setSelfie] = useState<any>(null);
  const [businessReg, setBusinessReg] = useState<any>(null);
  const [certOfIn, setCertOfIn] = useState<any>(null);
  const [reveal, setReveal] = useState<boolean>(false);
  const [remove, setRemove] = useState<boolean>(false);
  const [managedWallet, setManagedWallet] = useState<any>();
  const [privateKey, setPrivateKey] = useState<string>('');
  const [managedAddress, setManagedAddress] = useState<string>('');
  const [createWallet, setCreateWallet] = useState<boolean>(false);
  const [isUnderstand, setIsUnderstand] = useState<boolean>(false);
  const [read, setRead] = useState<boolean>(false);
  const [isSelect, setIsSelect] = useState<boolean>(false);
  const [key, setKey] = useState('');

  const customStyles = {
    menuList: (base) => ({
      ...base,
      '::-webkit-scrollbar': {
        width: '4px',
      },
      height: '200px',
    }),
    control: (base) => ({
      ...base,
      height: '100%',
      border: '1px #d1d5db solid',
    }),
  };
  // const handleChange=(e)=>{
  //   const fileReader = new FileReader();
  //   fileReader.readAsText(e.target.files[0], "UTF-8");
  //   fileReader.onload = e => {
  //     setPassport(e.target.result);
  //   };
  // };
  // }
  // const resellerRequest = async() =>{
  //   if(defaultEthereumAddress ){
  //     if(query.get('type') === 'individual' && billingAddress){
  //       const data:ICreateResellerRequestInput = {
  //         ethereumAddress: defaultEthereumAddress,
  //         type: ResellerRequestType.INDIVIDUAL,
  //         individual:{
  //           firstname: billingAddress.firstname,
  //           lastname:billingAddress.lastname,
  //           email: billingAddress.addressLine1,
  //           documents:{
  //             passport:
  //           }
  //         }
  //       }
  //     }
  //   }
  // }
  // useEffect(() => {
  //   if (isAuthenticated) {
  //     const exec = async () => {
  //       const _accessToken = await getAccessTokenSilently();
  //       setAccessToken(_accessToken);
  //       if (_accessToken) {
  //         const _domains = await getDomains(_accessToken);
  //         setDomains(_domains);
  //       }
  //     };
  //     exec();
  //   } else {
  //     navigate('/');
  //   }
  // }, [isAuthenticated, accessToken, getAccessTokenSilently, navigate]);

  useEffect(() => {
    const _countryOptions = new Set<{ value: string; label: string }>();
    const names = i18nIsoCountries.getNames('en');
    for (const code in names) {
      _countryOptions.add({ label: names[code], value: code });
    }
    setCountryOptions([..._countryOptions]);
  }, []);

  // Get access token from Auth0
  // useEffect(() => {
  //   async function exec() {
  //     if (isAuthenticated) {
  //       const token = await getAccessTokenSilently();
  //       setAccessToken(token);
  //     }
  //     setIsRetreivedAccessToken(true);
  //   }
  //   exec();
  // }, [isAuthenticated, getAccessTokenSilently]);

  // Get and set user
  useEffect(() => {
    async function exec() {
      if (accessToken) {
        const _user = await getUser(accessToken);
        try {
          setManagedWallet(_user.managedWallet);
          setManagedAddress(_user.managedWallet.address);
        } catch (e) {
          console.error(e);
        }
      }
    }
    exec();
    if (query.get('action') !== 'create') {
      setRead(false);
      setIsUnderstand(false);
      setIsSelect(false);
    }
    scrollToRight();
  }, [accessToken, query]);

  // useEffect(() => {
  //   if (accessToken && isRetreivedAccessToken && isAuthenticated && billingAddress) {
  //     const _isBillingAddressHasBeenSetup =
  //       !!billingAddress.addressLine1 &&
  //       !!billingAddress.city &&
  //       !!billingAddress.state &&
  //       !!billingAddress.zip &&
  //       !!billingAddress.country &&
  //       !!billingAddress.firstname &&
  //       !!billingAddress.lastname;
  //     setIsBillingAddressHasBeenSetup(_isBillingAddressHasBeenSetup);
  //   }
  // }, [accessToken, billingAddress, isAuthenticated, isRetreivedAccessToken]);

  // useEffect(() => {
  //   if (accessToken && isRetreivedAccessToken && isAuthenticated && ethereumAddresses) {
  //     setIsEthereumAddressHasBeenSetup(ethereumAddresses.length > 0);
  //   }
  // }, [accessToken, ethereumAddresses, isAuthenticated, isRetreivedAccessToken]);

  // Get and set orders
  // useEffect(() => {
  //   async function exec() {
  //     if (accessToken && isRetreivedAccessToken && isAuthenticated) {
  //       const _orders = await getOrders(accessToken);
  //       setOrders(_.orderBy(_orders.orders, 'createdAt', 'desc'));
  //     }
  //   }
  //   exec();
  // }, [accessToken, isAuthenticated, isRetreivedAccessToken]);

  const createEthereumAddress = async (address: string, message: string, signature: string) => {
    if (accessToken) {
      const isEthereumAddressAlreadyExist = ethereumAddresses?.find((_address) => _address.address === address);
      if (!isEthereumAddressAlreadyExist) {
        await _createEthereumAddress(accessToken, address, message, signature);
        await Promise.all([updateDefaultEthereumAddress(), updateEthereumAddresses()]);
      } else {
        setIsDuplicatedBindingEthereumAddress(true);
      }
    }
  };

  const handleAddWalletClick = async () => {
    if (accessToken) {
      try {
        const result = await useWeb3Function[137].signMessage();
        if (result) {
          const { address, signature, message } = result;
          await createEthereumAddress(address, message, signature);
        }
      } catch (e) {
        console.error({ e });
        if (e instanceof Error) {
          if (e.message === 'No Binance Chain Wallet found') setCustomAddWalletErrorMessage(e.message);
        }
      }
    }
  };

  const handleSetAsDefaultEthereumAddressClick = async (address: string) => {
    if (accessToken) {
      await createDefaultEthereumAddress(accessToken, address);
      await updateDefaultEthereumAddress();
    }
  };

  const handleDeleteEthereumAddressClick = async (address: string) => {
    if (accessToken) {
      await deleteEthereumAddress(accessToken, address);
      await Promise.all([updateDefaultEthereumAddress(), updateEthereumAddresses()]);
    }
  };

  const handleCancelOrderClick = async (orderId: string) => {
    if (accessToken && !isCancelingOrder[orderId]) {
      const _isCancelingOrder = _.clone(isCancelingOrder);
      _isCancelingOrder[orderId] = true;
      setIsCancelingOrder(_isCancelingOrder);

      await cancelOrder(accessToken, orderId);
      await updateOrders();

      _isCancelingOrder[orderId] = false;
      setIsCancelingOrder(_isCancelingOrder);
    }
  };

  const handleCreateManagedWallet = async () => {
    if (accessToken) {
      await createManganedWallet(accessToken);
      await updateUser();
      navigate(0);
    }
  };

  const handleWalletRevailClick = async () => {
    if (accessToken) {
      const response = await revealWalletKey(accessToken);
      let _response = '';
      for (let i = 0; response.privateKey.length >= i; i++) {
        _response += '*';
      }
      setPrivateKey(_response);
      setKey(response.privateKey);
      setReveal((prev) => !prev);
    }
  };

  const EthereumAddressesView = ethereumAddresses?.map((ethereumAddress, i) => {
    return (
      <EthereumAddressView
        key={i}
        index={i + 1}
        ethereumAddress={ethereumAddress}
        isDefaultEthereumAddress={ethereumAddress.address === defaultEthereumAddress?.address}
        handleSetAsDefaultEthereumAddressClick={handleSetAsDefaultEthereumAddressClick}
        handleDeleteEthereumAddressClick={handleDeleteEthereumAddressClick}
        type={ethereumAddress.type}
        setReveal={setReveal}
        handleReveal={handleWalletRevailClick}
      />
    );
  });

  useEffect(() => {
    if (scrolls.current) {
      if (scrolls.current.scrollWidth > scrolls.current.clientWidth) {
        scrolls.current.classList.add('cursor-grab');
      } else {
        scrolls.current.classList.remove('cursor-grab');
      }
    }

    window.addEventListener('resize', () => {
      // check horizontal overflow
      if (scrolls.current) {
        if (scrolls.current.scrollWidth > scrolls.current.clientWidth) {
          scrolls.current.classList.add('cursor-grab');
        } else {
          scrolls.current.classList.remove('cursor-grab');
        }
      }
    });
  }, []);

  if (scrolls.current) {
    if (scrolls.current.scrollWidth > scrolls.current.clientWidth) {
      scrolls.current.classList.add('cursor-grab');
    } else {
      // console.log(scrolls.current.scrollWidth, scrolls.current.clientWidth);
      scrolls.current.classList.remove('cursor-grab');
    }
  }

  let pos = { top: 0, left: 0, x: 0, y: 0 };
  let ele;
  const mouseDownHandler = function (e) {
    if (scrolls.current) {
      ele = scrolls.current;
    }
    ele.style.cursor = 'grab';
    ele.style.userSelect = 'none';
    pos = {
      // The current scroll
      left: ele.scrollLeft,
      top: ele.scrollTop,
      // Get the current mouse position
      x: e.clientX,
      y: e.clientY,
    };

    document.addEventListener('mousemove', mouseMoveHandler);
    document.addEventListener('mouseup', mouseUpHandler);
  };
  const mouseMoveHandler = function (e) {
    if (scrolls.current) {
      ele = scrolls.current;
    }
    // How far the mouse has been moved
    const dx = e.clientX - pos.x;
    const dy = e.clientY - pos.y;

    // Scroll the element
    ele.scrollTop = pos.top - dy;
    ele.scrollLeft = pos.left - dx;
  };
  const mouseUpHandler = function () {
    if (scrolls.current) {
      ele = scrolls.current;
    }
    ele.style.cursor = 'default';
    document.removeEventListener('mousemove', mouseMoveHandler);
    document.removeEventListener('mouseup', mouseUpHandler);

    ele.style.removeProperty('user-select');
  };

  useEffect(() => {
    let a = document.querySelector(':root') as HTMLElement;
    let ethernetAddressesLength;
    if (ethereumAddresses) {
      ethereumAddresses?.length >= 5 ? (ethernetAddressesLength = '5') : (ethernetAddressesLength = (ethereumAddresses?.length + 1).toString());
    }
    if (ethernetAddressesLength) {
      // console.log(ethernetAddressesLength);
      a.style.setProperty('--card-quantity', ethernetAddressesLength);
    }
  }, [ethereumAddresses]);

  useEffect(() => {
    let r = document.querySelector(':root') as HTMLElement;
    if (footer.current) {
      r.style.setProperty('--footer-h', footer.current.offsetHeight + 'px');
    }
  });

  // useEffect(() => {
  //   window.addEventListener('click', (event) => {
  //     const e = event.target as HTMLElement;
  //     if (!e.closest('.reseller-con') && query.get('action') === 'reseller') {
  //       navigate('/account');
  //     }
  //   });
  // });

  // useEffect(() => {
  //   window.onclick = function (event) {
  //     if (!event.target.matches('.order-box') && isOrderDetailModalOpen) {
  //       setIsOrderDetailModalOpen(false);
  //     }
  //   };
  // });
  // useEffect(() => {
  //   orders?.map((order) => {
  //     console.log(_.startCase(_.toLower(order.status)));
  //   });
  // });

  const createRequest = async () => {
    const url = new URL(`/reseller/request`, `${config.api.endpoint}`);
    if (query.get('type') === 'individual') {
      let bodyFormData = new FormData();
      if (accessToken) {
        bodyFormData.append('ethereumAddress', defaultEthereumAddress?.address!);
        bodyFormData.append('type', 'INDIVIDUAL');
        bodyFormData.append('individual[firstname]', individualInfo?.firstname!);
        bodyFormData.append('individual[lastname]', individualInfo?.lastname!);
        bodyFormData.append('individual[email]', individualInfo?.email!);
        bodyFormData.append('individual[documents][selfie]', selfie);
        bodyFormData.append('individual[documents][passport]', passport);
        await axios.post(url.href, bodyFormData, { headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${accessToken}` } });
      }
    } else if (query.get('type') === 'corporate') {
      let bodyFormData = new FormData();
      if (accessToken) {
        bodyFormData.append('ethereumAddress', defaultEthereumAddress?.address!);
        bodyFormData.append('type', 'CORPORATE');
        bodyFormData.append('corporate[name]', corporateInfo?.name!);
        bodyFormData.append('corporate[address]', corporateInfo?.address!);
        bodyFormData.append('corporate[email]', corporateInfo?.email!);
        bodyFormData.append('corporate[phone]', corporateInfo?.phone!);
        bodyFormData.append('corporate[documents][businessRegistration]', businessReg);
        bodyFormData.append('corporate[documents][certificateOfIncorporation]', certOfIn);
        await axios.post(url.href, bodyFormData, { headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${accessToken}` } });
      }
    }
  };

  useEffect(() => {
    console.log(
      orders.slice(0, 7).map((order) => {
        console.log(_.toLower(order.status));
      }),
    );
  });

  return (
    <>
      <div
        data-toggle={query.get('action') === 'create'}
        onClick={() => {
          navigate('/account');
        }}
        className="selectWallet-wrapper fixed left-0 top-0 z-[700] bg-black/30"
      ></div>
      <div data-toggle={query.get('action') === 'create'} className="selectWallet-con z-[701] flex flex-col gap-5 rounded-xl p-6">
        <h2 className="text-xl font-medium">Select Wallet Type</h2>
        <div className="flex flex-1 flex-wrap gap-5">
          <button
            onClick={() => {
              handleAddWalletClick();
              navigate('/account');
            }}
            className="min-w-[250px] flex-1 rounded-lg border-2 border-[#93278f] p-3 py-5 font-medium text-[#93278f] duration-150 hover:bg-[#93278f] hover:text-white"
          >
            Bind Ethereum Wallet
          </button>
          {!isManagedWallet ? (
            <button
              onClick={() => {
                setIsSelect(true);
                if (isUnderstand) {
                  handleCreateManagedWallet();
                  navigate('/account');
                } else {
                  setRead(true);
                }
              }}
              className={`min-w-[250px] flex-1 rounded-lg border-2 border-[#93278f] p-3 py-5 font-medium  duration-150 hover:bg-[#93278f] hover:text-white ${
                isSelect ? `bg-[#93278f] text-white` : `bg-white text-[#93278f]`
              }`}
            >
              Create Managed Wallet
            </button>
          ) : (
            <></>
          )}
          {!isManagedWallet && read && (
            <div className="text-xs text-black">
              <fieldset style={{ border: `1px solid ${isUnderstand ? '#4BB543' : 'red'}`, borderRadius: '20px', padding: '1rem' }}>
                <legend style={{ padding: '0 1rem' }}>Caution</legend>
                <p>
                  The managed wallet is not intended to be used for transactions. If the user intends to sell their domain on any secondary market, the user must transfer their
                  domain to a crypto wallet. Any gas fee incurred in this transfer is to be borne by the user.
                </p>
              </fieldset>
              <br />
              <div className="font-center flex w-full flex-col gap-5 space-x-4 p-0 text-left text-black">
                <span>
                  I understand:{' '}
                  <input
                    type="checkbox"
                    onChange={() => {
                      setIsUnderstand((prev) => !prev);
                    }}
                  />
                </span>
                <button
                  onClick={() => {
                    if (isUnderstand) {
                      handleCreateManagedWallet();
                      navigate('/account');
                    }
                  }}
                  className="min-w-[250px] flex-1 rounded-lg border-2 border-[#93278f] p-3 py-5 font-medium text-[#93278f] duration-150 hover:bg-[#93278f] hover:text-white"
                  disabled={!isUnderstand}
                  style={{ display: `${isUnderstand ? 'block' : 'none'}` }}
                >
                  Comfirm Create
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* <div data-show={query.get('action') === 'reseller'} className="reseller-con-outer absolute top-0 left-0 z-[500] h-full w-full">
        <div className="absolute left-0 top-0 h-full w-full bg-gray-500/20" onClick={() => navigate('/account')}></div>
        <div
          data-show={query.get('action') === 'reseller'}
          data-type={query.get('action')}
          data-action={query.get('type')}
          className="reseller-con box-shadow flex flex-col rounded-xl border p-10 pt-8"
        >
          <p className="text-2xl font-medium">
            {query.get('type') === 'individual' && 'Individual'}
            {query.get('type') === 'corporate' && 'Corporate'}
            {query.get('action') === 'reseller' && query.get('type') === null && 'Reseller type'}
          </p>
          {query.get('action') === 'reseller' && query.get('type') !== 'submit' && query.get('type') !== 'code' && (
            <>
              <div className="flex flex-1 flex-col">
                <div className="mt-5 flex w-full gap-5">
                  <Link
                    to="/account?action=reseller&type=individual"
                    className={`${
                      query.get('type') === 'individual' ? 'bg-[#93278f] text-white' : ''
                    } box-shadow flex flex-1 cursor-pointer flex-col items-center justify-between rounded-md border pt-5 duration-200 hover:scale-105 hover:bg-[#93278f] hover:text-white`}
                  >
                    <p className="mb-5 flex flex-1 items-center text-lg font-medium">Individual</p>
                  </Link>
                  <Link
                    to="/account?action=reseller&type=corporate"
                    className={`${
                      query.get('type') === 'corporate' ? 'bg-[#93278f] text-white' : ''
                    } box-shadow flex flex-1 cursor-pointer flex-col items-center justify-between rounded-md border pt-5 duration-200 hover:scale-105 hover:bg-[#93278f] hover:text-white`}
                  >
                    <p className="mb-5 flex flex-1 items-center text-lg font-medium">Corporate</p>
                  </Link>
                </div>
                <div className="flex-1 pt-10">
                  <div className="reseller-info-con">
                    {query.get('type') === 'individual' && (
                      <div className="flex flex-1 flex-col gap-4">
                        <span className="text-[#809FB8]">First Name</span>
                        <div>
                          <motion.input
                            defaultValue={individualInfo?.firstname}
                            required
                            className="w-full rounded-md border border-gray-300 p-5 outline-blue-500"
                            placeholder="First Name"
                            value={individualInfo?.firstname}
                            onChange={(e) => {
                              setIndividualInfo({ ...individualInfo, firstname: e.currentTarget.value } as IindividualInfo);
                            }}
                          ></motion.input>
                        </div>
                      </div>
                    )}
                    {query.get('type') === 'individual' && (
                      <div className="flex flex-1 flex-col gap-4">
                        <span className="text-[#809FB8]">Last Name</span>
                        <div>
                          <input
                            defaultValue={individualInfo?.lastname}
                            required
                            className="w-full rounded-md border border-gray-300 p-5 outline-blue-500"
                            placeholder="Last Name"
                            value={individualInfo?.lastname}
                            onChange={(e) => {
                              setIndividualInfo({ ...individualInfo, lastname: e.currentTarget.value } as IindividualInfo);
                            }}
                          ></input>
                        </div>
                      </div>
                    )}

                    {query.get('type') === 'individual' && (
                      <div className="flex flex-col gap-4">
                        <span className="text-[#809FB8]">Email</span>
                        <div>
                          <input
                            defaultValue={individualInfo?.email}
                            type="email"
                            required
                            className="w-full rounded-md border border-gray-300 p-5 outline-blue-500"
                            placeholder="Address Line 1"
                            value={individualInfo?.email}
                            onChange={(e) => {
                              setIndividualInfo({ ...individualInfo, email: e.currentTarget.value } as IindividualInfo);
                            }}
                          ></input>
                        </div>
                      </div>
                    )}
                    {query.get('type') === 'individual' && (
                      <div className="flex gap-5">
                        <div className="flex flex-col gap-4">
                          <span className="text-[#809FB8]">Passport</span>
                          <div>
                            <input
                              required
                              type="file"
                              className="w-full rounded-md border border-gray-300 p-5 outline-blue-500"
                              onChange={(e) => {
                                if (e.target.files) {
                                  setPassport(e.target.files[0]);
                                }
                              }}
                            ></input>
                          </div>
                        </div>
                        <div className="flex flex-col gap-4">
                          <span className="text-[#809FB8]">Selfie</span>
                          <div>
                            <input
                              required
                              type="file"
                              className="w-full rounded-md border border-gray-300 p-5 outline-blue-500"
                              onChange={(e) => {
                                if (e.target.files) {
                                  setSelfie(e.target.files[0]);
                                }
                              }}
                            ></input>
                          </div>
                        </div>
                      </div>
                    )}

                    {query.get('type') === 'corporate' && (
                      <div className={`${query.get('type') === 'corporate' ? 'flex' : 'hidden'} flex-[1] flex-col gap-4`}>
                        <span className="text-[#809FB8]">Company Name</span>
                        <div>
                          <input
                            defaultValue={corporateInfo?.name}
                            required
                            className="w-full rounded-md border border-gray-300 p-5 outline-blue-500"
                            placeholder="Company Name"
                            value={corporateInfo?.name}
                            onChange={(e) => {
                              setCorporateInfo({ ...corporateInfo, name: e.currentTarget.value } as IcoperateInfo);
                            }}
                          ></input>
                        </div>
                      </div>
                    )}
                    {query.get('type') === 'corporate' && (
                      <div className={`${query.get('type') === 'corporate' ? 'flex' : 'hidden'} flex-[1] flex-col gap-4`}>
                        <span className="text-[#809FB8]">Company Phone Number</span>
                        <div>
                          <input
                            defaultValue={corporateInfo?.phone}
                            required
                            className="w-full rounded-md border border-gray-300 p-5 outline-blue-500"
                            placeholder="Company Phone Number"
                            value={corporateInfo?.phone}
                            onChange={(e) => {
                              setCorporateInfo({ ...corporateInfo, phone: e.currentTarget.value } as IcoperateInfo);
                            }}
                          ></input>
                        </div>
                      </div>
                    )}
                    {query.get('type') === 'corporate' && (
                      <div className={`${query.get('type') === 'corporate' ? 'flex' : 'hidden'} flex-[1] flex-col gap-4`}>
                        <span className="text-[#809FB8]">Company Address</span>
                        <div>
                          <input
                            defaultValue={corporateInfo?.address}
                            required
                            className="w-full rounded-md border border-gray-300 p-5 outline-blue-500"
                            placeholder="Company Address"
                            value={corporateInfo?.address}
                            onChange={(e) => {
                              setCorporateInfo({ ...corporateInfo, address: e.currentTarget.value } as IcoperateInfo);
                            }}
                          ></input>
                        </div>
                      </div>
                    )}
                    {query.get('type') === 'corporate' && (
                      <div className={`${query.get('type') === 'corporate' ? 'flex' : 'hidden'} flex-[1] flex-col gap-4`}>
                        <span className="text-[#809FB8]">Company email</span>
                        <div>
                          <input
                            defaultValue={corporateInfo?.email}
                            required
                            className="w-full rounded-md border border-gray-300 p-5 outline-blue-500"
                            placeholder="Company Email"
                            value={corporateInfo?.email}
                            onChange={(e) => {
                              setCorporateInfo({ ...corporateInfo, email: e.currentTarget.value } as IcoperateInfo);
                            }}
                          ></input>
                        </div>
                      </div>
                    )}
                    {query.get('type') === 'corporate' && (
                      <div className="flex gap-5">
                        <div className="flex flex-col gap-4">
                          <span className="text-[#809FB8]">Business Registration</span>
                          <div>
                            <input
                              required
                              type="file"
                              className="w-full rounded-md border border-gray-300 p-5 outline-blue-500"
                              onChange={(e) => {
                                if (e.target.files) {
                                  setBusinessReg(e.target.files[0]);
                                }
                              }}
                            ></input>
                          </div>
                        </div>
                        <div className="flex flex-col gap-4">
                          <span className="text-[#809FB8]">Certificate Of Incorporation</span>
                          <div>
                            <input
                              required
                              type="file"
                              className="w-full rounded-md border border-gray-300 p-5 outline-blue-500"
                              onChange={(e) => {
                                if (e.target.files) {
                                  setCertOfIn(e.target.files[0]);
                                }
                              }}
                            ></input>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <button
                onClick={() => {
                  navigate('/account?action=reseller&type=submit');
                  setReseller(true);
                  createRequest();
                }}
                className="rounded-lg bg-[#93278f] py-5 text-lg font-medium text-white"
              >
                Submit
              </button>
            </>
          )}
          {query.get('type') === 'submit' && (
            <div data-show={query.get('type') === 'submit'} className="pending-con disabled flex flex-1 flex-col items-center justify-center">
              <Icon className="h-auto w-24 text-green-500" icon="teenyicons:tick-circle-solid" />
              <p className="mt-10 text-2xl font-medium">Request Pending</p>
              <p className="mx-auto mt-5 max-w-[368px] text-center font-light text-gray-600">
                Please wait for two to three working days, we will have staff to process your application. <br></br>Thank you
              </p>
            </div>
          )}
          {query.get('type') === 'code' && (
            <div data-show={query.get('type') === 'submit'} className="flex flex-1 flex-col">
              <p className="text-2xl font-medium">Reseller Program</p>
              <div className="flex-1">
                <p className="mt-5 flex items-center justify-between">
                  <p>Reseller Code</p>
                  <p className="text-xs text-[#93278f]">Credit: 100</p>
                </p>
                <input className="reseller-code mt-2 w-full rounded-md border p-3" value="a1b2c3d4" disabled></input>
              </div>
              <button
                onClick={(event) => {
                  const e = event.target as HTMLElement;
                  e.classList.add('copy');
                  const code = document.querySelector('.reseller-code') as HTMLInputElement;
                  code.select();
                  code.setSelectionRange(0, 99999);
                  navigator.clipboard.writeText(code.value);
                }}
                className="btn-reseller grid place-items-center rounded-md bg-[#93278f] py-4 text-base font-medium text-white duration-200 hover:bg-[#7a2077] active:bg-[#8b2488]"
              >
                <Icon icon="charm:tick" className="h-auto w-6" />
                <p>Copy</p>
              </button>
            </div>
          )}
        </div>
      </div> */}
      <motion.div
        variants={ani}
        animate={isOrderDetailModalOpen ? 'show' : 'hide'}
        onClick={() => setIsOrderDetailModalOpen(false)}
        className="absolute z-[-1] grid h-full w-full place-items-center overflow-hidden opacity-0 backdrop-blur-sm"
      >
        <div className="flex h-full w-full flex-col overflow-hidden rounded-none shadow-2xl lg:h-auto lg:w-[32rem] lg:rounded-lg">
          <div className="theme bg flex items-center justify-between px-8 py-7 text-xl font-semibold text-white shadow-2xl lg:rounded-t-lg">
            <span>Order information</span>
            <Icon icon="ep:close-bold" className="h-7 w-auto cursor-pointer" onClick={() => setIsOrderDetailModalOpen(false)} />
          </div>
          <div className="max-h-auto z-50 flex h-full flex-col gap-5 overflow-y-scroll bg-white px-8 py-9 lg:max-h-[500px] lg:rounded-b-lg">
            {orderInModal && (
              <>
                <div className="flex flex-col items-start gap-0 lg:flex-row lg:items-center lg:gap-5">
                  <span className="flex-[2] text-lg font-medium">Order ID</span>
                  <span className="lg:text-md text-xs">{orderInModal.id}</span>
                </div>
                <div className="flex flex-col items-start gap-0 lg:flex-row lg:items-center lg:gap-5">
                  <span className="flex-[2] font-medium">Status</span>
                  <span>{_.startCase(_.toLower(orderInModal.status))}</span>
                </div>
                <div className="border-b"></div>
                {orderInModal.domains.map((orderDomain, i) => (
                  <div key={i} className="flex flex-col gap-5">
                    <div className="flex flex-col items-start gap-0 lg:flex-row lg:items-center lg:gap-5">
                      <span className="flex-[2] font-medium">Domain</span>
                      <span>{orderDomain.fqdn}</span>
                    </div>
                    <div className="flex flex-col items-start gap-0 lg:flex-row lg:items-center lg:gap-5">
                      <span className="flex-[2] font-medium">Owner Address</span>
                      <span className="lg:text-md text-xs">{orderDomain.owner['address']}</span>
                    </div>
                    <div className="flex flex-col items-start gap-0 lg:flex-row lg:items-center lg:gap-5">
                      <span className="flex-[2] font-medium">Price</span>
                      <span>$ {orderDomain.price} USD</span>
                    </div>
                    <div className="flex flex-col items-start gap-0 lg:flex-row lg:items-center lg:gap-5">
                      <span className="flex-[2] font-medium">Type</span>
                      <span>{_.startCase(_.toLower(orderDomain.type))}</span>
                    </div>
                    <div className="flex flex-col items-start gap-0 lg:flex-row lg:items-center lg:gap-5">
                      <span className="flex-[2] font-medium">Durations</span>
                      <span>{orderDomain.durations} years</span>
                    </div>
                    <div className="border-b"></div>
                  </div>
                ))}
                {orderInModal.successNowPayments && (
                  <div className="flex gap-5">
                    <span className="flex-[2] font-medium">Payment Gateway</span>
                    <span>Cryptocurrency</span>
                  </div>
                )}
                {orderInModal.successCheckout && (
                  <div className="flex gap-5">
                    <span className="flex-[2] font-medium">Payment Gateway</span>
                    <span>Credit Card</span>
                  </div>
                )}
                <div className="flex gap-5">
                  <span className="flex-[2] font-medium">Amount</span>
                  <span>$ {orderInModal.amount} USD</span>
                </div>
              </>
            )}
          </div>
        </div>
      </motion.div>
      <motion.div
        variants={ani}
        animate={reveal ? 'show' : 'hide'}
        // onClick={() => setReveal(false)}
        className="absolute z-[-1] grid h-full w-full place-items-center overflow-hidden opacity-0 backdrop-blur-sm"
      >
        <div className="box-shadow flex flex-col overflow-hidden rounded-none border lg:h-auto lg:w-[44rem] lg:rounded-xl">
          <div className="flex items-center justify-between bg-white px-8 py-7 text-xl font-medium lg:rounded-t-lg">
            <span>Private Key</span>
            <Icon icon="ep:close-bold" className="h-6 w-auto cursor-pointer" onClick={() => setReveal(false)} />
          </div>
          <div className="max-h-auto relative z-50 flex h-full w-full flex-col gap-5 bg-white px-8 pb-8 lg:max-h-[500px] lg:rounded-b-lg">
            <div className="relative">
              <input
                // value="ads"
                value={privateKey}
                disabled
                className="w-full truncate rounded-md border px-6 py-5 pr-16"
              />
              <button
                className="btn-copy rounded-full p-3 hover:bg-gray-200 "
                onClick={() => {
                  navigator.clipboard.writeText(key);
                  setReveal(false);
                }}
              >
                <Icon icon="ant-design:copy-filled" className="mx-auto h-5 w-auto text-gray-500" />
              </button>
            </div>
          </div>
        </div>
      </motion.div>
      <Nav title="Dashboard" />
      <div className="flex flex-1 flex-col overflow-y-auto p-10 px-6 pb-0 pt-6 md:px-10">
        <div className="flex w-full flex-1 flex-col gap-8">
          {customAddWalletErrorMessage && (
            <div className="relative rounded border border-red-400 bg-red-100 px-4 py-3 text-red-700" role="alert">
              <span className="block sm:inline">{customAddWalletErrorMessage}</span>
              <span className="absolute top-0 bottom-0 right-0 px-4 py-3" onClick={() => setCustomAddWalletErrorMessage(undefined)}>
                <svg className="h-6 w-6 fill-current text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <title>Close</title>
                  <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
                </svg>
              </span>
            </div>
          )}
          {isDuplicatedBindingEthereumAddress && (
            <div className="relative rounded border border-red-400 bg-red-100 px-4 py-3 text-red-700" role="alert">
              <strong className="font-bold">Duplicated Address!</strong>
              <span className="block sm:inline"> You have already bind this address into your account.</span>
              <span className="absolute top-0 bottom-0 right-0 px-4 py-3" onClick={() => setIsDuplicatedBindingEthereumAddress(false)}>
                <svg className="h-6 w-6 fill-current text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <title>Close</title>
                  <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
                </svg>
              </span>
            </div>
          )}
          <div
            ref={scrolls}
            id="ethview"
            onMouseDown={mouseDownHandler}
            className=" wallet-bar z-[0] flex min-h-[100px] w-full shrink-0  gap-5 overflow-x-auto rounded-xl border p-6 md:p-10"
          >
            {isEthereumAddressesFinishSetup === false && managedAddress === '' && (
              <div className="flex min-h-[220px] min-w-[25%] shrink-0 items-center justify-center rounded-2xl border bg-red-500 px-5 py-5 font-medium text-white">
                Please add your wallet address
              </div>
            )}
            {ethereumAddresses ? (
              <>
                <motion.button
                  initial={{ x: -20, opacity: 0 }}
                  whileInView={{ x: 0, opacity: 1 }}
                  transition={{ ease: 'easeOut', duration: 0.5 }}
                  viewport={{ once: true }}
                  className="box-shadow flex min-h-[220px] w-full shrink-0 items-center justify-center gap-5 rounded-2xl border bg-white px-7 py-3 text-lg font-medium text-blue-500 duration-200 hover:bg-blue-500 hover:text-white md:w-[350px]"
                  onClick={() => {
                    navigate('/account?action=create');
                    // setIsConnectingToWeb3Wallet(true);
                    // setIsDuplicatedBindingEthereumAddress(false);
                  }}
                >
                  <Icon icon="ant-design:plus-outlined" className="h-8 w-auto" />
                  Add Wallet
                </motion.button>
                {/* {managedWallet && (
                  <motion.button
                    initial={{ x: -20, opacity: 0 }}
                    whileInView={{ x: 0, opacity: 1 }}
                    transition={{ ease: 'easeOut', duration: 0.5 }}
                    viewport={{ once: true }}
                    className="box-shadow relative flex h-full shrink-0 flex-col justify-between gap-5 overflow-hidden rounded-2xl border px-8 py-8 duration-200"
                    onClick={() => {
                      // handleWalletRevailClick();
                      // setIsConnectingToWeb3Wallet(true);
                      // setIsDuplicatedBindingEthereumAddress(false);
                    }}
                  >
                    <div className="z-50 flex items-end gap-12">
                      <span className="flex-[1] text-2xl mt-2 font-medium text-black lg:mr-5">Managed Wallet</span>
                    </div>

                    <span className={`z-50 w-full truncate text-center font-medium lg:text-left`}>{managedWallet.address}</span>

                    <div
                      className="absolute top-6 right-4 z-[100] flex cursor-pointer"
                      onClick={() => {
                        setRemove(!remove);
                      }}
                    >
                      <Icon className={`option h-10 w-auto cursor-pointer text-black`} icon="iwwa:option" />
                      <motion.div animate={remove ? { display: 'block' } : { display: 'none' }} className="e absolute top-[135%] right-0 z-50 flex w-[10rem] flex-col">
                        {!defaultEthereumAddress?.address === managedWallet.address && (
                          <button
                            onClick={() => handleSetAsDefaultEthereumAddressClick(managedWallet.address)}
                            className="w-full cursor-pointer rounded-md rounded-b-none border border-b-0 bg-white px-8 py-3 font-medium shadow-md duration-200 hover:bg-blue-500 hover:text-white"
                          >
                            Set Default
                          </button>
                        )}
                        <button
                          onClick={() => handleWalletRevailClick()}
                          className={`w-full cursor-pointer rounded-md border bg-white px-8 py-3 font-medium shadow-md duration-200 hover:bg-red-500 hover:text-white`}
                        >
                          Reveal
                        </button>
                      </motion.div>
                    </div>
                  </motion.button>
                )} */}
                {EthereumAddressesView}
              </>
            ) : (
              <Skeleton style={{ border: '1px solid rgb(229, 231, 235)' }} width={400} height={210} borderRadius={'1.25rem'} baseColor={'white'} highlightColor={'#f1f1f1'} />
            )}
          </div>
          {/* <section className="rounded-xl border p-10 py-5">
            <p className="font-meidum text-xl">Reseller Program</p>
            {false ? (
              <p
                className="mt-2 w-fit cursor-pointer text-gray-600 hover:underline"
                onClick={() => {
                  navigate('/account?action=reseller&type=code');
                }}
              >
                Click here to show the reseller code
              </p>
            ) : (
              <p
                className={`${reseller && 'text-green-400'} mt-2 w-fit cursor-pointer text-gray-600 hover:underline`}
                onClick={() => {
                  navigate('/account?action=reseller');
                }}
              >
                {reseller
                  ? 'Please wait for two to three working days, we will have staff to process your application. Thank you'
                  : 'You have not joined the EDNS reseller program, please click here to join our family!'}
              </p>
            )}
          </section> */}
          <section className="account-b-con flex w-full flex-1 gap-5 pb-10">
            <div className="flex flex-[3] flex-col gap-6 rounded-2xl border bg-white p-6 md:min-w-[600px] md:p-10">
              <div className="flex items-center justify-between text-xl font-medium">My Domains</div>
              <div className="flex-1">
                <div className="domain-grid w-full gap-5 overflow-hidden md:pr-2">
                  {domains ? domains.slice(0, 4).map((domain, i) => <Manage name={domain.name} tld={domain.tld.name} chainID={domain.tld.chainId} index={i} />) : <></>}
                </div>
              </div>
              <Link
                to="/mydomain"
                className="mx-auto w-fit rounded-full border-2 border-[#93278f] px-8  py-3 text-center font-medium text-[#93278f] duration-150 hover:bg-[#93278f] hover:text-white"
              >
                Show more
              </Link>
            </div>
            <div className="flex flex-[7] flex-col justify-between gap-5 rounded-2xl border bg-white p-6 md:min-w-[700px] md:p-10">
              <div className="flex items-center justify-between text-xl font-medium">Latest orders</div>
              <div className="flex h-full flex-col gap-5 lg:gap-0">
                {/* <div className="hidden lg:flex gap-5 w-full bg-blue-500 text-white px-8 py-5 rounded-t-lg shadow-xl border-t">
                        <span className="flex-[2]">ID</span>
                        <span className="flex-[1]">Amount</span>
                        <span className="flex-[1]">Status</span>
                        <span className="flex-[1]">Created At</span>
                        <span className="flex-[1]"></span>
                      </div> */}
                {orders ? (
                  orders.slice(0, 7).map((order, i) => {
                    return (
                      <div key={i} className={`w-full bg-white`}>
                        <div className="flex h-full w-full flex-col gap-5 border-b py-10 px-6 lg:flex-row lg:py-5">
                          <span className="flex flex-[2] flex-col items-center gap-2 lg:flex-row">
                            <span className="block text-lg font-medium lg:hidden">ID</span>
                            <span className="w-full truncate lg:w-44">{order.id}</span>
                          </span>
                          <span className="flex flex-[1] items-center justify-between">
                            <span className="block text-lg font-medium lg:hidden">Amount</span>
                            <span>${order.amount}</span>
                          </span>
                          <span className="flex flex-[1] items-center justify-between">
                            <span className="block text-lg font-medium lg:hidden">Status</span>
                            <span>
                              {_.startCase(order.status) === 'PENDING PAYMENT' ? (
                                <div className="flex items-center justify-between gap-2 font-medium text-orange-500">
                                  <div className="aspect-square h-3 rounded-full bg-orange-500"></div>Pending
                                </div>
                              ) : _.startCase(order.status) === 'COMPLETED' ? (
                                <div className="flex items-center justify-between gap-2 font-medium text-blue-900">
                                  <div className="aspect-square h-3 rounded-full bg-blue-900"></div>Completed
                                </div>
                              ) : _.startCase(order.status) === 'MINTING' ? (
                                <div className="flex items-center justify-between gap-2 font-medium text-orange-500">
                                  <div className="aspect-square h-3 rounded-full bg-orange-500"></div>Minting
                                </div>
                              ) : _.startCase(order.status) === 'CANCELED' ? (
                                <div className="flex items-center justify-between gap-2 font-medium text-red-600">
                                  <div className="aspect-square h-3 rounded-full bg-red-600"></div>Canceled
                                </div>
                              ) : _.startCase(order.status) === 'PAID' ? (
                                <div className="flex items-center justify-between gap-2 font-medium text-green-600">
                                  <div className="aspect-square h-3 rounded-full bg-green-600"></div>Paid
                                </div>
                              ) : _.startCase(order.status) === 'FAILED' ? (
                                <div className="flex items-center justify-between gap-2 font-medium text-red-600">
                                  <div className="aspect-square h-3 rounded-full bg-red-600"></div>Failed
                                </div>
                              ) : _.startCase(order.status) === 'PARTIAL COMPLETED' ? (
                                <div className="flex items-center justify-between gap-2 font-medium text-orange-500">
                                  <div className="aspect-square h-3 rounded-full bg-orange-500"></div>Partial Completed
                                </div>
                              ) : _.startCase(order.status) === 'CREATED' ? (
                                <div className="flex items-center justify-between gap-2 font-medium text-yellow-600">
                                  <div className="aspect-square h-3 rounded-full bg-yellow-600"></div>Created
                                </div>
                              ) : (
                                <div className="flex items-center justify-between gap-2 font-medium text-red-600">
                                  <div className="aspect-square h-3 rounded-full bg-red-600"></div>
                                  {_.startCase(order.status)}
                                </div>
                              )}
                            </span>
                          </span>
                          <span className="flex flex-[1] items-center justify-between">
                            <span className="block text-lg font-medium lg:hidden">Created At</span>
                            <span>{luxon.DateTime.fromISO(order.createdAt).toRelative()}</span>
                          </span>
                          {order.status !== OrderStatus.PENDING_PAYMENT ? (
                            <span className="props mt-6 flex flex-[1] items-center justify-between gap-2 lg:mt-0">
                              <Icon
                                icon="akar-icons:info"
                                className="h-6 w-auto cursor-pointer text-blue-500 duration-200 hover:scale-110"
                                onClick={() => {
                                  setOrderInModal(order);
                                  setIsOrderDetailModalOpen(true);
                                }}
                              />
                            </span>
                          ) : (
                            <span className="mt-6 flex flex-[1] items-center justify-between gap-2 lg:mt-0">
                              <Icon
                                icon="akar-icons:info"
                                className="h-6 w-auto cursor-pointer text-blue-500 duration-200 hover:scale-110"
                                onClick={() => {
                                  setOrderInModal(order);
                                  setIsOrderDetailModalOpen(true);
                                }}
                              />
                              <Icon
                                icon="gridicons:cross"
                                className="h-7 w-auto cursor-pointer text-blue-500 duration-200 hover:scale-110"
                                onClick={() => handleCancelOrderClick(order.id)}
                              />
                              <Icon
                                onClick={() => {
                                  if (order.status === OrderStatus.PENDING_PAYMENT) {
                                    navigate(`/checkout?id=${order.id}`);
                                  }
                                }}
                                icon="fluent:payment-16-regular"
                                className="h-7 w-auto cursor-pointer text-blue-500 duration-200 hover:scale-110"
                              />
                            </span>
                          )}
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="grid h-full w-full place-items-center py-10">
                    <Icon icon="eos-icons:loading" className="h-16 w-auto text-gray-300" />
                  </div>
                )}
              </div>
              {orders ? (
                <Link
                  to="/order"
                  className="mx-auto w-fit rounded-full border-2 border-[#93278f] px-8 py-3 text-center font-medium text-[#93278f] duration-150 hover:bg-[#93278f] hover:text-white"
                >
                  Show more
                </Link>
              ) : null}
            </div>
          </section>
        </div>
      </div>
    </>
  );
};
export default withAuthenticationRequired(Account, {
  onRedirecting: () => <div className="load-center"></div>,
});
