import React, {useRef, useState} from 'react';
import axios from 'axios';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-number-input/style.css';
import 'react-phone-input-2/lib/material.css';
import {isValidPhoneNumber} from 'libphonenumber-js';

interface IBindPhone {
  domain: string;
  accessToken;
  fn;
  backProfile?;
}

const BindPhone = (props: IBindPhone) => {
  const [phoneNumber, setPhoneNumber] = useState<any>();
  const code = useRef<HTMLInputElement>(null);
  const [stage, setStage] = useState(0);
  const [errorMsg, setErrorMsg] = useState();
  const [send, setSend] = useState(false);
  const [verify, setVerify] = useState(false);
  const [wrong, setWrong] = useState(false);
  const [country, setCountry] = useState<any>();
  async function sendVerifyCodeHandle() {
    if (!isValidPhoneNumber('+' + phoneNumber, country['countryCode'])) {
      // alert(`This is wrong PhoneNumber : ${phoneNumber}`);
      setWrong(true);
      return;
    }
    try {
      const result = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/domain/bindPhone/${'+' + phoneNumber}/${props.domain}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${props.accessToken}`,
        },
      });
      if (result.status == 200) {
        setSend(true);
        wrong && setWrong(false);
      }
    } catch (e) {
      const error = e as any;
      alert(error.response.data.message);
    }
  }
  async function verifyHandle() {
    if (!isValidPhoneNumber('+' + phoneNumber, country['countryCode'])) {
      alert(`This is wrong PhoneNumber : ${phoneNumber}`);
      return;
    }
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/domain/bindPhone`,
        {
          verificationCode: code.current!.value,
          phoneNumber: '+' + phoneNumber,
          domain: props.domain,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${props.accessToken}`,
          },
        },
      );
      if (result.status == 200) {
        // alert(`Bound Phone ${phoneNumber}`);
        setSend(false);
        setVerify(true);
        props.fn();
        setVerify(false);
        setPhoneNumber('');
        code.current!.value = '';
      }
    } catch (e) {
      const error = e as any;
      alert(error.response.data.message);
    }
  }

  return (
    <div className="box-shadow flex flex-col gap-2 p-10">
      {errorMsg != undefined && (
        <div className="border-l-4 border-red-500 bg-red-100 p-4 text-red-700" role="alert">
          <p className="font-bold">{errorMsg}</p>
        </div>
      )}
      {/* <button
        onClick={(x) => {
          props.backProfile();
        }}
        className="border-2 rounded px-2 py-2 w-10"
      >
        <Icon icon="mdi:keyboard-return" />
      </button> */}
      {verify == false && (
        <div>
          <p className="text-2xl font-medium">Bound Phone</p>
          <p className="mt-5 text-lg font-medium">Phone number</p>
          <div className="mt-3 flex flex-col items-center gap-5 md:flex-row ">
            <PhoneInput
              country="hk"
              placeholder="Enter phone number"
              value={phoneNumber}
              onChange={(e, country) => {
                setCountry(country);
                setPhoneNumber(e);
              }}
            />

            <button
              name="sendVerifyCode"
              onClick={sendVerifyCodeHandle}
              className={`font-mediumtext-sm bg theme w-full rounded-md px-8 py-3.5 text-white outline-none duration-200`}
            >
              Send Code
            </button>
          </div>
          <div className={`mt-3 rounded-md border-2 border-green-500 bg-green-300 px-3 py-3 text-green-600 ${send ? 'block' : 'hidden'}`}>Verification Code sent to your phone</div>
          <div className={`mt-3 rounded-md border-2 border-red-400 bg-red-300 px-3 py-3 text-red-500 ${wrong ? 'block' : 'hidden'}`}>This is wrong PhoneNumber : {phoneNumber}</div>
          <p className="mt-6 text-lg font-medium lg:mt-3">Code</p>
          <div className="mt-2 flex flex-col gap-8">
            <input name="code" ref={code} className="w-full rounded-lg border py-3 px-3 outline-blue-500 lg:w-auto" placeholder="Enter Verification Code" disabled={!send} />

            <button
              name="sendVerifyCode"
              onClick={verifyHandle}
              className={`bg theme w-full rounded-md px-10 py-4 text-base font-medium text-white duration-200 hover:bg-purple-600 lg:mt-0 lg:w-auto`}
            >
              Verify
            </button>
          </div>
        </div>
      )}
      {verify == true && (
        <div className={`mt-3 rounded-md border-2 border-green-500 bg-green-300 px-3 py-5 text-green-600 ${verify ? 'block' : 'hidden'}`}>Bound Phone {phoneNumber}</div>
      )}
    </div>
  );
};

export default BindPhone;
