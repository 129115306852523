import {Icon} from '@iconify/react';
import {useContext, useEffect, useRef, useState} from 'react';
import Select from 'react-select';
import {motion} from 'framer-motion';
import {IShoppingCartDomain} from '../../interface/shopping-cart-domain.interface';
import {IUser} from '../../interface/user.interface';
import {updateShoppingCartDomain} from '../../api/update-shopping-cart-domain';
import {deleteShoppingCartDomain} from '../../api/delete-shopping-cart-domain';
import _ from 'lodash';
import {GlobalStateContext} from '../../context/GlobalStateContext';

interface IProps {
  domain: IShoppingCartDomain;
  user: IUser;
  index: number;
  accessToken: string;
  popup?: boolean;
  length?: number;
}

function Domain(props: IProps) {
  const item = useRef<HTMLDivElement>(null);

  const { updateShoppingCartDomainsCount, updateShoppingCart, setShoppingCart } = useContext(GlobalStateContext);

  const [user] = useState<IUser>(props.user);
  const [domain, setDomain] = useState<IShoppingCartDomain>(props.domain);
  const [isDomainUpdating, setIsDomainUpdating] = useState<boolean>(false);
  const [ethereumAddresses, setEthereumAddresses] = useState<{ value: string; label: string }[]>([]);
  const [option, setOption] = useState(false);
  const [quantity, setQuantity] = useState<number>(domain.durations);
  const increase = useRef<HTMLButtonElement>(null);
  const decrease = useRef<HTMLButtonElement>(null);
  const [managedAddress, setManagedAddress] = useState<string>('');

  useEffect(() => {
    const _ethereumAddresses = user.ethereumAddresses.map((addr) => ({
      value: addr.address,
      label: addr.address,
    }));

    setEthereumAddresses(_ethereumAddresses);
  }, [user.ethereumAddresses]);

  useEffect(() => {
    setDomain(props.domain);
  }, [props.domain]);

  const updateOwnerAddress = async (id: number, address: string) => {
    setIsDomainUpdating(true);
    const _cart = await updateShoppingCartDomain(props.accessToken, id, {
      owner: address,
    });
    const _domain = _cart.domains.find((__domain) => __domain.id === domain.id);
    if (_domain) {
      setDomain(_domain);
      updateShoppingCart();
    }
    setIsDomainUpdating(false);
  };

  const updateDuration = async (id: number, durations: number) => {
    if (durations > 0 && durations < 10) {
      setIsDomainUpdating(true);
      // console.log(durations);

      const _cart = await updateShoppingCartDomain(props.accessToken, id, {
        durations,
      });
      const _domain = _cart.domains.find((__domain) => __domain.id === domain.id);
      if (_domain) {
        setDomain(_domain);
        setShoppingCart(_cart);
      }
      setIsDomainUpdating(false);
    }
  };

  const deleteDomain = async (id: number) => {
    setIsDomainUpdating(true);
    const _cart = await deleteShoppingCartDomain(props.accessToken, id);
    setShoppingCart(_cart);
    setIsDomainUpdating(false);
    // updateShoppingCart();
    updateShoppingCartDomainsCount();
  };

  const getDefaultAddressIndex = () => {
    return domain.owner.address !== null ? ethereumAddresses.findIndex((address) => address.value === domain.owner.address) : null;
  };

  useEffect(() => {
    let r = document.querySelector(':root') as HTMLElement;
    if (item.current) {
      // console.log(r.style.getPropertyValue('--cart-h'));
      r.style.setProperty('--cart-h', `${item.current.clientHeight}px`);
    }
  });

  useEffect(() => {
    window.onclick = (e) => {
      if (!e.target.closest('.domain-select') && option) {
        setOption(false);
      }
    };
    // console.log((document.querySelector('.domain-select') as HTMLSelectElement).tagName);
  });

  // useEffect(() => {
  //   updateDuration(domain.id, quantity);
  // }, [quantity]);

  const increaseQuantity = () => {
    if (quantity < 5) {
      const _quantity = quantity + 1;
      setQuantity(_quantity);
      updateDuration(domain.id, _quantity);
    }
  };

  const decreaseQuantity = () => {
    if (quantity > 0) {
      const _quantity = quantity - 1;
      setQuantity(_quantity);
      updateDuration(domain.id, _quantity);
    }
  };
  const renderDate=():String=>{
    const date = new Date(domain.expiredAt)
    date.setFullYear(date.getFullYear()+domain.durations)
    return date.toUTCString()
  }

  return (
    <div key={props.index} ref={item} className={`cart-con flex flex-col ${!(props.index === props.length) && 'border-b'} bg-white before:gap-5`}>
      {domain.status === 'AVAILABLE' ? null : <div className="mt-5 rounded-md bg-red-500 px-5 py-5 font-medium text-red-200">Domain not available</div>}

      <div className="relative flex flex-col justify-between gap-7 py-12 pb-10">
        <div className="flex flex-[2] items-center justify-between  text-base lg:text-lg">
          <p>
            {domain.name}.{domain.tld.name}
          </p>
          <div className={`flex min-w-fit shrink-0 items-center truncate font-light text-gray-400 ${quantity >= 3 && 'line-through'}`}>$ {domain.tld.price.purchase}</div>
        </div>
        <div>
          {domain.type}  {domain.type==="RENEWAL"? `-> ${renderDate()}` :null}
        </div>
        <div className="flex justify-between">
          <div className="relative flex min-w-[110px] max-w-[140px] flex-1 justify-center lg:justify-start">
            <div className="absolute z-[10] h-full w-full"></div>
            <button
              ref={decrease}
              onClick={() => {
                decreaseQuantity();
              }}
              className="absolute left-0 top-0 z-[11] grid aspect-square h-full cursor-pointer place-items-center rounded-full border bg-white active:bg-gray-200"
              disabled={isDomainUpdating}
            >
              -
            </button>
            <button
              ref={increase}
              onClick={() => {
                increaseQuantity();
              }}
              className="absolute right-0 top-0 z-[11] grid aspect-square h-full cursor-pointer place-items-center rounded-full border bg-white active:bg-gray-200"
              disabled={isDomainUpdating}
            >
              +
            </button>
            <input
              className="h-[40px] w-full rounded-full border text-center"
              onChange={(e) => {
                _.toNumber(e.currentTarget.value) !== 0 && updateDuration(domain.id, _.toNumber(e.currentTarget.value));
              }}
              onClick={() => {
                return false;
              }}
              value={`${quantity} Years`}
              // disabled
            ></input>
          </div>
          <div className="flex items-center justify-end text-left md:justify-start">
            <motion.span className="h-full w-full items-center justify-end lg:justify-start" animate={isDomainUpdating ? { display: 'flex' } : { display: 'none' }}>
              <Icon icon="eos-icons:loading" className="h-6 w-auto text-gray-300" />
            </motion.span>
            <motion.span className="text-right text-base font-medium md:text-left" animate={isDomainUpdating ? { display: 'none' } : { display: 'block' }}>
              ${domain.price}
            </motion.span>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center gap-2 pb-12 lg:flex-row lg:gap-10">
        <span className="self-start lg:self-center">Address:</span>
        {ethereumAddresses.length ? (
          <Select
            options={ethereumAddresses}
            defaultValue={getDefaultAddressIndex() !== null ? ethereumAddresses[getDefaultAddressIndex()!] : ethereumAddresses[0]}
            className="w-full"
            onChange={(e) => {
              updateOwnerAddress(domain.id, `${e?.value}`);
            }}
          />
        ) : null}
      </div>
      <button
        onClick={() => {
          deleteDomain(domain.id);
          setQuantity(1);
        }}
        data-show={quantity === 0}
        className="btn-delete w-full rounded-md bg-red-500"
      >
        <Icon className="mx-auto h-auto w-5 text-white" icon="ant-design:delete-outlined" />
      </button>
    </div>
  );
}

export default Domain;
