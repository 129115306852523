import {Icon} from '@iconify/react';
import {motion} from 'framer-motion';
import {useContext, useEffect, useState} from 'react';
import {CreateShoppingCartDomainType} from '../../api/create-shopping-cart-domain';
import {lookupDomain} from '../../api/lookup-domain';
import {lookupDomainWithAuth} from '../../api/lookup-domain-with-auth';
import {GlobalStateContext} from '../../context/GlobalStateContext';

const show = {
  hidden: {
    opacity: 0,
  },
  show: (i: number) => {
    const delay = i * 0.2;
    return {
      opacity: 1,
      transition: { delay: delay, duration: 0.5, ease: 'easeInOut' },
    };
  },
};

interface IComponentsProps {
  key: number;
  name: string;
  tld: string;
  price: number;
  isAddToCartButtonAvailable: boolean;
  isAuthenticated: boolean;
  accessToken?: string;
  addDomainToShoppingCartFn(type: CreateShoppingCartDomainType, tld: string, name: string, code?: string): Promise<void>;
  setAdd?: (value: boolean) => void;
  setShowNav?: any;
  search?: string;
  isManaged?: boolean;
}

function List(props: IComponentsProps) {
  const [isDomainAvailable, setIsDomainAvailable] = useState<boolean>(false);
  const [isAddingDomainToShoppingCart, setIsAddingDomainToShoppingCart] = useState<boolean>(false);
  const [isAddedDomainToShoppingCart, setIsAddedDomainToShoppingCart] = useState<boolean>(false);
  const [isAlreadyInShoppingCart, setIsAlreadyInShoppingCart] = useState<boolean>(false);

  const { shoppingCart } = useContext(GlobalStateContext);

  useEffect(() => {
    if (shoppingCart) {
      const isExist = shoppingCart.domains.find((domain) => `${domain.name}.${domain.tld.name}` === `${props.name}.${props.tld}`);
      setIsAlreadyInShoppingCart(!!isExist);
    }
  }, [shoppingCart, props]);

  useEffect(() => {
    async function exec() {
      try {
        if (props.isAuthenticated && props.accessToken) {
          const _lookupDomain = await lookupDomainWithAuth(`${props.name}.${props.tld}`, props.accessToken);
          setIsDomainAvailable(_lookupDomain.available);
        } else {
          const _lookupDomain = await lookupDomain(`${props.name}.${props.tld}`);
          setIsDomainAvailable(_lookupDomain.available);
        }
      } catch (error) {
        console.error(error);
        setIsDomainAvailable(false);
      }
    }
    exec();
  }, [props.isAuthenticated, props.accessToken, props.name, props.tld]);

  return (
    <motion.div
      variants={show}
      initial="hidden"
      animate={isDomainAvailable ? 'show' : { display: 'none' }}
      key={props.key}
      className="box-shadow b-4 lg:pb-auto flex w-full max-w-[100%] flex-col justify-between gap-5 overflow-hidden rounded-lg border bg-white px-6 py-7 text-xl md:flex-row md:items-center lg:gap-3 lg:px-8 lg:py-8"
    >
      <span className="md:text:xl flex text-lg">
        <span className="truncate">{props.name}</span>
        <span className="shrink-0 font-semibold">.{props.tld}</span>
      </span>
      <div className="flex justify-between gap-6 md:justify-start">
        <div className="flex items-center gap-2">
          <Icon className="text-green-500" icon="teenyicons:tick-circle-outline" />
          <span className="text-sm text-gray-400">USD${props.price}/Year</span>
        </div>
        {props.isAuthenticated && (
          <div className="grid w-7 place-items-center">
            <motion.button
              onClick={() => {
                async function exec() {
                  if (props.setAdd) {
                    props.setAdd(true);
                  }
                  setIsAddingDomainToShoppingCart(true);
                  if (props.search && props.search !== '') {
                    await props.addDomainToShoppingCartFn(CreateShoppingCartDomainType.PURCHASE, props.tld, props.name, props.search.split('ref=')[1]);
                  } else {
                    await props.addDomainToShoppingCartFn(CreateShoppingCartDomainType.PURCHASE, props.tld, props.name);
                  }
                  setIsAddingDomainToShoppingCart(false);
                  setIsAddedDomainToShoppingCart(true);
                  window.innerWidth > 768 && props.setShowNav(true);
                }
                exec();
              }}
              animate={isAddingDomainToShoppingCart || isAddedDomainToShoppingCart || isAlreadyInShoppingCart ? { display: 'none' } : { display: 'block' }}
              disabled={(!props.isAddToCartButtonAvailable || isAlreadyInShoppingCart) && !props.isManaged}
            >
              <Icon icon="lucide:shopping-cart" className="h-5 w-auto cursor-pointer text-gray-600 duration-200 ease-in-out hover:text-green-500" />
            </motion.button>

            <motion.div
              animate={isAddingDomainToShoppingCart ? (isAddedDomainToShoppingCart || isAlreadyInShoppingCart ? { display: 'none' } : { display: 'flex' }) : { display: 'none' }}
            >
              <Icon icon="eos-icons:loading" className="h-5 w-auto text-green-500 duration-200 hover:text-green-600" />
            </motion.div>
            <motion.div animate={isAddedDomainToShoppingCart || isAlreadyInShoppingCart ? { display: 'flex' } : { display: 'none' }}>
              <Icon icon="charm:tick" className="h-5 w-auto text-green-500" />
            </motion.div>
          </div>
        )}
      </div>
    </motion.div>
  );
}

export default List;
