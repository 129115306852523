import config from '../config';
import axios from 'axios';

export async function createCheckoutPay(accessToken: string, orderId: string, country: string, rememberCountrySetting: boolean) {
  const url = new URL(`/payment/${orderId}/checkout`, `${config.api.endpoint}`);
  const response = await axios.post(
    url.href,
    {
      country: country,
      rememberCountrySetting: rememberCountrySetting,
    },
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    },
  );
  return response.data;
}
