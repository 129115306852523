import logo from '../../static/EDNS_white.png';
import { Icon } from '@iconify/react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { IOrder } from '../../interface';

const Logo = () => {
  return (
    <svg className="home-logo z-[80] mx-auto w-60" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 250.333 80.085">
      <defs>
        <clipPath id="clip-path">
          <rect id="Rectangle_331" data-name="Rectangle 331" width="250.333" height="73.085" fill="none" />
        </clipPath>
      </defs>
      <g id="Group_413" data-name="Group 413" clipPath="url(#clip-path)">
        <path
          id="Path_120"
          data-name="Path 120"
          d="M52.039,63.5H26.007a5.1,5.1,0,0,1-5.09-5.09V55.915a5.1,5.1,0,0,1,5.09-5.09H52.039a5.1,5.1,0,0,1,5.09,5.09v2.494a5.1,5.1,0,0,1-5.09,5.09"
          transform="translate(-4.151 -10.085)"
          className="main fill"
        />
        <path
          id="Path_121"
          data-name="Path 121"
          d="M46.806,25.186l26.027-.513a5.1,5.1,0,0,1,5.189,4.989l.05,2.494a5.106,5.106,0,0,1-4.989,5.189l-26.028.513a5.106,5.106,0,0,1-5.19-4.989l-.049-2.494a5.106,5.106,0,0,1,4.99-5.189"
          transform="matrix(1, 0.017, -0.017, 1, -7.743, -5.937)"
          className="theme fill"
        />
        <path
          id="Path_122"
          data-name="Path 122"
          d="M51.662,12.674H25.63a5.1,5.1,0,0,1-5.09-5.09V5.09A5.1,5.1,0,0,1,25.63,0H51.662a5.1,5.1,0,0,1,5.09,5.09V7.584a5.1,5.1,0,0,1-5.09,5.09"
          transform="translate(-4.076 0)"
          className="main fill"
        />
        <path
          id="Path_123"
          data-name="Path 123"
          d="M17.519,56.092,1.352,35.688a5.105,5.105,0,0,1,.828-7.151l1.955-1.549a5.106,5.106,0,0,1,7.151.829l16.167,20.4a5.105,5.105,0,0,1-.828,7.151l-1.955,1.549a5.1,5.1,0,0,1-7.151-.829"
          transform="translate(-0.051 -5.138)"
          className="main fill"
        />
        <path
          id="Path_124"
          data-name="Path 124"
          d="M26.528,10.235,11.219,31.291a5.1,5.1,0,0,1-7.11,1.124L2.091,30.948A5.105,5.105,0,0,1,.967,23.837L16.276,2.781a5.105,5.105,0,0,1,7.111-1.123L25.4,3.124a5.105,5.105,0,0,1,1.124,7.111"
          transform="translate(0 -0.137)"
          className="main fill"
        />
        <path
          id="Path_125"
          data-name="Path 125"
          d="M48.937,75.24l26.028-.513a5.106,5.106,0,0,1,5.189,4.989l.048,2.494A5.105,5.105,0,0,1,75.213,87.4l-26.027.513A5.106,5.106,0,0,1,44,82.923l-.049-2.494a5.106,5.106,0,0,1,4.99-5.189"
          transform="matrix(1, 0.017, -0.017, 1, -7.292, -15.899)"
          className="theme fill"
        />
        <path
          id="Path_126"
          data-name="Path 126"
          d="M83.338,27.176,99.9,47.258a5.1,5.1,0,0,1-.688,7.165L97.292,56.01a5.105,5.105,0,0,1-7.166-.687L73.56,35.242a5.106,5.106,0,0,1,.688-7.166l1.924-1.587a5.106,5.106,0,0,1,7.166.688"
          transform="translate(-14.367 -5.026)"
          className="theme fill"
        />
        <path
          id="Path_127"
          data-name="Path 127"
          d="M75.524,72.992,90.414,51.639A5.106,5.106,0,0,1,97.5,50.376L99.547,51.8a5.106,5.106,0,0,1,1.263,7.087L85.92,80.242a5.1,5.1,0,0,1-7.087,1.263l-2.046-1.427a5.1,5.1,0,0,1-1.263-7.087"
          transform="translate(-14.806 -9.815)"
          className="theme fill"
        />
        <path
          id="Path_128"
          data-name="Path 128"
          d="M78.871,12.22h-7.61a2.54,2.54,0,0,1-2.532-2.532v-6.4A2.54,2.54,0,0,1,71.261.753h7.61A2.539,2.539,0,0,1,81.4,3.285v6.4a2.54,2.54,0,0,1-2.532,2.532"
          transform="translate(-13.638 -0.149)"
          className="theme fill"
        />
        <path
          id="Path_129"
          data-name="Path 129"
          d="M34.447,88.269h-7.61a2.54,2.54,0,0,1-2.532-2.532v-6.4A2.54,2.54,0,0,1,26.837,76.8h7.61a2.539,2.539,0,0,1,2.532,2.532v6.4a2.54,2.54,0,0,1-2.532,2.532"
          transform="translate(-4.823 -15.24)"
          className="main fill"
        />
        <path
          id="Path_130"
          data-name="Path 130"
          d="M123.978,58.2h8.881a27.39,27.39,0,0,1,6.13.666,14.591,14.591,0,0,1,5.067,2.157,10.806,10.806,0,0,1,3.436,3.876,12.16,12.16,0,0,1,1.272,5.787A11.067,11.067,0,0,1,147.53,76a11.64,11.64,0,0,1-3.322,3.858,15.116,15.116,0,0,1-4.821,2.368,20.143,20.143,0,0,1-5.732.806h-9.678ZM129.9,77.985h3.075a17.838,17.838,0,0,0,3.814-.386,8.5,8.5,0,0,0,3.056-1.28,6.251,6.251,0,0,0,2.03-2.35,7.773,7.773,0,0,0,.741-3.56,6.307,6.307,0,0,0-2.734-5.4,9.05,9.05,0,0,0-2.942-1.315,14.056,14.056,0,0,0-3.549-.438H129.9Z"
          transform="translate(-24.601 -11.549)"
          className="main fill"
        />
        <path
          id="Path_131"
          data-name="Path 131"
          d="M158.166,76.336a8.35,8.35,0,0,1,.8-3.682,8.521,8.521,0,0,1,2.183-2.823,9.8,9.8,0,0,1,3.264-1.807,13.008,13.008,0,0,1,8.008,0,9.806,9.806,0,0,1,3.264,1.807,8.52,8.52,0,0,1,2.183,2.823,8.908,8.908,0,0,1,0,7.365,8.515,8.515,0,0,1-2.183,2.824,9.818,9.818,0,0,1-3.264,1.806,13.008,13.008,0,0,1-8.008,0,9.81,9.81,0,0,1-3.264-1.806,8.515,8.515,0,0,1-2.183-2.824,8.345,8.345,0,0,1-.8-3.682m5.694,0a4.214,4.214,0,0,0,1.2,3.121,5.328,5.328,0,0,0,6.72,0,4.673,4.673,0,0,0,0-6.243,5.328,5.328,0,0,0-6.72,0,4.217,4.217,0,0,0-1.2,3.121"
          transform="translate(-31.386 -13.373)"
          className="main fill"
        />
        <path
          id="Path_132"
          data-name="Path 132"
          d="M187.9,67.813h5.466v2.315h.076a4.288,4.288,0,0,1,.759-.982,5.474,5.474,0,0,1,1.177-.877,7.013,7.013,0,0,1,3.455-.877,7.689,7.689,0,0,1,3.435.737,4.821,4.821,0,0,1,2.259,2.315,5.9,5.9,0,0,1,2.391-2.35,7.683,7.683,0,0,1,3.455-.7,7.55,7.55,0,0,1,3.131.579,5.038,5.038,0,0,1,2.012,1.578,6.412,6.412,0,0,1,1.063,2.35,12.439,12.439,0,0,1,.323,2.894V84.858h-5.694V74.933a3.729,3.729,0,0,0-.551-2.052,2.141,2.141,0,0,0-1.955-.859,4.028,4.028,0,0,0-1.651.3,2.67,2.67,0,0,0-1.063.824,3.312,3.312,0,0,0-.57,1.228,6.205,6.205,0,0,0-.17,1.472v9.014h-5.694V75.845q0-.456-.038-1.122a4.181,4.181,0,0,0-.266-1.262,2.412,2.412,0,0,0-.74-1.017,2.314,2.314,0,0,0-1.5-.421,3.756,3.756,0,0,0-1.784.368,2.614,2.614,0,0,0-1.044.982,3.779,3.779,0,0,0-.475,1.386,11,11,0,0,0-.115,1.613v8.488H187.9Z"
          transform="translate(-37.287 -13.373)"
          className="main fill"
        />
        <path
          id="Path_133"
          data-name="Path 133"
          d="M240.455,82.72h-.076a5.274,5.274,0,0,1-2.524,1.964,9.5,9.5,0,0,1-5.807.263,6.462,6.462,0,0,1-2.107-1A4.93,4.93,0,0,1,228.5,82.3a4.718,4.718,0,0,1-.531-2.28,4.9,4.9,0,0,1,.588-2.49,5.005,5.005,0,0,1,1.594-1.683,8.2,8.2,0,0,1,2.3-1.035,17.871,17.871,0,0,1,2.676-.543,27.563,27.563,0,0,1,2.772-.211q1.384-.035,2.562-.035a2.653,2.653,0,0,0-1.082-2.227,4.107,4.107,0,0,0-2.562-.825,5.955,5.955,0,0,0-2.563.544A6.971,6.971,0,0,0,232.18,73l-3.036-2.876a10.655,10.655,0,0,1,3.72-2.051,14.28,14.28,0,0,1,4.4-.685,12.361,12.361,0,0,1,4.118.58,6.005,6.005,0,0,1,2.582,1.7,6.334,6.334,0,0,1,1.347,2.753,16.734,16.734,0,0,1,.38,3.771v8.663h-5.238Zm-1.4-5.331c-.431,0-.967.018-1.614.052a8.8,8.8,0,0,0-1.86.3,4.257,4.257,0,0,0-1.518.736,1.642,1.642,0,0,0-.627,1.368,1.489,1.489,0,0,0,.874,1.4,3.883,3.883,0,0,0,1.822.456,6.156,6.156,0,0,0,1.613-.211,5,5,0,0,0,1.386-.6,2.98,2.98,0,0,0,.967-.982,2.657,2.657,0,0,0,.361-1.4V77.388Z"
          transform="translate(-45.236 -13.373)"
          className="main fill"
        />
        <path
          id="Path_134"
          data-name="Path 134"
          d="M254.059,60.468a2.833,2.833,0,0,1,.968-2.157,3.5,3.5,0,0,1,4.669,0,2.888,2.888,0,0,1,0,4.314,3.494,3.494,0,0,1-4.669,0,2.833,2.833,0,0,1-.968-2.157m.456,5.366h5.694V82.88h-5.694Z"
          transform="translate(-50.414 -11.393)"
          className="main fill"
        />
        <path
          id="Path_135"
          data-name="Path 135"
          d="M266.845,67.813h5.466v2.315h.076a4.287,4.287,0,0,1,.759-.982,5.474,5.474,0,0,1,1.177-.877,7.012,7.012,0,0,1,3.455-.877,8.409,8.409,0,0,1,3.492.614,4.94,4.94,0,0,1,2.069,1.7,6.565,6.565,0,0,1,1.006,2.56,18.08,18.08,0,0,1,.265,3.192v9.4h-5.694V76.511q0-.736-.057-1.525a4.5,4.5,0,0,0-.341-1.456,2.537,2.537,0,0,0-.874-1.087,2.877,2.877,0,0,0-1.688-.421,3.756,3.756,0,0,0-1.784.368,2.614,2.614,0,0,0-1.044.982,3.779,3.779,0,0,0-.475,1.386,11.007,11.007,0,0,0-.115,1.613v8.488h-5.694Z"
          transform="translate(-52.951 -13.373)"
          className="main fill"
        />
        <path
          id="Path_136"
          data-name="Path 136"
          d="M304.192,72.829a4.168,4.168,0,0,0-3.3-1.438,3.308,3.308,0,0,0-1.4.316,1.105,1.105,0,0,0-.683,1.087.983.983,0,0,0,.7.93,9.8,9.8,0,0,0,1.784.526q1.082.228,2.315.491a9.148,9.148,0,0,1,2.316.824A5.051,5.051,0,0,1,307.7,77.09a4.29,4.29,0,0,1,.7,2.578,4.5,4.5,0,0,1-.778,2.718,5.792,5.792,0,0,1-1.993,1.719,9.038,9.038,0,0,1-2.732.912,17.535,17.535,0,0,1-3,.263,15.656,15.656,0,0,1-3.909-.508,7.446,7.446,0,0,1-3.34-1.807l3.454-3.542a5.624,5.624,0,0,0,1.765,1.385,5.08,5.08,0,0,0,2.259.474,5.673,5.673,0,0,0,1.784-.263.985.985,0,0,0,.8-.965,1.068,1.068,0,0,0-.7-1,8.142,8.142,0,0,0-1.784-.562q-1.082-.227-2.316-.491a10.055,10.055,0,0,1-2.315-.79,4.721,4.721,0,0,1-1.784-1.49,4.287,4.287,0,0,1-.7-2.578,4.886,4.886,0,0,1,.664-2.6,5.58,5.58,0,0,1,1.747-1.789,7.868,7.868,0,0,1,2.486-1.034,12.285,12.285,0,0,1,2.846-.333,14.394,14.394,0,0,1,3.682.491,6.962,6.962,0,0,1,3.15,1.754Z"
          transform="translate(-58.072 -13.373)"
          className="main fill"
        />
        <path
          id="Path_137"
          data-name="Path 137"
          d="M124.06,2.509h24.676V9.9H132.063v6.772h15.75v7.388h-15.75v7.387h17.6v7.387h-25.6Z"
          transform="translate(-24.618 -0.498)"
          className="main fill"
        />
        <path
          id="Path_138"
          data-name="Path 138"
          d="M164,2.509h12a34.288,34.288,0,0,1,8.285.975,18.972,18.972,0,0,1,6.849,3.155,15.463,15.463,0,0,1,4.643,5.669,19.015,19.015,0,0,1,1.719,8.465,17.275,17.275,0,0,1-1.667,7.772,16.714,16.714,0,0,1-4.489,5.643,19.842,19.842,0,0,1-6.516,3.463,25.288,25.288,0,0,1-7.746,1.18H164Zm8,28.934h4.155a22.344,22.344,0,0,0,5.156-.564,11.027,11.027,0,0,0,4.13-1.872,8.974,8.974,0,0,0,2.744-3.437,12.176,12.176,0,0,0,1-5.207,10.144,10.144,0,0,0-1-4.643,9.279,9.279,0,0,0-2.692-3.258,11.786,11.786,0,0,0-3.977-1.924,17.639,17.639,0,0,0-4.8-.642H172Z"
          transform="translate(-32.543 -0.498)"
          className="main fill"
        />
        <path
          id="Path_139"
          data-name="Path 139"
          d="M212.574,2.509h10.876L238.892,27.75h.1V2.509h8V38.831H236.532L220.679,12.975h-.1V38.831h-8Z"
          transform="translate(-42.182 -0.498)"
          className="main fill"
        />
        <path
          id="Path_140"
          data-name="Path 140"
          d="M282.016,11.156a5.705,5.705,0,0,0-2.642-1.821,9.486,9.486,0,0,0-3.156-.59,8.289,8.289,0,0,0-1.795.205,6.893,6.893,0,0,0-1.744.641,3.8,3.8,0,0,0-1.334,1.154,2.922,2.922,0,0,0-.514,1.744A2.89,2.89,0,0,0,272.063,15a11.653,11.653,0,0,0,3.1,1.488q1.872.616,4.027,1.231a16.623,16.623,0,0,1,4.027,1.744,9.547,9.547,0,0,1,3.1,3.028,9.224,9.224,0,0,1,1.231,5.079,11.681,11.681,0,0,1-1.129,5.284,10.718,10.718,0,0,1-3.052,3.745,12.975,12.975,0,0,1-4.463,2.206,20.471,20.471,0,0,1-11.928-.359,16.449,16.449,0,0,1-5.643-3.488l5.694-6.259a8.3,8.3,0,0,0,3.1,2.539,8.861,8.861,0,0,0,3.874.9,8.772,8.772,0,0,0,1.975-.231,6.44,6.44,0,0,0,1.8-.693,3.875,3.875,0,0,0,1.283-1.18,2.943,2.943,0,0,0,.487-1.693,3.072,3.072,0,0,0-1.258-2.591,11.621,11.621,0,0,0-3.154-1.617q-1.9-.665-4.1-1.334a18.391,18.391,0,0,1-4.1-1.795,9.9,9.9,0,0,1-3.155-2.976,8.514,8.514,0,0,1-1.257-4.874,10.911,10.911,0,0,1,1.154-5.13,11.149,11.149,0,0,1,3.078-3.694A13.4,13.4,0,0,1,271.191,2.1a18.187,18.187,0,0,1,5.181-.744,20.386,20.386,0,0,1,5.952.872A13.741,13.741,0,0,1,287.5,5.154Z"
          transform="translate(-51.859 -0.269)"
          className="main fill"
        />
        <path
          id="Path_141"
          data-name="Path 141"
          d="M306.288,45.2h-5.25a1.907,1.907,0,0,1-1.9-1.9v-5.25a1.906,1.906,0,0,1,1.9-1.9h5.25a1.906,1.906,0,0,1,1.9,1.9v5.25a1.907,1.907,0,0,1-1.9,1.9"
          transform="translate(-59.359 -7.172)"
          className="main fill"
        />
      </g>
    </svg>
  );
};

const Cancel = () => {
  const id = useParams();
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [accessToken, setAccessToken] = useState<string>();
  const [order, setOrder] = useState<IOrder>();
  useEffect(() => {
    if (isAuthenticated) {
      const exec = async () => {
        const token = await getAccessTokenSilently();
        setAccessToken(token);
      };
      exec();
      getOrder();
    }
  }, [isAuthenticated, accessToken]);
  const getOrder = async () => {
    try {
      const result = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/order/${id.id}`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      // console.log(result.data);
      setOrder(result.data);
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div className="mx-auto w-auto p-10 lg:w-[35rem]">
      <Link to="/">
        <Logo />
      </Link>
      <div className="relative mt-10 flex flex-col items-center rounded-lg border bg-white py-16 shadow-md">
        <Icon icon="akar-icons:cross" className="mx-auto h-20 w-auto text-red-500" />
        <div className="mt-5 text-xl font-medium">Payment Cancel</div>
        <div className="mt-10 flex w-full flex-col justify-between px-10 text-gray-500 lg:flex-row">
          <span>Amount paid:</span> <span className="cursor-pointer text-blue-500 hover:underline">${order?.amount} USD</span>
        </div>
        <div className="mt-5 mb-16 flex w-full flex-col justify-between px-10 text-gray-500 lg:flex-row">
          <span>Transaction ID:</span> <span className="cursor-pointer text-blue-500 hover:underline">{order?.id}</span>
        </div>
        <Link to="/account" className="absolute bottom-0 w-full bg-[#93278f] py-5 text-center text-white outline-none duration-200 hover:bg-[#93278f]">
          Close
        </Link>
      </div>
    </div>
  );
};

export default Cancel;
