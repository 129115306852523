import config from '../config';
import provider from './provider';
import {EDNSRegistry__factory as EDNSRegistryFactory} from './typechain/factories/EDNSRegistry__factory';

// const EDNSRegistry = {
//     5:EDNSRegistryFactory.connect(config.contractAddress[5].registry, provider[5]),
//     137:EDNSRegistryFactory.connect(config.contractAddress[137].registry, provider[137]),
//     4689:EDNSRegistryFactory.connect(config.contractAddress[4689].registry,provider[4689]),
//     4690:EDNSRegistryFactory.connect(config.contractAddress[4690].registry,provider[4690])
// }
function EDNSRegistry(){
    const objs = {}
    for (const [key, value] of Object.entries(config.contractAddress)) {
        objs[key] = EDNSRegistryFactory.connect(config.contractAddress[key].registry, provider[key])
    }
    console.log({objs})
    return objs
}
export default EDNSRegistry;
