import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './App.scss';
import Home from './Pages/Home/Home';
import Result from './Pages/SearchResult/Result';
import Cart from './Pages/Cart/Cart';
import Contact from './Pages/CustomerService/ContactUs/Contact';
import Checkout from './Pages/Checkout/Checkout';
import Account from './Pages/Account/Account';
import Order from './Pages/Account/Order';
import Success from './Pages/CheckoutRedirect/Success';
import Fail from './Pages/CheckoutRedirect/Fail';
import Cancel from './Pages/CheckoutRedirect/Cancel';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import ScrollToTop from './scripts/ScrollToTop';
import {Auth0Provider} from '@auth0/auth0-react';
import config from './config';
import * as Sentry from '@sentry/react';
import {BrowserTracing} from '@sentry/tracing';
import Setting from './Pages/Account/Setting';
import MyDomainN from './Pages/DomainList/MyDomainN';
import Sms from './Pages/SMS/Sms';
import Friends from './Pages/SMS/Friends';
import UIContainer from './components/UIContainer';
import Profile from './Pages/DomainManagement/Profile';
import TransferOrSell from './Pages/MyDomain/TransferOrSell';
import Reseller from './Pages/Reseller/Reseller';
import Lookup from './Pages/Look-up/Lookup';
import GlobalStateProvider from './context/GlobalStateContext';
import Maintenance from './Pages/Maintenance';
import Missing from './Pages/Missing';
import ResellerBind from "./Pages/Reseller/ResellerBind";

Sentry.init({
  dsn: config.sentry.dsn,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 0.2,
});

// function getLibrary(provider): ethers.providers.Web3Provider {
//   return new ethers.providers.Web3Provider(provider);
// }

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Auth0Provider
        domain={config.auth0.domain}
        clientId={config.auth0.clientId}
        audience={new URL('/api/v2/', config.auth0.defaultDomain).href}
        redirectUri={window.location.origin}
      >
        <GlobalStateProvider>
          <ScrollToTop>
            <Routes>
              {/* Maintenance Switch */}
              {false ? (
                <Route path="/" element={<Maintenance />} />
              ) : (
                <Route path="/" element={<UIContainer />}>
                  <Route index element={<Home />}></Route>
                  {/* <Route path="/about" element={<About />}></Route> */}
                  <Route path="/setting" element={<Setting />}></Route>
                  <Route path="/contact" element={<Contact />}></Route>
                  <Route path="/profile/:domain/:type/:tab" element={<Profile />}></Route>
                  <Route path="/mydomain" element={<MyDomainN />}></Route>
                  <Route path="/account" element={<Account />}></Route>
                  <Route path="/order" element={<Order />}></Route>
                  <Route path="/search/:fqdn" element={<Result />}></Route>
                  <Route path="/look-up" element={<Lookup />}></Route>
                  {/*<Route path="/look-up/:fqdn" element={<LookupResult />}></Route>*/}
                  <Route path="/cart" element={<Cart />}></Route>
                  <Route path="/checkout" element={<Checkout />}></Route>
                  <Route path="/sms" element={<Sms />}></Route>
                  <Route path="/friends" element={<Friends />}></Route>
                  <Route path="/payment/success/:id" element={<Success />}></Route>
                  <Route path="/payment/failed/:id" element={<Fail />}></Route>
                  <Route path="/payment/cancel/:id" element={<Cancel />}></Route>
                  <Route path="/transfersell" element={<TransferOrSell />}></Route>
                  <Route path="/reseller" element={<Reseller />}></Route>
                  <Route path="/reseller/bind" element={<Reseller />}></Route>
                </Route>
              )}
              <Route path="*" element={<Missing />}></Route>
            </Routes>
          </ScrollToTop>
        </GlobalStateProvider>
      </Auth0Provider>
    </BrowserRouter>
  </React.StrictMode>,
  // rootElemnet
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
