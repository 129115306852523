import {Icon} from '@iconify/react';
import {Link, NavLink, useLocation, useNavigate} from 'react-router-dom';
import {useAuth0} from '@auth0/auth0-react';
import {motion} from 'framer-motion';
import {useContext, useEffect, useRef, useState} from 'react';
import _ from 'lodash';
import {GlobalStateContext} from "../../context/GlobalStateContext";

interface ILeftNav {
  isAuthenticated?: boolean;
  nav?: boolean;
  setNav: (nav: boolean) => void;
  setDisplay: (display: boolean) => void;
  setResellerCode : (code:string)=>void;
}

const Logo = (...props) => {
  return (
    <svg className="mx-auto w-[100px] md:mb-[1vw] md:w-[81%]" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 250.333 80.085">
      <defs>
        <clipPath id="clip-path">
          <rect id="Rectangle_331" data-name="Rectangle 331" width="250.333" height="73.085" fill="none" />
        </clipPath>
      </defs>
      <g id="Group_413" data-name="Group 413" clipPath="url(#clip-path)">
        <path
          id="Path_120"
          data-name="Path 120"
          d="M52.039,63.5H26.007a5.1,5.1,0,0,1-5.09-5.09V55.915a5.1,5.1,0,0,1,5.09-5.09H52.039a5.1,5.1,0,0,1,5.09,5.09v2.494a5.1,5.1,0,0,1-5.09,5.09"
          transform="translate(-4.151 -10.085)"
        />
        <path
          id="Path_121"
          data-name="Path 121"
          d="M46.806,25.186l26.027-.513a5.1,5.1,0,0,1,5.189,4.989l.05,2.494a5.106,5.106,0,0,1-4.989,5.189l-26.028.513a5.106,5.106,0,0,1-5.19-4.989l-.049-2.494a5.106,5.106,0,0,1,4.99-5.189"
          transform="matrix(1, 0.017, -0.017, 1, -7.743, -5.937)"
          className="theme fill"
        />
        <path
          id="Path_122"
          data-name="Path 122"
          d="M51.662,12.674H25.63a5.1,5.1,0,0,1-5.09-5.09V5.09A5.1,5.1,0,0,1,25.63,0H51.662a5.1,5.1,0,0,1,5.09,5.09V7.584a5.1,5.1,0,0,1-5.09,5.09"
          transform="translate(-4.076 0)"
        />
        <path
          id="Path_123"
          data-name="Path 123"
          d="M17.519,56.092,1.352,35.688a5.105,5.105,0,0,1,.828-7.151l1.955-1.549a5.106,5.106,0,0,1,7.151.829l16.167,20.4a5.105,5.105,0,0,1-.828,7.151l-1.955,1.549a5.1,5.1,0,0,1-7.151-.829"
          transform="translate(-0.051 -5.138)"
        />
        <path
          id="Path_124"
          data-name="Path 124"
          d="M26.528,10.235,11.219,31.291a5.1,5.1,0,0,1-7.11,1.124L2.091,30.948A5.105,5.105,0,0,1,.967,23.837L16.276,2.781a5.105,5.105,0,0,1,7.111-1.123L25.4,3.124a5.105,5.105,0,0,1,1.124,7.111"
          transform="translate(0 -0.137)"
        />
        <path
          id="Path_125"
          data-name="Path 125"
          d="M48.937,75.24l26.028-.513a5.106,5.106,0,0,1,5.189,4.989l.048,2.494A5.105,5.105,0,0,1,75.213,87.4l-26.027.513A5.106,5.106,0,0,1,44,82.923l-.049-2.494a5.106,5.106,0,0,1,4.99-5.189"
          transform="matrix(1, 0.017, -0.017, 1, -7.292, -15.899)"
          className="theme fill"
        />
        <path
          id="Path_126"
          data-name="Path 126"
          d="M83.338,27.176,99.9,47.258a5.1,5.1,0,0,1-.688,7.165L97.292,56.01a5.105,5.105,0,0,1-7.166-.687L73.56,35.242a5.106,5.106,0,0,1,.688-7.166l1.924-1.587a5.106,5.106,0,0,1,7.166.688"
          transform="translate(-14.367 -5.026)"
          className="theme fill"
        />
        <path
          id="Path_127"
          data-name="Path 127"
          d="M75.524,72.992,90.414,51.639A5.106,5.106,0,0,1,97.5,50.376L99.547,51.8a5.106,5.106,0,0,1,1.263,7.087L85.92,80.242a5.1,5.1,0,0,1-7.087,1.263l-2.046-1.427a5.1,5.1,0,0,1-1.263-7.087"
          transform="translate(-14.806 -9.815)"
          className="theme fill"
        />
        <path
          id="Path_128"
          data-name="Path 128"
          d="M78.871,12.22h-7.61a2.54,2.54,0,0,1-2.532-2.532v-6.4A2.54,2.54,0,0,1,71.261.753h7.61A2.539,2.539,0,0,1,81.4,3.285v6.4a2.54,2.54,0,0,1-2.532,2.532"
          transform="translate(-13.638 -0.149)"
          className="theme fill"
        />
        <path
          id="Path_129"
          data-name="Path 129"
          d="M34.447,88.269h-7.61a2.54,2.54,0,0,1-2.532-2.532v-6.4A2.54,2.54,0,0,1,26.837,76.8h7.61a2.539,2.539,0,0,1,2.532,2.532v6.4a2.54,2.54,0,0,1-2.532,2.532"
          transform="translate(-4.823 -15.24)"
        />
        <path
          id="Path_130"
          data-name="Path 130"
          d="M123.978,58.2h8.881a27.39,27.39,0,0,1,6.13.666,14.591,14.591,0,0,1,5.067,2.157,10.806,10.806,0,0,1,3.436,3.876,12.16,12.16,0,0,1,1.272,5.787A11.067,11.067,0,0,1,147.53,76a11.64,11.64,0,0,1-3.322,3.858,15.116,15.116,0,0,1-4.821,2.368,20.143,20.143,0,0,1-5.732.806h-9.678ZM129.9,77.985h3.075a17.838,17.838,0,0,0,3.814-.386,8.5,8.5,0,0,0,3.056-1.28,6.251,6.251,0,0,0,2.03-2.35,7.773,7.773,0,0,0,.741-3.56,6.307,6.307,0,0,0-2.734-5.4,9.05,9.05,0,0,0-2.942-1.315,14.056,14.056,0,0,0-3.549-.438H129.9Z"
          transform="translate(-24.601 -11.549)"
        />
        <path
          id="Path_131"
          data-name="Path 131"
          d="M158.166,76.336a8.35,8.35,0,0,1,.8-3.682,8.521,8.521,0,0,1,2.183-2.823,9.8,9.8,0,0,1,3.264-1.807,13.008,13.008,0,0,1,8.008,0,9.806,9.806,0,0,1,3.264,1.807,8.52,8.52,0,0,1,2.183,2.823,8.908,8.908,0,0,1,0,7.365,8.515,8.515,0,0,1-2.183,2.824,9.818,9.818,0,0,1-3.264,1.806,13.008,13.008,0,0,1-8.008,0,9.81,9.81,0,0,1-3.264-1.806,8.515,8.515,0,0,1-2.183-2.824,8.345,8.345,0,0,1-.8-3.682m5.694,0a4.214,4.214,0,0,0,1.2,3.121,5.328,5.328,0,0,0,6.72,0,4.673,4.673,0,0,0,0-6.243,5.328,5.328,0,0,0-6.72,0,4.217,4.217,0,0,0-1.2,3.121"
          transform="translate(-31.386 -13.373)"
        />
        <path
          id="Path_132"
          data-name="Path 132"
          d="M187.9,67.813h5.466v2.315h.076a4.288,4.288,0,0,1,.759-.982,5.474,5.474,0,0,1,1.177-.877,7.013,7.013,0,0,1,3.455-.877,7.689,7.689,0,0,1,3.435.737,4.821,4.821,0,0,1,2.259,2.315,5.9,5.9,0,0,1,2.391-2.35,7.683,7.683,0,0,1,3.455-.7,7.55,7.55,0,0,1,3.131.579,5.038,5.038,0,0,1,2.012,1.578,6.412,6.412,0,0,1,1.063,2.35,12.439,12.439,0,0,1,.323,2.894V84.858h-5.694V74.933a3.729,3.729,0,0,0-.551-2.052,2.141,2.141,0,0,0-1.955-.859,4.028,4.028,0,0,0-1.651.3,2.67,2.67,0,0,0-1.063.824,3.312,3.312,0,0,0-.57,1.228,6.205,6.205,0,0,0-.17,1.472v9.014h-5.694V75.845q0-.456-.038-1.122a4.181,4.181,0,0,0-.266-1.262,2.412,2.412,0,0,0-.74-1.017,2.314,2.314,0,0,0-1.5-.421,3.756,3.756,0,0,0-1.784.368,2.614,2.614,0,0,0-1.044.982,3.779,3.779,0,0,0-.475,1.386,11,11,0,0,0-.115,1.613v8.488H187.9Z"
          transform="translate(-37.287 -13.373)"
        />
        <path
          id="Path_133"
          data-name="Path 133"
          d="M240.455,82.72h-.076a5.274,5.274,0,0,1-2.524,1.964,9.5,9.5,0,0,1-5.807.263,6.462,6.462,0,0,1-2.107-1A4.93,4.93,0,0,1,228.5,82.3a4.718,4.718,0,0,1-.531-2.28,4.9,4.9,0,0,1,.588-2.49,5.005,5.005,0,0,1,1.594-1.683,8.2,8.2,0,0,1,2.3-1.035,17.871,17.871,0,0,1,2.676-.543,27.563,27.563,0,0,1,2.772-.211q1.384-.035,2.562-.035a2.653,2.653,0,0,0-1.082-2.227,4.107,4.107,0,0,0-2.562-.825,5.955,5.955,0,0,0-2.563.544A6.971,6.971,0,0,0,232.18,73l-3.036-2.876a10.655,10.655,0,0,1,3.72-2.051,14.28,14.28,0,0,1,4.4-.685,12.361,12.361,0,0,1,4.118.58,6.005,6.005,0,0,1,2.582,1.7,6.334,6.334,0,0,1,1.347,2.753,16.734,16.734,0,0,1,.38,3.771v8.663h-5.238Zm-1.4-5.331c-.431,0-.967.018-1.614.052a8.8,8.8,0,0,0-1.86.3,4.257,4.257,0,0,0-1.518.736,1.642,1.642,0,0,0-.627,1.368,1.489,1.489,0,0,0,.874,1.4,3.883,3.883,0,0,0,1.822.456,6.156,6.156,0,0,0,1.613-.211,5,5,0,0,0,1.386-.6,2.98,2.98,0,0,0,.967-.982,2.657,2.657,0,0,0,.361-1.4V77.388Z"
          transform="translate(-45.236 -13.373)"
        />
        <path
          id="Path_134"
          data-name="Path 134"
          d="M254.059,60.468a2.833,2.833,0,0,1,.968-2.157,3.5,3.5,0,0,1,4.669,0,2.888,2.888,0,0,1,0,4.314,3.494,3.494,0,0,1-4.669,0,2.833,2.833,0,0,1-.968-2.157m.456,5.366h5.694V82.88h-5.694Z"
          transform="translate(-50.414 -11.393)"
        />
        <path
          id="Path_135"
          data-name="Path 135"
          d="M266.845,67.813h5.466v2.315h.076a4.287,4.287,0,0,1,.759-.982,5.474,5.474,0,0,1,1.177-.877,7.012,7.012,0,0,1,3.455-.877,8.409,8.409,0,0,1,3.492.614,4.94,4.94,0,0,1,2.069,1.7,6.565,6.565,0,0,1,1.006,2.56,18.08,18.08,0,0,1,.265,3.192v9.4h-5.694V76.511q0-.736-.057-1.525a4.5,4.5,0,0,0-.341-1.456,2.537,2.537,0,0,0-.874-1.087,2.877,2.877,0,0,0-1.688-.421,3.756,3.756,0,0,0-1.784.368,2.614,2.614,0,0,0-1.044.982,3.779,3.779,0,0,0-.475,1.386,11.007,11.007,0,0,0-.115,1.613v8.488h-5.694Z"
          transform="translate(-52.951 -13.373)"
        />
        <path
          id="Path_136"
          data-name="Path 136"
          d="M304.192,72.829a4.168,4.168,0,0,0-3.3-1.438,3.308,3.308,0,0,0-1.4.316,1.105,1.105,0,0,0-.683,1.087.983.983,0,0,0,.7.93,9.8,9.8,0,0,0,1.784.526q1.082.228,2.315.491a9.148,9.148,0,0,1,2.316.824A5.051,5.051,0,0,1,307.7,77.09a4.29,4.29,0,0,1,.7,2.578,4.5,4.5,0,0,1-.778,2.718,5.792,5.792,0,0,1-1.993,1.719,9.038,9.038,0,0,1-2.732.912,17.535,17.535,0,0,1-3,.263,15.656,15.656,0,0,1-3.909-.508,7.446,7.446,0,0,1-3.34-1.807l3.454-3.542a5.624,5.624,0,0,0,1.765,1.385,5.08,5.08,0,0,0,2.259.474,5.673,5.673,0,0,0,1.784-.263.985.985,0,0,0,.8-.965,1.068,1.068,0,0,0-.7-1,8.142,8.142,0,0,0-1.784-.562q-1.082-.227-2.316-.491a10.055,10.055,0,0,1-2.315-.79,4.721,4.721,0,0,1-1.784-1.49,4.287,4.287,0,0,1-.7-2.578,4.886,4.886,0,0,1,.664-2.6,5.58,5.58,0,0,1,1.747-1.789,7.868,7.868,0,0,1,2.486-1.034,12.285,12.285,0,0,1,2.846-.333,14.394,14.394,0,0,1,3.682.491,6.962,6.962,0,0,1,3.15,1.754Z"
          transform="translate(-58.072 -13.373)"
        />
        <path id="Path_137" data-name="Path 137" d="M124.06,2.509h24.676V9.9H132.063v6.772h15.75v7.388h-15.75v7.387h17.6v7.387h-25.6Z" transform="translate(-24.618 -0.498)" />
        <path
          id="Path_138"
          data-name="Path 138"
          d="M164,2.509h12a34.288,34.288,0,0,1,8.285.975,18.972,18.972,0,0,1,6.849,3.155,15.463,15.463,0,0,1,4.643,5.669,19.015,19.015,0,0,1,1.719,8.465,17.275,17.275,0,0,1-1.667,7.772,16.714,16.714,0,0,1-4.489,5.643,19.842,19.842,0,0,1-6.516,3.463,25.288,25.288,0,0,1-7.746,1.18H164Zm8,28.934h4.155a22.344,22.344,0,0,0,5.156-.564,11.027,11.027,0,0,0,4.13-1.872,8.974,8.974,0,0,0,2.744-3.437,12.176,12.176,0,0,0,1-5.207,10.144,10.144,0,0,0-1-4.643,9.279,9.279,0,0,0-2.692-3.258,11.786,11.786,0,0,0-3.977-1.924,17.639,17.639,0,0,0-4.8-.642H172Z"
          transform="translate(-32.543 -0.498)"
        />
        <path
          id="Path_139"
          data-name="Path 139"
          d="M212.574,2.509h10.876L238.892,27.75h.1V2.509h8V38.831H236.532L220.679,12.975h-.1V38.831h-8Z"
          transform="translate(-42.182 -0.498)"
        />
        <path
          id="Path_140"
          data-name="Path 140"
          d="M282.016,11.156a5.705,5.705,0,0,0-2.642-1.821,9.486,9.486,0,0,0-3.156-.59,8.289,8.289,0,0,0-1.795.205,6.893,6.893,0,0,0-1.744.641,3.8,3.8,0,0,0-1.334,1.154,2.922,2.922,0,0,0-.514,1.744A2.89,2.89,0,0,0,272.063,15a11.653,11.653,0,0,0,3.1,1.488q1.872.616,4.027,1.231a16.623,16.623,0,0,1,4.027,1.744,9.547,9.547,0,0,1,3.1,3.028,9.224,9.224,0,0,1,1.231,5.079,11.681,11.681,0,0,1-1.129,5.284,10.718,10.718,0,0,1-3.052,3.745,12.975,12.975,0,0,1-4.463,2.206,20.471,20.471,0,0,1-11.928-.359,16.449,16.449,0,0,1-5.643-3.488l5.694-6.259a8.3,8.3,0,0,0,3.1,2.539,8.861,8.861,0,0,0,3.874.9,8.772,8.772,0,0,0,1.975-.231,6.44,6.44,0,0,0,1.8-.693,3.875,3.875,0,0,0,1.283-1.18,2.943,2.943,0,0,0,.487-1.693,3.072,3.072,0,0,0-1.258-2.591,11.621,11.621,0,0,0-3.154-1.617q-1.9-.665-4.1-1.334a18.391,18.391,0,0,1-4.1-1.795,9.9,9.9,0,0,1-3.155-2.976,8.514,8.514,0,0,1-1.257-4.874,10.911,10.911,0,0,1,1.154-5.13,11.149,11.149,0,0,1,3.078-3.694A13.4,13.4,0,0,1,271.191,2.1a18.187,18.187,0,0,1,5.181-.744,20.386,20.386,0,0,1,5.952.872A13.741,13.741,0,0,1,287.5,5.154Z"
          transform="translate(-51.859 -0.269)"
        />
        <path
          id="Path_141"
          data-name="Path 141"
          d="M306.288,45.2h-5.25a1.907,1.907,0,0,1-1.9-1.9v-5.25a1.906,1.906,0,0,1,1.9-1.9h5.25a1.906,1.906,0,0,1,1.9,1.9v5.25a1.907,1.907,0,0,1-1.9,1.9"
          transform="translate(-59.359 -7.172)"
        />
      </g>
    </svg>
  );
};

const LeftNav = (props: ILeftNav) => {
  const { loginWithRedirect, logout, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const {user,} = useContext(GlobalStateContext);
  const [sms, setSms] = useState(true);
  const [domain, setDomain] = useState(true);
  const regex = new RegExp(/^[a-zA-Z0-9\-_]{5,128}$/);
  const input = useRef<HTMLInputElement>(null);
  const [error, setError] = useState(false);
  const [i, setI] = useState('');
  const [t, setT] = useState<string>();
  const navigate = useNavigate();
  const [options, setOptions] = useState(0);
  const [show, setShow] = useState(false);
  const ham = useRef<HTMLDivElement>(null);
  const { search } = useLocation();
  if (input.current) {
    input.current.addEventListener('change', () => {
      if (input.current) setI(_.toLower(input.current.value.toLowerCase()));
    });
  }

  useEffect(()=>{
    const query = new URLSearchParams(window.location.pathname);
    let code:string|null = window.location.href
    try {
      code = code.substring(code.indexOf("?resellerCode=")+14, code.length)
      console.log({code})
    }catch (e){
      code = null
    }
    if(code != null && code.length ==8){
      props.setResellerCode(code)
      props.setDisplay(true)
    }
  },[])
  const setState = () => {
    setShow(!show);
  };

  return (
    <div data-show={props.nav} className="duration-0 leftNav-outer top-0 left-0 w-full shrink-0 overflow-hidden md:h-full md:w-auto">
      <div className="leftNav l-nav-bg navbar flex h-full justify-between overflow-y-auto px-6 py-4 pb-3 pr-0 md:py-12 md:pb-4">
        <div className={`flex flex-row justify-between md:flex-col`}>
          <div className="nav-inner fixed top-0 left-0 h-[60px] w-full bg-[#f5f7fc]"></div>
          <a className="z-[800] md:z-[0]" href="https://www.edns.domains/">
            <Logo options={options} setOption={setOptions} />
          </a>
          <div className="flex gap-6">
            <Link to="/cart" onClick={() => setShow(false)} className="z-[800] md:hidden">
              <Icon className="h-6 w-auto cursor-pointer text-gray-500" icon="prime:shopping-cart" />
            </Link>
            <div onClick={() => setShow(!show)} className="ham z-[8000] cursor-pointer">
              <Icon className="z-[800] h-6 w-auto cursor-pointer text-gray-500 md:hidden" icon="charm:menu-hamburger" />
            </div>
          </div>
          <div
            data-show={show}
            onClick={() => setShow(false)}
            className="leftNav-inner text-md flex h-full min-h-[350px] w-full flex-col border-b bg-white text-gray-600 md:relative md:mt-2 md:h-auto md:w-auto md:gap-2 md:border-b-0 md:bg-transparent"
          >
            {isAuthenticated && (
              <NavLink
                to={'/account' + search}
                className={({ isActive }) => `nav-option flex cursor-pointer items-center gap-8 rounded-xl duration-200 ${isActive ? 'bg-[rgba(84, 58, 183, 1)] on' : ''}`}
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-auto w-5" width="18" height="18" viewBox="0 0 18 18">
                  <path
                    id="Icon_material-dashboard"
                    data-name="Icon material-dashboard"
                    d="M4.5,14.5h8V4.5h-8Zm0,8h8v-6h-8Zm10,0h8v-10h-8Zm0-18v6h8v-6Z"
                    transform="translate(-4.5 -4.5)"
                  />
                </svg>
                <div className="relative">Dashboard</div>
              </NavLink>
            )}
            <NavLink to={'/' + search} className={({ isActive }) => `nav-option option-outer flex cursor-pointer items-center gap-8 md:mt-2 ${isActive && 'on'}`}>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-auto" width="25" height="25" viewBox="0 0 25 25">
                <path
                  id="search_icon"
                  data-name="search icon"
                  d="M1416.839,106.613l-5.664-5.664a10.624,10.624,0,1,1,1.768-1.768l5.665,5.665a1.25,1.25,0,0,1-1.768,1.767ZM1396.473,92.6a8.125,8.125,0,0,0,13.789,5.826q.036-.044.077-.084t.085-.077a8.125,8.125,0,1,0-13.951-5.664Z"
                  transform="translate(-1393.973 -81.979)"
                />
              </svg>

              <div className=" relative">Search Domains</div>
            </NavLink>
            <NavLink to={'/look-up' + search} className={({ isActive }) => `nav-option option-outer flex cursor-pointer items-center gap-8 md:mt-2 ${isActive && 'on'}`}>
              <Icon icon="fluent:globe-search-24-filled" width="20" height="20" inline={true} />

              <div className=" relative">Look-up</div>
            </NavLink>
            {isAuthenticated && (
              <div className="flex flex-col">
                {isAuthenticated && (
                  <NavLink
                    to={'/mydomain' + search}
                    className={({ isActive }) => `nav-option relative flex cursor-pointer items-center gap-8 rounded-xl duration-200 md:mt-2 ${isActive && 'on'}`}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-auto w-5" viewBox="0 0 18 19.877">
                      <path
                        id="Icon_simple-webpack"
                        data-name="Icon simple-webpack"
                        d="M20.946,17.892l-8.091,4.557V18.9L17.9,16.139l3.05,1.753Zm.553-.5V7.863l-2.961,1.7V15.69Zm-17.447.5,8.091,4.557V18.9L7.1,16.139l-3.05,1.753Zm-.553-.5V7.863l2.961,1.7V15.69L3.5,17.393ZM3.846,7.246l8.3-4.674V6L6.828,8.915l-.041.023L3.846,7.246Zm17.307,0-8.3-4.674V6l5.316,2.911.041.023,2.942-1.692ZM12.144,18.092,7.171,15.37v-5.4l4.974,2.859v5.258Zm.71,0,4.974-2.723v-5.4l-4.974,2.859v5.258ZM7.508,9.35,12.5,6.616,17.493,9.35,12.5,12.221Z"
                        transform="translate(-3.5 -2.572)"
                      />
                    </svg>
                    <div className="relative">My Domains</div>
                    <motion.div
                      className="btn-extend absolute right-5 top-5 hidden md:block"
                      onClick={() => setDomain(!domain)}
                      animate={domain ? { rotate: '0deg' } : { rotate: '-90deg' }}
                      transition={{ ease: 'easeOut' }}
                    >
                      <Icon className="arrow h-5 w-auto" icon="akar-icons:chevron-down" />
                    </motion.div>
                  </NavLink>
                )}
                <motion.div
                  className="opacity-1 flex flex-col overflow-hidden md:mt-2 md:gap-2"
                  initial={{ height: 'fit-content' }}
                  animate={domain ? { height: 'fit-content', opacity: 1 } : { height: '0px', opacity: 0 }}
                  transition={{ ease: 'easeOut', opacity: { delay: 0.1 } }}
                >
                  {isAuthenticated && (
                    <NavLink
                      to={'/transfersell' + search}
                      className={({ isActive }) =>
                        `nav-option nav-inner-option option-outer relative flex cursor-pointer items-center gap-8 duration-200 md:mt-2 ${isActive && 'on'}`
                      }
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-auto w-5" width="18" height="15.75" viewBox="0 0 18 15.75">
                        <path id="Icon_open-transfer" data-name="Icon open-transfer" d="M13.5,0V2.25H0V4.5H13.5V6.75L18,3.375Zm-9,9L0,12.375,4.5,15.75V13.5H18V11.25H4.5Z" />
                      </svg>
                      <div className="relative">Transfer / Sell</div>
                    </NavLink>
                  )}
                  {isAuthenticated && (
                    <NavLink
                      to={'/sms' + search}
                      className={({ isActive }) =>
                        `nav-option nav-inner-option relative flex cursor-pointer items-center gap-8 rounded-xl duration-200 md:mt-2 ${isActive && ' on'}`
                      }
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-auto w-5" width="18" height="18" viewBox="0 0 18 18">
                        <path
                          id="Icon_material-textsms"
                          data-name="Icon material-textsms"
                          d="M19.2,3H4.8A1.8,1.8,0,0,0,3.009,4.8L3,21l3.6-3.6H19.2A1.805,1.805,0,0,0,21,15.6V4.8A1.805,1.805,0,0,0,19.2,3ZM9.3,11.1H7.5V9.3H9.3Zm3.6,0H11.1V9.3h1.8Zm3.6,0H14.7V9.3h1.8Z"
                          transform="translate(-3 -3)"
                        />
                      </svg>

                      <div className="relative">Message</div>

                      {/* <motion.div
                    className="absolute top-5 right-5"
                    animate={sms ? { rotate: '0deg' } : { rotate: '-90deg' }}
                    transition={{ ease: 'easeOut' }}
                    onClick={() => setSms(!sms)}
                  >
                    <Icon className="h-5 w-auto" icon="akar-icons:chevron-down" />
                  </motion.div> */}
                    </NavLink>
                  )}
                  {/* <motion.div
                  className="opacity-1 flex h-[72px] flex-col gap-2 overflow-hidden"
                  animate={sms ? { height: '72px', opacity: 1 } : { height: '0px', opacity: 0 }}
                  transition={{ ease: 'easeOut', opacity: { delay: 0.1 } }}
                >
                  <NavLink to="/friends" className={({ isActive }) => `nav-option nav-inner-option-2 option-outer mt-2 flex cursor-pointer items-center gap-8 ${isActive && 'on'}`}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-auto w-5" width="18" height="12.6" viewBox="0 0 18 12.6">
                      <path
                        id="Icon_awesome-user-friends"
                        data-name="Icon awesome-user-friends"
                        d="M5.4,8.55A3.15,3.15,0,1,0,2.25,5.4,3.148,3.148,0,0,0,5.4,8.55Zm2.16.9H7.327a4.349,4.349,0,0,1-3.853,0H3.24A3.241,3.241,0,0,0,0,12.69v.81a1.35,1.35,0,0,0,1.35,1.35h8.1A1.35,1.35,0,0,0,10.8,13.5v-.81A3.241,3.241,0,0,0,7.56,9.45Zm5.94-.9a2.7,2.7,0,1,0-2.7-2.7A2.7,2.7,0,0,0,13.5,8.55Zm1.35.9h-.107a3.547,3.547,0,0,1-2.486,0H12.15a3.124,3.124,0,0,0-1.567.433A4.116,4.116,0,0,1,11.7,12.69v1.08c0,.062-.014.121-.017.18H16.65A1.35,1.35,0,0,0,18,12.6a3.148,3.148,0,0,0-3.15-3.15Z"
                        transform="translate(0 -2.25)"
                      />
                    </svg>

                    <div className=" relative">Friends </div>
                  </NavLink>
                </motion.div> */}
                </motion.div>
              </div>
            )}

            {isAuthenticated && (
              <NavLink to={'/order' + search} className={({ isActive }) => `nav-option option-outer flex cursor-pointer items-center gap-8 md:mt-2 ${isActive && 'on'}`}>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-auto w-5" width="18" height="14.824" viewBox="0 0 18 14.824">
                  <path
                    id="Icon_material-view-list"
                    data-name="Icon material-view-list"
                    d="M6,17.029h4.235V12.794H6Zm0,5.294h4.235V18.088H6ZM6,11.735h4.235V7.5H6Zm5.294,5.294H24V12.794H11.294Zm0,5.294H24V18.088H11.294Zm0-14.824v4.235H24V7.5Z"
                    transform="translate(-6 -7.5)"
                  />
                </svg>
                <div className="relative">Orders</div>
              </NavLink>
            )}
            {isAuthenticated && (
              <NavLink to={'/cart' + search} className={({ isActive }) => `nav-option option-outer flex cursor-pointer items-center gap-8 md:mt-2 md:hidden ${isActive && 'on'}`}>
                <Icon className="h-auto w-5" icon="eva:shopping-cart-fill" />
                <div className="relative">Cart</div>
              </NavLink>
            )}
            {isAuthenticated && (
              <NavLink to="/reseller" className={({ isActive }) => `nav-option option-outer flex cursor-pointer items-center gap-8 md:mt-2 ${isActive && 'on'}`}>
                <Icon className="h-[18px] w-auto" icon="fa6-solid:hand-holding-dollar" />
                <div className="relative">Join Reseller</div>
              </NavLink>
            )}
            {(isAuthenticated&&user?.reseller==null) && (
              <div onClick={() => props.setDisplay(true)} className={`nav-option option-outer flex cursor-pointer items-center gap-8 md:mt-2`}>
                <Icon className="h-[18px] w-auto" icon="icon-park-solid:connect" />
                <div className="relative">Reseller Binding</div>
              </div>
            )}
            {!isAuthenticated && (
              <button
                className={`nav-option option-outer flex cursor-pointer items-center gap-8 md:mt-2 `}
                onClick={() =>
                  loginWithRedirect({
                    redirectUri: window.location.origin + '/reseller?action=intro',
                    appState: {
                      returnTo: window.location.origin + '/reseller?action=intro',
                    },
                  })
                }
              >
                <Icon className="h-[18px] w-auto" icon="fa6-solid:hand-holding-dollar" />
                <div className="relative">Join Reseller</div>
              </button>
            )}
            {isAuthenticated && (
              <NavLink
                to={'/setting' + search}
                className={({ isActive }) => `nav-option option-outer flex cursor-pointer items-center gap-8 md:mt-2 md:hidden ${isActive && 'on'}`}
              >
                <Icon className="h-auto w-5" icon="icon-park-solid:setting" />
                <div className="relative">Setting</div>
              </NavLink>
            )}
            {isAuthenticated ? (
              <div className="relative flex flex-col justify-between gap-5 md:hidden">
                <Link
                  to=""
                  // ref={option}
                  onClick={() => {
                    window.localStorage.removeItem('cart-domains-count');
                    logout({ returnTo: window.location.origin });
                  }}
                  className="out-con flex cursor-pointer items-center gap-8 p-5 px-6 text-red-700"
                >
                  <Icon icon="material-symbols:logout-rounded" className="h-auto w-5 rotate-[180deg] " /> <span className="text-md relative">Sign out</span>
                </Link>
              </div>
            ) : (
              <div className="relative flex flex-col justify-between gap-5 md:hidden">
                <Link
                  to=""
                  // ref={option}
                  onClick={() =>
                    loginWithRedirect({
                      redirectUri: window.location.origin,
                      appState: {
                        returnTo: window.location.href,
                      },
                    })
                  }
                  className="out-con flex cursor-pointer items-center gap-8 p-5 px-6 pt-6 text-red-700"
                >
                  <Icon icon="material-symbols:logout-rounded" className="h-auto w-5 rotate-[180deg] " /> <span className="text-md relative">Sign in</span>
                </Link>
              </div>
            )}
          </div>
        </div>
        {isAuthenticated ? (
          <div className="relative hidden flex-col justify-between gap-5 md:flex">
            <Link
              to=""
              // ref={option}
              onClick={() => {
                window.localStorage.removeItem('cart-domains-count');
                logout({ returnTo: window.location.origin });
              }}
              className="out-con flex cursor-pointer items-center gap-6 p-5 pt-6 text-red-700"
            >
              <Icon icon="material-symbols:logout-rounded" className="h-auto w-5 rotate-[180deg] " /> <span className="text-md relative">Sign out</span>
            </Link>
          </div>
        ) : (
          <div className="relative hidden flex-col justify-between gap-5 md:flex">
            <Link
              to=""
              // ref={option}
              onClick={() =>
                loginWithRedirect({
                  redirectUri: window.location.origin,
                  appState: {
                    returnTo: window.location.href,
                  },
                })
              }
              className="out-con flex cursor-pointer items-center gap-6 p-5 pt-6 text-red-700"
            >
              <Icon icon="material-symbols:logout-rounded" className="h-auto w-5 rotate-[180deg] " /> <span className="text-md relative">Sign in</span>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default LeftNav;
