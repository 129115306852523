import axios from 'axios';
import config from '../config';

export enum CreateShoppingCartDomainType {
  PURCHASE = 'PURCHASE',
  RENEWAL = 'RENEWAL',
}
export async function createShoppingCartDomain(
  accessToken: string,
  type: CreateShoppingCartDomainType,
  tld: string,
  name: string,
  durations: number = 1,
  code?: string,
): Promise<void> {
    try {
        const url = new URL(`/cart/domain`, `${config.api.endpoint}`);
        if (code) {
            await axios.post(
                url.href,
                {
                    type,
                    name,
                    tld,
                    durations,
                    reseller: code,
                },
                {
                    headers: { Authorization: `Bearer ${accessToken}` },
                },
            );
        } else {
            await axios.post(
                url.href,
                {
                    type,
                    name,
                    tld,
                    durations,
                },
                {
                    headers: { Authorization: `Bearer ${accessToken}` },
                },
            );
        }
    }catch (error:any) {
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data.message)
            throw Error(error.response.data.message);
        } else {
            throw Error(error.message);
        }
    }
}
