import {ethers} from 'ethers';

// const provider_ = new ethers.providers.JsonRpcProvider(config.env === 'production' ? 'https://polygon-rpc.com/' : 'https://goerli.infura.io/v3/6ac23cd1c67249949ee6666e42d03278');
//
// const polygon:Provider  = new ethers.providers.JsonRpcProvider(config.env === 'production' ? 'https://polygon-rpc.com/' : 'https://goerli.infura.io/v3/6ac23cd1c67249949ee6666e42d03278');
//

const provider={
    5:new ethers.providers.JsonRpcProvider( 'https://goerli.infura.io/v3/6ac23cd1c67249949ee6666e42d03278'),
    137: new ethers.providers.JsonRpcProvider( 'https://polygon-rpc.com/'),
    4689: new ethers.providers.JsonRpcProvider( 'https://babel-api.mainnet.iotex.io'),
    4690: new ethers.providers.JsonRpcProvider( 'https://babel-api.testnet.iotex.io'),
    65 : new ethers.providers.JsonRpcProvider( 'https://exchaintestrpc.okex.org')
}


export default provider;
