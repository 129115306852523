import config from "../config";
import axios from "axios";

interface IReseller {
    code:string
    id:string
}
export async function getReseller(accessToken: string, resellerID:string): Promise<IReseller> {
    const url = new URL(`/reseller/profile/${resellerID}`, `${config.api.endpoint}`);
    const response = await axios.get(
        url.href,
        {
            headers: { Authorization: `Bearer ${accessToken}` },
        },
    );
    return response.data;
}
