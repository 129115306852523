import { IEthereumAddress } from './ethereum-address.interface';
import { ITld } from './tld.interface';

export enum DomainType {
  PROTECTED = 'PROTECTED',
  RESERVED = 'RESERVED',
  PUBLIC_AVAILABLE = 'PUBLIC_AVAILABLE',
}

export interface IDomain {
  id: string;
  type: DomainType;
  name: string;
  fqdn: string;
  tld: ITld;
  owner: IEthereumAddress;
  tokenId: string;
  durations: number;
  status: DomainStatus;
  price: number;
  createdAt: string;
  updatedAt: string;
  expiredAt: string;
  phoneNumber: string;
}

export enum DomainStatus {
  PENDING = 'PENDING',
  OWNED = 'OWNED',
  PURCHASE_PENDING_PAYMENT = 'PURCHASE_PENDING_PAYMENT',
  RENEWAL_PENDING_PAYMENT = 'RENEWAL_PENDING_PAYMENT',
  EXPIRED = 'EXPIRED',
  EXPIRED_BUT_IN_GRACE_PERIOD = 'EXPIRED_BUT_IN_GRACE_PERIOD',
  MINTING = 'MINTING',
  AVAILABLE = 'AVAILABLE',
}

export interface IDomainResult {
  id: number;
  name: string;
  type: DomainType;
  tld: ITld;
  durations: number;
  status: DomainStatus;
  owner: IEthereumAddress;
  price: number;
  createdAt: string;
  updatedAt: string;
}

export interface IListDomainResult {
  id: string;
  type: DomainType;
  status: DomainStatus;
  name: string;
  fqdn: string;
  tokenId: string;
  ownerId: number;
  price: null;
  registeredAt: string;
  expiredAt: string;
  createdAt: string;
  updatedAt: string;
}
export interface IGetOrderDomain {
  id: number;
  type: DomainType;
  name: string;
  fqdn: string;
  price: number;
  createdAt: string;
  updatedAt: string;
}

export interface IOrderListDomain {
  id: number;
  type: string;
  tld: ITld;
  name: string;
  fqdn: string;
  owner: null;
  durations: number;
  domain: IListDomainResult;
  price: number;
  createdAt: string;
  updatedAt: string;
}
