import Nav from '../../components/Nav/Nav';
import { motion } from 'framer-motion';
import { useContext, useEffect, useState } from 'react';
import { IBillingAddress } from '../../interface';
import { useAuth0 } from '@auth0/auth0-react';
import Select from 'react-select';
import i18nIsoCountries from 'i18n-iso-countries';
import _ from 'lodash';
import { resetPassword } from '../../api/reset-password';
import { updateBillingAddress } from '../../api/updated-billing-address';
import { Icon } from '@iconify/react';
import { GlobalStateContext } from '../../context/GlobalStateContext';

const Setting = () => {
  const { user, billingAddress: globalBillingAddress, accessToken, setBillingAddress: setGlobalBillingAddress, useWeb3Function } = useContext(GlobalStateContext);

  // const [user, setUser] = useState<IUser | undefined>(undefined);
  const [isShowingSentResetPasswordMessage, setIsShowingSentResetPasswordMessage] = useState<boolean>(false);
  // const [isBillingAddressHasBeenSetup, setIsBillingAddressHasBeenSetup] = useState<boolean | undefined>(undefined);
  const [isEditingBillingAddress, setIsEditingBillingAddress] = useState<boolean>(false);
  // const [accessToken, setAccessToken] = useState<string | undefined>(undefined);
  // const [isRetreivedAccessToken, setIsRetreivedAccessToken] = useState<boolean>(false);
  // const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [billingAddress, setBillingAddress] = useState<IBillingAddress | undefined>(globalBillingAddress);
  const [countryOptions, setCountryOptions] = useState<{ value: string; label: string }[]>([]);
  // const [defaultEthereumAddress, setDefaultEthereumAddress] = useState<IEthereumAddress | undefined>(undefined);
  // const [ethereumAddresses, setEthereumAddresses] = useState<IEthereumAddress[] | undefined>(undefined);
  // const [isEthereumAddressHasBeenSetup, setIsEthereumAddressHasBeenSetup] = useState<boolean | undefined>(undefined);
  const { user: auth0User } = useAuth0();

  const customStyles = {
    menuList: (base) => ({
      ...base,
      '::-webkit-scrollbar': {
        width: '4px',
      },
      height: '200px',
    }),
    control: (base) => ({
      ...base,
      height: '100%',
      border: '1px #d1d5db solid',
    }),
  };

  useEffect(() => {
    const _countryOptions = new Set<{ value: string; label: string }>();
    const names = i18nIsoCountries.getNames('en');
    for (const code in names) {
      _countryOptions.add({ label: names[code], value: code });
    }
    setCountryOptions([..._countryOptions]);
  }, []);

  useEffect(() => {
    setBillingAddress(globalBillingAddress);
  }, [globalBillingAddress]);

  const handleResetPasswordClick = async () => {
    if (user && !isShowingSentResetPasswordMessage) {
      await resetPassword(user);
      setIsShowingSentResetPasswordMessage(true);
      setTimeout(() => {
        setIsShowingSentResetPasswordMessage(false);
      }, 3000);
    }
  };

  const handleEditBillingAddressSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (accessToken && billingAddress) {
      const _billingAddress = _.clone(billingAddress);
      if (
        _billingAddress.addressLine1 &&
        _billingAddress.city &&
        _billingAddress.state &&
        _billingAddress.zip &&
        _billingAddress.country &&
        _billingAddress.firstname &&
        _billingAddress.lastname
      ) {
        // @ts-ignore
        const latestBillingAddress = await updateBillingAddress(accessToken, { ..._billingAddress });
        setGlobalBillingAddress(latestBillingAddress);
        setIsEditingBillingAddress(false);
      }
    }
  };

  return (
    <>
      <Nav title="General" />
      <div className="flex flex-1 flex-col overflow-y-auto p-10 px-6 pt-6 md:px-10">
        <div className="flex flex-1 flex-col gap-8 md:min-w-[550px]">
          <div className="flex flex-1 flex-col gap-5">
            <div className="flex flex-1 flex-col gap-5 md:max-h-[400px] md:flex-row">
              <div className="flex min-h-[400px] flex-1 flex-col justify-between rounded-xl border border-gray-300 p-10">
                <p className="text-lg font-medium md:text-xl">Account Setting</p>
                <div className="flex gap-5">
                  <div className="aspect-square h-24 rounded-full bg-slate-500">
                    <img className="aspect-square h-24 rounded-full bg-slate-500" src={auth0User?.picture} alt="" />
                  </div>
                  <div className="flex items-center">
                    <div className="flex flex-col">
                      <p className="text-lg">{user?.username}</p>
                      {/* <div className="mt-2 rounded-lg bg-gray-300 p-2 px-3 text-xs">change avatar</div> */}
                    </div>
                  </div>
                </div>
                <div className="w-full">
                  <p className="text-gray-500">Email</p>
                  <input className="mt-4 w-full max-w-[400px] rounded-xl border-2 p-4 px-6 outline-none md:w-[40%] md:min-w-[350px]" value={user?.email} disabled />
                </div>
                <div className="flex flex-row space-x-5">
                  <p
                    className="theme text w-fit cursor-pointer text-sm"
                    onClick={() => {
                      handleResetPasswordClick();
                    }}
                  >
                    Reset Password
                  </p>
                  {isShowingSentResetPasswordMessage ? <p className="theme translate-y-0 text-green-400">A reset email has sent to your email.</p> : <></>}
                </div>
              </div>
              {/* <div className="flex flex-1 flex-col rounded-xl border border-gray-300 p-10">
                <p className="text-lg font-medium md:text-xl">Two Factor Authentication</p>
                <p className="font-meidum mt-8 text-base">Enabling two-factor authentication helps to keep your domains safe.</p>
                <button className="theme bg mt-8 flex w-fit items-center justify-evenly gap-5 rounded-lg p-4 px-6 text-white">
                  <Icon icon="carbon:add" className="btn-svg w-auto" />
                  Add Two Factor Authentication
                </button>
              </div> */}
            </div>
            <form className="flex flex-1 flex-col gap-5 rounded-xl border border-gray-300 p-10 md:max-h-[500px]" action="" onSubmit={(e) => handleEditBillingAddressSubmit(e)}>
              <div className="flex items-center gap-6">
                <p className="text-lg font-medium md:text-xl">Billing Address</p>
                <button
                  className={`theme bg btn-save grid place-items-center rounded-[0.25rem] p-2 px-8 text-white duration-200 ${isEditingBillingAddress && 'show-btn'}`}
                  type="submit"
                >
                  <p className="text">Save</p>
                  <Icon className={`btn-save-icon absolute h-auto w-4 ${!isEditingBillingAddress && 'show-btn'}`} icon="charm:tick" />
                </button>
              </div>
              <div className="billing-con">
                <div className="flex flex-1 flex-col gap-4 md:pr-5">
                  <span className="text-[#809FB8]">First Name</span>
                  <div>
                    <motion.input
                      defaultValue={billingAddress?.firstname}
                      required
                      className="w-full rounded-md border border-gray-300 p-5 outline-blue-500"
                      placeholder="First Name"
                      value={billingAddress?.firstname}
                      onChange={(e) => {
                        if (!isEditingBillingAddress) {
                          setIsEditingBillingAddress(true);
                        }
                        if (billingAddress) {
                          const _billingAddress = _.clone(billingAddress);
                          _billingAddress.firstname = e.target.value;
                          setBillingAddress(_billingAddress);
                        }
                      }}
                    ></motion.input>
                  </div>
                </div>
                <div className="flex flex-1 flex-col gap-4 md:pr-5">
                  <span className="text-[#809FB8]">Last Name</span>
                  <div>
                    <input
                      defaultValue={billingAddress?.lastname}
                      required
                      className="w-full rounded-md border border-gray-300 p-5 outline-blue-500"
                      placeholder="Last Name"
                      value={billingAddress?.lastname}
                      onChange={(e) => {
                        if (!isEditingBillingAddress) {
                          setIsEditingBillingAddress(true);
                        }
                        if (billingAddress) {
                          const _billingAddress = _.clone(billingAddress);
                          _billingAddress.lastname = e.target.value;
                          setBillingAddress(_billingAddress);
                        }
                      }}
                    ></input>
                  </div>
                </div>
                <div className="flex flex-[1] flex-col gap-4 md:pr-5">
                  <span className="text-[#809FB8]">Country</span>
                  <div className="h-16">
                    <div className="h-full w-full rounded-md outline-blue-500">
                      <Select
                        options={countryOptions}
                        value={billingAddress?.country ? { label: i18nIsoCountries.getName(billingAddress.country, 'en'), value: billingAddress.country } : countryOptions[0]}
                        isSearchable={true}
                        name="color"
                        className="h-full"
                        styles={customStyles}
                        onChange={(_option) => {
                          if (!isEditingBillingAddress) {
                            setIsEditingBillingAddress(true);
                          }
                          if (billingAddress && _option) {
                            const _billingAddress = _.clone(billingAddress);
                            _billingAddress.country = _option.value;
                            setBillingAddress(_billingAddress);
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex flex-col gap-4">
                  <span className="text-[#809FB8]">Address Line 1</span>
                  <div>
                    <input
                      defaultValue={billingAddress?.addressLine1}
                      required
                      className="w-full rounded-md border border-gray-300 p-5 outline-blue-500"
                      placeholder="Address Line 1"
                      value={billingAddress?.addressLine1}
                      onChange={(e) => {
                        if (!isEditingBillingAddress) {
                          setIsEditingBillingAddress(true);
                        }
                        if (billingAddress) {
                          const _billingAddress = _.clone(billingAddress);
                          _billingAddress.addressLine1 = e.target.value;
                          setBillingAddress(_billingAddress);
                        }
                      }}
                    ></input>
                  </div>
                </div>
                <div className="flex flex-col gap-4 md:pr-5">
                  <span className="text-[#809FB8]">Address Line 2</span>
                  <div>
                    <input
                      defaultValue={billingAddress?.addressLine2}
                      className="w-full rounded-md border border-gray-300 p-5 outline-blue-500"
                      placeholder="Address Line 2"
                      value={billingAddress?.addressLine2}
                      onChange={(e) => {
                        if (!isEditingBillingAddress) {
                          setIsEditingBillingAddress(true);
                        }
                        if (billingAddress) {
                          const _billingAddress = _.clone(billingAddress);
                          _billingAddress.addressLine2 = e.target.value;
                          setBillingAddress(_billingAddress);
                        }
                      }}
                    ></input>
                  </div>
                </div>

                <div className="flex flex-[1] flex-col gap-4 md:pr-5">
                  <span className="text-[#809FB8]">City</span>
                  <div>
                    <input
                      defaultValue={billingAddress?.city}
                      required
                      className="w-full rounded-md border border-gray-300 p-5 outline-blue-500"
                      placeholder="City"
                      value={billingAddress?.city}
                      onChange={(e) => {
                        if (!isEditingBillingAddress) {
                          setIsEditingBillingAddress(true);
                        }
                        if (billingAddress) {
                          const _billingAddress = _.clone(billingAddress);
                          _billingAddress.city = e.target.value;
                          setBillingAddress(_billingAddress);
                        }
                      }}
                    ></input>
                  </div>
                </div>
                <div className="flex flex-[1] flex-col gap-4 md:pr-5">
                  <span className="text-[#809FB8]">State</span>
                  <div>
                    <input
                      defaultValue={billingAddress?.state}
                      required
                      className="w-full rounded-md border border-gray-300 p-5 outline-blue-500"
                      placeholder="State"
                      value={billingAddress?.state}
                      onChange={(e) => {
                        if (!isEditingBillingAddress) {
                          setIsEditingBillingAddress(true);
                        }
                        if (billingAddress) {
                          const _billingAddress = _.clone(billingAddress);
                          _billingAddress.state = e.target.value;
                          setBillingAddress(_billingAddress);
                        }
                      }}
                    ></input>
                  </div>
                </div>

                <div className="flex flex-[1] flex-col gap-4">
                  <span className="text-[#809FB8]">Zip</span>
                  <div>
                    <input
                      defaultValue={billingAddress?.zip}
                      required
                      className="w-full rounded-md border border-gray-300 p-5 outline-blue-500"
                      placeholder="Zip Code"
                      value={billingAddress?.zip}
                      onChange={(e) => {
                        if (!isEditingBillingAddress) {
                          setIsEditingBillingAddress(true);
                        }
                        if (billingAddress) {
                          const _billingAddress = _.clone(billingAddress);
                          _billingAddress.zip = e.target.value;
                          setBillingAddress(_billingAddress);
                        }
                      }}
                    ></input>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Setting;
