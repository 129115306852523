import React, {useContext, useEffect, useRef, useState} from 'react';
import NFooter from './NFooter';
import LeftNav from './Nav/LeftNav';
import {Outlet, useLocation, useNavigate} from 'react-router-dom';
import {GlobalStateContext} from '../context/GlobalStateContext';
import {createManganedWallet} from '../api/create-managed-wallet';
import {createEthereumAddress as _createEthereumAddress} from '../api/create-ethereum-address';
import {Icon} from '@iconify/react';
import ResellerBind from '../Pages/Reseller/ResellerBind';

interface IRight {
  title?: string;
}

const UIContainer = (props: IRight) => {
  const navigate = useNavigate();
  const {
    domains,
    accessToken,
    orders,
    defaultEthereumAddress,
    ethereumAddresses,
    ethereumAddressesLength,
    isEthereumAddressesFinishSetup,
    updateDefaultEthereumAddress,
    updateEthereumAddresses,
    updateOrders,
    updateUser,
    user,
    isManagedWallet,
    useWeb3Function,
  } = useContext(GlobalStateContext);
  const handleAddWalletClick = async () => {
    if (accessToken) {
      try {
        const result = await useWeb3Function[137].signMessage();
        if (result) {
          const { address, signature, message } = result;
          await createEthereumAddress(address, message, signature);
        }
      } catch (e) {
        console.error({ e });
        if (e instanceof Error) {
          if (e.message === 'No Binance Chain Wallet found') setCustomAddWalletErrorMessage(e.message);
        }
      }
    }
  };
  const createEthereumAddress = async (address: string, message: string, signature: string) => {
    if (accessToken) {
      const isEthereumAddressAlreadyExist = ethereumAddresses?.find((_address) => _address.address === address);
      if (!isEthereumAddressAlreadyExist) {
        await _createEthereumAddress(accessToken, address, message, signature);
        await Promise.all([updateDefaultEthereumAddress(), updateEthereumAddresses()]);
      } else {
        setIsDuplicatedBindingEthereumAddress(true);
      }
    }
  };
  const [isDuplicatedBindingEthereumAddress, setIsDuplicatedBindingEthereumAddress] = useState<boolean | undefined>(undefined);
  const [isSelect, setIsSelect] = useState<boolean>(false);
  const [customAddWalletErrorMessage, setCustomAddWalletErrorMessage] = useState<string | undefined>(undefined);
  const [read, setRead] = useState<boolean>(false);
  let location = useLocation();
  const [nav, setNav] = useState(false);
  // const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const { isAuthenticated } = useContext(GlobalStateContext);
  const [oldPath, setOldPath] = useState<String>('');
  const container = useRef<HTMLDivElement>(null);
  const [isUnderstand, setIsUnderstand] = useState<boolean>(false);
  const [done, setDone] = useState(false);
  const [display, setDisplay] = useState(false);
  const [resellerCode, setResellerCode] = useState("");
  useEffect(() => {
    if (location.pathname.split('/')[1] !== oldPath) {
      if (container.current?.classList.contains('shows')) {
        container.current.classList.remove('shows');
        setTimeout(() => {
          container.current?.classList.add('shows');
        }, 100);
      } else {
        container.current?.classList.add('shows');
      }
      setOldPath(location.pathname.split('/')[1]);
    } else {
      container.current?.classList.add('shows');
    }
  }, [location]);

  const handleCreateManagedWallet = async () => {
    if (accessToken) {
      await createManganedWallet(accessToken);
      await updateUser();
      // navigate(0);
    }
  };
  // useEffect(() => {
  //   console.log(ethereumAddressesLength);
  // });

  return (
    <>
      {isAuthenticated && (
        <>
          <div data-toggle={ethereumAddressesLength === 0} className="selectWallet-wrapper fixed left-0 top-0 z-[700] bg-black/70"></div>
          <div
            data-toggle={ethereumAddressesLength === 0}
            data-done={done}
            className="selectWallet-con z-[701] rounded-xl p-6"
            style={{ display: ethereumAddressesLength === 0 ? 'block' : 'none' }}
          >
            <div data-done={done} className="done-con flex h-full w-full flex-col items-center gap-8">
              <Icon icon="charm:circle-tick" className="h-auto w-[120px] text-green-500" />
              <div className="text-center">
                <h3 className="text-xl font-medium">All done !</h3>
                <h3 className="mt-3 text-lg font-medium text-gray-500">Welcome to EDNS</h3>
              </div>
            </div>
            <div data-done={done} className="wallet-con flex flex-col gap-5">
              <h2 className="text-xl font-medium">Select Wallet Type</h2>
              <div className="flex flex-1 flex-wrap gap-5">
                <button
                  onClick={() => {
                    handleAddWalletClick();
                    setRead(false);
                    setIsUnderstand(false);
                    setIsSelect(false);
                  }}
                  className="min-w-[250px] flex-1 rounded-lg border-2 border-[#93278f] p-3 py-5 font-medium text-[#93278f] duration-150 hover:bg-[#93278f] hover:text-white"
                >
                  Bind Ethereum Wallet
                </button>
                {!isManagedWallet ? (
                  <button
                    onClick={() => {
                      setIsSelect(true);
                      if (isUnderstand) {
                        handleCreateManagedWallet();
                        navigate('/account');
                      } else {
                        setRead(true);
                      }
                    }}
                    className={`min-w-[250px] flex-1 rounded-lg border-2 border-[#93278f] p-3 py-5 font-medium  duration-150 hover:bg-[#93278f] hover:text-white ${
                      isSelect ? `bg-[#93278f] text-white` : `bg-white text-[#93278f]`
                    }`}
                  >
                    Create Managed Wallet
                  </button>
                ) : (
                  <></>
                )}
                {!isManagedWallet && read && (
                  <div className="text-xs text-black">
                    Caution: The managed wallet is not intended to be used for transactions. If the user intends to sell their domain on any secondary market, the user must
                    transfer their domain to a crypto wallet. Any gas fee incurred in this transfer is to be borne by the user. <br />{' '}
                    <div className="font-center flex w-full flex-col gap-5 px-0 pt-5 text-center text-black">
                      <span className="flex items-center justify-center gap-3">
                        I understand:{' '}
                        <input
                          type="checkbox"
                          onChange={() => {
                            setIsUnderstand((prev) => !prev);
                          }}
                        />
                      </span>
                      <button
                        onClick={() => {
                          if (isUnderstand) {
                            handleCreateManagedWallet();
                            setDone(true);
                            setTimeout(() => {
                              navigate('/account');
                              navigate(0);
                            }, 2000);
                          }
                        }}
                        className="min-w-[250px] flex-1 cursor-pointer rounded-lg border-2 border-[#93278f] p-3 py-5 font-medium text-[#93278f] duration-150 hover:bg-[#93278f] hover:text-white"
                        disabled={!isUnderstand}
                      >
                        Comfirm Create
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}

      <div className="flex h-screen flex-col" data-theme="light">
        <div className="top-nav-con hidden shrink-0 justify-end gap-5 py-5 px-10 pb-0 text-xs text-gray-500 md:text-sm">
          <Icon onClick={() => setNav(true)} className="btn-option h-5 w-auto cursor-pointer" icon="charm:menu-hamburger" color="#93278f" />
        </div>
        <div className="flex flex-1 flex-col overflow-hidden md:flex-row">
          <ResellerBind display={display} setDisplay={setDisplay} code={resellerCode} />
          <LeftNav isAuthenticated={isAuthenticated} nav={nav} setNav={setNav} setDisplay={setDisplay} setResellerCode={setResellerCode} />
          <section className="test flex flex-1 flex-col overflow-y-hidden">
            <div className="right-con h-full w-full overflow-y-hidden md:mt-0">
              <div ref={container} className="relative flex h-full w-full flex-col overflow-hidden bg-white opacity-0 md:rounded-2xl">
                <Outlet />
              </div>
            </div>
            <NFooter setNav={setNav} nav={nav} />
          </section>
        </div>
      </div>
    </>
  );
};

export default UIContainer;
