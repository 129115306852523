import config from '../config';
import { PublicResolver__factory as PublicResolverFactory } from './typechain/factories/PublicResolver__factory';
import { PublicResolver } from './typechain';
import provider from './provider';

interface IPublicResolver {
  [chainID: number]: PublicResolver;
}

// const callPublicResolver:IPublicResolver = {
//     137:PublicResolverFactory.connect(config.contractAddress[137].publicResolver, provider[137]),
//     5:PublicResolverFactory.connect(config.contractAddress[5].publicResolver, provider[5]),
//     4689:PublicResolverFactory.connect(config.contractAddress[4689].publicResolver, provider[4689]),
//     4690:PublicResolverFactory.connect(config.contractAddress[4690].publicResolver,provider[4690])
// }
function callPublicResolver() {
  const objs = {};
  for (const [key, value] of Object.entries(config.contractAddress)) {
    objs[key] = PublicResolverFactory.connect(config.contractAddress[key].publicResolver, provider[key]);
  }
  return objs;
}
export default callPublicResolver;
