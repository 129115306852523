import {useContext, useEffect, useRef, useState} from 'react';
import '../../App.scss';
import {Icon} from '@iconify/react';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {motion} from 'framer-motion';
import {useAuth0} from '@auth0/auth0-react';
import Domain from '../cart/Domain';
import Select from 'react-select';
import _ from 'lodash';
import MessageNotification from '../SMS/MessageNotification';
import {GlobalStateContext} from '../../context/GlobalStateContext';
import {domainNameCount} from "../DomainFIlter/domainNameCount";


const display = {
  hide: { y: '-100%', opacity: 0 },
  show: { y: '0%', opacity: 1 },
};

const display1 = {
  hide: { y: '0%', opacity: 1, transition: { duration: 0 } },
  show: { y: '0%', opacity: 1, transition: { duration: 0 } },
};

interface IProps {
  reload?: boolean;
  setreload?(item: boolean): void;
  setCartCount?(item: boolean): void;
  cartCount?: boolean;
  home?: boolean;
  title?: string;
  showNav?: boolean;
  setShowNav?: any;
  setIsAddedDomainToShoppingCart?: any;
}

type OptionType = {
  value: any;
  label: any;
};

function Nav(props: IProps) {
  const { tlds, accessToken, user, shoppingCart, shoppingCartDomainsCount } = useContext(GlobalStateContext);

  const [width, setWidth] = useState<number>(window.outerWidth);
  const users = useRef<HTMLDivElement>(null);
  const option = useRef<HTMLAnchorElement>(null);
  const [showCart, setShowCart] = useState(false);
  const { logout } = useAuth0();
  const [noti, setNoti] = useState<boolean>(false);
  const cart_con = useRef<HTMLDivElement>(null);
  const cart_out = useRef<HTMLDivElement>(null);
  const input = useRef<HTMLInputElement>(null);
  const [error, setError] = useState(false);
  const regex = new RegExp(/^[a-z0-9\-_\u00a9\u00ae\u2000-\u3300\ud83c\ud000-\udfff\ud83d\ud000-\udfff\ud83e\ud000-\udfff]{5,128}$/);
  const [tldOptions, setTldOptions] = useState<OptionType[]>([]);
  const [selectedTldOption, setSelectedTldOption] = useState<OptionType | undefined>(undefined);
  const [i, setI] = useState('');
  const [site, setSite] = useState(false);

  useEffect(() => {
    const _tldOptions = tlds.map((_tld) => ({ value: _tld.name, label: `.${_tld.name}` }));
    setTldOptions(_tldOptions);
    const metaTld = tlds.find((_tld) => _tld.name === 'meta');
    if (metaTld) setSelectedTldOption({ value: metaTld.name, label: metaTld.name });
  }, [tlds]);

  const location = useLocation();
  useEffect(() => {
    if (input.current) {
      input.current.addEventListener('change', () => {
        if (input.current) setI(_.toLower(input.current.value.toLowerCase()));
        // console.log(i);
      });
    }
    if (location.search !== '' && !site) {
      setSite(true);
      setTimeout(() => {
        // console.log(window.location.href);
        const url = window.location.href;
        const _url = url.split(`${window.location.origin}`)[1];
        navigate(_url);
      }, 1000);
    }
  }, [input, location]);
  const customStyles = {
    menuList: (base) => ({
      ...base,
      '::-webkit-scrollbar': {
        width: '4px',
      },
    }),
    control: (base) => ({
      ...base,
      width: '100%',
      height: '100%',
      border: '0px',
      outline: 'none',
    }),
  };
  const handleChange = (options) => {
    setSelectedTldOption(options);
  };

  useEffect(() => {
    window.addEventListener('resize', () => {
      setWidth(window.outerWidth);
    });
  });

  const navigate = useNavigate();

  useEffect(() => {
    const root = document.querySelector(':root') as HTMLElement;
    if (shoppingCartDomainsCount) {
      root.style.setProperty('--shoppingCart-count', `${shoppingCartDomainsCount}`);
    }
  }, [shoppingCartDomainsCount]);

  useEffect(() => {
    const num = () => {
      const r = document.querySelector(':root') as HTMLElement;
      const con = document.querySelector('.shoppingCart-con') as HTMLElement;
      if (cart_con.current && cart_out.current && con) {
        // console.log(cart_out.current.clientHeight, (con.clientHeight + 20) * (parseInt(r.style.getPropertyValue('--shoppingCart-count')) + 1));
        while (cart_out.current.clientHeight < (con.clientHeight + 20) * Number(r.style.getPropertyValue('--shoppingCart-count'))) {
          const i = Number(r.style.getPropertyValue('--shoppingCart-count')) - 1;
          r.style.setProperty('--shoppingCart-count', `${i}`);
        }
        while (cart_out.current.clientHeight >= (con.clientHeight + 20) * (parseInt(r.style.getPropertyValue('--shoppingCart-count')) + 1)) {
          const i = Number(r.style.getPropertyValue('--shoppingCart-count')) + 1;
          r.style.setProperty('--shoppingCart-count', `${i}`);
        }
      }
    };

    num();

    window.addEventListener('resize', () => {
      num();
    });
  }, [shoppingCart]);

  window.addEventListener(
    'mousedown',
    (event) => {
      if (!((event.target as HTMLElement).closest('.noti-box') || (event.target as HTMLElement).closest('.btn-top-option')) && noti) {
        setNoti(false);
      }
    },
    false,
  );
  return (
    <div className={`left-0 top-0 z-[400] w-full items-center justify-center px-6 py-6 pb-0 duration-200 md:px-10`}>
      {/* Cart */}
      {/* <motion.div
        initial={{ visibility: 'hidden' }}
        animate={noti ? { visibility: 'visible', opacity: 1 } : { visibility: 'hidden', opacity: 0, transition: { visibility: { delay: 0.5 } } }}
        transition={{ ease: 'easeOut', duration: 0.25 }}
        className="fixed w-full h-full top-0 left-0 z-[80]"
        onClick={() => setNoti(false)}
      ></motion.div> */}
      <motion.div
        initial={{ visibility: 'hidden' }}
        animate={showCart || props.showNav ? { visibility: 'visible', opacity: 1 } : { visibility: 'hidden', opacity: 0, transition: { visibility: { delay: 0.5 } } }}
        className="fixed top-0 left-0 z-[400] h-full w-full bg-gray-500/20"
        onClick={() => {
          setShowCart(false);
          props.setShowNav(false);
        }}
      ></motion.div>
      <motion.div
        initial={{ x: '100%' }}
        animate={showCart || props.showNav ? { x: '0%' } : { x: '100%' }}
        transition={{ ease: 'easeOut' }}
        className="shadow-l-2xl fixed top-0 right-0 z-[400] flex h-screen w-[575px] flex-col gap-5 overflow-x-visible border-l bg-white p-10 text-black"
      >
        <Icon
          className="absolute top-10 right-8 h-8 w-auto cursor-pointer"
          onClick={() => {
            setShowCart(false);
            props.setShowNav(false);
          }}
          icon="gridicons:cross"
        />
        <div className="h-[30px] text-2xl font-medium">Cart</div>
        {shoppingCart ? (
          shoppingCart.domains.length !== 0 ? (
            <>
              <div ref={cart_out} className="shopping-cart-con relative flex flex-1 flex-col overflow-y-scroll">
                {/* <div data-shoppingCart="true" className="scroll-shadow"></div>
                <div data-shoppingCart="true" data-bottom="true" className="scroll-shadow"></div> */}
                <div ref={cart_con} className="shoppingCart-h relative">
                  {user &&
                    accessToken &&
                    shoppingCart &&
                    _.sortBy(shoppingCart.domains, 'createdAt').map((domain, i) => (
                      <Domain domain={domain} user={user} index={i} accessToken={accessToken} length={shoppingCart.domains.length - 1} />
                    ))}
                </div>
              </div>

              <div className="flex shrink-0 flex-col gap-2">
                <div className="mt-5 flex items-end justify-end gap-10 pb-2 text-2xl">
                  <span className="text-xl">Subtotal</span>
                  <span className="font-semibold">${shoppingCart.total}</span>
                </div>
                <Link
                  to={'/cart' + location.search}
                  // ref={button}
                  className={`btn-now mt-1 flex w-full items-center justify-center gap-2 rounded-lg py-5 text-xl font-semibold text-white opacity-100`}
                >
                  <motion.span animate={{ display: 'block' }}>Checkout</motion.span>
                </Link>
              </div>
            </>
          ) : (
            <div className="flex flex-1 flex-col items-center justify-center">
              <span className="text-xl">Your shopping cart is empty.</span>
            </div>
          )
        ) : (
          <div className="flex flex-1 flex-col items-center justify-center">
            <Icon icon="eos-icons:loading" className="h-16 w-auto text-gray-300" />
          </div>
        )}
      </motion.div>

      <nav className="relative flex w-full items-center justify-between">
        {!props.home && <div className="scroll-shadow"></div>}

        <div className="flex text-2xl font-medium">{props.title}</div>
        <div
          ref={users}
          className="opacity-1 z-50 hidden h-full w-full flex-row items-center justify-center gap-5 text-xl md:relative md:flex md:h-auto md:w-auto md:justify-between md:text-sm"
        >
          {props.home ? null : (
            <>
              <div className="relative h-full w-full">
                <input
                  ref={input}
                  onChange={(e) => {
                    setError(!regex.test(e.currentTarget.value));
                    setError(!(domainNameCount(e.currentTarget.value)>= 5))

                  }}
                  className="md:text-md relative h-12 w-full rounded-lg border px-12 pl-4 text-sm outline-blue-400"
                  type="text"
                  placeholder="Input domain name"
                ></input>
                <div className={`search-warn box-shadow absolute mt-3 w-full rounded-lg border bg-white p-10 py-8 text-xs text-red-500 ${error && 'visible'}`}>
                  <Icon className="mx-auto mb-5 h-auto w-12" icon="ep:warning-filled" />
                  <div className="ml-4 list-item">Sorry, only letters (a-z), numbers (0-9), underscore (_), and hyphen (-) are allowed.</div>
                  <div className="ml-4 mt-3 mb-2 list-item">Your domain must be between 5-128 characters long.</div>
                  {/* <li>Domain must be between 5 and 128 characters. </li> */}
                </div>
                <svg
                  className="absolute right-3 top-[13px] h-auto w-5 cursor-pointer fill-current text-3xl text-gray-300"
                  x="0px"
                  y="0px"
                  viewBox="0 0 56.966 56.966"
                  onClick={() => {
                    if (regex.test(i)) {
                      setError(false);
                      navigate(`/search/${i}.${selectedTldOption?.value}${location.search}`);
                      // props.setIsAddedDomainToShoppingCart(fqdn, false);
                    } else {
                      setError(true);
                    }
                  }}
                >
                  <path d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z" />
                </svg>
              </div>
              <div className="flex h-12 w-[250px] items-center rounded-md border bg-white text-base outline-none">
                {tldOptions && <Select options={tldOptions} value={selectedTldOption} styles={customStyles} onChange={handleChange} className="h-full w-full text-center"></Select>}
              </div>
            </>
          )}

          <div className="relative z-50 flex flex-col items-center md:block">
            {accessToken && (
              <>
                {/* <img className="mb-10 block w-28 rounded-full md:hidden" src={user?.picture} alt="gam"></img> */}
                <div className="flex items-center">
                  <div className="relative" onClick={() => setNoti(true)}>
                    <div className="msg-box absolute top-[45px] right-[-20px] h-[500px] w-[200px] items-end" data-show={noti}>
                      <div className="noti-box box-shadow border">
                        <div className="noti-title w-full px-6 text-left text-base">Lates Notifications</div>
                        <div className="flex h-full w-full flex-1 flex-col justify-between gap-3 py-2 px-4">
                          <MessageNotification />
                          <MessageNotification />
                          <MessageNotification />
                          <MessageNotification />
                        </div>
                      </div>
                    </div>
                    {/* <div className="btn-top-option relative grid aspect-square h-12 place-items-center rounded-lg border">
                      <svg className="relative h-5 w-auto cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="26.25" height="30" viewBox="0 0 26.25 30">
                        <path
                          id="Icon_awesome-bell"
                          data-name="Icon awesome-bell"
                          d="M13.125,30a3.749,3.749,0,0,0,3.748-3.75h-7.5A3.749,3.749,0,0,0,13.125,30Zm12.621-8.772c-1.132-1.216-3.25-3.046-3.25-9.04A9.253,9.253,0,0,0,15,3.1V1.875a1.874,1.874,0,1,0-3.748,0V3.1a9.253,9.253,0,0,0-7.5,9.091c0,5.994-2.118,7.824-3.25,9.04A1.831,1.831,0,0,0,0,22.5a1.877,1.877,0,0,0,1.881,1.875H24.369A1.876,1.876,0,0,0,26.25,22.5a1.83,1.83,0,0,0-.5-1.272Z"
                        />
                      </svg>
                      <div className="absolute tops-[13px] right-[11px] grid aspect-square h-2 place-items-center rounded-full bg-red-400 text-xs"></div>
                    </div> */}
                  </div>
                  <Link to="/setting" className="btn-top-option grid aspect-square h-12 place-items-center rounded-lg border">
                    <svg className="h-5 w-auto" xmlns="http://www.w3.org/2000/svg" width="29.992" height="30" viewBox="0 0 29.992 30">
                      <path
                        id="Icon_ionic-ios-settings"
                        data-name="Icon ionic-ios-settings"
                        d="M32.016,19.5a3.859,3.859,0,0,1,2.476-3.6,15.3,15.3,0,0,0-1.851-4.46,3.911,3.911,0,0,1-1.57.336,3.851,3.851,0,0,1-3.523-5.42A15.252,15.252,0,0,0,23.1,4.5a3.856,3.856,0,0,1-7.2,0,15.3,15.3,0,0,0-4.46,1.851,3.851,3.851,0,0,1-3.523,5.42,3.784,3.784,0,0,1-1.57-.336A15.636,15.636,0,0,0,4.5,15.9a3.858,3.858,0,0,1,.008,7.2,15.3,15.3,0,0,0,1.851,4.46,3.852,3.852,0,0,1,5.085,5.085A15.387,15.387,0,0,0,15.9,34.5a3.849,3.849,0,0,1,7.186,0,15.3,15.3,0,0,0,4.46-1.851,3.856,3.856,0,0,1,5.085-5.085,15.387,15.387,0,0,0,1.851-4.46A3.878,3.878,0,0,1,32.016,19.5Zm-12.45,6.241a6.248,6.248,0,1,1,6.248-6.248A6.247,6.247,0,0,1,19.566,25.737Z"
                        transform="translate(-4.5 -4.5)"
                      />
                    </svg>
                  </Link>
                </div>
                <div className="user-option absolute mt-[-1px] flex w-full flex-col">
                  <Link
                    to="/mydomain"
                    ref={option}
                    className="option option-in cursor-pointer whitespace-nowrap bg-white bg-transparent px-3 py-3 duration-300 ease-in-out hover:text-sky-600 lg:py-5"
                  >
                    My Domain
                  </Link>
                  <Link
                    to="/account"
                    ref={option}
                    className="option option-in cursor-pointer whitespace-nowrap bg-white bg-transparent px-3 py-3 duration-300 ease-in-out hover:text-sky-600 lg:py-5"
                  >
                    My Account
                  </Link>
                  {/* <Link
                    to=""
                    ref={option}
                    className="option px-3 py-3 whitespace-nowrap cursor-pointer bg-white hover:text-sky-600 ease-in-out duration-300 option-in bg-transparent"
                  >
                    My Storage
                  </Link> */}
                  <Link
                    to=""
                    ref={option}
                    onClick={() => {
                      window.localStorage.removeItem('shoppingCart-domains-count');
                      logout({ returnTo: window.location.origin });
                    }}
                    className="option option-in cursor-pointer whitespace-nowrap bg-white bg-transparent px-3 py-3 duration-300 ease-in-out hover:text-sky-600 lg:py-5"
                  >
                    Logout
                  </Link>
                </div>
              </>
            )}
          </div>
          {accessToken && (
            <>
              <div onClick={() => setShowCart(true)} className="btn-shoppingCart relative  cursor-pointer text-2xl duration-200 ease-in-out hover:text-blue-600">
                <div className="btn-top-option grid aspect-square h-12 place-items-center rounded-lg border">
                  <svg className="h-5 w-auto" xmlns="http://www.w3.org/2000/svg" width="33.75" height="30" viewBox="0 0 33.75 30">
                    <path
                      id="Icon_awesome-shopping-shoppingCart"
                      data-name="Icon awesome-shopping-shoppingCart"
                      d="M30.945,17.655l2.77-12.187A1.406,1.406,0,0,0,32.343,3.75H9.329L8.792,1.124A1.406,1.406,0,0,0,7.414,0H1.406A1.406,1.406,0,0,0,0,1.406v.938A1.406,1.406,0,0,0,1.406,3.75H5.5L9.617,23.873a3.282,3.282,0,1,0,3.928.5H25.829a3.28,3.28,0,1,0,3.726-.61l.323-1.422a1.406,1.406,0,0,0-1.371-1.718H12.78L12.4,18.75H29.573A1.406,1.406,0,0,0,30.945,17.655Z"
                    />
                  </svg>
                </div>

                <div className="absolute top-[6px] right-[2px] grid aspect-square h-5 place-items-center rounded-full text-xs">
                  {shoppingCartDomainsCount ? <div className="aspect-square h-2   rounded-full bg-red-400"></div> : null}
                </div>
              </div>
              <Link
                to="/account"
                className="btn-user ml-5 flex flex-row items-center gap-1 hover:text-sky-600 "
                // onClick={() => setUser(!user)}
              >
                {user?.username}
              </Link>
            </>
          )}
        </div>
      </nav>
    </div>
  );
}

export default Nav;
