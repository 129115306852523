import { usePreviousProps } from '@mui/utils';

interface Props {
  page: number;
  pages: number;
  setPage: (page: number) => void;
}

const OrderPages = (props: Props) => {
  return (
    <li
      className={`hidden w-[37px] cursor-pointer rounded-sm p-2 px-3 text-center text-white duration-200 hover:bg-[#a52ba1] md:block ${
        props.page === props.pages ? 'bg-[#a822a4]' : 'bg-[#93278f]'
      }`}
      data-num={props.pages}
      onClick={() => {
        props.setPage(props.pages * 15);
      }}
    >
      {props.pages + 1}
    </li>
  );
};

export default OrderPages;
