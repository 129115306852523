import { BigNumber, ethers } from 'ethers';
import WalletConnectProvider from '@walletconnect/web3-provider';
import CoinbaseWalletSDK from '@coinbase/wallet-sdk';
import * as luxon from 'luxon';
import config from '../../config';
import Web3Modal, { IProviderOptions } from 'web3modal';
import {
    BaseRegistrarImplementation__factory,
    BaseRegistrarImplementation__factory as BaseRegistrarImplementationFactory,
} from '../../contracts/typechain/factories/BaseRegistrarImplementation__factory';
import {
    PublicResolver__factory as PublicResolverFactory
} from '../../contracts/typechain/factories/PublicResolver__factory';
import axios from 'axios';
import { EDNSRegistrarController__factory, ReverseRegistrar__factory } from '../../contracts/typechain';
import EDNSRegistry from '../../contracts/EDNSRegistry';
import BaseRegistrarImplementation from '../../contracts/BaseRegistrarImplementation';
import PublicResolver from '../../contracts/PublicResolver';
import callPublicResolver from '../../contracts/PublicResolver';
import provider from '../../contracts/provider';

export const mainnetFunction_chainID = config.env === 'development'?5:137
export interface ISignedMessage {
  address: string;
  signature: string;
  message: string;
}

export interface SafeLow {
  maxPriorityFee: number;
  maxFee: number;
}

export interface Standard {
  maxPriorityFee: number;
  maxFee: number;
}

export interface Fast {
  maxPriorityFee: number;
  maxFee: number;
}

export interface GasStationResponse {
  safeLow: SafeLow;
  standard: Standard;
  fast: Fast;
  estimatedBaseFee: number;
  blockTime: number;
  blockNumber: number;
}
export interface ITransferDomainProps {
  tokenId: string;
  newOwner: string;
  provider?: ethers.providers.Web3Provider;
}
export interface ISetRecordProps {
  _ownerAddress: string;
  type: 'address' | 'text' | 'nft';
  nodehash: string;
  address?: {
    coinType: number;
    address: string;
  };
  text?: {
    type: string;
    text: string;
  };
}
export interface IcheckReclaim {
  domain: string;
  tokenID: string;
}

export interface IReClaim {
  domain: string;
  _ownerAddress: string;
  tokenID: string;
}

export interface ISetReverse {
  domain: string;
  _ownerAddress: string;
}

export interface IgetReverse {
  domain: string;
}

export interface ISetNFT {
  chainID: string
  _ownerAddress: string;
  tokenId: string;
  contractAddress: string;
  nodehash: string;
}

export interface IGetNFT {
  chainID: string
  nodehash: string;
}

class Web3Functions {


  CHAIN_ID:number

  RPC_ENDPOINT = {
    5: config.web3.blockchain.node[5],
    137: config.web3.blockchain.node[137],
    4689: config.web3.blockchain.node[4689],
    4690:config.web3.blockchain.node[4690],
    65:config.web3.blockchain.node[65],
  }
  GAS_LIMIT:number|undefined

  providerOptions: IProviderOptions
    NETWORK_PARAMS = {
        5: {
            chainId: '0x5', // 5
            rpcUrls: [config.web3.blockchain.node[5].endpoint],
            chainName: 'Goerli',
            nativeCurrency: {
                name: 'ETH',
                symbol: 'ETH',
                decimals: 18,
            },
        },
            137: {
                chainId: '0x89', // 137
                rpcUrls: [config.web3.blockchain.node[137].endpoint],
                chainName: 'Polygon Mainnet',
                nativeCurrency: {
                    name: 'MATIC',
                    symbol: 'MATIC',
                    decimals: 18,
                },
                blockExplorerUrls: ['https://polygonscan.com/'],
            },
        4689:{
            chainId: '0x1251', // 4689
            rpcUrls: [`https://babel-api.mainnet.iotex.io`],
            chainName: 'IoTeX Testnet',
            nativeCurrency: {
                name: 'IOTX',
                symbol: 'IOTX',
                decimals: 18,
            },
            blockExplorerUrls: ['https://iotexscan.io/'],
        },
        4690:{
            chainId: '0x1252', //4690
            rpcUrls: [`https://babel-api.testnet.iotex.io`],
            chainName: 'IoTeX Testnet',
            nativeCurrency: {
                name: 'IOTX',
                symbol: 'IOTX',
                decimals: 18,
            },
            blockExplorerUrls: ['https://testnet.iotexscan.io/'],
        },
        65:{
            chainId: '0x41', //65
            rpcUrls: [`https://exchaintestrpc.okex.org`],
            chainName: 'OKC Testnet',
            nativeCurrency: {
                name: 'OKT',
                symbol: 'OKT',
                decimals: 18,
            },
            blockExplorerUrls: ['https://www.oklink.com/okc-test/'],
        }

    }
  constructor(chainID:number) {
    this.CHAIN_ID = chainID
    this.GAS_LIMIT = chainID === 5 ? 400000 : undefined;

    this.providerOptions = {
      walletconnect: {
        package: WalletConnectProvider,
        options: {
          infuraId: config.web3.blockchain.infura.id,
          rpc: {
            [chainID]: this.RPC_ENDPOINT[chainID],
          },
        },
      },
      binancechainwallet: {
        package: true,
      },
      coinbasewallet: {
        package: CoinbaseWalletSDK,
        options: {
          appName: 'EDNS Domains',
          infuraId: config.web3.blockchain.infura.id,
        },
      },
    };
  }


  // config.env === 'development'
  //   ? {
  //       chainId: '0x5', // 5
  //       rpcUrls: [RPC_ENDPOINT],
  //       chainName: 'Goerli',
  //       nativeCurrency: {
  //         name: 'ETH',
  //         symbol: 'ETH',
  //         decimals: 18,
  //       },
  //       blockExplorerUrls: ['https://goerli.etherscan.io/'],
  //     }





   namehash = (name: string, tld: string): string => {
    const basenode = ethers.utils.namehash(tld);
    const labelhash = ethers.utils.solidityKeccak256(['string', 'bytes32'], [name, basenode]);
    const nodehash = ethers.utils.solidityKeccak256(['bytes32', 'bytes32'], [basenode, labelhash]);
    return nodehash;
  };



 async signMessage(): Promise<ISignedMessage> {
  const web3Modal = new Web3Modal({
    network: 'mainnet',
    providerOptions:this.providerOptions,
    disableInjectedProvider: false,
    cacheProvider: false
  });
  web3Modal.clearCachedProvider();
  localStorage.removeItem('walletconnect');
  const _provider = await web3Modal.connect();
  const provider = new ethers.providers.Web3Provider(_provider);
  console.debug('Getting signer...');
  const signer = provider.getSigner();
  console.debug('Assembling message...');
  const message = `Welcome to EDNS Domains.\n You are about to link your address ${await signer.getAddress()} to our system.\n${luxon.DateTime.now().toISO()}`;
  console.debug('Signing message...');
  const signature = await signer.signMessage(message);
  console.debug('Getting address..');
  const address = await signer.getAddress();
  localStorage.removeItem('walletconnect');
  console.debug({message, signature, address});
  return {message, signature, address};
}



 async init (provider_?: ethers.providers.Web3Provider){
  console.debug(`init...`);
  let _provider_: ethers.providers.Web3Provider | undefined = provider_;
  let _provider_save;
  if (!_provider_) {
    const web3Modal = new Web3Modal({providerOptions:this.providerOptions, disableInjectedProvider: false, cacheProvider: false});
    console.debug(`Web3Modal...`);
    web3Modal.clearCachedProvider();
    localStorage.removeItem('walletconnect');
    console.debug(`Connecting to Web3 Modal...`);
    const _provider = await web3Modal.connect();
    _provider_save = _provider
    console.debug(`Retrieving 'ethers' provider...`);
    _provider_ = new ethers.providers.Web3Provider(_provider);
  }
  console.debug(`Checking network...`);

  const network = await _provider_.getNetwork();
  let currentChainId = network.chainId;
  if (currentChainId !== this.CHAIN_ID) {
    try {
      try {
        console.debug(`Incorrect network`, `Current ID ${currentChainId}`, `This ID ${this.CHAIN_ID}`);
        await _provider_.send('wallet_switchEthereumChain', [{chainId: this.NETWORK_PARAMS[this.CHAIN_ID].chainId}]);
      } catch (error: any) {
          console.log(this.NETWORK_PARAMS[this.CHAIN_ID])
        if (error.code === 4902) {
          await _provider_.send('wallet_addEthereumChain', [this.NETWORK_PARAMS[this.CHAIN_ID]]);
        } else {
          await this.init();
        }
      }
      //
      currentChainId = 5;
    } catch (error) {
      console.error(error);
    }
  }
  const provider_afterChangeNetwork =  new ethers.providers.Web3Provider(_provider_save);

  const _network = await provider_afterChangeNetwork.getNetwork();
  currentChainId = _network.chainId;
  console.debug(`Returning 'ethers' provider...`);
  if (this.CHAIN_ID === 5) {
      provider_afterChangeNetwork.getFeeData = async () => {
      const gasPrice = await _provider_!.getGasPrice();
      return {
        maxFeePerGas: ethers.utils.parseUnits('1.5', 'gwei'),
        maxPriorityFeePerGas: ethers.utils.parseUnits('1.5', 'gwei'),
        gasPrice,
      };
    };
  } else if (this.CHAIN_ID === 137) {
      provider_afterChangeNetwork.getFeeData = async () => {
      const gasPrice = await _provider_!.getGasPrice();
      const response = await axios.get<GasStationResponse>('https://gasstation-mainnet.matic.network/v2');
      return {
        maxFeePerGas: ethers.utils.parseUnits(`${Math.ceil(response.data.fast.maxFee)}`, 'gwei'),
        maxPriorityFeePerGas: ethers.utils.parseUnits(`${Math.ceil(response.data.fast.maxPriorityFee)}`, 'gwei'),
        gasPrice,
      };
    };
  }
  return provider_afterChangeNetwork
};



async transferDomain(props: ITransferDomainProps): Promise<ethers.providers.Web3Provider | undefined>{
  const provider = props.provider || (await this.init());
  if ((await provider.getNetwork()).chainId === this.CHAIN_ID) {
    console.debug('Getting signer...');
    const signer = provider.getSigner();
    console.debug(`Connecting the the contract...`);
    const registrar = BaseRegistrarImplementationFactory.connect(config.contractAddress[this.CHAIN_ID].baseRegistrar, signer);
    console.debug(`Getting the 'owner' address of the token...`);
    const owner = await registrar.callStatic.ownerOf(props.tokenId);
    console.debug(`Start transfer...`);
    await registrar.transferFrom(owner, props.newOwner, props.tokenId);
    await new Promise<void>((resolve, reject) => {
      try {
        registrar.on(registrar.filters.Transfer(owner, props.newOwner, props.tokenId), () => resolve());
      } catch (error) {
        reject(error);
      }
    });
    await new Promise((resolve) => setTimeout(resolve, 60000));
    return provider;
  }
};



async setRecord (props: ISetRecordProps): Promise<string|null> {
  try {
  const provider = await this.init();
    let signer

      signer = provider.getSigner(props._ownerAddress);

  const resolver = PublicResolverFactory.connect(config.contractAddress[this.CHAIN_ID].publicResolver, signer);

  if (props.type === 'address' && props.address) {
    console.debug(`Start setting address...`);
    // await resolver['setAddr(bytes32,uint256,bytes)'](props.nodehash, props.address.coinType, ethers.utils.toUtf8Bytes(props.address.address), { gasLimit: GAS_LIMIT });
    console.log("cointype", props.address.coinType)
    console.log("props.nodehash", props.nodehash)
    console.log("props.address.address", props.address.address)
    await resolver['setAddr(bytes32,uint256,bytes)'](props.nodehash, props.address.coinType, (props.address.address), {gasLimit: this.GAS_LIMIT});
    await new Promise<void>((resolve, reject) => {
      try {
        resolver.on(resolver.filters['AddressChanged(bytes32,uint256,bytes)'](props.nodehash), () => resolve());
      } catch (error) {
        reject(error);
      }
    });
    console.debug('Successfully set address');
  } else if (props.type === 'text' && props.text) {
    console.debug(`Start setting Text...`);
    console.debug(props.nodehash, props.text.type, props.text.text);
    await resolver.setText(props.nodehash, props.text.type, props.text.text, {gasLimit: this.GAS_LIMIT});
    await new Promise<void>((resolve, reject) => {
      try {
        resolver.on(resolver.filters.TextChanged(props.nodehash), () => resolve());
      } catch (error) {
        reject(error);
      }
    });
  }
  }catch (e){
      const error:any = e

      if(error.code == 4100){
          return "Wrong Signer. Please Switch to Correct Signer before set Record."
      }else{
          return error.reason
      }
  }
  return null
};


 async checkReclaim (props: IcheckReclaim): Promise<boolean> {
  const [name, tld] = props.domain.split('.');
  const regisiryOwner = await EDNSRegistry()[this.CHAIN_ID].callStatic.owner(this.namehash(name, tld));
  const registrarOwner = await BaseRegistrarImplementation()[this.CHAIN_ID].ownerOf(props.tokenID);
  console.log({regisiryOwner});
  console.log({registrarOwner});
  if (regisiryOwner === registrarOwner) {
    return true;
  }
  return false;
};
 //
 // async getExpiryDate(tokenId:string):Promise<number>{
 //     const registrarOwner = await BaseRegistrarImplementation()[this.CHAIN_ID].nameExpires(tokenId);
 //     console.log(new Date((registrarOwner as BigNumber)._hex))
 //     return (registrarOwner as BigNumber).toNumber()
 // }


 async reclaim (props: IReClaim): Promise<ethers.Transaction>{
  const provider = await this.init();
  const signer = provider.getSigner(props._ownerAddress);
  const [name, tld] = props.domain.split('.');
  const Registrar = BaseRegistrarImplementation__factory.connect(config.contractAddress[this.CHAIN_ID].baseRegistrar, signer);
  const RegistrarController = EDNSRegistrarController__factory.connect(config.contractAddress[this.CHAIN_ID].registrarController, signer);
  const tokenId = BigNumber.from(props.tokenID);
  const basenode = await RegistrarController.tlds(ethers.utils.toUtf8Bytes(tld));
  const transaction = await Registrar.reclaim(tokenId, basenode, props._ownerAddress);

  // await new Promise<void>((resolve, reject) => {
  //   try {
  //     EDNSRegistry.on(EDNSRegistry.filters.NewOwner(namehash(name,tld)),() => resolve())
  //   }catch (error) {
  //     reject(error);
  //   }
  // });

  return transaction;
};
async setReverse(props: ISetReverse): Promise<ethers.Transaction> {
  const provider = await this.init();
  const signer = provider.getSigner(props._ownerAddress);
  const Registrar = ReverseRegistrar__factory.connect(config.contractAddress[this.CHAIN_ID].reverseResolver, signer);
  const transaction = await Registrar.setName(props.domain);
  await new Promise((resolve) => setTimeout(resolve, 18000));
  return transaction;
};

async getReverse(props: IgetReverse): Promise<string>{
  const Registrar = ReverseRegistrar__factory.connect(config.contractAddress[this.CHAIN_ID].reverseResolver, provider[this.CHAIN_ID]);
  const node = await Registrar.callStatic.node(props.domain);
  const domain = await PublicResolver()[this.CHAIN_ID].callStatic.name(node);
  return domain;
};

async setNFTRecordWeb3(props: ISetNFT): Promise<string|null>{
    try {
        const provider = await this.init();
        const signer = provider.getSigner(props._ownerAddress);
        const resolver = PublicResolverFactory.connect(config.contractAddress[this.CHAIN_ID].publicResolver, signer);
        await resolver.setNFT(props.nodehash, (props.chainID), props.contractAddress, props.tokenId)
    }catch (e){
        const error:any = e
        console.log({error})
        if(error.code == 4100){
            return "Wrong Signer. Please Switch to Correct Signer before set Record."
        }
    }
    return null

};

async getNFT (props: IGetNFT): Promise<[BigNumber, string, BigNumber] & { chainId: BigNumber; contractAddress: string; tokenId: BigNumber }> {
  const result = await callPublicResolver()[this.CHAIN_ID].callStatic.getNFT(props.nodehash, (props.chainID))
  return result
};
// export async function signMessageFromWalletConnect(): Promise<ISignedMessage> {
//   console.log('Creating WalletConnect provider...');
//   const _provider = new WalletConnectProvider({
//     infuraId: config.web3.blockchain.infura.id,
//     rpc: {
//       137: config.web3.blockchain.node.polygonMainnet.endpoint,
//     },
//   });
//   try {
//     console.log('Checking is WalletConnect have a existing connection ...');
//     if (_provider.connected) {
//       console.log('WalletConnect already has a connection, not terminating...');
//       await _provider.disconnect(); // Dissconnect anyway, prevent there is any connection still exist
//       console.log('Disconnected');
//     }
//     console.log('Connecting to WalletConnect again...');
//     await _provider.enable();
//     console.log('Creating Web3 provider from Ethers...');
//     const provider = new providers.Web3Provider(_provider);
//     console.log('Getting signer...');
//     const signer = provider.getSigner();
//     console.log('Assembling message...');
//     const message = `Welcome to EDNS Domains.\n You are about to link your address ${await signer.getAddress()} to our system.\n${luxon.DateTime.now().toISO()}`;
//     console.log('Signing message...');
//     const signature = await signer.signMessage(message);
//     console.log('Getting address..');
//     const address = await signer.getAddress();
//     console.log('Disconnect from WalletConnect');
//     await _provider.disconnect();
//     return { message, signature, address };
//   } catch (e) {
//     throw e;
//   } finally {
//     await _provider.disconnect();
//   }
// }

// export async function signMessageFromMetaMask(): Promise<ISignedMessage> {
//   if (!window.ethereum) throw new Error('No crypto wallet found. Please install it.');
//   // await window.ethereum.send('eth_requestAccounts');
//   const provider = new ethers.providers.Web3Provider(window.ethereum, {
//     name: config.env === DeploymentEnvironment.PRODUCTION ? 'Polygon Mainnet' : 'Ethereum Goerli',
//     chainId: config.env === DeploymentEnvironment.PRODUCTION ? 137 : 5,
//   });
//   const signer = provider.getSigner();
//   const message = `Welcome to EDNS Domains.\n You are about to link your address ${await signer.getAddress()} to our system.\n${luxon.DateTime.now().toISO()}`;
//   const signature = await signer.signMessage(message);
//   const address = await signer.getAddress();
//   return { message, signature, address };
// }

}
export interface IuseWeb3{
    [chainID:number]:Web3Functions
}
export const useWeb3:IuseWeb3 = {
    //Polygon
    137:new Web3Functions(137),

    //IoTeX Main
    4689:new Web3Functions(4689),
}

export const useWeb3_TestNet:IuseWeb3 = {
    137:new Web3Functions(137),
    //Goerli
    5: new Web3Functions(5),
    //IoTeX Test
    4690: new Web3Functions(4690),
    //OKC Test
    65: new Web3Functions(65),
}
