import axios from 'axios';
import config from '../config';
import { IShoppingCart } from '../interface';

export async function applyPromotionalCode(accessToken: string, code: string): Promise<IShoppingCart> {
  const url = new URL(`/cart/code`, `${config.api.endpoint}`);
  const response = await axios.post<IShoppingCart>(
    url.href,
    {
      code,
    },
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    },
  );
  return response.data;
}
