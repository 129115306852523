export enum DeploymentEnvironment {
  DEVELOPMENT = 'development',
  STAGING = 'staging',
  PRODUCTION = 'production',
}

export interface IConfig {
  env: string;
  node_endpoint: string;
  auth0_domain: string;
  auth0_client_id: string;
  auth0_default_domain: string;
  auth0: {
    defaultDomain: string;
    domain: string;
    clientId: string;
    connection: string;
  };
  api: {
    endpoint: string;
  };
  contractAddress: {
    [chainid: number]: {
      registry: string;
      publicResolver: string;
      baseRegistrar: string;
      registrarController: string;
      reverseResolver: string;
      affiliateProgram: string;
    };
  };
  sentry: {
    dsn: string;
  };
  web3: {
    portis: {
      id: string;
    };
    blockchain: {
      infura: {
        id: string;
      };
      node: {
        [chainid: number]: {
          endpoint: string;
        };
      };
    };
  };
}

const config: IConfig = {
  env: process.env.REACT_APP_ENVIRONMENT || 'development',
  node_endpoint: process.env.REACT_APP_API_ENDPOINT || '',
  auth0_domain: process.env.REACT_APP_AUTH0_DOMAIN || '',
  auth0_default_domain: process.env.REACT_APP_AUTH0_DEFAULT_DOMAIN || '',
  auth0_client_id: process.env.REACT_APP_AUTH0_CLIENT_ID || '',
  api: {
    endpoint: process.env.REACT_APP_API_ENDPOINT || 'http://localhost:3001',
  },
  sentry: {
    dsn: process.env.REACT_APP_SENTRY_DSN || '',
  },
  auth0: {
    defaultDomain: process.env.REACT_APP_AUTH0_DEFAULT_DOMAIN || 'UNKNOWN_',
    domain: process.env.REACT_APP_AUTH0_DOMAIN || 'UNKNOWN_',
    clientId: process.env.REACT_APP_AUTH0_CLIENT_ID || 'UNKNOWN_',
    connection: process.env.REACT_APP_AUTH0_CONNECTION || 'UNKNOWN__',
  },
  contractAddress: {
    5: {
      registry: '0x467cfd51c227b334D8c71d843BCE54b235092a66',
      publicResolver: '0x87EEBE3c2bEDE909A9825977df5E852Df3314BcF',
      baseRegistrar: '0xafFDDAd389bEe8a2AcBa0367dFAE5609B93c7F9b',
      registrarController: '0xb977101Fba674a61c2a999CA36438FCB28E69e3b',
      reverseResolver: '0x5716EBAe036AE2c3652902dd89EeD1c73c74384D',
      affiliateProgram: process.env.REACT_APP_AFFILIATE_PROGRAM_CONTRACT_ADDRESS || 'UNKNOWN',
    },
    137: {
      registry: '0x7c5DbFE487D01BC0C75704dBfD334198E6AB2D12',
      publicResolver: '0x3c2DAab0AF88B0c5505ccB585e04FB33d7C80144',
      baseRegistrar: '0x53a0018f919bde9C254bda697966C5f448ffDDcB',
      registrarController: '0x8C856f71d71e8CF4AD9A44cDC426b09e315c6A6a',
      reverseResolver: '0xD986F9083F006D0E2d08c9F22247b4a0a213146D',
      affiliateProgram: process.env.REACT_APP_AFFILIATE_PROGRAM_CONTRACT_ADDRESS || 'UNKNOWN',
    },
    4690: {
      registry: '0xa1eD26c554fCa24DABdfda8c690785cd4DE50b7c',
      publicResolver: '0x0bd951d7B58ea94dB153cEF60336012FB9d466eA',
      baseRegistrar: '0x0d33ECCcc3629B33a9CeE62108Ef39deD736d4E0',
      registrarController: '0x4ECAafcc6Aa082F14C98e2bC7A37a35Dc30B13C5',
      reverseResolver: '0xCDEbE246529e6c5f549b08dC8DC9a720A34C3149',
      affiliateProgram: 'UNKNOWN',
    },
    4689: {
      registry: '0x6CfcCD07f5461755E1a02E00CDaEEdf2bC2A5Eed',
      publicResolver: '0xA70206dC65a63a9A5DdF8F8e5e2B83Ccd6709dFA',
      baseRegistrar: '0x2A84bF602cD8CF7637d8F247Dd5Dd0ed10525EBA',
      registrarController: '0xFE15B81Ee783CB592f3DbE4CDF66ECfa12A8847B',
      reverseResolver: '0xaE2e725957f7EA2a5A5D7f73301ed5c3B23B06AA',
      affiliateProgram: process.env.REACT_APP_AFFILIATE_PROGRAM_CONTRACT_ADDRESS || 'UNKNOWN',
    },
    65: {
      registry: '0x0d33ECCcc3629B33a9CeE62108Ef39deD736d4E0',
      publicResolver: '0x4ECAafcc6Aa082F14C98e2bC7A37a35Dc30B13C5',
      baseRegistrar: '0xCDEbE246529e6c5f549b08dC8DC9a720A34C3149',
      registrarController: '0xb3BF41C4B2A53D34296F7F237C4CcE145631d96D',
      reverseResolver: '0xBF962734Abb798807a2875595cefE86FDF6726cc',
      affiliateProgram: process.env.REACT_APP_AFFILIATE_PROGRAM_CONTRACT_ADDRESS || 'UNKNOWN',
    },
  },
  web3: {
    portis: {
      id: process.env.REACT_APP_PORTIS_ID || 'UNKNOWN',
    },
    blockchain: {
      infura: {
        id: process.env.REACT_APP_INFURA_ID || '',
      },
      node: {
        5: {
          endpoint: process.env.REACT_APP_ETHEREUM_GOERLI_NODE_ENDPOINT || '',
        },
        137: {
          endpoint: process.env.REACT_APP_POLYGON_MAINNET_NODE_ENDPOINT || '',
        },
        4690: {
          endpoint: process.env.REACT_APP_IOTEX_TEST_ENDPOINT || 'https://babel-api.testnet.iotex.io',
        },
        4689: {
          endpoint: process.env.REACT_APP_IOTEX_ENDPOINT || 'https://babel-api.mainnet.iotex.io',
        },
        65: {
          endpoint: process.env.REACT_APP_OKC_TEST_ENDPOINT || 'https://exchaintestrpc.okex.org',
        },
      },
    },
  },
};
export default config;
