import React, { useState } from 'react';
import { Icon } from '@iconify/react';
interface IMessageBox {
  message?: boolean;
  setMessage: any;
  sender: string;
  setSelectedSender: any;
  last?: boolean;
  laterMsg?: string;
  latestDate?: string;
}

function time_ago(time) {
  switch (typeof time) {
    case 'number':
      break;
    case 'string':
      time = +new Date(time);
      break;
    case 'object':
      if (time.constructor === Date) time = time.getTime();
      break;
    default:
      time = +new Date();
  }
  var time_formats = [
    [60, 'seconds', 1], // 60
    [120, '1 minute ago', '1 minute from now'], // 60*2
    [3600, 'minutes', 60], // 60*60, 60
    [7200, '1 hour ago', '1 hour from now'], // 60*60*2
    [86400, 'hours', 3600], // 60*60*24, 60*60
    [172800, 'Yesterday', 'Tomorrow'], // 60*60*24*2
    [604800, 'days', 86400], // 60*60*24*7, 60*60*24
    [1209600, 'Last week', 'Next week'], // 60*60*24*7*4*2
    [2419200, 'weeks', 604800], // 60*60*24*7*4, 60*60*24*7
    [4838400, 'Last month', 'Next month'], // 60*60*24*7*4*2
    [29030400, 'months', 2419200], // 60*60*24*7*4*12, 60*60*24*7*4
    [58060800, 'Last year', 'Next year'], // 60*60*24*7*4*12*2
    [2903040000, 'years', 29030400], // 60*60*24*7*4*12*100, 60*60*24*7*4*12
    [5806080000, 'Last century', 'Next century'], // 60*60*24*7*4*12*100*2
    [58060800000, 'centuries', 2903040000], // 60*60*24*7*4*12*100*20, 60*60*24*7*4*12*100
  ];
  var seconds = (+new Date() - time) / 1000,
    token = 'ago',
    list_choice = 1;

  if (seconds == 0) {
    return 'Just now';
  }
  if (seconds < 0) {
    seconds = Math.abs(seconds);
    token = 'from now';
    list_choice = 2;
  }
  var i = 0,
    format;
  while ((format = time_formats[i++]))
    if (seconds < format[0]) {
      if (typeof format[2] == 'string') return format[list_choice];
      else return Math.floor(seconds / format[2]) + ' ' + format[1] + ' ' + token;
    }
  return time;
}

const MessageBox = (props: IMessageBox) => {
  const [star, setStar] = useState<boolean>(false);

  return (
    <div
      className={`flex cursor-pointer ${!props.last && 'border-b'} p-5 pb-7 hover:bg-gray-100 active:bg-gray-100`}
      onClick={() => {
        props.setMessage(true);
        props.setSelectedSender(props.sender);
      }}
    >
      <div className="shrink-0 pr-5">
        <div className="relative aspect-square h-10 overflow-hidden rounded-full">
          <Icon icon="cryptocurrency:matic" className="h-auto w-full text-5xl text-purple-500" />
        </div>
      </div>
      <div className="flex-[1] overflow-hidden">
        <div className="mt-1 flex items-center justify-between">
          <p className="text-md ">{props.sender}</p>{' '}
          {/* 
          <p className="text-sm text-gray-500">
            {star ? (
              <svg onClick={() => setStar(false)} xmlns="http://www.w3.org/2000/svg" width="20.998" height="19.996" viewBox="0 0 20.998 19.996">
                <path
                  id="star_on"
                  d="M903.5,680a1.379,1.379,0,0,0-1.23.76l-2.5,5.042c-1.015.147-3.9.562-5.593.807a1.363,1.363,0,0,0-.76,2.328l4.047,3.923c-.173,1-.667,3.865-.955,5.539a1.365,1.365,0,0,0,.545,1.336h0a1.38,1.38,0,0,0,1.444.1l5-2.614,5,2.614a1.38,1.38,0,0,0,1.444-.1h0a1.365,1.365,0,0,0,.545-1.334l-.955-5.541,4.047-3.923a1.365,1.365,0,0,0,.347-1.4v0a1.37,1.37,0,0,0-1.107-.928c-1.69-.245-4.578-.661-5.593-.807-.453-.914-1.747-3.519-2.5-5.042a1.379,1.379,0,0,0-1.23-.76Zm-3.665,5.791v0h0Zm7.33,0,0,0h0Z"
                  transform="translate(-893.001 -680.001)"
                  fill="#93278f"
                />
              </svg>
            ) : (
              <svg onClick={() => setStar(true)} xmlns="http://www.w3.org/2000/svg" width="20.998" height="19.996" viewBox="0 0 20.998 19.996">
                <path
                  id="star"
                  d="M903.5,291a1.379,1.379,0,0,0-1.23.76l-2.5,5.042-5.593.807a1.363,1.363,0,0,0-.76,2.328l4.047,3.923c-.173,1-.667,3.865-.955,5.539a1.365,1.365,0,0,0,.545,1.336h0a1.38,1.38,0,0,0,1.444.1l5-2.614,5,2.614a1.38,1.38,0,0,0,1.444-.1h0a1.365,1.365,0,0,0,.545-1.335l-.955-5.54,4.047-3.923a1.365,1.365,0,0,0,.347-1.4h0a1.37,1.37,0,0,0-1.107-.928l-5.593-.807c-.453-.914-1.747-3.519-2.5-5.042a1.379,1.379,0,0,0-1.23-.76Zm0,1.651,2.417,4.87a1.375,1.375,0,0,0,1.033.747l5.4.781-3.908,3.791a1.362,1.362,0,0,0-.4,1.208c.16.927.627,3.633.923,5.351l-4.833-2.526a1.387,1.387,0,0,0-1.277,0l-4.833,2.526c.3-1.718.763-4.424.923-5.351a1.362,1.362,0,0,0-.4-1.208l-3.908-3.791,5.4-.781a1.374,1.374,0,0,0,1.033-.747l2.417-4.87Zm-3.665,4.14v0h0Zm7.33,0,0,0h0Z"
                  transform="translate(-893.001 -291.001)"
                  fill="#93278f"
                />
              </svg>
            )}
          </p> */}
        </div>
        <div className="mt-2 flex items-center justify-between gap-2">
          <p className="truncate text-xs font-light">{props.laterMsg === '' ? <div className="h-[16px]"></div> : props.laterMsg}</p>{' '}
          {/* <p className="text-sm rounded-full bg-blue-900 aspect-square h-5 grid place-items-center text-white">1</p> */}
        </div>
        <div className="mt-4 flex w-fit items-center gap-2 rounded-lg bg-[#D6E0E8] p-3 py-2">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-auto" width="17" height="16.999" viewBox="0 0 17 16.999">
            <path
              id="clock"
              d="M484,402.5a8.5,8.5,0,1,1,8.5,8.5A8.5,8.5,0,0,1,484,402.5Zm1.545,0a6.954,6.954,0,1,0,6.954-6.954A6.954,6.954,0,0,0,485.546,402.5Zm8.726,2.865-2.318-2.318a.773.773,0,0,1-.227-.546v-4.636a.773.773,0,1,1,1.546,0v4.315l2.092,2.092a.773.773,0,0,1-1.093,1.093Z"
              transform="translate(-484 -394)"
              fill="#809fb8"
            />
          </svg>
          <div className="text-xs">{time_ago(props.latestDate)}</div>
        </div>
      </div>
    </div>
  );
};

export default MessageBox;
