import Nav from '../../../components/Nav/Nav';
import Footer from '../../../components/Footer';
import bg from '../../../static/bg.webp';
import { motion } from 'framer-motion';

function Contact() {
  return (
    <>
      <Nav />
      <section className="relative min-h-[250px] bg-cover bg-no-repeat lg:min-h-[300px]" style={{ backgroundImage: `url(${bg})` }}>
        <div className="absolute bottom-8 h-auto w-full text-center text-5xl text-white md:font-bold lg:text-7xl">
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ ease: 'easeOut', delay: 0.1, duration: 0.6 }}
            viewport={{ once: true }}
            className="mx-auto px-8 lg:max-w-7xl lg:px-14"
          >
            Contact us
          </motion.div>
        </div>
      </section>
      <section className="open-san contact mx-auto flex flex-col items-center justify-center px-12 py-16 text-center text-7xl font-bold md:py-20 lg:max-w-xl lg:px-14">
        <form className="w-full text-base font-normal">
          <div className="flex w-full flex-col gap-5 md:flex-row">
            <div className="flex flex-1 flex-col text-left">
              <span>First Name</span>
              <input className="input-contact mt-2 w-full rounded-lg border px-3 py-2"></input>
            </div>
            <div className="flex flex-1 flex-col text-left">
              <span>Last name</span>
              <input className="input-contact mt-2 w-full rounded-lg border px-3 py-2"></input>
            </div>
          </div>
          <div className="mt-5 flex w-full flex-col text-left">
            <span>Email</span>
            <input className="input-contact mt-2 w-full rounded-lg border px-3 py-2"></input>
          </div>
          <div className="mt-5 flex w-full flex-col text-left">
            <span>Message</span>
            <textarea className="input-contact mt-2 h-24 w-full resize-none rounded-lg border px-3 py-2"></textarea>
          </div>
          <button className="btn-con btn-contact mt-12 w-auto rounded-full border-2 px-20 py-4 text-lg font-bold text-white duration-100 ease-linear hover:bg-blue-500 hover:text-white">
            Send
          </button>
        </form>
      </section>
      <Footer />
    </>
  );
}

export default Contact;
