/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import {Contract, ContractFactory, Overrides, Signer, utils} from "ethers";
import {Provider, TransactionRequest} from "@ethersproject/providers";
import type {SHA1, SHA1Interface} from "../SHA1";

const _abi = [
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "bytes32",
        name: "x",
        type: "bytes32",
      },
    ],
    name: "Debug",
    type: "event",
  },
];

const _bytecode =
  "0x60566050600b82828239805160001a6073146043577f4e487b7100000000000000000000000000000000000000000000000000000000600052600060045260246000fd5b30600052607381538281f3fe73000000000000000000000000000000000000000030146080604052600080fdfea2646970667358221220b4ebb91805b2a644ae49f20ea34f3aeaebd5fa4ab881adabdd1092be38d512d964736f6c634300080a0033";

export class SHA1__factory extends ContractFactory {
  constructor(
    ...args: [signer: Signer] | ConstructorParameters<typeof ContractFactory>
  ) {
    if (args.length === 1) {
      super(_abi, _bytecode, args[0]);
    } else {
      super(...args);
    }
  }

  deploy(
    overrides?: Overrides & { from?: string | Promise<string> }
  ): Promise<SHA1> {
    return super.deploy(overrides || {}) as Promise<SHA1>;
  }
  getDeployTransaction(
    overrides?: Overrides & { from?: string | Promise<string> }
  ): TransactionRequest {
    return super.getDeployTransaction(overrides || {});
  }
  attach(address: string): SHA1 {
    return super.attach(address) as SHA1;
  }
  connect(signer: Signer): SHA1__factory {
    return super.connect(signer) as SHA1__factory;
  }
  static readonly bytecode = _bytecode;
  static readonly abi = _abi;
  static createInterface(): SHA1Interface {
    return new utils.Interface(_abi) as SHA1Interface;
  }
  static connect(address: string, signerOrProvider: Signer | Provider): SHA1 {
    return new Contract(address, _abi, signerOrProvider) as SHA1;
  }
}
