import Nav from '../../components/Nav/Nav';
import { motion } from 'framer-motion';
import { Icon } from '@iconify/react';
import React, { useContext, useEffect, useRef, useState } from 'react';

import { IBillingAddress, IDomain, IEthereumAddress, IOrder, IUser } from '../../interface';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import i18nIsoCountries from 'i18n-iso-countries';
import { cancelOrder } from '../../api/delete-order';
import { getUser } from '../../api/get-user';
import { createEthereumAddress as _createEthereumAddress } from '../../api/create-ethereum-address';
import { getOrders } from '../../api/get-orders';
import _ from 'lodash';
import { createDefaultEthereumAddress } from '../../api/create-default-ethereum-address';
import { getDefaultEthereumAddress } from '../../api/get-default-ethereum-address';
import { deleteEthereumAddress } from '../../api/delete-ethereum-address';
import { getEthereumAddresses } from '../../api/get-ethereum-addresses';
import EthereumAddressView from '../../components/Account/EthereumAddressView';
import { getDomains } from '../../api/get-domains';
import Manage from '../../components/domain/Manage';
import Skeleton from 'react-loading-skeleton';
import { revealWalletKey } from '../../api/reveal-private-key';
import { toast, Toaster } from 'react-hot-toast';
import { createManganedWallet } from '../../api/create-managed-wallet';
import { GlobalStateContext } from '../../context/GlobalStateContext';

i18nIsoCountries.registerLocale(require('i18n-iso-countries/langs/en.json'));

const ani = {
  hide: {
    opacity: 0,
    zIndex: '-1',
    transition: { zIndex: { delay: 0.35 } },
    duration: 0.2,
  },
  show: { opacity: 1, zIndex: '100', transition: { duration: 0.2 } },
};
function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const MyDomainN = () => {
  let navigate = useNavigate();
  const { updateUser, isManagedWallet } = useContext(GlobalStateContext);
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [accessToken, setAccessToken] = useState<string | undefined>(undefined);
  const [isRetreivedAccessToken, setIsRetreivedAccessToken] = useState<boolean>(false);
  const [isBillingAddressHasBeenSetup, setIsBillingAddressHasBeenSetup] = useState<boolean | undefined>(undefined);
  const [user, setUser] = useState<IUser | undefined>(undefined);
  const [orders, setOrders] = useState<IOrder[] | undefined>(undefined);

  const [billingAddress, setBillingAddress] = useState<IBillingAddress | undefined>(undefined);
  const [ethereumAddresses, setEthereumAddresses] = useState<IEthereumAddress[] | undefined>(undefined);
  const [defaultEthereumAddress, setDefaultEthereumAddress] = useState<IEthereumAddress | undefined>(undefined);

  const [orderInModal, setOrderInModal] = useState<IOrder | undefined>(undefined);

  const [isConnectingToWeb3Wallet, setIsConnectingToWeb3Wallet] = useState<boolean>(false);
  const [isOrderDetailModalOpen, setIsOrderDetailModalOpen] = useState<boolean>(false);

  const [isCancelingOrder, setIsCancelingOrder] = useState<{ [key: string]: boolean }>({});

  const [isEthereumAddressHasBeenSetup, setIsEthereumAddressHasBeenSetup] = useState<boolean | undefined>(undefined);
  const [isDuplicatedBindingEthereumAddress, setIsDuplicatedBindingEthereumAddress] = useState<boolean | undefined>(undefined);
  const scrolls = useRef<HTMLDivElement>(null);
  const [customAddWalletErrorMessage, setCustomAddWalletErrorMessage] = useState<string | undefined>(undefined);
  const footer = useRef<HTMLDivElement>(null);
  const [countryOptions, setCountryOptions] = useState<{ value: string; label: string }[]>([]);
  const [domains, setDomains] = useState<IDomain[]>([]);
  const [selectWallet, setSelectWallet] = useState<string>('');
  const [selectDomain, setSelectDomain] = useState<string>('');
  const [change, setChange] = useState<number>(-1);
  const [reveal, setReveal] = useState<boolean>(false);
  const [remove, setRemove] = useState<boolean>(false);
  const [managedWallet, setManagedWallet] = useState<any>();
  const [privateKey, setPrivateKey] = useState<string>('');
  const [defaultDomain, setDefaultDomain] = useState<string[] | undefined>(undefined);
  const [managedAddress, setManagedAddress] = useState<string>('');
  const { useWeb3Function } = useContext(GlobalStateContext);
  let query = useQuery();

  useEffect(() => {
    const _countryOptions = new Set<{ value: string; label: string }>();
    const names = i18nIsoCountries.getNames('en');
    for (const code in names) {
      _countryOptions.add({ label: names[code], value: code });
    }
    setCountryOptions([..._countryOptions]);
  }, []);

  // Get access token from Auth0
  useEffect(() => {
    async function exec() {
      if (isAuthenticated) {
        const token = await getAccessTokenSilently();
        setAccessToken(token);
      }
      setIsRetreivedAccessToken(true);
    }
    exec();
  }, [isAuthenticated, getAccessTokenSilently]);

  // Get and set user
  useEffect(() => {
    async function exec() {
      if (accessToken && isRetreivedAccessToken && isAuthenticated && countryOptions.length > 0) {
        const _user = await getUser(accessToken);
        setUser(_user);
        setBillingAddress(_user.billingAddress);
        setEthereumAddresses(_.orderBy(_user.ethereumAddresses, 'updatedAt', 'desc'));
        setDefaultEthereumAddress(_user.defaultEthereumAddress);
        const _domains = await getDomains(accessToken);
        setDomains(_domains);
        if (_user.defaultEthereumAddress) setSelectWallet(_user.defaultEthereumAddress.address);
      }
    }
    exec();
  }, [accessToken, countryOptions, isAuthenticated, isRetreivedAccessToken]);

  function setWallet(ethereumAddress: string) {
    // console.log(ethereumAddress)
    setSelectWallet(ethereumAddress);
  }
  function viewDomainInfo(ethereumAddress: string) {
    setSelectDomain(ethereumAddress);
  }

  useEffect(() => {
    if (accessToken && isRetreivedAccessToken && isAuthenticated && billingAddress) {
      const _isBillingAddressHasBeenSetup =
        !!billingAddress.addressLine1 &&
        !!billingAddress.city &&
        !!billingAddress.state &&
        !!billingAddress.zip &&
        !!billingAddress.country &&
        !!billingAddress.firstname &&
        !!billingAddress.lastname;
      setIsBillingAddressHasBeenSetup(_isBillingAddressHasBeenSetup);
    }
  }, [accessToken, billingAddress, isAuthenticated, isRetreivedAccessToken]);

  useEffect(() => {
    if (accessToken && isRetreivedAccessToken && isAuthenticated && ethereumAddresses) {
      setIsEthereumAddressHasBeenSetup(ethereumAddresses.length > 0);
    }
  }, [accessToken, ethereumAddresses, isAuthenticated, isRetreivedAccessToken]);

  useEffect(() => {
    async function exec() {
      if (accessToken) {
        const _user = await getUser(accessToken);
        if (_user)
          try {
            setManagedWallet(_user.managedWallet);
            setManagedAddress(_user.managedWallet.address);
          } catch (e) {
            console.error(e);
          }
      }
    }
    exec();
  }, [accessToken]);

  // Get and set orders
  useEffect(() => {
    async function exec() {
      if (accessToken && isRetreivedAccessToken && isAuthenticated) {
        const _orders = await getOrders(accessToken);
        setOrders(_.orderBy(_orders.orders, 'createdAt', 'desc'));
      }
    }
    exec();
  }, [accessToken, isAuthenticated, isRetreivedAccessToken]);
  useEffect(() => {
    async function exec() {
      if (selectWallet != '') {
        const dfDomainList: string[] = [];
        for (const e of Object.keys(useWeb3Function)) {
          console.log({ e });
          const domain = await useWeb3Function[e].getReverse({ domain: selectWallet });

          console.log({ domain });
          if (domain != null || domain != '') dfDomainList.push(domain);
        }

        console.log({ dfDomainList });
        setDefaultDomain(dfDomainList);
      }
    }
    setDefaultDomain(undefined);
    exec();
  }, [selectWallet]);
  const createEthereumAddress = async (address: string, message: string, signature: string) => {
    if (accessToken) {
      const isEthereumAddressAlreadyExist = ethereumAddresses?.find((_address) => _address.address === address);
      if (!isEthereumAddressAlreadyExist) {
        await _createEthereumAddress(accessToken, address, message, signature);
        const [_defaultEthereumaddress, _ethereumAddresses] = await Promise.all([getDefaultEthereumAddress(accessToken), getEthereumAddresses(accessToken)]);
        setEthereumAddresses(_ethereumAddresses);
        setDefaultEthereumAddress(_defaultEthereumaddress);
      } else {
        setIsDuplicatedBindingEthereumAddress(true);
      }
    }
  };

  const handleAddWalletClick = async () => {
    if (accessToken && isRetreivedAccessToken && isAuthenticated) {
      try {
        const result = await useWeb3Function[137].signMessage();
        if (result) {
          const { address, signature, message } = result;
          await createEthereumAddress(address, message, signature);
        }
      } catch (e) {
        console.error({ e });
        if (e instanceof Error) {
          if (e.message === 'No Binance Chain Wallet found') setCustomAddWalletErrorMessage(e.message);
        }
      }
    }
  };

  const handleSetAsDefaultEthereumAddressClick = async (address: string) => {
    if (accessToken && isRetreivedAccessToken && isAuthenticated) {
      await createDefaultEthereumAddress(accessToken, address);
      const _address = await getDefaultEthereumAddress(accessToken);
      setDefaultEthereumAddress(_address);
    }
  };

  const handleDeleteEthereumAddressClick = async (address: string) => {
    if (accessToken && isRetreivedAccessToken && isAuthenticated) {
      await deleteEthereumAddress(accessToken, address);
      const [_defaultEthereumaddress, _ethereumAddresses] = await Promise.all([getDefaultEthereumAddress(accessToken), getEthereumAddresses(accessToken)]);
      setEthereumAddresses(_ethereumAddresses);
      setDefaultEthereumAddress(_defaultEthereumaddress);
    }
  };

  const handleCancelOrderClick = async (orderId: string) => {
    if (accessToken && isRetreivedAccessToken && isAuthenticated && !isCancelingOrder[orderId]) {
      const _isCancelingOrder = _.clone(isCancelingOrder);
      _isCancelingOrder[orderId] = true;
      setIsCancelingOrder(_isCancelingOrder);

      await cancelOrder(accessToken, orderId);
      const _orders = await getOrders(accessToken);
      setOrders(_.orderBy(_orders.orders, 'createdAt', 'desc'));

      _isCancelingOrder[orderId] = false;
      setIsCancelingOrder(_isCancelingOrder);
    }
  };

  const handleWalletRevailClick = async () => {
    if (accessToken) {
      const response = await revealWalletKey(accessToken);
      setPrivateKey(response.privateKey);
      setReveal((prev) => !prev);
    }
  };

  const EthereumAddressesView = ethereumAddresses?.map((ethereumAddress, i) => {
    return (
      <EthereumAddressView
        key={i}
        index={i + 1}
        ethereumAddress={ethereumAddress}
        isDefaultEthereumAddress={ethereumAddress.address === defaultEthereumAddress?.address && selectWallet === defaultEthereumAddress.address ? true : false}
        handleClick={setWallet}
        handleSetAsDefaultEthereumAddressClick={handleSetAsDefaultEthereumAddressClick}
        handleDeleteEthereumAddressClick={handleDeleteEthereumAddressClick}
        hoverChangeBg={true}
        change={change}
        setChange={setChange}
        type={ethereumAddress.type}
        handleReveal={handleWalletRevailClick}
      />
    );
  });

  useEffect(() => {
    if (scrolls.current) {
      if (scrolls.current.scrollWidth > scrolls.current.clientWidth) {
        scrolls.current.classList.add('cursor-grab');
      } else {
        scrolls.current.classList.remove('cursor-grab');
      }
    }

    window.addEventListener('resize', () => {
      // check horizontal overflow
      if (scrolls.current) {
        if (scrolls.current.scrollWidth > scrolls.current.clientWidth) {
          scrolls.current.classList.add('cursor-grab');
        } else {
          scrolls.current.classList.remove('cursor-grab');
        }
      }
    });
  }, []);

  if (scrolls.current) {
    if (scrolls.current.scrollWidth > scrolls.current.clientWidth) {
      scrolls.current.classList.add('cursor-grab');
    } else {
      // console.log(scrolls.current.scrollWidth, scrolls.current.clientWidth);
      scrolls.current.classList.remove('cursor-grab');
    }
  }

  let pos = { top: 0, left: 0, x: 0, y: 0 };
  let ele;
  const mouseDownHandler = function (e) {
    if (scrolls.current) {
      ele = scrolls.current;
    }
    ele.style.cursor = 'grab';
    ele.style.userSelect = 'none';
    pos = {
      // The current scroll
      left: ele.scrollLeft,
      top: ele.scrollTop,
      // Get the current mouse position
      x: e.clientX,
      y: e.clientY,
    };

    document.addEventListener('mousemove', mouseMoveHandler);
    document.addEventListener('mouseup', mouseUpHandler);
  };
  const mouseMoveHandler = function (e) {
    if (scrolls.current) {
      ele = scrolls.current;
    }
    // How far the mouse has been moved
    const dx = e.clientX - pos.x;
    const dy = e.clientY - pos.y;

    // Scroll the element
    ele.scrollTop = pos.top - dy;
    ele.scrollLeft = pos.left - dx;
  };
  const mouseUpHandler = function () {
    if (scrolls.current) {
      ele = scrolls.current;
    }
    document.removeEventListener('mousemove', mouseMoveHandler);
    document.removeEventListener('mouseup', mouseUpHandler);

    ele.style.cursor = 'default';
    ele.style.removeProperty('user-select');
  };
  interface handleSetReverseDomain {
    domain: string;
    chainID: number;
    _ownerAddress: string;
  }
  async function handleSetReverseDomain(props: handleSetReverseDomain) {
    await toast.promise(setReverseDomain(props), {
      loading: 'Loading',
      success: 'Success.',
      error: 'Error, Please Check the wallet account',
    });
  }
  async function setReverseDomain(props: handleSetReverseDomain) {
    //TODO reload
    await useWeb3Function[props.chainID].setReverse({ domain: props.domain, _ownerAddress: props._ownerAddress });
    const domain = await useWeb3Function[props.chainID].getReverse({ domain: selectWallet });
    // setDefaultDomain(domain);
  }

  useEffect(() => {
    let a = document.querySelector(':root') as HTMLElement;
    let ethernetAddressesLength;
    if (ethereumAddresses) {
      ethereumAddresses?.length >= 5 ? (ethernetAddressesLength = '5') : (ethernetAddressesLength = (ethereumAddresses?.length + 1).toString());
    }
    if (ethernetAddressesLength) {
      // console.log(ethernetAddressesLength);
      a.style.setProperty('--card-quantity', ethernetAddressesLength);
    }
  }, [ethereumAddresses]);

  useEffect(() => {
    let r = document.querySelector(':root') as HTMLElement;
    if (footer.current) {
      r.style.setProperty('--footer-h', footer.current.offsetHeight + 'px');
    }
  });

  function renderDomain(name: string, tld: string) {
    return (
      <Link
        to={`/profile/${name}/${tld}`}
        className="transfer-domain-con flex flex-col items-center justify-center gap-2 rounded-xl border bg-white p-10 py-6 duration-200 hover:bg-purple-700 hover:text-white"
      >
        <Icon icon="cryptocurrency:matic" className="text-5xl text-purple-500 " />
        <text className="mt-2 text-lg">{name}</text>
        <div className="font-medium">.{tld}</div>
      </Link>
    );
  }
  function checkWalletOwnDomain() {
    let newList = domains;
    newList = newList.filter((x) => x.owner.address === selectWallet);
    if (newList.length > 0) return true;
    else return false;
  }
  const handleCreateManagedWallet = async () => {
    if (accessToken) {
      await createManganedWallet(accessToken);
      await updateUser();
      navigate(0);
    }
  };

  return (
    <>
      <div
        data-toggle={query.get('action') === 'create'}
        onClick={() => {
          navigate('/account');
        }}
        className="selectWallet-wrapper fixed left-0 top-0 z-[700] bg-black/30"
      ></div>
      <div data-toggle={query.get('action') === 'create'} className="selectWallet-con z-[701] flex flex-col gap-5 rounded-xl p-6">
        <h2 className="text-xl font-medium">Select Wallet Type</h2>
        <div className="flex flex-1 flex-wrap gap-5">
          <button
            onClick={() => {
              handleAddWalletClick();
              navigate('/mydomain');
            }}
            className="min-w-[250px] flex-1 rounded-lg border-2 border-[#93278f] p-3 py-5 font-medium text-[#93278f] duration-150 hover:bg-[#93278f] hover:text-white"
          >
            Create Ethereum Wallet
          </button>
          {!isManagedWallet ? (
            <button
              onClick={() => {
                handleCreateManagedWallet();
                navigate('/account');
              }}
              className="min-w-[250px] flex-1 rounded-lg border-2 border-[#93278f] p-3 py-5 font-medium text-[#93278f] duration-150 hover:bg-[#93278f] hover:text-white"
            >
              Create Managed Wallet
            </button>
          ) : (
            <></>
          )}
          {!isManagedWallet && <div className="text-red-700">Caution: Managed Wallet </div>}
        </div>
      </div>
      <Toaster />
      <motion.div
        variants={ani}
        animate={isOrderDetailModalOpen ? 'show' : 'hide'}
        onClick={() => setIsOrderDetailModalOpen(false)}
        data-show={isOrderDetailModalOpen}
        className="modal-con fixed z-[-1] grid h-full w-full place-items-center overflow-hidden opacity-0 backdrop-blur-sm"
      >
        <div className="flex h-full w-full flex-col overflow-hidden rounded-none shadow-2xl lg:h-auto lg:w-[32rem] lg:rounded-lg">
          <div className="flex items-center justify-between bg-blue-500 px-8 py-7 text-xl font-semibold text-white shadow-2xl lg:rounded-t-lg">
            <span>Order information</span>
            <Icon icon="ep:close-bold" className="h-7 w-auto cursor-pointer" onClick={() => setIsOrderDetailModalOpen(false)} />
          </div>
          <div className="max-h-auto z-50 flex h-full flex-col gap-5 overflow-y-scroll bg-white px-8 py-9 lg:max-h-[500px] lg:rounded-b-lg">
            {orderInModal && (
              <>
                <div className="flex flex-col items-start gap-0 lg:flex-row lg:items-center lg:gap-5">
                  <span className="flex-[2] text-lg font-medium">Order ID</span>
                  <span className="lg:text-md text-xs">{orderInModal.id}</span>
                </div>
                <div className="flex flex-col items-start gap-0 lg:flex-row lg:items-center lg:gap-5">
                  <span className="flex-[2] font-medium">Status</span>
                  <span>{_.startCase(_.toLower(orderInModal.status))}</span>
                </div>
                <div className="border-b"></div>
                {orderInModal.domains.map((orderDomain, i) => (
                  <div key={i} className="flex flex-col gap-5">
                    <div className="flex flex-col items-start gap-0 lg:flex-row lg:items-center lg:gap-5">
                      <span className="flex-[2] font-medium">Domain</span>
                      <span>{orderDomain.fqdn}</span>
                    </div>
                    <div className="flex flex-col items-start gap-0 lg:flex-row lg:items-center lg:gap-5">
                      <span className="flex-[2] font-medium">Owner Address</span>
                      <span className="lg:text-md text-xs">{orderDomain.owner['address']}</span>
                    </div>
                    <div className="flex flex-col items-start gap-0 lg:flex-row lg:items-center lg:gap-5">
                      <span className="flex-[2] font-medium">Price</span>
                      <span>$ {orderDomain.price} USD</span>
                    </div>
                    <div className="flex flex-col items-start gap-0 lg:flex-row lg:items-center lg:gap-5">
                      <span className="flex-[2] font-medium">Type</span>
                      <span>{_.startCase(_.toLower(orderDomain.type))}</span>
                    </div>
                    <div className="flex flex-col items-start gap-0 lg:flex-row lg:items-center lg:gap-5">
                      <span className="flex-[2] font-medium">Durations</span>
                      <span>{orderDomain.durations} years</span>
                    </div>
                    <div className="border-b"></div>
                  </div>
                ))}
                {orderInModal.successNowPayments && (
                  <div className="flex gap-5">
                    <span className="flex-[2] font-medium">Payment Gateway</span>
                    <span>Cryptocurrency</span>
                  </div>
                )}
                {orderInModal.successCheckout && (
                  <div className="flex gap-5">
                    <span className="flex-[2] font-medium">Payment Gateway</span>
                    <span>Credit Card</span>
                  </div>
                )}
                <div className="flex gap-5">
                  <span className="flex-[2] font-medium">Amount</span>
                  <span>$ {orderInModal.amount} USD</span>
                </div>
              </>
            )}
          </div>
        </div>
      </motion.div>

      <Nav title="My Domains" />

      <div className="flex flex-1 flex-col overflow-y-auto p-10 px-0 pb-0">
        <div className="account-r-con flex w-full flex-1 flex-col gap-8">
          {customAddWalletErrorMessage && (
            <div className="relative rounded border border-red-400 bg-red-100 px-4 py-3 text-red-700" role="alert">
              <span className="block sm:inline">{customAddWalletErrorMessage}</span>
              <span className="absolute top-0 bottom-0 right-0 px-4 py-3" onClick={() => setCustomAddWalletErrorMessage(undefined)}>
                <svg className="h-6 w-6 fill-current text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <title>Close</title>
                  <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
                </svg>
              </span>
            </div>
          )}
          {isDuplicatedBindingEthereumAddress && (
            <div className="relative rounded border border-red-400 bg-red-100 px-4 py-3 text-red-700" role="alert">
              <strong className="font-bold">Duplicated Address!</strong>
              <span className="block sm:inline"> You have already bind this address into your account.</span>
              <span className="absolute top-0 bottom-0 right-0 px-4 py-3" onClick={() => setIsDuplicatedBindingEthereumAddress(false)}>
                <svg className="h-6 w-6 fill-current text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <title>Close</title>
                  <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
                </svg>
              </span>
            </div>
          )}
          <div className="px-6 text-xl md:px-10">Select the wallet</div>
          <div className="px-6 md:px-10">
            <div ref={scrolls} onMouseDown={mouseDownHandler} className="wallet-bar z-[0] flex min-h-[100px] w-full shrink-0 gap-5 overflow-x-auto rounded-xl border p-10">
              {isEthereumAddressHasBeenSetup === false && managedAddress === '' && (
                <div className="flex min-h-[220px] shrink-0 items-center justify-center rounded-2xl border bg-red-500 px-5 py-5 font-medium text-white">
                  Please add your wallet address
                </div>
              )}
              {ethereumAddresses ? (
                <>
                  <motion.button
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    transition={{ ease: 'easeOut', duration: 0.3 }}
                    viewport={{ once: true }}
                    className="box-shadow flex min-h-[220px] w-full shrink-0 items-center justify-center gap-5 rounded-2xl border bg-white px-7 py-3 text-lg font-medium text-blue-500 duration-200 hover:bg-blue-500 hover:text-white md:w-[350px]"
                    onClick={() => {
                      // handleAddWalletClick();
                      navigate('/mydomain?action=create');
                      // setIsConnectingToWeb3Wallet(true);
                      // setIsDuplicatedBindingEthereumAddress(false);
                    }}
                  >
                    <Icon icon="ant-design:plus-outlined" className="h-8 w-auto" />
                    Add Wallet
                  </motion.button>
                  {/* {managedWallet && (
                    <motion.button
                      initial={{ x: -20, opacity: 0 }}
                      whileInView={{ x: 0, opacity: 1 }}
                      transition={{ ease: 'easeOut', duration: 0.5 }}
                      viewport={{ once: true }}
                      className={`box-shadow relative flex shrink-0 flex-col justify-between gap-5 overflow-hidden rounded-2xl border px-8 py-8 duration-200`}
                      onClick={() => {
                        setWallet(managedWallet.address);

                        // handleWalletRevailClick();
                        // setIsConnectingToWeb3Wallet(true);
                        // setIsDuplicatedBindingEthereumAddress(false);
                      }}
                    >
                      <div className="z-50 flex items-end gap-12">
                      <span className="flex-[1] text-2xl mt-2 font-medium text-black lg:mr-5">Managed Wallet</span>
                    </div>

                      <span className={`z-50 w-full truncate text-center font-medium lg:text-left`}>{managedWallet.address}</span>
                    </motion.button>
                  )} */}
                  {ethereumAddresses.length > 0 ? EthereumAddressesView : <></>}
                </>
              ) : (
                <Skeleton style={{ border: '1px solid rgb(229, 231, 235)' }} width="350px" height={210} borderRadius={'1.25rem'} baseColor={'white'} highlightColor={'#f1f1f1'} />
              )}
            </div>
            {/*
            <button className="mt-5 font-semibold text-blue-900">SEE ALL WALLETS</button> */}
          </div>

          <div className="flex-1 px-6 md:px-10" style={selectWallet ? { display: 'flex' } : { display: 'none' }}>
            {defaultDomain != undefined ? (
              <section className="mb-10 flex w-full flex-1 flex-col gap-5 overflow-hidden">
                <div>
                  <span className="text-lg">Domains</span>
                </div>
                <div className="" style={checkWalletOwnDomain() === true ? { display: 'none' } : { display: 'flex' }}>
                  <span className="text-lg">No Domain in this Wallet</span>
                </div>

                <div className="domains-grid-con w-full ">
                  {domains.map((x, i) => {
                    if (x.owner.address === selectWallet) {
                      // return renderDomain(x.name, x.tld.name);
                      const check = defaultDomain?.indexOf(`${x.name}.${x.tld.name}`) == -1 ? false : true;
                      return <Manage name={x.name} tld={x.tld.name} chainID={x.tld.chainId} index={i} callback={handleSetReverseDomain} owner={selectWallet} default={check} />;
                    }
                  })}
                </div>
              </section>
            ) : (
              <div className="grid w-full flex-1 place-items-center">
                <div className="lds-facebook">
                  <div></div>
                  <div></div>
                  <div></div>
                </div>{' '}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default withAuthenticationRequired(MyDomainN, {
  onRedirecting: () => <div className="load-center"></div>,
});
