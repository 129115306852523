import React, { useContext, useEffect, useState } from 'react';
import '../../App.scss';
import { Icon } from '@iconify/react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Nav from '../../components/Nav/Nav';
import List from '../../components/result/List';
import { motion } from 'framer-motion';
import { useAuth0 } from '@auth0/auth0-react';
import { getUser } from '../../api/get-user';
import { lookupDomainWithAuth } from '../../api/lookup-domain-with-auth';
import { lookupDomain } from '../../api/lookup-domain';
import { createShoppingCartDomain, CreateShoppingCartDomainType } from '../../api/create-shopping-cart-domain';
import { ITld } from '../../interface';
import { GlobalStateContext } from '../../context/GlobalStateContext';

export interface IUserResult {
  id: string;
  provider: string;
  status: string;
  username: string;
  email: string;
  defaultEthereumAddress: null;
  ethereumAddresses: [];
  billingAddress: {};
  shoppingCart: {};
  role: string;
  languagePreference: string;
  createdAt: string;
}

const Result = () => {
  // const [accessToken, setAccessToken] = useState<string | undefined>(undefined);
  // const [isRetreivedAccessToken, setIsRetreivedAccessToken] = useState<boolean>(false);
  // const [user, setUser] = useState<IUser | undefined>(undefined);
  // const [tlds, setTlds] = useState<ITld[]>([]);
  const { loginWithRedirect, isAuthenticated } = useAuth0();
  const [isLookupDomainResultReturned, setIsLookupDomainResultReturned] = useState<boolean>(false);
  const [isLookupDomainAvailable, setIsLookupDomainAvailable] = useState<boolean>(false);
  // const [isMissingBillingAddress, setIsMissingBillingAddress] = useState<boolean>(false);
  // const [isMissingEthereumAddress, setIsMissingEthereumAddress] = useState<boolean>(false);
  const [isAddingDomainToShoppingCart, setIsAddingDomainToShoppingCart] = useState<boolean>(false);
  const [isAddedDomainToShoppingCart, setIsAddedDomainToShoppingCart] = useState<boolean>(false);
  const [isAlreadyInShoppingCart, setIsAlreadyInShoppingCart] = useState<boolean>(false);

  const [cartReload, setCartReload] = useState<boolean>(false);
  const [add, setAdd] = useState<boolean>(false);
  // const [count, setCount] = useState(0);
  // const [update, setUpdate] = useState<boolean>(false);
  const [showNav, setShowNav] = useState(false);
  const { accessToken, tlds, isBillingAddressFinishSetup, isEthereumAddressesFinishSetup, shoppingCart, updateShoppingCart, updateShoppingCartDomainsCount, isManagedWallet } =
    useContext(GlobalStateContext);
  // const [fqdn, setFqdn] = useState<string | undefined>(undefined);
  const regex = new RegExp(/^[a-z0-9\-_\u00a9\u00ae\u2000-\u3300\ud83c\ud000-\udfff\ud83d\ud000-\udfff\ud83e\ud000-\udfff]{5,128}$/);
  const { search } = useLocation();
  const [managedWallet, setManagedWallet] = useState<any>();
  const [managedAddress, setManagedAddress] = useState<string>('');
  let urlParams = useParams();
  const fqdn = urlParams.fqdn?.toLowerCase();
  let location = useLocation();
  const navigate = useNavigate();

  const [oldPath, setOldPath] = useState<String>('');

  useEffect(() => {
    // console.log(location.pathname.split('/')[2]);
    if (location.pathname.split('/')[2] !== oldPath) {
      // console.log('true');
      setIsAlreadyInShoppingCart(false);
      setIsAddingDomainToShoppingCart(false);
      setIsAddedDomainToShoppingCart(false);
      setOldPath(location.pathname.split('/')[2]);
    }
  }, [location]);
  // const { isAuthenticated, getAccessTokenSilently, loginWithRedirect } = useAuth0();

  // const setCartCount = () => {
  //   let i;
  //   if (window.localStorage.getItem('cart-domains-count') != null) {
  //     i = window.localStorage.getItem('cart-domains-count');
  //   }
  //   setCount(parseInt(i));
  // };

  // useEffect(() => {
  //   async function exec() {
  //     if (isAuthenticated) {
  //       const token = await getAccessTokenSilently();
  //       const response = await getShoppingCartDomainsCount(token);
  //       window.localStorage.setItem('cart-domains-count', `${response.count}`);
  //       setCartCount();
  //     }
  //   }
  //   exec();
  //   // if (props.reload && props.setreload !== undefined) {
  //   //   exec();
  //   //   props.setreload(false);
  //   // }
  // }, [getAccessTokenSilently, isAuthenticated]);

  useEffect(() => {
    if (urlParams.fqdn) {
      const [_name, _tld] = urlParams.fqdn.split('.');
      if (!regex.test(_name)) navigate('/');
    } else {
      navigate('/');
    }
  }, [navigate, regex, urlParams]);

  useEffect(() => {
    if (shoppingCart) {
      const isExist = shoppingCart.domains.find((domain) => `${domain.name}.${domain.tld.name}` === fqdn);
      setIsAlreadyInShoppingCart(!!isExist);
    }
  }, [shoppingCart, fqdn]);

  // Get TLDs
  // useEffect(() => {
  //   async function exec() {
  //     const _tlds = await getTlds();
  //     setTlds(_tlds);
  //   }
  //   exec();
  // }, []);

  // Get access token from Auth0
  // useEffect(() => {
  //   async function exec() {
  //     if (isAuthenticated) {
  //       const token = await getAccessTokenSilently();
  //       setAccessToken(token);
  //       setIsRetreivedAccessToken(true);
  //     } else {
  //       setIsRetreivedAccessToken(true);
  //     }
  //   }
  //   exec();
  // }, [isAuthenticated, getAccessTokenSilently]);

  // Get user information
  // useEffect(() => {
  //   async function exec() {
  //     if (accessToken && isRetreivedAccessToken && isAuthenticated) {
  //       let _user: IUser | undefined = user;
  //       if (!_user) {
  //         _user = await getUser(accessToken);
  //         setUser(_user);
  //       }
  //       if (!_user.billingAddress.country) {
  //         setIsMissingBillingAddress(true);
  //       }
  //       if (_user.ethereumAddresses.length === 0) {
  //         setIsMissingEthereumAddress(true);
  //       }
  //     }
  //   }
  //   exec();
  // }, [accessToken, isAuthenticated, isRetreivedAccessToken, user]);

  useEffect(() => {
    async function exec() {
      if (accessToken) {
        const _user = await getUser(accessToken);
        try {
          setManagedWallet(_user.managedWallet);
          setManagedAddress(_user.managedWallet.address);
        } catch (e) {
          console.error(e);
        }
      }
    }
    exec();
  }, [accessToken]);

  // Lookup domain
  useEffect(() => {
    async function exec() {
      try {
        if (accessToken && fqdn) {
          const _lookupDomain = await lookupDomainWithAuth(fqdn, accessToken);
          setIsLookupDomainResultReturned(true);
          setIsLookupDomainAvailable(_lookupDomain.available);
        } else if (!accessToken && fqdn) {
          const _lookupDomain = await lookupDomain(fqdn);
          setIsLookupDomainResultReturned(true);
          setIsLookupDomainAvailable(_lookupDomain.available);
        }
      } catch {
        setIsLookupDomainResultReturned(false);
        setIsLookupDomainAvailable(false);
      }
    }
    exec();
  }, [accessToken, urlParams, navigate, fqdn]);

  const addDomainToShoppingCart = async (type: CreateShoppingCartDomainType, tld: string, name: string, code?: string) => {
    if (accessToken) {
      try {
        if (code) {
          await createShoppingCartDomain(accessToken, type, tld, name, undefined, code);
          setCartReload(true);
        } else {
          await createShoppingCartDomain(accessToken, type, tld, name);
        }
        await updateShoppingCart();
        await updateShoppingCartDomainsCount();
      } catch (e) {
        console.error(e);
      }
    } else {
      await loginWithRedirect({
        redirectUri: window.location.origin,
        appState: {
          returnTo: window.location.href,
        },
      });
    }
  };

  const reload = (state: boolean) => {
    setCartReload(state);
  };

  console.log(isBillingAddressFinishSetup);

  return (
    <>
      <Nav
        reload={cartReload}
        setreload={setCartReload}
        cartCount={add}
        setCartCount={setAdd}
        title="Domains"
        showNav={showNav}
        setShowNav={setShowNav}
        setIsAddedDomainToShoppingCart={setIsAddedDomainToShoppingCart}
      />
      <section className="relative flex h-full w-full flex-col overflow-y-scroll p-10 px-6 pb-0 pt-5 md:px-10">
        {/* <BtnCart /> */}
        {isLookupDomainResultReturned ? (
          <>
            {!isEthereumAddressesFinishSetup && (
              <div
                data-show={!isEthereumAddressesFinishSetup}
                className="missingCon box-shadow relative border px-10 py-5 lg:rounded-md"
                // onClick={() => {
                //   navigate('/setting');
                // }}
              >
                <div className="absolute top-0 left-0 h-full w-2 rounded-l-md bg-red-500"></div>
                <div className="absolute left-0 top-0 h-full w-[10px] rounded-l-md bg-[#ea6667]"></div>
                <div className="flex items-center gap-4">
                  <Icon className="h-20 w-auto text-[#ea6667] md:h-10" icon="radix-icons:cross-circled" />
                  <div className="flex flex-col justify-between">
                    <p className="text-lg">Alert</p>
                    <span onClick={() => navigate('/account')} className={`hover:underline ${!isEthereumAddressesFinishSetup ? 'block' : 'hidden'}`}>
                      Please bind at least one wallet address before adding domain into shopping cart
                    </span>
                    <span onClick={() => navigate('/account?action=create')} className={`hover:underline ${!isEthereumAddressesFinishSetup ? 'block' : 'hidden'}`}>
                      Click here if you dont want to bind your wallet and use our Mangaed Wallet service instead.
                    </span>
                    {/* <span onClick={() => navigate('/setting')} className={`hover:underline ${!isBillingAddressFinishSetup ? 'hidden' : 'hidden'}`}>
                      Click here to setup your billing address before adding domain into shopping cart
                    </span> */}
                  </div>
                </div>
              </div>
            )}

            <div className="flex w-full gap-5">
              {isLookupDomainResultReturned ? (
                isLookupDomainAvailable ? (
                  <motion.div
                    initial={{ x: -20, opacity: 0 }}
                    whileInView={{ x: 0, opacity: 1 }}
                    transition={{ ease: 'easeOut', duration: 0.5 }}
                    viewport={{ once: true }}
                    className="box-shadow relative mb-5 flex w-full flex-1 flex-col gap-8 rounded-xl border bg-white p-10 md:gap-10"
                  >
                    <div className="hidden w-full rounded-md bg-green-600 p-2 px-6 text-center text-2xl font-medium text-white md:block md:w-[200px]">Available</div>
                    <span className="flex items-center gap-6 text-xl font-medium md:gap-10 md:text-3xl">
                      <Icon className="h-auto w-10 shrink-0 text-green-600 md:w-16" icon="teenyicons:tick-circle-outline" />
                      <p className="flex flex-1 overflow-hidden">
                        <span className="truncate font-normal">{fqdn?.split('.')[0]}</span>
                        <span className="font-semibold">.{fqdn?.split('.')[1]}</span>
                      </p>
                    </span>
                    <motion.div
                      // initial={{ y: 25, opacity: 0 }}
                      // whileInView={{ y: 0, opacity: 1 }}
                      // transition={{ ease: 'easeOut', delay: 0.5, duration: 0.6 }}
                      // viewport={{ once: true }}
                      className="flex flex-col items-center justify-between gap-5 md:flex-row md:gap-0"
                    >
                      <div className="self-start text-3xl font-semibold">
                        USD 25 <span className="text-lg font-normal text-gray-300">/year</span>
                      </div>
                      {isAuthenticated && (
                        <button
                          className={`btn-now flex w-full justify-center rounded-md bg-yellow-500 px-12 py-4 text-xl text-white md:w-[200px] ${
                            isLookupDomainAvailable ? 'opacity-100' : 'opacity-50'
                          }`}
                          onClick={() => {
                            async function exec() {
                              setAdd(true);
                              setIsAddingDomainToShoppingCart(true);
                              if (search !== '') {
                                await addDomainToShoppingCart(CreateShoppingCartDomainType.PURCHASE, fqdn!.split('.')[1], fqdn!.split('.')[0], search.split('ref=')[1]);
                              } else {
                                await addDomainToShoppingCart(CreateShoppingCartDomainType.PURCHASE, fqdn!.split('.')[1], fqdn!.split('.')[0]);
                              }
                              setIsAddingDomainToShoppingCart(false);
                              setIsAddedDomainToShoppingCart(true);
                              window.innerWidth > 768 && setShowNav(true);
                            }
                            exec();
                          }}
                          disabled={!isEthereumAddressesFinishSetup || isAddedDomainToShoppingCart || isAlreadyInShoppingCart}
                        >
                          <motion.div
                            className="text-lg"
                            animate={
                              isLookupDomainAvailable && !isAddingDomainToShoppingCart && !isAddedDomainToShoppingCart && !isAlreadyInShoppingCart
                                ? { display: 'flex' }
                                : { display: 'none' }
                            }
                          >
                            Add to cart
                          </motion.div>
                          <motion.div animate={isAddingDomainToShoppingCart ? { display: 'flex' } : { display: 'none' }} className="load">
                            <Icon icon="eos-icons:loading" className="h-7 w-auto" />
                          </motion.div>
                          <motion.div animate={isAddedDomainToShoppingCart || isAlreadyInShoppingCart ? { display: 'flex' } : { display: 'none' }} className="dones">
                            <Icon icon="charm:tick" className="h-7 w-auto" />
                          </motion.div>
                        </button>
                      )}
                    </motion.div>
                  </motion.div>
                ) : (
                  <>
                    <motion.div
                      initial={{ x: -20, opacity: 0 }}
                      whileInView={{ x: 0, opacity: 1 }}
                      transition={{ ease: 'easeOut', duration: 0.5 }}
                      viewport={{ once: true }}
                      className="box-shadow relative mb-5 flex w-full flex-1 flex-col gap-8 rounded-xl border bg-white p-10 md:gap-10"
                    >
                      <div className="hidden w-full rounded-md bg-red-600 p-2 px-6 text-center text-2xl font-medium text-white md:block md:w-[200px]">Unavailable</div>
                      <span className="flex items-center gap-6 text-xl font-medium md:gap-10 md:text-3xl">
                        <Icon className="h-auto w-10 shrink-0 text-red-600 md:w-16" icon="radix-icons:cross-circled" />
                        <p className="flex flex-1 overflow-hidden">
                          <span className="truncate font-normal">{fqdn?.split('.')[0]}</span>
                          <span className="font-semibold">.{fqdn?.split('.')[1]}</span>
                        </p>
                      </span>
                      <motion.div className="flex flex-col items-center justify-between gap-5 md:flex-row md:gap-0">
                        <div className="self-start text-3xl font-semibold">
                          USD 25 <span className="text-lg font-normal text-gray-300">/year</span>
                        </div>
                        <a href="/" className={`btn-now flex w-full justify-center rounded-md bg-yellow-500 px-12 py-4 text-xl text-white md:w-[200px]`}>
                          <motion.div className="text-lg" animate={!isAddingDomainToShoppingCart && !isAddedDomainToShoppingCart ? { display: 'flex' } : { display: 'none' }}>
                            Search Another Domain
                          </motion.div>
                        </a>
                      </motion.div>
                    </motion.div>
                  </>
                )
              ) : (
                <div className="min-h-[500px]"></div>
              )}
            </div>
            <motion.div
              whileInView={isLookupDomainResultReturned ? { opacity: 1 } : { opacity: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.5 }}
              className="scroll-box flex min-h-[400px] w-full flex-1 flex-col opacity-0"
            >
              <div className="mt-5 w-full text-2xl font-medium">Recommend</div>
              <div className="mt-5 flex-1 rounded-lg border p-10">
                <div className="result-grid grid">
                  {fqdn ? (
                    tlds
                      .filter((_tld) => _tld.name !== fqdn.split('.')[1])
                      .map((_tld: ITld, index) => (
                        <List
                          key={index}
                          name={fqdn.split('.')[0]}
                          tld={_tld.name}
                          price={_tld.price.purchase}
                          isAuthenticated={!!accessToken}
                          accessToken={accessToken}
                          addDomainToShoppingCartFn={addDomainToShoppingCart}
                          isAddToCartButtonAvailable={isEthereumAddressesFinishSetup}
                          isManaged={managedAddress !== ''}
                          setAdd={setAdd}
                          setShowNav={setShowNav}
                          search={search}
                        />
                      ))
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </motion.div>
          </>
        ) : (
          <div className="grid h-full w-full place-items-center">
            <Icon icon="eos-icons:loading" className="h-24 w-auto self-center text-gray-300" />
          </div>
        )}
      </section>
    </>
  );
};

export default Result;
