import config from '../config';
import provider from './provider';
import {
    BaseRegistrarImplementation__factory as BaseRegistrarImplementationFactory
} from './typechain/factories/BaseRegistrarImplementation__factory';

// const BaseRegistrarImplementation = {
//     5: BaseRegistrarImplementationFactory.connect(config.contractAddress[5].baseRegistrar, provider[5]),
//     137: BaseRegistrarImplementationFactory.connect(config.contractAddress[137].baseRegistrar, provider[137]),
//     4689:BaseRegistrarImplementationFactory.connect(config.contractAddress[4689].baseRegistrar,provider[4689]),
//     4690:BaseRegistrarImplementationFactory.connect(config.contractAddress[4690].baseRegistrar,provider[4690]),
//     65:BaseRegistrarImplementationFactory.connect(config.contractAddress[65].baseRegistrar,provider[65]),
// }
function BaseRegistrarImplementation() {
    const objs = {}
    for (const [key, value] of Object.entries(config.contractAddress)) {
        objs[key] = BaseRegistrarImplementationFactory.connect(config.contractAddress[key].baseRegistrar, provider[key])
    }
    return objs
    // 5: BaseRegistrarImplementationFactory.connect(config.contractAddress[5].baseRegistrar, provider[5]),
    // 137: BaseRegistrarImplementationFactory.connect(config.contractAddress[137].baseRegistrar, provider[137]),
    // 4689:BaseRegistrarImplementationFactory.connect(config.contractAddress[4689].baseRegistrar,provider[4689]),
    // 4690:BaseRegistrarImplementationFactory.connect(config.contractAddress[4690].baseRegistrar,provider[4690]),
    // 65:BaseRegistrarImplementationFactory.connect(config.contractAddress[65].baseRegistrar,provider[65]),
}



export default BaseRegistrarImplementation;
