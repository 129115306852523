export enum TldStatus {
  AVAILABLE = 'AVAILABLE',
  UNAVAILABLE = 'UNAVAILABLE',
  RESERVED = 'RESERVED',
  PRIVATE = 'PRIVATE',
}
export interface ITld {
  name: string;
  status: TldStatus;
  chainId:number
  price: {
    id: number;
    purchase: number;
    renewal: number;
    createdAt: Date;
    updatedAt: Date;
  };
  nodehash: string;
  labelhash: string;
  createdAt: string;
  updatedAt: string;
}
