import _ from 'lodash';
import Nav from '../../components/Nav/Nav';
import Select from 'react-select';
import {formatsByName} from '@ensdomains/address-encoder';
import {useContext, useEffect, useRef, useState} from 'react';
import {motion} from 'framer-motion';
import {GlobalStateContext} from '../../context/GlobalStateContext';
import {useLocation, useNavigate} from 'react-router-dom';
import LookupResult from "./LookupResult";

const recordTypeOptions = [
  { value: 'all', label: 'All' },
  { value: 'address', label: 'Address' },
  // {value:'NFT',label:'NFT'}
  { value: 'textRecord', label: 'Text Record' },
  // { value: "content", label: "Content" },
];
const textRecordTypeOptions = [
  { value: 'EMAIL', label: 'Email' },
  { value: 'URL', label: 'Url' },
  { value: 'AVATAR', label: 'Avatar' },
  { value: 'DESCRIPTION', label: 'Description' },
  { value: 'NOTICE', label: 'Notice' },
  { value: 'KEYWORDS', label: 'Keywords' },
  { value: 'DISCORD', label: 'Discord' },
  { value: 'GITHUB', label: 'Github' },
  { value: 'REDDIT', label: 'Reddit' },
  { value: 'TWITTER', label: 'Twitter' },
  { value: 'TELEGRAM', label: 'Telegram' },
  { value: 'DELEGATE', label: 'Delegate' },
];
interface IRecord {
  type: string;
  coinType: string;
  address: string;
}
interface ITextRecord {
  type: string;
  textType: string;
  address: string;
}
const recordAddressCategoryOptions = Object.keys(formatsByName)
  .filter((c) => !c.match(/_LEGACY/))
  .sort()
  .map((v) => ({ value: v, label: v }));
const Lookup = () => {
  type OptionType = {
    value: any;
    label: any;
  };
  const { tlds } = useContext(GlobalStateContext);
  const [currentSelectedRecordCoinType, setCurrentSelectedRecordCoinType] = useState<string|undefined >('BTC');
  const [currentSelectedRecordTextype, setCurrentSelectedRecordTextype] = useState<string|undefined>('email');
  const [currentSelectedRecordType, setCurrentSelectedRecordType] = useState<string|undefined >('address');
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const input = useRef<HTMLInputElement>(null);
  const [site, setSite] = useState(false);
  const [i, setI] = useState('');
  const regex = new RegExp(/^[a-zA-Z0-9\-_]{5,128}$/);
  const { search } = useLocation();
  const [tldOptions, setTldOptions] = useState<OptionType[]>([]);
  const [selectedTldOption, setSelectedTldOption] = useState<OptionType | undefined>(undefined);
  const location = useLocation();
  const [inputDomain, setInputDomain] = useState('');
  const [page, setPage] = useState(false);

  useEffect(() => {
    const _tldOptions = tlds.map((_tld) => ({ value: _tld.name, label: `.${_tld.name}` }));
    setTldOptions(_tldOptions);
    const metaTld = tlds.find((_tld) => _tld.name === 'meta');
    if (metaTld) setSelectedTldOption({ value: metaTld.name, label: `.${metaTld.name}` });
  }, [tlds]);

  useEffect(() => {
    if (input.current) {
      input.current.addEventListener('change', () => {
        if (input.current) setI(_.toLower(input.current.value.toLowerCase()));
      });
    }
    if (location.search !== '' && !site) {
      setSite(true);
      setTimeout(() => {
        const url = window.location.href;
        const _url = url.split(`${window.location.origin}`)[1];
        navigate(_url);
      }, 1000);
    }
    // exec();
  }, [input, location]);

  const customStyles = {
    control: (base) => ({
      ...base,
      width: '100%',
      height: '100%',
      border: '2px solid rgb(229, 231, 235)',
      outline: 'none',
      padding: '0.375rem 0',
      borderRadius: '0.5rem',
    }),
  };

  const customStylesDark = {
    control: (base) => ({
      ...base,
      width: '100%',
      height: '100%',
      border: '2px',
      outline: 'none',
      backgroundColor: '#2c2c2c',
      color: '#fff',
    }),
  };

  const handleChange = (options) => {
    setSelectedTldOption(options);
  };
  function handleOnclick(){
    setPage(true)

  }
  if(page === false){
    return (
        <div>
          <div className="main bg lookup-bg  absolute top-0 left-0 flex h-full w-full flex-col justify-end"></div>
          <Nav home={true} title="Look Up" />
          <div>
            <div className="mx-auto flex h-full w-full  max-w-4xl flex-1 flex-col items-center justify-center px-12 xl:justify-center xl:px-0">
              <div className="mx-10 my-10 flex h-full  flex-1 flex-col place-content-center p-10 px-6 pt-6 md:px-10">
                <motion.div className="relative ">
                  <h1 className="text-center text-lg font-bold">Enter Domain and Select Record Type below</h1>
                </motion.div>
                <motion.div className="relative mt-10 flex  w-full flex-col gap-5 text-xl md:h-14 md:flex-row   md:gap-5">
                  <div className="relative h-full w-full flex-col">
                    <input
                        ref={input}
                        onChange={(e) => {
                          setError(!regex.test(e.currentTarget.value));
                          setInputDomain(e.target.value);
                        }}
                        className="main input md:text-md h-full w-full rounded-lg border-2 px-10 py-3.5 text-sm outline-blue-400 md:block 2xl:px-12"
                        type="text"
                        placeholder="Enter Valid Domain"
                    />
                    <svg className="absolute left-3 top-[16px] h-5 w-5 fill-current text-3xl text-gray-300 2xl:left-5 2xl:top-[18px]" x="0px" y="0px" viewBox="0 0 56.966 56.966">
                      <path d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z" />
                    </svg>
                  </div>
                  <div className="search-con z-[80] flex  h-full items-center rounded-md text-base outline-none md:block">
                    {tldOptions && (
                        <Select options={tldOptions} value={selectedTldOption} styles={customStyles} onChange={handleChange} className="h-full w-full text-center "></Select>
                    )}
                  </div>
                </motion.div>
                <motion.div className="text-md relative mt-3 mb-3 flex  w-full flex-col gap-5  text-center md:flex-row">
                  <label className="py-4" htmlFor="">
                    Record Type:
                  </label>
                  {/* <button
                className={`rounded border-2 px-10 py-2 ${
                  currentSelectedRecordType === recordTypeOptions[0].value ? 'border-[#93278f] bg-[#93278f] text-white' : 'border-[#93278f] text-[#93278f]'
                }`}
                onClick={() => setCurrentSelectedRecordType(recordTypeOptions[0].value)}
              >
                All
              </button> */}
                  <button
                      className={`rounded border-2 px-6 py-2 ${
                          currentSelectedRecordType === recordTypeOptions[1].value ? 'border-[#93278f] bg-[#93278f] text-white' : 'border-[#93278f] text-[#93278f]'
                      }`}
                      onClick={() => setCurrentSelectedRecordType(recordTypeOptions[1].value)}
                  >
                    Address
                  </button>{' '}
                  <button
                      className={`rounded border-2 px-4 py-2 ${
                          currentSelectedRecordType === recordTypeOptions[2].value ? 'border-[#93278f] bg-[#93278f] text-white' : 'border-[#93278f] text-[#93278f]'
                      }`}
                      onClick={() => setCurrentSelectedRecordType(recordTypeOptions[2].value)}
                  >
                    Text Record
                  </button>
                </motion.div>
                <br />
                <motion.div
                    animate={currentSelectedRecordType !== recordTypeOptions[0].value ? 'hidden' : 'show'}
                    className={`relative mt-3 mb-3 flex h-full w-full flex-col gap-5 text-center text-sm md:flex-row `}
                >
                  {currentSelectedRecordType === recordTypeOptions[1].value && (
                      <div className="flex flex-col md:flex-row ">
                        <label className="py-2 pr-4" htmlFor="">
                          Record Subtype:
                        </label>
                        <div className="relative flex py-2">
                          <Select
                              defaultValue={recordAddressCategoryOptions.find((v) => v.value === 'BTC')}
                              options={recordAddressCategoryOptions}
                              onChange={(e) => setCurrentSelectedRecordCoinType(e?.value)}
                              className={`fixed top-0 left-0 h-full w-full text-center `}
                          />
                        </div>
                      </div>
                  )}
                  {currentSelectedRecordType === recordTypeOptions[2].value && (
                      <div className="flex">
                        <label className="py-2 pr-4" htmlFor="">
                          Record Subtype:
                        </label>
                        <div className="relative flex py-2">
                          <Select
                              defaultValue={textRecordTypeOptions[0]}
                              options={textRecordTypeOptions}
                              onChange={(e) => setCurrentSelectedRecordTextype(e?.value)}
                              className={`fixed top-0 left-0 z-[80] h-full w-full text-center`}
                          />
                        </div>
                      </div>
                  )}
                </motion.div>
                <motion.div className="relative mt-5 text-right md:text-center">
                  <button
                      className="rounded border-2 border-[#93278f] px-4 py-4 text-[#93278f] hover:bg-[#93278f] hover:text-white"
                      onClick={() => {
                        handleOnclick()
                      }}
                  >
                    Look up Domain
                  </button>
                </motion.div>
              </div>
            </div>
          </div>
        </div>
    );
  }else{
    const subType = currentSelectedRecordType==="address"? currentSelectedRecordCoinType:currentSelectedRecordTextype
    if(subType && currentSelectedRecordType&&selectedTldOption){
      return(
          <LookupResult subType={subType} fqdn={`${inputDomain}.${selectedTldOption.value}`} recordCoinType={currentSelectedRecordType}></LookupResult>
      )
    }else {
      return (
          <div>Not Found</div>
      )
    }


  }

};
export default Lookup;
