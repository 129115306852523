import Nav from '../../components/Nav/Nav';
import {motion} from 'framer-motion';
import {Icon} from '@iconify/react';
import React, {useEffect, useRef, useState} from 'react';
import {IBillingAddress, IEthereumAddress, IOrder, IUser} from '../../interface';
import {useAuth0, withAuthenticationRequired} from '@auth0/auth0-react';
import Select from 'react-select';
import i18nIsoCountries from 'i18n-iso-countries';
import {getUser} from '../../api/get-user';
import {getOrders} from '../../api/get-orders';
import _ from 'lodash';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import axios from 'axios';
import config from '../../config';
import {IShoppingCartDomain} from '../../interface/shopping-cart-domain.interface';
import notFound from '../../static/404.png';
import tokenImage from '../../static/token.png';
import tether from '../../static/tether.png';

i18nIsoCountries.registerLocale(require('i18n-iso-countries/langs/en.json'));

const ani = {
  hide: {
    opacity: 0,
    zIndex: '-1',
    transition: { zIndex: { delay: 0.35 } },
    duration: 0.2,
  },
  show: { opacity: 1, zIndex: '100', transition: { duration: 0.2 } },
};

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

interface IindividualInfo {
  firstname: string;
  lastname: string;
  email: string;
}

interface IcoperateInfo {
  name: string;
  phone: string;
  email: string;
  address: string;
}

const Reseller = () => {
  let navigate = useNavigate();

  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [accessToken, setAccessToken] = useState<string | undefined>(undefined);
  const [isRetreivedAccessToken, setIsRetreivedAccessToken] = useState<boolean>(false);
  const [isBillingAddressHasBeenSetup, setIsBillingAddressHasBeenSetup] = useState<boolean | undefined>(undefined);
  const [user, setUser] = useState<IUser | undefined>(undefined);
  const [orders, setOrders] = useState<IOrder[] | undefined>(undefined);
  const [billingAddress, setBillingAddress] = useState<IBillingAddress | undefined>(undefined);
  const [defaultEthereumAddress, setDefaultEthereumAddress] = useState<IEthereumAddress | undefined>(undefined);
  const [isEthereumAddressHasBeenSetup, setIsEthereumAddressHasBeenSetup] = useState<boolean | undefined>(undefined);
  const scrolls = useRef<HTMLDivElement>(null);
  const [customAddWalletErrorMessage, setCustomAddWalletErrorMessage] = useState<string | undefined>(undefined);
  const footer = useRef<HTMLDivElement>(null);
  const [countryOptions, setCountryOptions] = useState<{ value: string; label: string }[]>([]);
  const [individualInfo, setIndividualInfo] = useState<IindividualInfo>();
  const [corporateInfo, setCorporateInfo] = useState<IcoperateInfo>();
  const [selfie, setSelfie] = useState<any>(null);
  const [passport, setPassport] = useState<any>(null);
  const [certOfIn, setCertOfIn] = useState<any>(null);
  const [bankOfStatment, setBankOfStatment] = useState<any>(null);
  const [reseller, setReseller] = useState(false);
  const [businessReg, setBusinessReg] = useState<any>(null);
  const [code, setCode] = useState('');
  const [edns, setEdns] = useState(0);
  const [usdt, setUSDT] = useState(0);
  const [domain, setDomain] = useState<IShoppingCartDomain>();
  const [isDomainUpdating, setIsDomainUpdating] = useState<boolean>(false);
  const [ethereumAddresses, setEthereumAddresses] = useState<{ value: string; label: string }[]>([]);
  const [formEthaddress, setFormEthaddress] = useState<any>();
  const [record, setRecord] = useState<any>();
  let query = useQuery();
  const customStyles = {
    menuList: (base) => ({
      ...base,
      '::-webkit-scrollbar': {
        width: '4px',
      },
    }),
    control: (base) => ({
      ...base,
      width: '100%',
      height: '63px',
      borderRadius: '6px',
      border: '1px solid rgb(209, 213, 219)',
    }),
  };
  useEffect(() => {
    const _countryOptions = new Set<{ value: string; label: string }>();
    const names = i18nIsoCountries.getNames('en');
    for (const code in names) {
      _countryOptions.add({ label: names[code], value: code });
    }
    setCountryOptions([..._countryOptions]);
  }, []);

  // Get access token from Auth0
  useEffect(() => {
    async function exec() {
      if (isAuthenticated) {
        const token = await getAccessTokenSilently();
        setAccessToken(token);
      }
      setIsRetreivedAccessToken(true);
    }
    exec();
  }, [isAuthenticated, getAccessTokenSilently]);

  useEffect(() => {
    async function exec() {
      if (isAuthenticated) {
        const token = await getAccessTokenSilently();
        setAccessToken(token);
      }
      setIsRetreivedAccessToken(true);
    }
    exec();
  }, [isAuthenticated, getAccessTokenSilently]);

  const createRequest = async () => {
    const url = new URL(`/reseller/request`, `${config.api.endpoint}`);
    if (query.get('type') === 'individual') {
      let bodyFormData = new FormData();
      if (accessToken) {
        bodyFormData.append('ethereumAddress', formEthaddress);
        bodyFormData.append('type', 'INDIVIDUAL');
        bodyFormData.append('individual[firstname]', individualInfo?.firstname!);
        bodyFormData.append('individual[lastname]', individualInfo?.lastname!);
        bodyFormData.append('individual[email]', individualInfo?.email!);
        bodyFormData.append('individual[documents][selfie]', selfie);
        bodyFormData.append('individual[documents][passport]', passport);
        await axios.post(url.href, bodyFormData, { headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${accessToken}` } });
      }
    } else if (query.get('type') === 'corporate') {
      let bodyFormData = new FormData();
      if (accessToken) {
        bodyFormData.append('ethereumAddress', formEthaddress);
        bodyFormData.append('type', 'CORPORATE');
        bodyFormData.append('corporate[name]', corporateInfo?.name!);
        bodyFormData.append('corporate[address]', corporateInfo?.address!);
        bodyFormData.append('corporate[email]', corporateInfo?.email!);
        bodyFormData.append('corporate[phone]', corporateInfo?.phone!);
        bodyFormData.append('corporate[documents][businessRegistration]', businessReg);
        bodyFormData.append('corporate[documents][bankStatement]', bankOfStatment);
        bodyFormData.append('corporate[documents][certificateOfIncorporation]', certOfIn);
        await axios.post(url.href, bodyFormData, { headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${accessToken}` } });
      }
    }
  };

  useEffect(() => {
    if (accessToken && isRetreivedAccessToken && isAuthenticated && billingAddress) {
      const _isBillingAddressHasBeenSetup =
        !!billingAddress.addressLine1 &&
        !!billingAddress.city &&
        !!billingAddress.state &&
        !!billingAddress.zip &&
        !!billingAddress.country &&
        !!billingAddress.firstname &&
        !!billingAddress.lastname;
      setIsBillingAddressHasBeenSetup(_isBillingAddressHasBeenSetup);
    }
  }, [accessToken, billingAddress, isAuthenticated, isRetreivedAccessToken]);

  useEffect(() => {
    if (accessToken && isRetreivedAccessToken && isAuthenticated && ethereumAddresses) {
      setIsEthereumAddressHasBeenSetup(ethereumAddresses.length > 0);
    }
  }, [accessToken, ethereumAddresses, isAuthenticated, isRetreivedAccessToken]);

  // Get and set orders
  useEffect(() => {
    async function exec() {
      if (accessToken && isRetreivedAccessToken && isAuthenticated) {
        const _orders = await getOrders(accessToken);
        setOrders(_.orderBy(_orders.orders, 'createdAt', 'desc'));
      }
    }
    exec();
  }, [accessToken, isAuthenticated, isRetreivedAccessToken]);

  useEffect(() => {
    if (scrolls.current) {
      if (scrolls.current.scrollWidth > scrolls.current.clientWidth) {
        scrolls.current.classList.add('cursor-grab');
      } else {
        scrolls.current.classList.remove('cursor-grab');
      }
    }

    window.addEventListener('resize', () => {
      // check horizontal overflow
      if (scrolls.current) {
        if (scrolls.current.scrollWidth > scrolls.current.clientWidth) {
          scrolls.current.classList.add('cursor-grab');
        } else {
          scrolls.current.classList.remove('cursor-grab');
        }
      }
    });
  }, []);

  if (scrolls.current) {
    if (scrolls.current.scrollWidth > scrolls.current.clientWidth) {
      scrolls.current.classList.add('cursor-grab');
    } else {
      // console.log(scrolls.current.scrollWidth, scrolls.current.clientWidth);
      scrolls.current.classList.remove('cursor-grab');
    }
  }

  useEffect(() => {
    let a = document.querySelector(':root') as HTMLElement;
    let ethernetAddressesLength;
    if (ethereumAddresses) {
      ethereumAddresses?.length >= 5 ? (ethernetAddressesLength = '5') : (ethernetAddressesLength = (ethereumAddresses?.length + 1).toString());
    }
    if (ethernetAddressesLength) {
      // console.log(ethernetAddressesLength);
      a.style.setProperty('--card-quantity', ethernetAddressesLength);
    }
  }, [ethereumAddresses]);

  useEffect(() => {
    let r = document.querySelector(':root') as HTMLElement;
    if (footer.current) {
      r.style.setProperty('--footer-h', footer.current.offsetHeight + 'px');
    }
  });

  useEffect(() => {
    async function exec() {
      if (accessToken && isRetreivedAccessToken && isAuthenticated && countryOptions.length > 0) {
        const _user = await getUser(accessToken);
        setUser(_user);
        setBillingAddress(_user.billingAddress);
        const _ethereumAddresses = _user.ethereumAddresses.map((addr) => ({
          value: addr.address,
          label: addr.address,
        }));
        setEthereumAddresses(_ethereumAddresses);
        setDefaultEthereumAddress(_user.defaultEthereumAddress);
        setFormEthaddress(_user.defaultEthereumAddress.address);
        // console.log(defaultEthereumAddress?.address, ethereumAddresses[0]);
      }
    }
    exec();
  }, [accessToken, countryOptions, isAuthenticated, isRetreivedAccessToken]);

  const getUserProfile = async () => {
    const url = new URL(`/reseller/profile`, `${config.api.endpoint}`);
    if (accessToken) {
      const result = await axios.get(url.href, { headers: { Authorization: `Bearer ${accessToken}` } });
      console.log({ result });
      setCode(result.data.code);
      setEdns(result.data.edns);
      setUSDT(result.data.usdt);
      setRecord(result.data.records);
      return result;
    }
  };

  // console.log(record);

  const getResellerProfile = async () => {
    const url = new URL(`/reseller/request/user`, `${config.api.endpoint}`);
    let result;
    if (accessToken) {
      try {
       result = await axios.get(url.href, { headers: { Authorization: `Bearer ${accessToken}` } });
      // console.log(result.data.status);
        if (result.data.status === 'PENDING') {
          navigate('/reseller?action=intro&type=submit');
        } else if (result.data.status === 'APPROVED') {
          await getUserProfile();
          navigate('/reseller');
        } else {
          navigate('/reseller?action=intro');
        }
      }catch (e) {
        navigate('/reseller?action=intro');
      }

      return result;
    }
  };
  useEffect(() => {
    getResellerProfile();
  }, [accessToken]);

  return (
    <>
      <Nav title="Reseller Program" />
      <div className="flex h-full w-full gap-10 p-10 px-6 pt-6 md:px-10 overflow-y-auto">
        <div data-show={query.get('action') === 'join' || query.get('action') === 'intro'} className="reseller-con-outer absolute top-0 left-0 z-[500] h-full w-full">
          <div className="absolute left-0 top-0 h-full w-full bg-gray-500/20" onClick={() => query.get('type') === 'submit' && navigate('/reseller')}></div>
          <div
            data-show={query.get('action') === 'join' || query.get('action') === 'intro'}
            data-intro={query.get('action') === 'intro'}
            data-type={query.get('action')}
            data-action={query.get('type')}
            className="reseller-con box-shadow flex flex-col rounded-xl border p-10 pt-8"
          >
            <p className="text-2xl font-medium">
              {query.get('type') === 'individual' && 'Individual'}
              {query.get('type') === 'corporate' && 'Corporate'}
              {query.get('action') === 'join' && query.get('type') === null && 'Reseller type'}
              {query.get('action') === 'intro' && query.get('type') === null && 'Reseller Program'}
            </p>
            {query.get('action') === 'intro' && query.get('type') !== 'submit' && query.get('type') !== 'code' && (
              <div className="flex flex-1 flex-col justify-between space-y-3">
                <p className="mt-5">You have not joined the EDNS Reseller Program.</p>
                <div
                  className="w-full cursor-pointer text-right text-xs text-slate-500 underline-offset-1 hover:underline"
                  onClick={() => {
                    window.open('https://www.edns.domains/reseller-program', '_blank')?.focus();
                  }}
                >
                  About The Reseller Program
                </div>
                <Link to="/reseller?action=join" className="w-full rounded-lg bg-[#93278f] py-4 text-center text-lg font-medium text-white">
                  Register now
                </Link>
              </div>
            )}
            {query.get('action') === 'join' && query.get('type') !== 'submit' && (
              <>
                <div className="flex flex-1 flex-col">
                  <div className="mt-5 flex w-full gap-5">
                    <Link
                      to="/reseller?action=join&type=individual"
                      className={`${
                        query.get('type') === 'individual' ? 'bg-[#93278f] text-white' : ''
                      } box-shadow flex flex-1 cursor-pointer flex-col items-center justify-between rounded-md border pt-5 duration-200 hover:scale-105 hover:bg-[#93278f] hover:text-white`}
                    >
                      <p className="mb-5 flex flex-1 items-center text-lg font-medium">Individual</p>
                    </Link>
                    <Link
                      to="/reseller?action=join&type=corporate"
                      className={`${
                        query.get('type') === 'corporate' ? 'bg-[#93278f] text-white' : ''
                      } box-shadow flex flex-1 cursor-pointer flex-col items-center justify-between rounded-md border pt-5 duration-200 hover:scale-105 hover:bg-[#93278f] hover:text-white`}
                    >
                      <p className="mb-5 flex flex-1 items-center text-lg font-medium">Corporate</p>
                    </Link>
                  </div>
                  <div className="reseller-info-con mt-10">
                    {(query.get('type') === 'individual' || query.get('type') === 'corporate') && (
                      <div className="flex flex-1 flex-col gap-4">
                        <span className="text-[#809FB8]">Address</span>
                        {ethereumAddresses?.length ? (
                          <Select
                            options={ethereumAddresses}
                            styles={customStyles}
                            defaultValue={
                              defaultEthereumAddress !== null
                                ? ethereumAddresses[ethereumAddresses.findIndex((e) => e.value === defaultEthereumAddress?.address)]
                                : ethereumAddresses[0]
                            }
                            className="w-full"
                            onChange={(e) => {
                              if (e) {
                                // console.log(e?.value)
                                setFormEthaddress(e?.value);
                              }
                            }}
                          />
                        ) : null}
                      </div>
                    )}

                    {query.get('type') === 'individual' && (
                      <div className="flex flex-1 flex-col gap-4">
                        <span className="text-[#809FB8]">First Name *</span>
                        <div>
                          <motion.input
                            defaultValue={individualInfo?.firstname}
                            required
                            className="w-full rounded-md border border-gray-300 p-5 outline-blue-500"
                            placeholder="First Name"
                            value={individualInfo?.firstname}
                            onChange={(e) => {
                              setIndividualInfo({ ...individualInfo, firstname: e.currentTarget.value } as IindividualInfo);
                            }}
                          ></motion.input>
                        </div>
                      </div>
                    )}
                    {query.get('type') === 'individual' && (
                      <div className="flex flex-1 flex-col gap-4">
                        <span className="text-[#809FB8]">Last Name *</span>
                        <div>
                          <input
                            defaultValue={individualInfo?.lastname}
                            required
                            className="w-full rounded-md border border-gray-300 p-5 outline-blue-500"
                            placeholder="Last Name"
                            value={individualInfo?.lastname}
                            onChange={(e) => {
                              setIndividualInfo({ ...individualInfo, lastname: e.currentTarget.value } as IindividualInfo);
                            }}
                          ></input>
                        </div>
                      </div>
                    )}

                    {query.get('type') === 'individual' && (
                      <div className="flex flex-col gap-4">
                        <span className="text-[#809FB8]">Email *</span>
                        <div>
                          <input
                            defaultValue={individualInfo?.email}
                            type="email"
                            required
                            className="w-full rounded-md border border-gray-300 p-5 outline-blue-500"
                            placeholder="Email"
                            value={individualInfo?.email}
                            onChange={(e) => {
                              setIndividualInfo({ ...individualInfo, email: e.currentTarget.value } as IindividualInfo);
                            }}
                          ></input>
                        </div>
                      </div>
                    )}
                    {query.get('type') === 'individual' && (
                      <div className="flex gap-5">
                        <div className="flex flex-col gap-4">
                          <span className="text-[#809FB8]">Passport *</span>
                          <div>
                            <input
                              required
                              type="file"
                              className="w-full rounded-md border border-gray-300 p-5 outline-blue-500"
                              onChange={(e) => {
                                if (e.target.files) {
                                  setPassport(e.target.files[0]);
                                }
                              }}
                            ></input>
                          </div>
                        </div>
                        <div className="flex flex-col gap-4">
                          <span className="text-[#809FB8]">Selfie *</span>
                          <div>
                            <input
                              required
                              type="file"
                              className="w-full rounded-md border border-gray-300 p-5 outline-blue-500"
                              onChange={(e) => {
                                if (e.target.files) {
                                  setSelfie(e.target.files[0]);
                                }
                              }}
                            ></input>
                          </div>
                        </div>
                      </div>
                    )}

                    {query.get('type') === 'corporate' && (
                      <div className={`${query.get('type') === 'corporate' ? 'flex' : 'hidden'} flex-[1] flex-col gap-4`}>
                        <span className="text-[#809FB8]">Company Name *</span>
                        <div>
                          <input
                            defaultValue={corporateInfo?.name}
                            required
                            className="w-full rounded-md border border-gray-300 p-5 outline-blue-500"
                            placeholder="Company Name"
                            value={corporateInfo?.name}
                            onChange={(e) => {
                              setCorporateInfo({ ...corporateInfo, name: e.currentTarget.value } as IcoperateInfo);
                            }}
                          ></input>
                        </div>
                      </div>
                    )}
                    {query.get('type') === 'corporate' && (
                      <div className={`${query.get('type') === 'corporate' ? 'flex' : 'hidden'} flex-[1] flex-col gap-4`}>
                        <span className="text-[#809FB8]">Company Phone Number *</span>
                        <div>
                          <input
                            defaultValue={corporateInfo?.phone}
                            required
                            className="w-full rounded-md border border-gray-300 p-5 outline-blue-500"
                            placeholder="Company Phone Number"
                            value={corporateInfo?.phone}
                            onChange={(e) => {
                              setCorporateInfo({ ...corporateInfo, phone: e.currentTarget.value } as IcoperateInfo);
                            }}
                          ></input>
                        </div>
                      </div>
                    )}
                    {query.get('type') === 'corporate' && (
                      <div className={`${query.get('type') === 'corporate' ? 'flex' : 'hidden'} flex-[1] flex-col gap-4`}>
                        <span className="text-[#809FB8]">Company Address *</span>
                        <div>
                          <input
                            defaultValue={corporateInfo?.address}
                            required
                            className="w-full rounded-md border border-gray-300 p-5 outline-blue-500"
                            placeholder="Company Address"
                            value={corporateInfo?.address}
                            onChange={(e) => {
                              setCorporateInfo({ ...corporateInfo, address: e.currentTarget.value } as IcoperateInfo);
                            }}
                          ></input>
                        </div>
                      </div>
                    )}
                    {query.get('type') === 'corporate' && (
                      <div className={`${query.get('type') === 'corporate' ? 'flex' : 'hidden'} flex-[1] flex-col gap-4`}>
                        <span className="text-[#809FB8]">Company email *</span>
                        <div>
                          <input
                            defaultValue={corporateInfo?.email}
                            required
                            className="w-full rounded-md border border-gray-300 p-5 outline-blue-500"
                            placeholder="Company Email"
                            value={corporateInfo?.email}
                            onChange={(e) => {
                              setCorporateInfo({ ...corporateInfo, email: e.currentTarget.value } as IcoperateInfo);
                            }}
                          ></input>
                        </div>
                      </div>
                    )}
                    {query.get('type') === 'corporate' && (
                      <div className={`${query.get('type') === 'corporate' ? 'flex' : 'hidden'} flex-[1] flex-col gap-4`}>
                        <span className="text-[#809FB8]">Bank Of Statment *</span>
                        <div>
                          <input
                            required
                            type="file"
                            className="w-full rounded-md border border-gray-300 p-5 outline-blue-500"
                            onChange={(e) => {
                              if (e.target.files) {
                                setBankOfStatment(e.target.files[0]);
                              }
                            }}
                          ></input>
                        </div>
                      </div>
                    )}
                    {query.get('type') === 'corporate' && (
                      <div className="flex gap-5">
                        <div className="flex flex-col gap-4">
                          <span className="text-[#809FB8]">Business Registration *</span>
                          <div>
                            <input
                              required
                              type="file"
                              className="w-full rounded-md border border-gray-300 p-5 outline-blue-500"
                              onChange={(e) => {
                                if (e.target.files) {
                                  setBusinessReg(e.target.files[0]);
                                }
                              }}
                            ></input>
                          </div>
                        </div>
                        <div className="flex flex-col gap-4">
                          <span className="text-[#809FB8]">Certificate Of Incorporation *</span>
                          <div>
                            <input
                              required
                              type="file"
                              className="w-full rounded-md border border-gray-300 p-5 outline-blue-500"
                              onChange={(e) => {
                                if (e.target.files) {
                                  setCertOfIn(e.target.files[0]);
                                }
                              }}
                            ></input>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {query.get('type') !== null && query.get('type') !== 'submit' && (
                  <button
                    onClick={() => {
                      if (
                        (formEthaddress && individualInfo?.firstname && individualInfo.lastname && individualInfo.email && passport && selfie) ||
                        (formEthaddress && corporateInfo?.name && corporateInfo.email, corporateInfo?.address, corporateInfo?.phone && businessReg && certOfIn && bankOfStatment)
                      ) {
                        navigate('/reseller?action=join&type=submit');
                        setReseller(true);
                        createRequest();
                      } else {
                        //to-do: Roy please add warning message trigger here
                        alert('One of the required field is not filled!');
                      }
                    }}
                    className="mt-10 rounded-lg bg-[#93278f] py-5 text-lg font-medium text-white"
                  >
                    Submit
                  </button>
                )}
              </>
            )}
            {query.get('type') === 'submit' && (
              <div data-show={query.get('type') === 'submit'} className="pending-con disabled flex flex-1 flex-col items-center justify-center">
                <Icon className="h-auto w-24 text-green-500" icon="teenyicons:tick-circle-solid" />
                <p className="mt-10 text-2xl font-medium">Application Submitted</p>
                <p className="mx-auto mt-5 max-w-[368px] text-center font-light text-gray-600">
                  Your application has been received and we will get back to you with further instructions when the Reseller Program officially launches.<br></br>Thank you!
                </p>
              </div>
            )}
            {query.get('type') === 'code' && (
              <div data-show={query.get('type') === 'submit'} className="flex flex-1 flex-col">
                <p className="text-2xl font-medium">Reseller Program</p>
                <div className="flex-1">
                  <p className="mt-5 flex items-center justify-between">
                    <p>Reseller Code</p>
                    {/* <p className="text-xs text-[#93278f]">Credit: 100</p> */}
                  </p>
                  <input className="reseller-code mt-2 w-full rounded-md border p-3" value={code} disabled></input>
                </div>
                <button
                  onClick={(event) => {
                    const e = event.target as HTMLElement;
                    e.classList.add('copy');
                    const _code = document.querySelector('.reseller-code') as HTMLInputElement;
                    _code.select();
                    _code.setSelectionRange(0, 99999);
                    navigator.clipboard.writeText(code);
                  }}
                  className="btn-reseller grid place-items-center rounded-md bg-[#93278f] py-4 text-base font-medium text-white duration-200 hover:bg-[#7a2077] active:bg-[#8b2488]"
                >
                  <Icon icon="charm:tick" className="h-auto w-6" />
                  <p>Copy</p>
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="flex max-w-full flex-1 flex-col rounded-xl h-fit border p-6 md:p-10">
          <div className="flex flex-col gap-4">
            <p className="text-2xl font-medium">EDNS Rewards Gained: </p>
            <div className="flex gap-5 flex-wrap">
              <div className="w-full md:w-[200px] shrink-0 rounded-lg border py-4 px-10 shadow-sm">
                <img src={tokenImage} className="mx-auto aspect-square w-16 rounded-full" />
                <p className="mx-auto mt-2 w-fit">EDNS TOKEN</p>
                <p className="text-center">{edns}</p>
              </div>
              <div className="w-full md:w-[200px] shrink-0 rounded-lg border py-4 px-10 shadow-sm">
                <img src={tether} className="mx-auto aspect-square w-16 rounded-full" />
                <p className="mx-auto mt-2 w-fit">USDT TOKEN</p>
                <p className="text-center">{usdt}</p>
              </div>
            </div>
            <p>
              *Please note Domains successfully redeemed with reseller code by invitee: 5 $EDNS per domain up to 500 $EDNS cap per reseller (code is only valid once for each
              account). Your EDNS Rewards will be distributed monthly.{' '}
            </p>
          </div>

          <div className="mt-5 flex max-w-[300px] flex-col gap-4">
            <div className="relative flex-1">
              <p className="flex max-w-[300px] items-center justify-between ">
                <p className="text-2xl font-medium">Reseller Code</p>

                {/* <p className="text-xs text-[#93278f]">Credit: 100</p> */}
              </p>

              <input className="reseller-code mt-6 w-full rounded-md border p-3" value={code} disabled></input>
              <button
                onClick={() => {
                  (document.querySelector('.btn-reseller') as HTMLButtonElement).classList.add('copy');
                  setTimeout(() => {
                    (document.querySelector('.btn-reseller') as HTMLButtonElement).classList.remove('copy');
                  }, 5000);
                  const code = document.querySelector('.reseller-code') as HTMLInputElement;
                  code.select();
                  code.setSelectionRange(0, 99999);
                  navigator.clipboard.writeText(`${window.location.origin}/account?resellerCode=${code.value}`);
                }}
                className="btn-reseller absolute right-[14px] bottom-[14px] grid place-items-center rounded-md text-sm font-medium text-gray-400 outline-none duration-200 hover:text-[#93278f]"
              >
                <Icon icon="charm:tick" className="h-auto w-5" />
                <p>Copy</p>
              </button>
            </div>
          </div>
          <p className="mt-10 flex w-[300px] items-center justify-between text-2xl font-medium">
            <p>Record</p>
            {/* <p className="text-xs font-normal">Point: 100</p> */}
          </p>
          <div className=" mt-6 ">
            <div className="flex justify-between gap-5 rounded-md border p-5 py-4" style={{ width: 'auto' }}>
              <p className="truncate font-medium">Type</p>
              <p className="truncate font-medium">Order Date</p>
              <p className="font-medium" style={{ width: '200px' }}>
                {' '}
                Reward
              </p>
            </div>
          </div>
          {record && record.length !== 0 ? (
            record.map((v) => (
              <div className=" mt-6 ">
                <div className="flex justify-between gap-5 rounded-md border p-5 py-4" style={{ width: 'auto' }}>
                  <p className="truncate font-medium">{v.type}</p>
                  <p className="truncate font-medium">{v.createdAt}</p>
                  <p className="text-green-500" style={{ width: '200px' }}>
                    +{v.point} {v.type == 'CODE' ? '$EDNS' : '$USDT'}
                  </p>
                </div>
              </div>
            ))
          ) : (
            <>
              <div className="grid flex-1 place-items-center">
                <div className="w-full">
                  <img src={notFound} className="mx-auto w-[40%]"></img>
                  <p className="mx-auto mt-8 w-fit text-2xl font-medium">Record not found</p>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default withAuthenticationRequired(Reseller, {
  onRedirecting: () => <div className="load-center"></div>,
});
